<div class="center" *ngIf="result && !measurementsLoading && !documentsLoading; else elseBlock">
  <header class="header result-header" i18n>Vielen Dank für das Ausfüllen des Fragebogens!</header>
  <ion-icon src="assets/svgs/mg_questionnaire_success.svg" class="result-icon"></ion-icon>

  <p class="result-text ion-no-padding" i18n>
    Durch eine gesunde und aktive Lebensweise wird sich Deine Gesundheit langfristig verbessern.
  </p>
  <div *ngIf="result.survey_id !== 'wellbeing'" class="buttons">
    <ng-content select="[pro-questionnaire-result-button]"></ng-content>
  </div>

  <div *ngIf="result.survey_id === 'wellbeing'" class="buttons">
    <ion-button expand="block" [routerLink]="['']" data-cy="complete-sv-btn" i18n
      >Beenden</ion-button
    >
  </div>
</div>

<ng-template #elseBlock>
  <ion-progress-bar
    *ngIf="measurementsLoading || documentsLoading"
    type="indeterminate"
  ></ion-progress-bar>
  <pro-error *ngIf="measurementsError && !measurementsLoading">
    <h1 header i18n>Leider konnten Deine Antworten nicht ausgewertet werden.</h1>
    <div style="width: 100%; padding-bottom: 25px" footer>
      <ng-content select="[pro-questionnaire-try-again-button]"></ng-content>
      <ng-content select="[pro-questionnaire-cancel-button]"></ng-content>
    </div>
  </pro-error>
</ng-template>
