import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'pro-six-lead-ecg-info',
  templateUrl: './six-lead-ecg-info.component.html',
  styleUrls: ['./six-lead-ecg-info.component.scss'],
})
export class SixLeadEcgInfoComponent {
  @Input() type: 'image' | 'checkbox' = 'image';
  hideEcgInfo = false;

  constructor(private popoverController: PopoverController) {}

  dismiss(role: 'close' | 'confirm') {
    this.popoverController.dismiss({ hideEcgInfo: this.hideEcgInfo }, role);
  }
}
