import { Action } from '@ngrx/store';
import * as fromBm from '../bm.reducer';
import * as Bm54Actions from './bm54.actions';

export const bm54sFeatureKey = 'bm54';

export type State = fromBm.State;

export const initialState = fromBm.getInitialState('BM54');

const bm54Reducer = fromBm.getReducer(Bm54Actions, initialState);

export function reducer(state: State | undefined, action: Action) {
  return bm54Reducer(state, action);
}

export const { selectFeature, selectIds, selectEntities, selectAll, selectTotal, selectAddress } =
  fromBm.getSelectors(bm54sFeatureKey);
