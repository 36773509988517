import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { BmResultBloodPressure } from 'app/app-store/devices-store/devices.model';
import {
  ObservationResultValue,
  ObservationResultBloodPressure,
  ResultValue,
  ResultBloodPressure,
  ResultWeight,
} from 'app/models';
import { Po60ResultValues } from 'app/app-store/devices-store/devices.model';

@Component({
  selector: 'pro-device-result-label',
  templateUrl: './device-result-label.component.html',
  styleUrls: ['./device-result-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceResultLabelComponent {
  @Input() result:
    | ResultValue
    | ResultWeight
    | ResultBloodPressure
    | Po60ResultValues
    | BmResultBloodPressure
    | ObservationResultValue
    | ObservationResultBloodPressure;

  constructor() {}
}
