import { createAction, props } from '@ngrx/store';
import { Message } from 'app/models';

export const loadMessages = createAction('[Messages/API] Load Messages');

export const loadMessagesSuccess = createAction(
  '[Messages/API] Load Messages Success',
  props<{ messages: Message[]; learningMessageCategories: string[] }>()
);

export const loadMessagesFailure = createAction(
  '[Messages/API] Load Messages Failure',
  props<{ error: any }>()
);

export const loadMessage = createAction('[Messages/API] Load Message', props<{ id: string }>());

export const loadMessageSuccess = createAction(
  '[Messages/API] Load Message Success',
  props<{ message: Message }>()
);

export const loadMessageFailure = createAction(
  '[Messages/API] Load Message Failure',
  props<{ error: any }>()
);

export const startPollingMessages = createAction('[Messages] Start Polling Messages');

export const stopPollingMessages = createAction('[Messages] Stop Polling Messages');

export const loadPollingMessages = createAction('[Messages/API] Load Polling Messages');

export const loadPollingMessagesSuccess = createAction(
  '[Messages/API] Load Polling Messages Success',
  props<{ messages: Message[]; learningMessageCategories: string[] }>()
);

export const loadPollingMessagesFailure = createAction(
  '[Messages/API] Load Polling Messages Failure',
  props<{ error: any }>()
);
