import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BluetoothLE } from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { DevicesEffects } from './devices.effects';
import * as fromDevices from './devices.reducer';
import { BmStoreModule } from './bm-store/bm-store.module';
import { Po60StoreModule } from './po60-store/po60-store.module';
import { Bf720StoreModule } from './bf720-store/bf720-store.module';
import { DevicesErrorEffects } from './devices-error.effects';
import { BluetoothPermissionInfoComponent } from './bluetooth-permission-info/bluetooth-permission-info.component';
import { SdkAboutScreenComponent } from './sdk-about-screen/sdk-about-screen.component';
import { DevicesStoreSharedModule } from './devices-store-shared/devices-store-shared.module';

@NgModule({
  declarations: [BluetoothPermissionInfoComponent, SdkAboutScreenComponent],
  imports: [
    CommonModule,
    IonicModule,
    StoreModule.forFeature(fromDevices.devicesFeatureKey, fromDevices.reducer),
    EffectsModule.forFeature([DevicesEffects, DevicesErrorEffects]),
    BmStoreModule,
    Po60StoreModule,
    Bf720StoreModule,
    DevicesStoreSharedModule,
  ],
  providers: [BluetoothLE, Diagnostic],
})
export class DevicesStoreModule {}
