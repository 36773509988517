import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromMedicatonIntakes from './medication-intakes.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MedicationIntakesEffects } from './medication-intakes.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromMedicatonIntakes.medicationsFeatureKey,
      fromMedicatonIntakes.reducer
    ),
    EffectsModule.forFeature([MedicationIntakesEffects]),
  ],
})
export class MedicationIntakesStoreModule {}
