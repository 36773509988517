import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'pro-grace-subscription-info',
  templateUrl: './grace-subscription-info.component.html',
  styleUrls: ['./grace-subscription-info.component.scss'],
})
export class GraceSubscriptionInfoComponent {
  supportPhone = $localize`+49 9191 95 04 311`;
  supportPhoneAriaLabel = $localize`Anruf an ${this.supportPhone} starten`;
  supportEmailAddress = $localize`support@procarement.com`;
  supportEmailAriaLabel = $localize`E-Mail an ${this.supportEmailAddress} schreiben`;

  constructor(public popoverController: PopoverController) {}
}
