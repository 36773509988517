import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, from, throwError, combineLatest } from 'rxjs';
import { first, map, switchMap, catchError } from 'rxjs/operators';
import { Device } from '@capacitor/device';
import { environment } from 'environments/environment';

@Injectable()
export class DeviceInfoInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([from(Device.getInfo()), from(Device.getId())]).pipe(
      first(),
      switchMap(([deviceInfo, deviceId]) => {
        let deviceInfoRequest = request;

        if (deviceInfo && deviceId) {
          // adds the device-info headers
          deviceInfoRequest = request.clone({
            setHeaders: {
              ['device-id']: deviceId.identifier,
              ['device-platform']: deviceInfo.platform,
              ['device-model']: deviceInfo.model,
              ['device-model-manufacturer']: deviceInfo.manufacturer,
              ['device-os']: deviceInfo.platform,
              ['device-os-version']: deviceInfo.osVersion,
              ['app-version']: environment.version,
            },
          });
        }

        return next.handle(deviceInfoRequest).pipe(
          map((event: HttpEvent<any>) => {
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(() => error);
          })
        );
      })
    );
  }
}
