import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { MedicalContact } from 'app/models';
import * as MedicalContactsActions from './medical-contacts.actions';
import * as fromMedicalContacts from './medical-contacts.reducer';

@Injectable({
  providedIn: 'root',
})
export class MedicalContactsFacade {
  loading$ = this.store.pipe(select(fromMedicalContacts.selectLoading));
  loaded$ = this.store.pipe(select(fromMedicalContacts.selectLoaded));
  medicalContacts$ = this.store.pipe(select(fromMedicalContacts.selectAll));

  constructor(private store: Store<fromMedicalContacts.State>) {}

  loadMedicalContacts() {
    this.store.dispatch(MedicalContactsActions.loadMedicalContacts());
  }

  updateMedicalContact(medicalContact: MedicalContact) {
    this.store.dispatch(MedicalContactsActions.updateMedicalContact({ medicalContact }));
  }

  createMedicalContact(medicalContact: MedicalContact) {
    this.store.dispatch(MedicalContactsActions.createMedicalContact({ medicalContact }));
  }

  deleteMedicalContact(medicalContactId: number) {
    this.store.dispatch(MedicalContactsActions.deleteMedicalContact({ medicalContactId }));
  }
}
