import { Directive, OnInit, Renderer2, OnDestroy, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';

@Directive({
  selector: '[proHorizontalStepper]',
})
export class HorizontalStepperDirective implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private stepper: MatStepper, private renderer: Renderer2) {}

  ngOnInit() {
    this.stepper.selectionChange.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((c) => {
      this.addStepHeaderClass(c.selectedIndex);
    });
  }

  ngAfterViewInit() {
    this.addStepHeaderClass(this.stepper.selectedIndex);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private addStepHeaderClass(selectedIndex: number) {
    const stepHeaders = Array.from(document.getElementsByClassName('mat-step-header'));
    const previousSteps = stepHeaders.filter((_, i) => i < selectedIndex);

    // remove selected and previous-step class
    stepHeaders.forEach((element) => {
      this.renderer.removeClass(element, 'pro-step-header-selected');
      this.renderer.removeClass(element, 'pro-step-header-previous-step');
    });

    // add selected and previous-step class
    this.renderer.addClass(stepHeaders[selectedIndex], 'pro-step-header-selected');
    previousSteps.forEach((el) => this.renderer.addClass(el, 'pro-step-header-previous-step'));
  }
}
