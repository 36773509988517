import { Pipe, PipeTransform } from '@angular/core';
import { AppStoreFacade } from 'app/app-store/app-store-facade/app-store.facade';
import { filter, first, map, catchError } from 'rxjs/operators';
import { FormOptions } from '../../models/api.model';
import { of, Observable } from 'rxjs';
import { has } from 'lodash-es';
import { find } from 'lodash-es';

@Pipe({
  name: 'formOptionsTranslate',
})
export class FormOptionsTranslatePipe implements PipeTransform {
  formOptions: FormOptions;

  constructor(public facade: AppStoreFacade) {}

  transform(value: string, ...args: string[]): Observable<any> {
    return this.facade.formOptions$.pipe(
      filter((o) => o !== null),
      first(),
      map((formOptions): any => {
        if (has(formOptions, args.join('.')) === false) {
          return of(value);
        }
        const resObj = args.reduce(
          (currObj: Record<string, string>, key): any =>
            currObj && currObj[key] ? currObj[key] : null,
          formOptions
        );
        const target = find(resObj, { id: value });
        return target && target.name ? target.name : value;
      }),
      catchError(() => of(value))
    );
  }
}
