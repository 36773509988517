import { Action, createReducer, createSelector, createFeatureSelector, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResultWeight, DeviceInformation } from 'app/models';
import { UserListB720, DeviceType, DeviceEntityState } from '../devices.model';
import * as Bf720Actions from './bf720.actions';
import * as DeviceActions from '../devices.actions';

export const bf720sFeatureKey = 'bf720';

export interface State extends DeviceEntityState<ResultWeight> {
  address: string | null;
  name: 'BF720';
  type: DeviceType;
  userList: UserListB720[] | null;
  userIndex: number | null;
  code: number | null;
  responseValue: string | null;
  loading: boolean;
  lastResults: ResultWeight[];
  transferring: boolean;
  scanning: boolean;
  deviceInformation: DeviceInformation | null;
  error: Error | string | null;
}

export function selectResultId(r: ResultWeight): string {
  return `${r.type}_${r.device_time}`;
}

export const adapter: EntityAdapter<ResultWeight> = createEntityAdapter<ResultWeight>({
  selectId: selectResultId,
});

export const initialState: State = adapter.getInitialState({
  address: null,
  name: 'BF720',
  type: DeviceType.Scale,
  userList: null,
  userIndex: null,
  code: null,
  responseValue: null,
  loading: false,
  lastResults: [],
  transferring: false,
  scanning: false,
  deviceInformation: {
    manufacturer: 'Beurer',
    model: 'BF720',
    software_version: '',
    hardware_version: '',
    firmware_version: '',
  },
  error: null,
});

const bf720Reducer = createReducer(
  initialState,
  on(Bf720Actions.loadMeasurementsSubscribed, (state, action) =>
    adapter.upsertMany(action.results, { ...state, deviceInformation: action.deviceInformation })
  ),
  on(Bf720Actions.loadMeasurementsSuccess, (state, action) =>
    adapter.upsertMany(action.results, {
      ...state,
      lastResults: action.lastResults,
      transferring: false,
      scanning: true,
      deviceInformation: action.deviceInformation,
    })
  ),
  on(Bf720Actions.addState, (state, action) => ({ ...action.state, type: DeviceType.Scale })),
  on(Bf720Actions.startScanning, (state) => ({ ...state, scanning: true })),
  on(DeviceActions.startScanManualMeasurements, (state) => ({
    ...state,
    transferring: true,
  })),
  on(Bf720Actions.loadUserList, (state, action) => ({
    ...state,
    address: action.address,
    transferring: true,
    error: null,
  })),
  on(Bf720Actions.loadUserListSuccess, (state, action) => ({
    ...state,
    userList: action.userList,
  })),
  on(Bf720Actions.loginUser, Bf720Actions.createUser, (state) => ({
    ...state,
    responseValue: null,
    loading: true,
    error: null,
  })),
  on(Bf720Actions.createUserSuccess, (state, action) => ({
    ...state,
    userList: [
      ...state.userList,
      {
        index: action.userIndex,
        initials: `P0${action.userIndex}`,
        birthdate: '1991-10-01',
        height: 165,
        gender: 1,
        activityLevel: 3,
      },
    ],
  })),
  on(
    Bf720Actions.loginUserSuccess,
    Bf720Actions.loginUserFailed,
    Bf720Actions.createUserSuccess,
    Bf720Actions.createUserFailed,
    (state, action) => ({
      ...state,
      responseValue: action.responseValue,
      loading: false,
    })
  ),
  on(Bf720Actions.loginUserSuccess, (state, action) => ({
    ...state,
    userIndex: action.userIndex,
    code: action.code,
    error: null,
  })),
  on(Bf720Actions.deleteDeviceSuccess, () => ({
    ...initialState,
  })),
  on(
    Bf720Actions.showConsentCode,
    Bf720Actions.createUserSuccess,
    Bf720Actions.writeCurrentTimeSuccess,
    Bf720Actions.writeScaleSettingsSuccess,
    Bf720Actions.writeGenderSuccess,
    Bf720Actions.writeScaleSettingsSuccess,
    Bf720Actions.changeDatabaseIncrementSuccess,
    Bf720Actions.loadMeasurementsSuccess,
    DeviceActions.startScan,
    (state) => ({
      ...state,
      error: null,
    })
  ),
  on(Bf720Actions.initialisingError, (state, action) => ({
    ...initialState,
    error: action.error,
  })),
  on(Bf720Actions.transferringError, (state, action) => ({
    ...state,
    error: action.error,
  })),
  on(Bf720Actions.updateLastResults, (state, action) => ({
    ...state,
    lastResults: action.lastResults,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return bf720Reducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('bf720');

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectAddress = createSelector(selectFeature, (state) => state.address);

export const selectUserList = createSelector(selectFeature, (state) => state.userList);

export const selectResponseValue = createSelector(selectFeature, (state) => state.responseValue);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);

export const selectTransferring = createSelector(selectFeature, (state) => state.transferring);

export const selectError = createSelector(selectFeature, (state) => state.error);

export const selectUserIndex = createSelector(selectFeature, (state) => state.userIndex);

export const selectCode = createSelector(selectFeature, (state) => state.code);

export const selectUser = createSelector(selectUserList, selectUserIndex, (userList, userIndex) =>
  userList && userIndex ? userList.find((u) => u.index === userIndex) : null
);

export const selectUserInitials = createSelector(selectUser, (user) =>
  user ? user.initials : null
);
