import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromMonitoringGoals from './monitoring-goals.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MonitoringGoalsEffects } from './monitoring-goals.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromMonitoringGoals.monitoringGoalsFeatureKey,
      fromMonitoringGoals.reducer
    ),
    EffectsModule.forFeature([MonitoringGoalsEffects]),
  ],
})
export class MonitoringGoalsStoreModule {}
