import {
  createFeatureSelector,
  createReducer,
  on,
  resultMemoize,
  createSelector,
  createSelectorFactory,
} from '@ngrx/store';
import { Dictionary, EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { AnyFn } from '@ngrx/store/src/selector';
import { DocumentCategory } from 'app/models';
import * as fromRouterSelectors from 'store/router-store/router.selectors';
import * as DocumentCategoriesActions from './document-categories.actions';
import { isEqual } from 'lodash-es';

export const documentCategoriesFeatureKey = 'documentCategories';

export interface State extends EntityState<DocumentCategory> {
  loading: boolean;
  error: Error | null;
}

const selectIdKey = (a: DocumentCategory) => {
  return a.key;
};

export const adapter: EntityAdapter<DocumentCategory> = createEntityAdapter<DocumentCategory>({
  selectId: selectIdKey,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});

export const reducer = createReducer(
  initialState,
  on(DocumentCategoriesActions.loadDocumentsCategories, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(DocumentCategoriesActions.loadDocumentsCategoriesSuccess, (state, action) =>
    adapter.upsertMany(action.documentsCategories, { ...state, loading: false })
  ),
  on(DocumentCategoriesActions.loadDocumentsCategoriesFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }))
);

export const customMemoized = (projectorFn: AnyFn) => resultMemoize(projectorFn, isEqual);

export const selectFeature = createFeatureSelector<State>('documentCategories');

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);

export const selectError = createSelector(selectFeature, (state) => state.error);

export const selectReportStationaryCategory = createSelectorFactory<State, DocumentCategory>(
  customMemoized
)(selectEntities, (categories: Dictionary<DocumentCategory>) => categories['report_stationary']);

export const selectReportAmbulantCategory = createSelectorFactory<State, DocumentCategory>(
  customMemoized
)(selectEntities, (categories: Dictionary<DocumentCategory>) => categories['report_ambulant']);

export const selectLaboratoryCategory = createSelectorFactory<State, DocumentCategory>(
  customMemoized
)(selectEntities, (categories: Dictionary<DocumentCategory>) => categories['laboratory']);

export const selectEcgCategory = createSelectorFactory<State, DocumentCategory>(customMemoized)(
  selectEntities,
  (categories: Dictionary<DocumentCategory>) => categories['ecg']
);

export const selectEcgResultCategory = createSelectorFactory<State, DocumentCategory>(
  customMemoized
)(selectEntities, (categories: Dictionary<DocumentCategory>) => categories['ecg_result']);

export const selectImagingCategory = createSelectorFactory<State, DocumentCategory>(customMemoized)(
  selectEntities,
  (categories: Dictionary<DocumentCategory>) => categories['imaging']
);

export const selectOtherCategory = createSelectorFactory<State, DocumentCategory>(customMemoized)(
  selectEntities,
  (categories: Dictionary<DocumentCategory>) => categories['other']
);

export const selectProcarementCategory = createSelectorFactory<State, DocumentCategory>(
  customMemoized
)(selectEntities, (categories: Dictionary<DocumentCategory>) => categories['procarement']);

export const selectQuestionnaireCategory = createSelectorFactory<State, DocumentCategory>(
  customMemoized
)(selectEntities, (categories: Dictionary<DocumentCategory>) => categories['questionnaire']);

export const selectRightsAndDisposalCategory = createSelectorFactory<State, DocumentCategory>(
  customMemoized
)(selectEntities, (categories: Dictionary<DocumentCategory>) => categories['rights_and_disposals']);

export const selectQuarterlyTmcReportCategory = createSelectorFactory<State, DocumentCategory>(
  customMemoized
)(selectEntities, (categories: Dictionary<DocumentCategory>) => categories['quarterly_tmc_report']);

export const selectEheartImplantReportCategory = createSelectorFactory<State, DocumentCategory>(
  customMemoized
)(
  selectEntities,
  (categories: Dictionary<DocumentCategory>) => categories['eheart_implant_report']
);

export const selectDocumentsCount = createSelector(
  selectEntities,
  fromRouterSelectors.selectQueryParam('category'),
  (entities, category) => entities[category]?.document_count
);
