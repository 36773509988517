import { Document } from 'app/models';
import { TestDataFactory, RANDOM_STRING, RANDOM_NUMBER } from './test-data.factory';

export class DocumentFactory extends TestDataFactory<Document> {
  constructor() {
    const generatorFunction = () => {
      const today = new Date();
      const date = `${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

      return {
        category: 'imaging',
        created_at: date + 'T16:13:57',
        description: RANDOM_STRING.getOne(8),
        files: [],
        id: RANDOM_NUMBER.getOne(1111, 9999),
        original_date: date,
        readonly: false,
        status: 'new',
      } as Document;
    };
    super(generatorFunction);
  }
}
