import { Action, createAction, props } from '@ngrx/store';
import {
  MeasurementNormalized,
  ObservationResults,
  ObservationResult,
  ObservationResultBloodPressure,
  ObservationResultEcg,
  ObservationResultSurvey,
  ObservationResultWeight,
  AddMeasurementsResponse,
  MonitoringGoal,
} from 'app/models';

export const loadMeasurements = createAction(
  '[Measurements/API] Load Measurements',
  props<{ from: string; to?: string }>()
);

export const loadMeasurementsSuccess = createAction(
  '[Measurements/API] Load Measurements Success',
  props<{
    measurements: MeasurementNormalized[];
    results: (
      | ObservationResult
      | ObservationResultWeight
      | ObservationResultBloodPressure
      | ObservationResultEcg
      | ObservationResultSurvey
    )[];
    minDate: string;
  }>()
);

export const loadMeasurementsFailure = createAction(
  '[Measurements/API] Load Measurements Failure',
  props<{ error: any }>()
);

export const loadMeasurementsComplete = createAction(
  '[Measurements Intake/API] Load Measurements Complete',
  props<{ action: Action }>()
);

export const addMeasurements = createAction(
  '[Measurement Results/API] Add Measurements',
  props<{ results: ObservationResults }>()
);

export const addMeasurementsSuccess = createAction(
  '[Measurement Results/API] Add Measurements Success',
  props<{
    data: AddMeasurementsResponse;
    entities: Array<MonitoringGoal>;
  }>()
);

export const addMeasurementsFailure = createAction(
  '[Measurement Results/API] Add Measurements Failure',
  props<{ error: any }>()
);

export const addMeasurementsPartialSuccess = createAction(
  '[Measurement Results/API] Add Measurements Partial Success',
  props<{
    data: AddMeasurementsResponse;
  }>()
);

export const addEcgMeasurementsPartialSuccess = createAction(
  '[Measurement Results/API] Add Ecg Measurements Partial Success',
  props<{
    data: AddMeasurementsResponse;
  }>()
);

export const addEcgMeasurementsSuccess = createAction(
  '[Measurement Results/API] Add Ecg Measurements Success',
  props<{
    data: AddMeasurementsResponse;
    entities: Array<MonitoringGoal>;
  }>()
);
