import { Activation } from 'app/models';
import * as dayjs from 'dayjs';
import { TestDataFactory, RANDOM_STRING } from './test-data.factory';

export class ActivationFactory extends TestDataFactory<Activation> {
  constructor() {
    const generatorFunction = (): Activation => {
      const today = dayjs();

      return {
        code: 'PH-' + RANDOM_STRING.getOne(8),
        start_date: today.subtract(3, 'week').format('YYYY-MM-DD'),
        end_date: today.add(4, 'week').format('YYYY-MM-DD'),
        status: 'active',
        category: 'test',
      };
    };

    super(generatorFunction);
  }
}
