import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { QuestionnaireListComponent } from './questionnaire-list/questionnaire-list.component';

import { SharedModule } from 'app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { QuestionnaireRoutingModule } from './questionnaire-routing.module';
import { SurveyComponent } from './survey/survey.component';
import { QuestionnaireSurveyComponent } from './questionnaire-survey/questionnaire-survey.component';
import { QuestionnaireResultComponent } from './questionnaire-result/questionnaire-result.component';
import { ResultIconComponent } from './result-icon/result-icon.component';
import { QuestionnaireCompletedComponent } from './questionnaire-completed/questionnaire-completed.component';
import { HelplineComponent } from './helpline/helpline.component';
import { QuestionnaireInformationComponent } from './questionnaire-information/questionnaire-information.component';

@NgModule({
  declarations: [
    QuestionnaireListComponent,
    SurveyComponent,
    QuestionnaireSurveyComponent,
    QuestionnaireResultComponent,
    ResultIconComponent,
    QuestionnaireCompletedComponent,
    HelplineComponent,
    QuestionnaireInformationComponent,
  ],
  imports: [CommonModule, QuestionnaireRoutingModule, IonicModule, SharedModule, FormsModule],
})
export class QuestionnaireModule {}
