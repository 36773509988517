import { TestDataFactory, RANDOM_NUMBER, RANDOM_STRING } from './test-data.factory';
import { MedicationIntake } from 'app/models';

export class MedicationIntakeFactory extends TestDataFactory<MedicationIntake> {
  constructor() {
    const generatorFunction = (): MedicationIntake => {
      const medId = RANDOM_NUMBER.getOne(30, 220);
      // dayjs imports not working currently in cypress-tests
      // use Date instead
      // const date = dayjs().format('YYYY-MM-DD');
      const today = new Date();
      const date = `${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

      return {
        rate: 'daily',
        medication_id: medId,
        status: 'UNKNOWN',
        date,
        part_of_day: 'morning',
        dose: RANDOM_NUMBER.getOne(5).toString(),
        unit: RANDOM_STRING.getOne(2),
        amount: RANDOM_NUMBER.getOne(5).toString(),
        form: RANDOM_STRING.getOne(5),
        chemical_agent: RANDOM_STRING.getOne(5),
        product_name: RANDOM_STRING.getOne(5),
        uuid: `${medId}|${date}|morning`,
        note: '',
        route: RANDOM_STRING.getOne(5),
      };
    };

    super(generatorFunction);
  }
}
