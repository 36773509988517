<div
  class="icon-badge"
  [ngClass]="{'small': small}"
>
  <ion-icon
    src="assets/svgs/pill.svg"
    color="medium"
  ></ion-icon>
</div>

<div
  class="icon-badge level"
  [ngClass]="{'small': small}"
  #levelIndicator
>
  <ion-icon
    src="assets/svgs/pill.svg"
    color="white"
  ></ion-icon>
</div>