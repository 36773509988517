import { Component, ChangeDetectionStrategy, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'pro-list-actions',
  templateUrl: './list-actions.component.html',
  styleUrls: ['./list-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListActionsComponent {
  @Input() hideEdit = false;
  @Input() hideDelete = false;

  @Output() edit: EventEmitter<boolean> = new EventEmitter();
  @Output() delete: EventEmitter<boolean> = new EventEmitter();

  emitEdit() {
    this.edit.emit();
  }

  emitDelete() {
    this.delete.emit();
  }
}
