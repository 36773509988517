import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ActivationsActions from './activations.actions';
import { ApiService } from 'store/api/api.service';

@Injectable()
export class ActivationsEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadActivations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivationsActions.loadActivations, ActivationsActions.addActivationSuccess),
      switchMap(() =>
        this.apiService.getActivations().pipe(
          map((data) =>
            ActivationsActions.loadActivationsSuccess({
              activations: data.results,
              currentStatus: data.current_status,
            })
          ),
          catchError((error) => of(ActivationsActions.loadActivationsFailure({ error })))
        )
      )
    );
  });

  addActivation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivationsActions.addActivation),
      switchMap((action) =>
        this.apiService.addActivation(action.code, action.cleanPatientData).pipe(
          map((activation) => ActivationsActions.addActivationSuccess({ activation })),
          catchError((error) => of(ActivationsActions.addActivationFailure({ error })))
        )
      )
    );
  });
}
