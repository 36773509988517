import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthFacade } from 'store/auth-store/auth.facade';
import { first } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'pro-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.css'],
})
export class ConfirmPasswordComponent implements OnInit {
  @Input() textContent: string;

  passwordForm = this.fb.group({
    password: ['', [Validators.required]],
  });
  hidePassword = true;
  loading = false;
  errorMsg = '';

  constructor(
    private fb: FormBuilder,
    private authFacade: AuthFacade,
    private modalController: ModalController
  ) {}

  ngOnInit(): void {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required]],
    });
  }

  /**
   * displays / hides passworts
   */
  togglePassword() {
    this.hidePassword = !this.hidePassword;
  }

  /**
   * check password
   */
  checkPassword() {
    const password = this.passwordForm.value.password;
    this.loading = true;

    this.authFacade
      .checkPassword(password)
      .pipe(first())
      .subscribe(
        () => {
          this.loading = false;
          this.modalController.dismiss({ password });
        },
        (err) => {
          this.loading = false;
          this.errorMsg =
            err?.status === 401
              ? $localize`Ungültiges Passwort. Bitte versuche es erneut.`
              : $localize`Ein Fehler ist aufgetreten.`;
        }
      );
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}
