import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'pro-app-subscriptions-info',
  templateUrl: './app-subscriptions-info.component.html',
  styleUrls: ['./app-subscriptions-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSubscriptionsInfoComponent {
  @Input() isAuthenticated = true;

  constructor(public popoverController: PopoverController) {}
}
