import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, map, skipWhile, concatMap } from 'rxjs/operators';
import { OpenPlannedSurveyMeasurement } from 'app/models';
import { SurveyFacade } from 'store/survey-store/survey.facade';
import { isOfType } from '../../utils/type-guards';
import { MonitoringGoalsFacade } from 'store/monitoring-goals-store/monitoring-goals.facade';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireGuard implements CanActivate {
  constructor(
    private monitoringGoalsFacade: MonitoringGoalsFacade,
    private surveyFacade: SurveyFacade,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.monitoringGoalsFacade.getOpenMeasurements('SurveyGoal').pipe(
      first(),
      concatMap((openMeasurements) =>
        openMeasurements?.length &&
        isOfType<OpenPlannedSurveyMeasurement[]>(openMeasurements, 'survey_id')
          ? this.getUrlTree(openMeasurements)
          : of(this.router.createUrlTree(['app/overview/dashboard']))
      ),
      map((r) => r)
    );
  }

  private getUrlTree(measurements: OpenPlannedSurveyMeasurement[]) {
    if (measurements.length > 1) {
      return of(true);
    } else if (this.surveyFacade.getSurvey(measurements[0].survey_id)) {
      return of(this.router.createUrlTree([`/app/questionnaire/${measurements[0].survey_id}`]));
    } else {
      this.surveyFacade.loadSurvey(measurements[0].survey_id);
      return this.surveyFacade.loading$.pipe(
        skipWhile((l) => l),
        first(),
        map(() => this.surveyFacade.getSurvey(measurements[0].survey_id)),
        map((s) =>
          s ? this.router.createUrlTree([`/app/questionnaire/${measurements[0].survey_id}`]) : false
        )
      );
    }
  }
}
