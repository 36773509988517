import { RANDOM_NUMBER, TestDataFactory } from './test-data.factory';
import {
  ObservationResult,
  ObservationResultBloodPressure,
  ObservationResultEcg,
  ObservationResultSurvey,
  ObservationResultWeight,
} from 'app/models';

export class MeasurementResultFactory extends TestDataFactory<
  | ObservationResult
  | ObservationResultWeight
  | ObservationResultBloodPressure
  | ObservationResultEcg
  | ObservationResultSurvey
> {
  constructor() {
    const generatorFunction = () => {
      return {} as
        | ObservationResult
        | ObservationResultWeight
        | ObservationResultBloodPressure
        | ObservationResultEcg
        | ObservationResultSurvey;
    };

    super(generatorFunction);
  }

  getOne(
    override: Partial<
      | ObservationResult
      | ObservationResultWeight
      | ObservationResultBloodPressure
      | ObservationResultEcg
      | ObservationResultSurvey
    > = {}
  ):
    | ObservationResult
    | ObservationResultWeight
    | ObservationResultBloodPressure
    | ObservationResultEcg
    | ObservationResultSurvey {
    const today = new Date();
    const date = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    const randomState = ['good', 'fair', 'critical'][RANDOM_NUMBER.getOne(0, 2)];
    const id = RANDOM_NUMBER.getOne(30, 220);
    const resultType = override?.type;
    const device_time = date + 'T16:13:57';
    const created_at = date + 'T16:14:07';

    if (resultType === 'blood_pressure') {
      const systolic = RANDOM_NUMBER.getOne(70, 300);
      const diastolic = RANDOM_NUMBER.getOne(1, systolic - 1);

      return super.getOne({
        id,
        type: 'blood_pressure',
        state: randomState,
        device_time,
        created_at,
        systolic,
        diastolic,
        ...override,
      });
    } else if (resultType === 'ecg') {
      return super.getOne({
        id,
        type: 'ecg',
        state: null,
        device_time,
        created_at,
        document_id: RANDOM_NUMBER.getOne(170, 2300),
        ...override,
      });
    } else {
      const value =
        resultType === 'oxygen_saturation'
          ? RANDOM_NUMBER.getOne(80, 100)
          : resultType === 'temperature'
          ? +`${RANDOM_NUMBER.getOne(32, 42)}.${RANDOM_NUMBER.getOne(0, 10)}`
          : resultType === 'weight'
          ? +`${RANDOM_NUMBER.getOne(50, 80)}.${RANDOM_NUMBER.getOne(0, 10)}`
          : RANDOM_NUMBER.getOne(50, 140); // heart_rate

      return super.getOne({
        id,
        type: resultType || 'heart_rate',
        state: randomState,
        device_time,
        created_at,
        value,
        ...override,
      });
    }
  }
}
