import { createAction, props } from '@ngrx/store';
import { SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { ActivatedRouteSnapshot, Params } from '@angular/router';

export const showHttpErrorMessage = createAction(
  '[App] Show Http Error Message',
  props<{ error: HttpErrorResponse }>()
);

export const showErrorMessage = createAction('[App] Show Error Message');

export const hideHttpErrorMessage = createAction('[App] Hide Http Error Message');

export const changeRouterState = createAction(
  '[App] Change Router State',
  props<{
    animation: 'page' | 'modal';
    previousRouteSnapshot: SerializedRouterStateSnapshot;
  }>()
);

export const resetCache = createAction('[App] Reset Cache');

export const resetCacheSuccess = createAction('[App] Reset Cache Success');

export const deleteCacheEntry = createAction(
  '[App] Delete Cache Entry',
  props<{ url: string; cache?: Map<string, HttpRequest<unknown>> }>()
);

export const deleteCacheEntrySuccess = createAction(
  '[App] Delete Cache Entry Success',
  props<{ url: string }>()
);

export const navigate = createAction('[App] Navigate', props<{ commands: any[]; delay: number }>());

export const suppressRedirect = createAction('[App] Supress Redirect');

export const showGraceSubscriptionPopup = createAction('[App] Show Grace Subscription Popup');
