import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { BluetoothLE } from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { of, from, Subject } from 'rxjs';
import {
  withLatestFrom,
  switchMap,
  map,
  catchError,
  concatMap,
  mergeMap,
  takeUntil,
  delay,
} from 'rxjs/operators';
import { differenceBy } from 'lodash-es';
import { Po60Service } from './po60.service';
import { BluetoothHelperService } from './../bluetooth-helper.service';
import { isInvalidDate } from '../devices.helper';
import * as Po60Actions from './po60.actions';
import * as fromPo60 from './po60.reducer';
import * as fromDevices from '../devices.reducer';

@Injectable()
export class Po60Effects {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private actions$: Actions,
    private store: Store<fromPo60.State | fromDevices.State>,
    private bluetoothLe: BluetoothLE,
    private bluetoothHelper: BluetoothHelperService,
    private po60: Po60Service
  ) {}

  addState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Po60Actions.addState),
      map((action) =>
        !action.state.address ? Po60Actions.addStateSuccess() : Po60Actions.startScanning()
      )
    );
  });

  loadDeviceInformation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Po60Actions.loadDeviceInformation),
      mergeMap((action) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          concatMap(() => this.bluetoothHelper.connectReconnectDiscover(action.address)),
          switchMap(() =>
            this.bluetoothHelper
              .getDeviceInformation(action.address)
              .pipe(
                map((deviceInformation) =>
                  Po60Actions.loadDataStorage({ address: action.address, deviceInformation })
                )
              )
          ),
          catchError((error) => of(Po60Actions.loadDeviceInformationFailure({ error }))),
          takeUntil(this.ngUnsubscribe$)
        )
      )
    );
  });

  loadDataStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Po60Actions.loadDataStorage),
      mergeMap((action) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          concatMap(() => this.bluetoothHelper.isConnectedConnectDiscover(action.address)),
          switchMap(() =>
            this.po60.getDataStorageInfo(action.address).pipe(
              map(() => {
                return Po60Actions.loadDataStorageSubscribedResult({
                  address: action.address,
                });
              })
            )
          ),
          catchError((e) =>
            of(e).pipe(
              withLatestFrom(this.store.pipe(select(fromPo60.selectTransferring))),
              map(([error, isTransferring]) =>
                isTransferring
                  ? Po60Actions.loadDataStorageFailure({ error })
                  : Po60Actions.loadDataStorageDisconnected()
              )
            )
          ),
          takeUntil(this.ngUnsubscribe$)
        )
      )
    );
  });

  setTime$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Po60Actions.loadMeasurementsSuccess),
      withLatestFrom(this.store.pipe(select(fromPo60.selectAddress))),
      mergeMap(([, address]) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          switchMap(() =>
            this.po60.setTime(address).pipe(
              map(() => {
                return Po60Actions.setTimeSuccess({ address });
              })
            )
          ),
          catchError((error) => of(Po60Actions.setTimeFailure({ error }))),
          takeUntil(this.ngUnsubscribe$)
        )
      )
    );
  });

  loadMeasurements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Po60Actions.loadMeasurements),
      mergeMap((action) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          concatMap(() => this.bluetoothHelper.isConnectedConnectDiscover(action.address)),
          switchMap(() =>
            this.po60.getMeasurementData(action.address).pipe(
              withLatestFrom(this.store.pipe(select(fromPo60.selectAll))),
              map(([r, entities]) => {
                const diff = differenceBy(r.data, entities, (m) => {
                  return m.device_time;
                });
                return r.isLastGroup
                  ? Po60Actions.loadMeasurementsSuccess({
                      results: r.data,
                      lastResults: diff.filter(
                        (m, i) => !isInvalidDate(m.device_time) || i === diff.length - 1
                      ),
                      deviceInformation: {
                        manufacturer: 'Beurer',
                        model: 'PO60',
                        software_version: '',
                        hardware_version: '',
                        firmware_version: '',
                      },
                    })
                  : Po60Actions.loadMeasurementsSubscribedResult();
              })
            )
          ),
          catchError((e) =>
            of(e).pipe(
              withLatestFrom(this.store.pipe(select(fromPo60.selectTransferring))),
              map(([error, isTransferring]) =>
                isTransferring
                  ? Po60Actions.loadMeasurementsFailure({ error })
                  : Po60Actions.loadMeasurementsDisconnected()
              )
            )
          ),
          takeUntil(this.ngUnsubscribe$)
        )
      )
    );
  });

  disconnectError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        Po60Actions.loadDeviceInformationFailure,
        Po60Actions.loadDataStorageFailure,
        Po60Actions.setTimeFailure,
        Po60Actions.loadMeasurementsFailure
      ),
      withLatestFrom(
        this.store.pipe(select(fromDevices.selectInitialising)),
        this.store.pipe(select(fromPo60.selectAddress))
      ),
      map(([action, initialising, address]) =>
        initialising
          ? Po60Actions.initialisingError({ error: action.error, address })
          : Po60Actions.transferringError({ error: action.error })
      )
    );
  });

  unsubscribePO60$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Po60Actions.loadDataStorageSubscribedResult),
      withLatestFrom(this.store.pipe(select(fromPo60.selectAddress))),
      switchMap(([action, address]) =>
        from(
          this.bluetoothLe.unsubscribe({
            address: address ? address : action.address,
            service: 'FF12',
            characteristic: 'FF02',
          })
        ).pipe(
          map(() => Po60Actions.unsubscribeSuccess()),
          catchError((error) => {
            return of(Po60Actions.unsubscribeFailure({ error }));
          })
        )
      )
    );
  });

  deleteDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Po60Actions.deleteDevice),
      withLatestFrom(this.store.select(fromPo60.selectAddress)),
      map(([, address]) => {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        return Po60Actions.deleteDeviceSuccess({ address });
      })
    );
  });

  disconnectDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        Po60Actions.deleteDeviceSuccess,
        Po60Actions.disconnectDevice,
        Po60Actions.initialisingError,
        Po60Actions.setTimeSuccess
      ),
      delay(3000),
      switchMap((action) =>
        this.bluetoothHelper.disconnectClose(action.address).pipe(
          map(() =>
            action.type === Po60Actions.setTimeSuccess.type
              ? Po60Actions.startScanning()
              : Po60Actions.disconnectDeviceSuccess()
          ),
          catchError((error) => of(Po60Actions.disconnectDeviceFailure({ error })))
        )
      )
    );
  });
}
