import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'pro-biometric-icon',
  template: `
    <ion-icon
      *ngIf="biometricType === 'Face ID'"
      src="assets/svgs/face-id.svg"
      class="face-icon"
    ></ion-icon>

    <ion-icon
      *ngIf="biometricType !== 'Face ID'"
      name="finger-print-outline"
      class="touch-icon"
    ></ion-icon>
  `,
  styles: [
    `
      :host {
        display: flex;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BiometricIconComponent {
  @Input() biometricType: 'Face ID' | 'Touch ID' = 'Touch ID';
}
