/* eslint-disable @typescript-eslint/no-this-alias */
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import * as d3 from 'd3';
import { isEqual, flatten } from 'lodash-es';
import { BaseChartComponent } from '../base-chart/base-chart.component';
import { ChartPage, GraphPoint } from '../chart.component';

@Component({
  selector: 'pro-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent extends BaseChartComponent implements OnChanges {
  /**
   * Minimum, averagae and minimum values over all items
   */
  @Input() extremas: GraphPoint[] = [];

  /**
   * Background lines and areas to illustrate value-ranges
   */
  @Input() indicators: {
    areas: {
      top: number;
      bottom: number;
      color: string;
    }[];
    lines: number[];
    unit: string;
  } = null;

  /**
   * d3-generator for creating graph-paths
   */
  graphLineGenerator = d3
    .line<{ x: number; y: number }>()
    .x((d) => {
      return this.xScale(d.x);
    })
    .y((d) => {
      return this.yScale(d.y);
    })
    .curve(d3.curveCatmullRom);

  constructor(elRef: ElementRef) {
    super(elRef);

    this.conf = {
      ...this.conf,
      rightAxisWitdh: 50,
    };
  }

  ngOnChanges(c: SimpleChanges) {
    super.ngOnChanges(c);
    // Forces a redraw of the current page if the extremas have changed
    if (c?.extremas?.currentValue) {
      const currentPage = this.renderedPages[this.currentPage];
      this.updateScaleFunctions();
      this.renderedPages[this.currentPage] = null;
      this.drawPage(currentPage);
    }
    // Redraws background elements und right axis if identifier, extremas or indicators change
    if (
      c?.identifier?.currentValue !== c?.identifier?.previousValue ||
      c?.extremas?.currentValue ||
      c?.indicators?.currentValue
    ) {
      this.drawBackgound();
      this.drawRightAxis();
    }
  }

  setupChart() {
    super.setupChart();
    // Daws background elements und right axis
    this.drawBackgound();
    this.drawRightAxis();
    this.svgRightAxis
      ?.transition()
      .duration(this.conf.defaultAnimationDuration)
      .style('opacity', 1);
  }

  initSVG() {
    const that = this;
    super.initSVG();

    // Adds a click-handler to the chart-svg to close the active tooltip
    d3.select('#chartSVG').on('click', function () {
      that.hideTooltip();
    });

    // Creates and appenda a svg and a group for the right axis
    this.svgRightAxis = d3
      .select(this.hostElement)
      .append('svg')
      .attr('id', 'rightAxisSVG')
      .attr('width', this.conf.rightAxisWitdh)
      .attr('height', this.conf.svgHeight)
      .attr('viewBox', `0 0 ${this.conf.rightAxisWitdh} ${this.conf.svgHeight}`)
      .style('opacity', 0);

    this.rightAxisGroup = this.svgRightAxis
      .append('g')
      .attr('class', 'right-axis-group')
      .attr('width', this.conf.rightAxisWitdh);
  }

  updateScaleFunctions() {
    super.updateScaleFunctions();

    // Uses second (dystolic) minimun as global minimun
    this.extremas.length === 2
      ? this.yScale.domain([this.extremas[1]?.min, this.extremas[0]?.max])
      : this.yScale.domain([this.extremas[0]?.min, this.extremas[0]?.max]);
  }

  /**
   * Invokes methods to draw a single page, except the page is drawn already
   * Adds a new page to the stored pages array
   * @param chartPage page to draw
   */
  drawPage(chartPage: ChartPage) {
    if (!chartPage) return;

    super.drawPage(chartPage);
    if (!isEqual(chartPage, this.renderedPages[chartPage.pageNumber])) {
      this.renderedPages[chartPage.pageNumber] = chartPage;
      this.drawGraph(chartPage);
      this.drawMarker(chartPage);
    }
  }

  /**
   * Gets graph-paths and draws graph path-elements to the corresponding page-group.
   * Updates and deletes path-elements if graph-data change
   * @param chartPage Page to draw the graph from
   */
  drawGraph(chartPage: ChartPage) {
    const that = this;

    this.pagesGroup
      .select(`.page-${chartPage.pageNumber} .graph-container`)
      .selectAll('.line')
      .data(this.createGraphPaths(chartPage))
      .join(
        (enter) => {
          return enter
            .append('path')
            .attr('class', 'line')
            .style('opacity', 0)
            .attr('d', that.graphLineGenerator);
        },
        (update) => {
          return update
            .transition()
            .duration(this.conf.defaultAnimationDuration)
            .attr('d', that.graphLineGenerator)
            .style('opacity', 1);
        },
        (exit) => {
          return exit
            .transition()
            .duration(this.conf.defaultAnimationDuration)
            .style('opacity', 0)
            .remove();
        }
      )
      .attr('d', that.graphLineGenerator)
      .style('fill', 'none')
      .style('stroke-width', that.conf.graphStrokeWidth)
      .style('stroke', that.conf.graphStrokeColor)
      .style('opacity', 1);
  }

  /**
   * Generates array of paths used by the generator to create graph-lines
   * @param chartPage page to draw the graph(s) for
   */
  createGraphPaths(chartPage: ChartPage) {
    const paths: {
      x: number;
      y: number;
    }[][] = [];

    let currentPath: {
      x: number;
      y: number;
    }[] = [];
    let isDrawing = false;

    // Calculates the point left between two items
    const getLeftBorderValue = (itemIndex: number, graphIndex: number) => {
      return (
        (chartPage.items[itemIndex - 1].graphPoints[graphIndex].avg +
          chartPage.items[itemIndex].graphPoints[graphIndex].avg) /
        2
      );
    };

    // Adds a point to the current path
    const addGraphPoint = (x: number, y: number) =>
      currentPath.push({
        x,
        y,
      });

    // Closes and clears the current path
    const finishPath = () => {
      isDrawing = false;
      paths.push(currentPath);
      currentPath = [];
    };

    // Creates paths for every chart-line
    this.extremas.map((_, graphIndex) => {
      chartPage.items.map((item, i) => {
        // First item on page
        if (i === 0) {
          // Starts a new path
          currentPath = [];

          if (item.showItem) {
            isDrawing = true;
            // Adds a left-border-point, expect for the last page
            if (chartPage.pageNumber !== this.totalPages) {
              addGraphPoint(i, chartPage.leftBorderValue[graphIndex]);
            }
            // Adds the current point
            addGraphPoint(i + 0.5, item.graphPoints[graphIndex].avg);
          }
        }
        // Last item on page
        else if (i === chartPage.items.length - 1) {
          if (item.showItem) {
            if (!isDrawing) {
              // Adds a left-border-point
              addGraphPoint(i, getLeftBorderValue(i, graphIndex));
            }
            // Adds the current point
            addGraphPoint(i + 0.5, item.graphPoints[graphIndex].avg);
            // Add the right-border-point, expect for the first page
            if (chartPage.pageNumber !== 1) {
              addGraphPoint(i + 1, chartPage.rightBorderValue[graphIndex]);
            }
            finishPath();
          } else {
            if (isDrawing) {
              // Adds a left-border-point
              addGraphPoint(i, getLeftBorderValue(i, graphIndex));
              finishPath();
            }
          }
        }
        // Item within page
        else {
          if (item.showItem) {
            if (!isDrawing) {
              // Adds a left-border-point
              addGraphPoint(i, getLeftBorderValue(i, graphIndex));
              isDrawing = true;
            }
            // Adds the current point
            addGraphPoint(i + 0.5, item.graphPoints[graphIndex].avg);
          } else {
            if (isDrawing) {
              // Adds a left-border-point
              addGraphPoint(i, getLeftBorderValue(i, graphIndex));
              finishPath();
            }
          }
        }
      });
    });
    return paths;
  }

  /**
   * Draws marker path-elements to the corresponding page-group.
   * Updates and deletes path-elements if graph-data change
   * @param chartPage Page to draw the markers from
   */
  drawMarker(chartPage: ChartPage) {
    const that = this;

    // Creates a d3.path to draw a marker
    const createMarkerPath = (
      min: number,
      max: number,
      index: number,
      radius: number,
      showItem = true
    ) => {
      if (!showItem) {
        return d3.path();
      }
      const path = d3.path();
      const xPosition = this.xScale(index + 0.5);

      path.moveTo(xPosition - radius, this.yScale(max));
      path.arc(xPosition, this.yScale(max), radius, 3.14, 0);
      path.lineTo(xPosition + radius, this.yScale(min));
      path.arc(xPosition, this.yScale(min), radius, 0, 3.14);

      path.closePath();
      return path;
    };

    // Calculates the max available click-area radius
    const clickAreaWidth = Math.floor(this.conf.graphWidth / this.itemsPerPage / 2);
    // Collects d3.paths for all markes on the page
    const allMarkerPaths: d3.Path[][] = flatten(
      this.extremas.map(
        (
          _,
          graphIndex // Blood-Pressure has two graphs
        ) =>
          chartPage.items.map((item, i) => {
            const points = item.graphPoints[graphIndex];
            return [
              createMarkerPath(points.min, points.max, i, 6, item.showItem), // inner marker
              createMarkerPath(points.min, points.max, i, 3, item.showItem), // outer marker
              createMarkerPath(points.min, points.max, i, clickAreaWidth, item.showItem), // Click-area
            ];
          })
      )
    );

    // Create, updates and deletes marker path-elements
    this.pagesGroup
      .select(`.page-${chartPage.pageNumber} .marker-container`)
      .selectAll('g')
      .data(allMarkerPaths.reverse())
      .join(
        (enter) => {
          const marker = enter
            .append('g')
            .style('opacity', 0)
            .attr(
              'class',
              (_, i) => `marker-item item-${that.itemsPerPage - (i % that.itemsPerPage) - 1}` // reverse order, count backwards
            );

          marker
            .append('path')
            .attr('d', (markerPath) => markerPath[0].toString())
            .attr('class', 'outer-marker')
            .style('fill', this.conf.markerBackgroundColor);

          marker
            .append('path')
            .attr('d', (markerPath) => markerPath[1].toString())
            .attr('class', 'inner-marker')
            .style('fill', this.conf.markerDefaultColor);

          // Increases the clickable area for a marker
          marker
            .append('path')
            .attr('d', (markerPath) => markerPath[2].toString())
            .attr('class', 'click-area')
            .attr('fill', 'transparent');

          return marker;
        },
        (update) => {
          update.select('.outer-marker').attr('d', (markerPath) => markerPath[0].toString());
          update.select('.inner-marker').attr('d', (markerPath) => markerPath[1].toString());
          update.select('.click-area').attr('d', (markerPath) => markerPath[2].toString());

          return update.style('opacity', 1);
        },
        (exit) => {
          return exit
            .transition()
            .duration(this.conf.defaultAnimationDuration)
            .style('opacity', 0)
            .remove();
        }
      )
      .style('opacity', 1);

    // Adds click-handler for every marker
    this.pagesGroup
      .selectAll(`.page-${chartPage.pageNumber} .marker-container .marker-item`)
      .raise()
      .on('click', (event: PointerEvent) => {
        event.preventDefault();
        event.stopPropagation();

        const clickedIndex = Math.floor(event.offsetX / (this.conf.graphWidth / this.itemsPerPage));
        that.showTooltip(clickedIndex);
      });
  }

  /**
   * Adds a right-axis including a result-unit-label and some key-value-labels
   */
  drawRightAxis() {
    const that = this;
    const rightAxisGroup = this.svgRightAxis.select('.right-axis-group');

    // Vertical marker-lines
    const linePoints = this.indicators.lines.map((yValue) => [
      [5, that.yScale(yValue)],
      [11, that.yScale(yValue)],
    ]);

    // Generator for horizontal lines
    const lineGenerator = d3
      .line<number[]>()
      .x((d) => d[0])
      .y((d) => d[1]);

    // Adds, updates and deletes horizontal lines
    rightAxisGroup
      .selectAll('.horizontal-line')
      .data(linePoints)
      .join(
        (enter) => {
          return enter.append('path').attr('d', lineGenerator).style('opacity', 0);
        },
        (update) => {
          return update
            .transition()
            .duration(this.conf.defaultAnimationDuration)
            .attr('d', lineGenerator)
            .style('opacity', 1);
        },
        (exit) => {
          return exit.remove();
        }
      )
      .attr('stroke', that.conf.graphStrokeColor)
      .attr('stroke-width', 2)
      .attr('fill', 'none')
      .style('opacity', 1)
      .attr('class', 'horizontal-line');

    // Adds, updates and deletes value labels
    rightAxisGroup
      .selectAll('.label')
      .data(that.indicators.lines)
      .join(
        (enter) => {
          return enter
            .append('text')
            .text((labelY) => labelY.toString())
            .attr('y', (labelY) => that.yScale(labelY) + 4)
            .style('opacity', 0);
        },
        (update) => {
          return update
            .transition()
            .duration(this.conf.defaultAnimationDuration)
            .text((labelY) => labelY.toString())
            .attr('y', (labelY) => that.yScale(labelY) + 4)
            .style('opacity', 1);
        },
        (exit) => {
          return exit.remove();
        }
      )
      .attr('class', 'label')
      .attr('fill', this.conf.graphStrokeColor)
      .attr('x', 15)
      .attr('text-anchor', 'left')
      .attr('font-family', 'sans-serif')
      .attr('font-size', '12px')
      .attr('font-weight', 'bold')
      .style('opacity', 1);

    // Adds a unit-label
    if (rightAxisGroup.select('.unit-label').empty()) {
      rightAxisGroup
        .append('text')
        .text(that.indicators.unit)
        .attr('class', 'unit-label')
        .attr('fill', this.conf.graphStrokeColor)
        .attr('text-anchor', 'left')
        .attr('x', 4)
        .attr('y', 14)
        .attr('font-family', 'sans-serif')
        .attr('font-size', '11px')
        .attr('font-weight', 'bold');
    }
  }
  /**
   * Adds horizontal background lines and colored areas to illustrate value-ranges
   */
  drawBackgound() {
    const that = this;
    // Gets points to create the horizontal-lines
    const linePoints = this.indicators?.lines?.map((yValue) => [
      [0, yValue],
      [this.itemsPerPage, yValue],
    ]);

    // Adds, updates and deletes horizontal line elements
    this.backgroundGroup
      .select('.lines')
      .selectAll('.horizontal-line')
      .data(linePoints || [])
      .join(
        (enter) => {
          return enter.append('path').attr('d', that.lineGenerator).style('opacity', 0);
        },
        (update) => {
          return update
            .transition()
            .duration(this.conf.defaultAnimationDuration)
            .attr('d', that.lineGenerator)
            .style('opacity', 1);
        },
        (exit) => {
          return exit
            .transition()
            .duration(this.conf.defaultAnimationDuration)
            .style('opacity', 0)
            .remove();
        }
      )
      .attr('class', 'horizontal-line')
      .attr('stroke', this.conf.gridColor)
      .attr('fill', 'none')
      .style('opacity', 1);

    // Background areas
    // Gets absolute minimum value to correct areas height
    const totalMin = this.extremas?.length
      ? this.extremas.length === 1
        ? this.extremas[0].min
        : this.extremas[1].min
      : 0;

    // Calculates corrected height-value to avoid drawining outside the svg
    const getAreaHeight = (area: { top: number; bottom: number; color: string }) => {
      const correctedBottom = area.bottom < totalMin ? totalMin : area.bottom;
      const correctedTop = area.top < totalMin ? totalMin : area.top;
      const height = that.yScale(correctedBottom) - that.yScale(correctedTop);
      return height < 0 ? 0 : height;
    };

    // Adds, updates and deletes rect-elements for the background-areas
    this.backgroundGroup
      .select('.areas')
      .selectAll('.indicator-area')
      .data(this.indicators.areas)
      .join(
        (enter) => {
          return enter
            .append('rect')
            .attr('y', (area) => that.yScale(area.top))
            .attr('height', (area) => getAreaHeight(area))
            .style('opacity', 0);
        },
        (update) => {
          return update
            .transition()
            .duration(this.conf.defaultAnimationDuration)
            .attr('y', (area) => that.yScale(area.top))
            .attr('height', (area) => getAreaHeight(area))
            .style('opacity', 0.2);
        },
        (exit) => {
          return exit
            .transition()
            .duration(this.conf.defaultAnimationDuration)
            .style('opacity', 0)
            .remove();
        }
      )
      .attr('class', 'indicator-area')
      .attr('x', 0)
      .attr('width', that.conf.graphWidth)
      .attr('fill', (area) => area.color)
      .style('opacity', 0.2);
  }

  /**
   * Creates and displays a tooltip including a info-label and labels
   * Tooltip content depends on the item, the click-event happened on
   * @param x X-Position the click-event occurred
   * @param y Y-Position the click-event occurred
   */
  async showTooltip(index: number) {
    const currentPage = this.renderedPages[this.currentPage];
    const clickedItem = currentPage.items[index];

    // Ignores click on same item twice
    if (this.activeTooltip === index) {
      return;
    }

    await this.hideTooltip();
    this.activeTooltip = index;

    // Set marker to selected state
    this.pagesGroup
      .selectAll(`.page-${currentPage.pageNumber} .marker-container .item-${index}`)
      .classed('tooltip-active', true)
      .selectAll('.inner-marker')
      .transition()
      .duration(this.conf.defaultAnimationDuration)
      .style('fill', this.conf.markerSuccessColor);

    // Gets tooltip dom element
    const tooltip = d3.select(this.hostElement).select('.tooltip') as any;
    // Removes old labels
    tooltip.selectAll('.labels span').remove();
    // Updates info text
    tooltip.select('.info').text(clickedItem.title);
    // Adds label(s)
    tooltip
      .select('.labels')
      .selectAll('span')
      .data(clickedItem.labels)
      .join('span')
      .text((label: string) => label)
      .style('display', 'block');

    // Gets final css dimensions
    const labelDimensions: {
      width: number;
    } = tooltip.node().getBoundingClientRect();
    // Items X-Position
    const itemXPosition = Math.round(this.xScale(index + 0.5));
    // Available space right of the tooltip
    const maxRightSpace =
      this.conf.graphWidth - 10 - Math.round(labelDimensions.width) - itemXPosition;
    // Calculates and corrects final tooltip X-Position
    const tooltipXPosition =
      maxRightSpace < 0
        ? this.conf.graphWidth - 10 - Math.round(labelDimensions.width)
        : itemXPosition - 10;

    // Displays tooltip
    tooltip
      .style('left', `${tooltipXPosition}px`)
      .transition()
      .style('opacity', '1')
      .duration(this.conf.defaultAnimationDuration);
  }
}
