import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromDocument from './document.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DocumentEffects } from './document.effects';
import * as fromUploadRequest from './upload-request.reducer';
import { UploadRequestEffects } from './upload-request.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromDocument.documentsFeatureKey, fromDocument.reducer),
    StoreModule.forFeature(fromUploadRequest.uploadRequestsFeatureKey, fromUploadRequest.reducer),
    EffectsModule.forFeature([DocumentEffects, UploadRequestEffects]),
  ],
})
export class DocumentsStoreModule {}
