import { createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DeviceInformation } from 'app/models';
import { BmResultBloodPressure, DeviceEntityState, DeviceType } from '../devices.model';
import * as Bm54Actions from './bm54-store/bm54.actions';
import * as Bm57Actions from './bm57-store/bm57.actions';
import * as Bm64Actions from './bm64-store/bm64.actions';
import * as DeviceActions from '../devices.actions';

export interface State extends DeviceEntityState<BmResultBloodPressure> {
  address: string | null;
  name: string;
  type: DeviceType;
  lastResults: BmResultBloodPressure[];
  transferring: boolean;
  scanning: boolean;
  deviceInformation: DeviceInformation | null;
  error: Error | string | null;
}

export function selectResultId(r: BmResultBloodPressure): string {
  return `${r.type}_${r.device_time}`;
}

export const adapter: EntityAdapter<BmResultBloodPressure> =
  createEntityAdapter<BmResultBloodPressure>({
    selectId: selectResultId,
  });

export function getInitialState(name: string) {
  return adapter.getInitialState({
    address: null,
    name,
    type: DeviceType.BloodPressureMonitor,
    lastResults: [],
    transferring: false,
    scanning: false,
    deviceInformation: {
      manufacturer: 'Beurer',
      model: name,
      software_version: '',
      hardware_version: '',
      firmware_version: '',
    },
    error: null,
  });
}

export function getReducer(
  actions: typeof Bm54Actions | typeof Bm57Actions | typeof Bm64Actions,
  initialState: State
) {
  return createReducer(
    initialState,
    on(actions.addState, (state, action) => ({
      ...action.state,
      type: DeviceType.BloodPressureMonitor,
    })),
    on(actions.startScanning, (state) => ({ ...state, scanning: true })),
    on(DeviceActions.startScanManualMeasurements, (state, action) => ({
      ...state,
      transferring: action.device === state.name,
    })),
    on(actions.loadMeasurements, actions.loadDeviceInformation, (state, action) => ({
      ...state,
      address: action.address,
      transferring: true,
    })),
    on(actions.loadMeasurementsSuccess, (state, action) =>
      adapter.upsertMany(action.results, {
        ...state,
        lastResults: action.lastResults,
        transferring: false,
        deviceInformation: action.deviceInformation,
      })
    ),
    on(actions.deleteDeviceSuccess, () => initialState),
    on(
      actions.loadDeviceInformation,
      actions.loadMeasurements,
      DeviceActions.startScan,
      (state) => ({
        ...state,
        error: null,
      })
    ),
    on(actions.loadDeviceInformationFailure, actions.loadMeasurementsFailure, (state, action) => ({
      ...initialState,
      error: action.error,
    })),
    on(actions.updateLastResults, (state, action) => ({
      ...state,
      lastResults: action.lastResults,
    }))
  );
}

export function getSelectors(featureName: string) {
  const selectFeature = createFeatureSelector<State>(featureName);
  const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors(selectFeature);
  const selectAddress = createSelector(selectFeature, (state) => state.address);

  return {
    selectFeature,
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
    selectAddress,
  };
}
