import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'pro-device-help',
  templateUrl: './device-help.component.html',
  styleUrls: ['./device-help.component.scss'],
})
export class DeviceHelpComponent {
  constructor(public popoverController: PopoverController) {}
}
