import {
  Action,
  createReducer,
  on,
  createFeatureSelector,
  createSelector,
  createSelectorFactory,
  resultMemoize,
} from '@ngrx/store';
import { AnyFn } from '@ngrx/store/src/selector';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import {
  AddMeasurementsResponse,
  Measurement,
  MeasurementNormalized,
  ObservationResult,
  ObservationResultBloodPressure,
  ObservationResultEcg,
  ObservationResultSurvey,
  ObservationResultWeight,
} from 'app/models';
import * as MeasurementsActions from './measurements.actions';
import * as fromMeasurementResults from './measurement-results-store/measurement-results.reducer';
import * as dayjs from 'dayjs';
import { isEqual } from 'lodash-es';

export const measurementsFeatureKey = 'measurements';

const selectIdKey = (a: MeasurementNormalized) => {
  return a.date + '_' + (a.type as string);
};

export interface State extends EntityState<MeasurementNormalized> {
  loading: boolean;
  addMeasurementsResponse: AddMeasurementsResponse | null;
  error: Error | null;
  minDate: string;
}

export const adapter: EntityAdapter<MeasurementNormalized> =
  createEntityAdapter<MeasurementNormalized>({
    selectId: selectIdKey,
  });

export const initialState: State = adapter.getInitialState({
  loading: false,
  addMeasurementsResponse: null,
  error: null,
  minDate: null,
});

const measurementsReducer = createReducer(
  initialState,
  on(MeasurementsActions.loadMeasurements, MeasurementsActions.addMeasurements, (state) => ({
    ...state,
    loading: true,
  })),
  on(MeasurementsActions.addMeasurements, (state) => ({
    ...state,
    addMeasurementsResponse: null,
    error: null,
  })),
  on(
    MeasurementsActions.addMeasurementsSuccess,
    MeasurementsActions.addEcgMeasurementsSuccess,
    (state, action) => ({
      ...state,
      addMeasurementsResponse: action.data,
    })
  ),
  on(MeasurementsActions.loadMeasurementsSuccess, (state, action) =>
    adapter.upsertMany(action.measurements, {
      ...state,
      minDate: action.minDate,
    })
  ),
  on(
    MeasurementsActions.loadMeasurementsSuccess,
    MeasurementsActions.addMeasurementsFailure,
    MeasurementsActions.loadMeasurementsFailure,
    MeasurementsActions.loadMeasurementsComplete,
    (state) => ({
      ...state,
      loading: false,
    })
  ),
  on(
    MeasurementsActions.loadMeasurementsFailure,
    MeasurementsActions.addMeasurementsFailure,
    (state, action) => ({
      ...state,
      error: action.error,
    })
  )
);

export const selectFeature = createFeatureSelector<State>('measurements');

export function reducer(state: State | undefined, action: Action) {
  return measurementsReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);

export const selectLoaded = createSelector(selectFeature, (state) => state.minDate !== null);

export const selectMinDate = createSelector(selectFeature, (state) => state.minDate);

export const selectAddMeasurementResponse = createSelector(
  selectFeature,
  (state): AddMeasurementsResponse | null => state.addMeasurementsResponse
);

export const selectError = createSelector(selectFeature, (state) => state.error);

export const customMemoized = (projectorFn: AnyFn) => resultMemoize(projectorFn, isEqual);

export const selectAllDenormalized = createSelectorFactory(customMemoized)(
  selectAll,
  fromMeasurementResults.selectEntities,
  (
    measurements: MeasurementNormalized[],
    results: Dictionary<
      | ObservationResult
      | ObservationResultWeight
      | ObservationResultBloodPressure
      | ObservationResultEcg
      | ObservationResultSurvey
    >
  ): Measurement[] => measurements.map((m) => ({ ...m, results: m.results.map((r) => results[r]) }))
);

export const selectMeasurementsFromTodayWithoutEcg = createSelector(
  selectAllDenormalized,
  (en): any => en.filter((e: any) => e.type !== 'ecg' && e.date === dayjs().format('YYYY-MM-DD'))
);
