import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromArticles from './articles.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ArticlesEffects } from './articles.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromArticles.articlesFeatureKey, fromArticles.reducer),
    EffectsModule.forFeature([ArticlesEffects]),
  ],
})
export class ArticlesStoreModule {}
