import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  Input,
  Renderer2,
} from '@angular/core';

declare const Survey: any;

const customCss = {
  container: 'pro-sv-container',
  headerText: 'pro-sv_header__text',
  footer: 'pro-sv-footer',
  navigation: {
    complete: 'pro-sv-button  cy-sv-complete-btn',
    prev: 'pro-sv-button-secondary  cy-sv-prev-btn',
    next: 'pro-sv-next  cy-sv-next-btn',
    start: 'pro-sv-button cy-sv-start-btn',
  },
  error: {
    root: 'pro-sv-error-root',
  },
};

@Component({
  selector: 'pro-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit {
  @Input() config: any;
  @Input() model: any = {};
  @Input() customCss: any;
  @Input() htmlLabel = false;
  @Output() completeEvent: EventEmitter<any> = new EventEmitter();
  @Output() currentPageChangedEvent: EventEmitter<any> = new EventEmitter();
  @Output() startedEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('survey', { static: true }) surveyElement: ElementRef;
  surveyModel: any;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    // extend nouslider properties
    Survey.Serializer.addProperty('nouislider', {
      name: 'maxRateDescription:string',
      default: '',
      category: 'slider',
    });

    Survey.Serializer.addProperty('nouislider', {
      name: 'minRateDescription:string',
      default: '',
      category: 'slider',
    });

    // initialise survey
    this.surveyModel = new Survey.Model(this.config);

    // override survey default settings
    this.surveyModel.locale = 'de';
    this.surveyModel.questionTitlePattern = 'numTitle';
    this.surveyModel.showCompletedPage = false;
    this.surveyModel.firstPageIsStarted = true;

    // override default setting with model input
    if (Object.entries(this.model).length > 0) {
      for (const [key, value] of Object.entries(this.model)) {
        this.surveyModel[key] = value;
      }
    }

    // set customCss
    this.customCss = {
      ...customCss,
      ...this.customCss,
    };

    // emit onStarted event
    this.surveyModel.onStarted.add((e: any) => {
      this.startedEvent.emit(e);
    });

    // emit onComplete event
    this.surveyModel.onComplete.add((e: any) => {
      this.completeEvent.emit(e);
    });

    // emit onAfterRenderPage event
    this.surveyModel.onAfterRenderPage.add(() => {
      this.addSliderSettings();
      this.generateRatingRadioButtons();
    });

    // emit onCurrentPageChanged event
    this.surveyModel.onCurrentPageChanged.add((e: any) => {
      this.currentPageChangedEvent.emit(e);
    });

    // process markdown and html text
    this.surveyModel.onTextMarkdown.add((survey: any, option: any) => {
      option.html =
        option.name === 'title'
          ? option.text
          : this.htmlLabel
          ? `<span class="pro-sv-label-html">${option.text}</span>`
          : null;
    });

    Survey.SurveyNG.render(this.surveyElement.nativeElement, {
      model: this.surveyModel,
      css: this.customCss,
    });
  }

  addSliderSettings() {
    this.surveyModel.showQuestionNumbers = 'off';
    const slider = document.querySelector('.noUi-target');
    if (slider) {
      this.addSliderTooltip();
      const sliderParent = slider.parentElement;
      this.renderer.addClass(sliderParent, 'pro-noUi-container');
      this.addSliderDescription();
    }
  }

  addSliderTooltip() {
    const tooltip = document.querySelector('.noUi-tooltip');
    const handle = document.querySelector('.noUi-handle');
    const customTooltip = document.createElement('div');
    customTooltip.textContent = tooltip.textContent.split('.')[0];
    customTooltip.className = 'noUi-tooltip';
    this.renderer.appendChild(handle, customTooltip);
    this.renderer.setStyle(tooltip, 'display', 'none');

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        customTooltip.textContent = mutation.target.textContent.split('.')[0];
      });
    });
    mutationObserver.observe(tooltip, {
      childList: true,
    });
  }

  addSliderDescription() {
    if (this.surveyModel?.currentPage?.elements) {
      this.surveyModel.currentPage.elements.map((e: any) => {
        if (e?.maxRateDescription) {
          const container = document.querySelector('.pro-noUi-container');
          const description = document.createElement('div');
          description.textContent = e.maxRateDescription;
          description.className = 'noUi-max-rate-description';
          this.renderer.appendChild(container, description);
        }

        if (e?.minRateDescription) {
          const container = document.querySelector('.pro-noUi-container');
          const description = document.createElement('div');
          description.textContent = e.minRateDescription;
          description.className = 'noUi-min-rate-description';
          this.renderer.appendChild(container, description);
        }
      });
    }
  }

  generateRatingRadioButtons() {
    const radioButtons = document.querySelectorAll(
      '.pro-sv-container-rating .sv_q_radiogroup_control_item'
    );
    const circles = document.querySelectorAll(
      '.pro-sv-container-rating .sv_q_radiogroup_label .circle'
    );

    radioButtons.forEach((el: HTMLInputElement, index) => {
      const value = el.value;
      const element = document.createElement('span');
      element.innerHTML = value;
      this.renderer.addClass(element, 'number');
      this.renderer.insertBefore(circles[index], element, undefined);
    });
  }
}
