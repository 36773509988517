import { Directive, HostListener, Input } from '@angular/core';
import { IonCheckbox } from '@ionic/angular';

@Directive({
  selector: '[proScrollNextOnCheck]',
})
export class ScrollNextOnCheckDirective {
  @Input('proScrollNextOnCheck') targetCheckbox: IonCheckbox;

  @HostListener('click', ['$event.target']) onClick(sourceCheckbox: HTMLElement) {
    // waits until class-list was updated
    setTimeout(() => {
      // procceed only if checkbox was checked
      if (sourceCheckbox.classList.contains('checkbox-checked')) {
        // find target element to scroll to
        if (this.targetCheckbox && this.targetCheckbox['el']?.parentElement) {
          this.targetCheckbox['el'].parentElement.scrollIntoView({
            block: 'start',
            inline: 'nearest',
            behavior: 'smooth',
          });
        }
      }
    }, 150);
  }

  constructor() {}
}
