import { Action, createReducer, on, createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  ObservationResult,
  ObservationResultBloodPressure,
  ObservationResultEcg,
  ObservationResultSurvey,
  ObservationResultWeight,
} from 'app/models';
import * as MeasurementsActions from '../measurements.actions';

export const measurementResultsFeatureKey = 'measurementResults';

export type State = EntityState<
  | ObservationResult
  | ObservationResultWeight
  | ObservationResultBloodPressure
  | ObservationResultEcg
  | ObservationResultSurvey
>;

const selectIdKey = (
  a:
    | ObservationResult
    | ObservationResultWeight
    | ObservationResultBloodPressure
    | ObservationResultEcg
    | ObservationResultSurvey
) => {
  return `${a.id}_${a.type}`;
};

export const adapter: EntityAdapter<
  | ObservationResult
  | ObservationResultWeight
  | ObservationResultBloodPressure
  | ObservationResultEcg
  | ObservationResultSurvey
> = createEntityAdapter<
  | ObservationResult
  | ObservationResultWeight
  | ObservationResultBloodPressure
  | ObservationResultEcg
  | ObservationResultSurvey
>({
  selectId: selectIdKey,
});

export const initialState: State = adapter.getInitialState({});

const measurementResultsReducer = createReducer(
  initialState,
  on(MeasurementsActions.loadMeasurementsSuccess, (state, action) => {
    return adapter.upsertMany(action.results, { ...state });
  })
);

export const selectFeature = createFeatureSelector<State>('measurementResults');

export function reducer(state: State | undefined, action: Action) {
  return measurementResultsReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);
