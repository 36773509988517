<form *ngIf="confirmPasswordForm" [formGroup]="confirmPasswordForm">
  <ion-item lines="full">
    <ion-label position="stacked">{{ passwordLabel }}</ion-label>
    <ion-input
      [type]="hidePassword === true ? 'password' : 'text'"
      inputmode="text"
      formControlName="password"
      clearOnEdit="false"
      proControlError
    ></ion-input>
    <ion-icon
      *ngIf="displayTogglePassword"
      (click)="togglePassword()"
      [name]="hidePassword === false ? 'eye-off-outline' : 'eye-outline'"
      slot="end"
      role="button"
      aria-label="{hidePassword, select, false {Passwort ausblenden} true {Passwort anzeigen}}"
      i18n-aria-label
    ></ion-icon>
  </ion-item>

  <ion-item lines="full">
    <div
      class="pw-state-infos"
      [ngClass]="{ 'emphasize-infos': confirmPasswordForm.value.password.length > 0 }"
    >
      <div
        class="pw-state-info"
        [ngClass]="{ alert: !passwordState.hasMinLength }"
        data-cy="pw-error-length"
      >
        <ion-icon
          [name]="passwordState.hasMinLength === false ? 'close-sharp' : 'checkmark-sharp'"
          slot="end"
          aria-label="{passwordState.hasMinLength, select, false {error} true {check}}"
          i18n-aria-label
        ></ion-icon>
        <p class="small ion-no-margin pw-state-info" i18n>Muss aus mindestens 8 Zeichen bestehen</p>
      </div>

      <div
        class="pw-state-info"
        [ngClass]="{ alert: !passwordState.hasSpecialOrDigit }"
        data-cy="pw-error-special-or-digit"
      >
        <ion-icon
          [name]="passwordState.hasSpecialOrDigit === false ? 'close-sharp' : 'checkmark-sharp'"
          slot="end"
          aria-label="{passwordState.hasSpecialOrDigit, select, false {error} true {check}}"
          i18n-aria-label
        ></ion-icon>
        <p class="small ion-no-margin pw-state-info" i18n>
          Muss mindestens ein Symbol oder Zahl enthalten
        </p>
      </div>

      <div
        class="pw-state-info"
        [ngClass]="{ alert: !passwordState.hasLowerCase }"
        data-cy="pw-error-lower"
      >
        <ion-icon
          [name]="passwordState.hasLowerCase === false ? 'close-sharp' : 'checkmark-sharp'"
          slot="end"
          aria-label="{passwordState.hasLowerCase, select, false {error} true {check}}"
          i18n-aria-label
        ></ion-icon>
        <p class="small ion-no-margin pw-state-info" i18n>
          Muss mindestens einen Kleinbuchstaben enthalten
        </p>
      </div>

      <div
        class="pw-state-info"
        [ngClass]="{ alert: !passwordState.hasUpperCase }"
        data-cy="pw-error-upper"
      >
        <ion-icon
          [name]="passwordState.hasUpperCase === false ? 'close-sharp' : 'checkmark-sharp'"
          slot="end"
          aria-label="{passwordState.hasUpperCase, select, false {error} true {check}}"
          i18n-aria-label
        ></ion-icon>

        <p class="small ion-no-margin pw-state-info" i18n>
          Muss mindestens einen Großbuchstaben enthalten
        </p>
      </div>
    </div>
  </ion-item>

  <ion-item lines="none">
    <ion-label position="stacked">{{ passwordConfirmLabel }}</ion-label>
    <ion-input
      [type]="hidePassword === true ? 'password' : 'text'"
      inputmode="text"
      formControlName="password_confirmation"
      clearOnEdit="false"
      proControlError
    ></ion-input>
  </ion-item>
</form>
