import { Component, OnInit, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'pro-modal-nav-outlet',
  template: `<ion-nav [root]="root" [rootParams]="rootParams" swipeGesture="false"></ion-nav>`,
})
export class ModalNavOutletComponent implements OnInit, OnDestroy {
  root: any;
  rootParams: Record<string, any>;
  subscription: any;

  constructor(private platform: Platform) {}

  ngOnInit(): void {
    // disable hardwareBackButton
    this.subscription = this.platform.backButton.subscribeWithPriority(9999, () => {});
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
