import { createAction, props } from '@ngrx/store';
import { DocumentCategory } from 'app/models';

export const loadDocumentsCategories = createAction(
  '[DocumentCategories/API] Load Documents Categories'
);

export const loadDocumentsCategoriesSuccess = createAction(
  '[DocumentCategories/API] Load Documents Categories Success',
  props<{ documentsCategories: DocumentCategory[] }>()
);

export const loadDocumentsCategoriesFailure = createAction(
  '[DocumentCategories/API] Load Documents Categories Failure',
  props<{ error: any }>()
);
