import { Injectable } from '@angular/core';
import { Message } from 'app/models';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  getImage(message: Message) {
    // Onboarding messages
    if (message.learning_message_categories?.includes('Onboarding')) {
      return `messages-onboarding-${!message.opened_at ? 'unread' : 'read'}.svg`;
    }

    // learning and communication_knowledge messages
    if (message.category === 'learning' || message.category === 'communication_knowledge') {
      if (message.learning_message_categories?.includes('Ernährung')) {
        return `messages-nutrition-${!message.opened_at ? 'unread' : 'read'}.svg`;
      }

      if (message.learning_message_categories?.includes('Bewegung')) {
        return `messages-motion-${!message.opened_at ? 'unread' : 'read'}.svg`;
      }

      if (message.learning_message_categories?.includes('Lebensstil')) {
        return `messages-life-style-${!message.opened_at ? 'unread' : 'read'}.svg`;
      }

      if (message.learning_message_categories?.includes('Medikamente')) {
        return `messages-medication-${!message.opened_at ? 'unread' : 'read'}.svg`;
      }

      if (message.learning_message_categories?.includes('Therapie')) {
        return `messages-therapy-${!message.opened_at ? 'unread' : 'read'}.svg`;
      }

      if (message.learning_message_categories?.includes('Symptome')) {
        return `messages-symptoms-${!message.opened_at ? 'unread' : 'read'}.svg`;
      }

      if (message.learning_message_categories?.includes('Arzt')) {
        return `messages-doctor-${!message.opened_at ? 'unread' : 'read'}.svg`;
      }

      if (message.learning_message_categories?.includes('Impfungen')) {
        return `messages-vaccination-${!message.opened_at ? 'unread' : 'read'}.svg`;
      }

      return `messages-good-to-know-${!message.opened_at ? 'unread' : 'read'}.svg`;
    }

    // measurement_request messages
    if (message.category === 'measurement_request' || message.category === 'measurement_reminder') {
      if (
        message.measurement_type === 'heart_rate' ||
        message.measurement_type === 'oxygen_saturation'
      ) {
        return `messages-pulseoximeter-${!message.opened_at ? 'unread' : 'read'}.svg`;
      }

      if (message.measurement_type === 'blood_pressure') {
        return `messages-blood-pressure-${!message.opened_at ? 'unread' : 'read'}.svg`;
      }

      if (message.measurement_type === 'weight') {
        return `messages-scale-${!message.opened_at ? 'unread' : 'read'}.svg`;
      }

      if (message.measurement_type === 'temperature') {
        return `messages-thermometer-${!message.opened_at ? 'unread' : 'read'}.svg`;
      }

      if (message.measurement_type === 'survey') {
        return `messages-questionnaire-${!message.opened_at ? 'unread' : 'read'}.svg`;
      }
    }

    // communication_measurement, communication_survey, communication_recommendation and communication_other messages
    if (
      message.category === 'communication_measurement' ||
      message.category === 'communication_survey' ||
      message.category === 'communication_recommendation' ||
      message.category === 'communication_other'
    ) {
      return `messages-care-manager-${!message.opened_at ? 'unread' : 'read'}.svg`;
    }

    // change messages
    if (message.category === 'change') {
      return `messages-changes-${!message.opened_at ? 'unread' : 'read'}.svg`;
    }

    return `messages-fallback-${!message.opened_at ? 'unread' : 'read'}.svg`;
  }
}
