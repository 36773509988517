import { Component, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'pro-device-image',
  templateUrl: './device-image.component.html',
  styleUrls: ['./device-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceImageComponent {
  @Input() device: 'BM54' | 'BM57' | 'BM64' | 'PO60' | 'BF720' = 'BM54';

  @HostBinding('class.thumbnail') @Input() thumbnail = false;

  constructor() {}
}
