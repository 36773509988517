<div
  class="icon-badge"
  [ngClass]="{'active': active, 'small': small}"
>
  <ion-icon
    *ngIf="type === 'BloodPressureGoal'"
    src="assets/svgs/blood-pressure.svg"
  ></ion-icon>

  <ion-icon
    *ngIf="type === 'HeartRateGoal'"
    src="assets/svgs/heart-check.svg"
  ></ion-icon>

  <ion-icon
    *ngIf="type === 'OxygenSaturationGoal'"
    src="assets/svgs/lungs.svg"
  ></ion-icon>

  <ion-icon
    *ngIf="type === 'TemperatureGoal'"
    src="assets/svgs/temperature.svg"
  ></ion-icon>

  <ion-icon
    *ngIf="type === 'WeightGoal'"
    src="assets/svgs/scale.svg"
  ></ion-icon>

  <ion-icon
    *ngIf="type === 'EcgGoal'"
    src="assets/svgs/ecg.svg"
  ></ion-icon>

  <ion-icon
    *ngIf="type === 'SurveyGoal'"
    src="assets/svgs/questionnaire-check.svg"
  ></ion-icon>

  <ion-icon
    *ngIf="type === 'appointment'"
    src="assets/svgs/appointment.svg"
  ></ion-icon>

  <ion-icon
    *ngIf="type === 'document'"
    name="document-text-outline"
  ></ion-icon>

  <ion-icon
    *ngIf="type === 'medication'"
    src="assets/svgs/pill.svg"
  ></ion-icon>

  <ion-icon
    *ngIf="type === 'device'"
    src="assets/svgs/icon-section-devices.svg"
  ></ion-icon>
</div>