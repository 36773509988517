import { Pipe, PipeTransform } from '@angular/core';
import { ActivationCategory } from 'app/models';

const subscriptionCategory: Record<ActivationCategory, string> = {
  shop: $localize`Selbstzahler-Abo`,
  shop_test: $localize`“Selbstzahler Test”-Abo`,
  study: $localize`Studienabo`,
  test: $localize`Probeabo`,
  diga: $localize`DiGA-Abo`,
  grace: $localize`Übergangsabo`,
  telemedicine_center: $localize`TMZ Abo`,
  telemedicine_center_test: $localize`TMZ Test”-Abo`,
  tmc_eheart: $localize`TMZ Eheart-Abo`,
};

@Pipe({
  name: 'subscriptionCategory',
})
export class SubscriptionCategoryPipe implements PipeTransform {
  transform(value: ActivationCategory): unknown {
    return subscriptionCategory[value];
  }
}
