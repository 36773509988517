import { Action, createAction, props } from '@ngrx/store';
import { MedicationIntake } from 'app/models';

export type MedicationIntakeKey = keyof MedicationIntake;

export const loadMedicationIntakes = createAction(
  '[Medication Intake/API] Load Medication Intakes',
  props<{ from: string; to?: string }>()
);

export const loadMedicationIntakesSuccess = createAction(
  '[Medication Intake/API] Load Medication Intakes Success',
  props<{ medicationIntakes: MedicationIntake[] }>()
);

export const loadMedicationIntakesFailure = createAction(
  '[Medication Intake/API] Load Medication Intakes Failure',
  props<{ error: any }>()
);

export const loadMedicationIntakesComplete = createAction(
  '[Medication Intake/API] Load Medication Intakes Complete',
  props<{ action: Action }>()
);

export const updateMedicationIntake = createAction(
  '[Medication Intake/API] Update Medication Intake',
  props<{ medicationIntake: MedicationIntake }>()
);

export const updateMedicationIntakePartialSuccess = createAction(
  '[Medication Intake/API] Update Medication Intake Partial Success',
  props<{ medicationIntake: MedicationIntake; updatedMedicationIntake: MedicationIntake }>()
);

export const updateMedicationIntakeSuccess = createAction(
  '[Medication Intake/API] Update Medication Intake Success',
  props<{ medicationIntake: MedicationIntake }>()
);

export const updateMedicationIntakeFailure = createAction(
  '[Medication Intake/API] Update Medication Intake Failure',
  props<{ medicationIntake: MedicationIntake }>()
);

export const updateMedicationIntakes = createAction(
  '[Medication Intake/API] Update Medication Intakes',
  props<{ medicationIntakes: MedicationIntake[]; changedProp?: MedicationIntakeKey }>()
);

export const updateMedicationIntakesPartialSuccess = createAction(
  '[Medication Intake/API] Update Medication Intakes Partial Success',
  props<{ medicationIntakes: MedicationIntake[]; updatedMedicationIntakes: MedicationIntake[] }>()
);

export const updateMedicationIntakesSuccess = createAction(
  '[Medication Intake/API] Update Medication Intakes Success'
);

export const updateMedicationIntakesFailure = createAction(
  '[Medication Intake/API] Update Medication Intakes Failure',
  props<{ medicationIntake: MedicationIntake }>()
);

export const loadTodaysMedicationIntakes = createAction(
  '[Medication Intake/API] Load Todays Medication Intake'
);

export const loadTodaysMedicationIntakesSuccess = createAction(
  '[Medication Intake/API] Load Todays Medication Intakes Success',
  props<{ medicationIntakes: MedicationIntake[] }>()
);

export const loadTodaysMedicationIntakesFailure = createAction(
  '[Medication Intake/API] Load Todays Medication Intake Failure',
  props<{ error: any }>()
);

export const updateFirstFetchDate = createAction(
  '[Medication Intake/Store] Update First Fetch Date',
  props<{ firstFetch: string }>()
);
