import { createAction, props } from '@ngrx/store';
import { DeviceInformation } from 'app/models';
import { BmResultBloodPressure } from '../devices.model';
import * as fromBm from './bm.reducer';

export enum BmName {
  BM54 = 'BM54',
  BM57 = 'BM57',
  BM64 = 'BM64',
}

enum AddState {
  'BM54' = '[BM54/Store] Add State',
  'BM57' = '[BM57/Store] Add State',
  'BM64' = '[BM64/Store] Add State',
}
enum AddStateSuccess {
  'BM54' = '[BM54/Store] Add State Success',
  'BM57' = '[BM57/Store] Add State Success',
  'BM64' = '[BM64/Store] Add State Success',
}
enum StartScanning {
  'BM54' = '[BM54/Store] Start Scanning',
  'BM57' = '[BM57/Store] Start Scanning',
  'BM64' = '[BM64/Store] Start Scanning',
}
enum StartScanningSuccess {
  'BM54' = '[BM54/Store] Start Scanning Success',
  'BM57' = '[BM57/Store] Start Scanning Success',
  'BM64' = '[BM64/Store] Start Scanning Success',
}
enum LoadDeviceInformation {
  'BM54' = '[BM54/Store] Load Device Information',
  'BM57' = '[BM57/Store] Load Device Information',
  'BM64' = '[BM64/Store] Load Device Information',
}
enum LoadDeviceInformationFailure {
  'BM54' = '[BM54/Store] Load Device Information Failure',
  'BM57' = '[BM57/Store] Load Device Information Failure',
  'BM64' = '[BM64/Store] Load Device Information Failure',
}
enum LoadMeasurements {
  'BM54' = '[BM54/Store] Load Measurements',
  'BM57' = '[BM57/Store] Load Measurements',
  'BM64' = '[BM64/Store] Load Measurements',
}
enum LoadMeasurementsSubscribed {
  'BM54' = '[BM54/API] Load Measurements Subscribed',
  'BM57' = '[BM57/API] Load Measurements Subscribed',
  'BM64' = '[BM64/API] Load Measurements Subscribed',
}
enum LoadMeasurementsSubscribedResult {
  'BM54' = '[BM54/API] Load Measurements Subscribed Result',
  'BM57' = '[BM57/API] Load Measurements Subscribed Result',
  'BM64' = '[BM64/API] Load Measurements Subscribed Result',
}
enum LoadMeasurementsSuccess {
  'BM54' = '[BM54/API] Load Measurements Success',
  'BM57' = '[BM57/API] Load Measurements Success',
  'BM64' = '[BM64/API] Load Measurements Success',
}
enum LoadMeasurementsFailure {
  'BM54' = '[BM54/Store] Load Measurements Failure',
  'BM57' = '[BM57/Store] Load Measurements Failure',
  'BM64' = '[BM64/Store] Load Measurements Failure',
}
enum DeleteDevice {
  'BM54' = '[BM54/Store] Delete Device',
  'BM57' = '[BM57/Store] Delete Device',
  'BM64' = '[BM64/Store] Delete Device',
}
enum DeleteDeviceSuccess {
  'BM54' = '[BM54/Store] Delete Device Success',
  'BM57' = '[BM57/Store] Delete Device Success',
  'BM64' = '[BM64/Store] Delete Device Success',
}
enum DisconnectDevice {
  'BM54' = '[BM54/Store] Disconnect Device',
  'BM57' = '[BM57/Store] Disconnect Device',
  'BM64' = '[BM64/Store] Disconnect Device',
}
enum DisconnectDeviceSuccess {
  'BM54' = '[BM54/Store] Disconnect Device Success',
  'BM57' = '[BM57/Store] Disconnect Device Success',
  'BM64' = '[BM64/Store] Disconnect Device Success',
}
enum DisconnectDeviceFailure {
  'BM54' = '[BM54/Store] Disconnect Device Failure',
  'BM57' = '[BM57/Store] Disconnect Device Failure',
  'BM64' = '[BM64/Store] Disconnect Device Failure',
}
enum UpdateLastResults {
  'BM54' = '[BM54/Store] Update Last Results',
  'BM57' = '[BM57/Store] Update Last Results',
  'BM64' = '[BM64/Store] Update Last Results',
}

export function getActions(name: BmName) {
  const addState = createAction(AddState[name], props<{ state: fromBm.State }>());
  const addStateSuccess = createAction(AddStateSuccess[name]);
  const startScanning = createAction(StartScanning[name]);
  const startScanningSuccess = createAction(
    StartScanningSuccess[name],
    props<{ name: string; address: string }>()
  );
  const loadDeviceInformation = createAction(
    LoadDeviceInformation[name],
    props<{ address: string }>()
  );
  const loadDeviceInformationFailure = createAction(
    LoadDeviceInformationFailure[name],
    props<{ error: any }>()
  );
  const loadMeasurements = createAction(
    LoadMeasurements[name],
    props<{ address: string; deviceInformation?: DeviceInformation }>()
  );
  const loadMeasurementsSubscribed = createAction(LoadMeasurementsSubscribed[name]);
  const loadMeasurementsSubscribedResult = createAction(LoadMeasurementsSubscribedResult[name]);
  const loadMeasurementsSuccess = createAction(
    LoadMeasurementsSuccess[name],
    props<{
      results: BmResultBloodPressure[];
      lastResults: BmResultBloodPressure[];
      deviceInformation: DeviceInformation;
    }>()
  );
  const loadMeasurementsFailure = createAction(
    LoadMeasurementsFailure[name],
    props<{ error: any }>()
  );
  const deleteDevice = createAction(DeleteDevice[name]);
  const deleteDeviceSuccess = createAction(DeleteDeviceSuccess[name], props<{ address: string }>());
  const disconnectDevice = createAction(DisconnectDevice[name], props<{ address: string }>());
  const disconnectDeviceSuccess = createAction(DisconnectDeviceSuccess[name]);
  const disconnectDeviceFailure = createAction(
    DisconnectDeviceFailure[name],
    props<{ error: any }>()
  );
  const updateLastResults = createAction(
    UpdateLastResults[name],
    props<{ lastResults: BmResultBloodPressure[] }>()
  );

  return {
    addState,
    addStateSuccess,
    startScanning,
    startScanningSuccess,
    loadDeviceInformation,
    loadDeviceInformationFailure,
    loadMeasurements,
    loadMeasurementsSubscribed,
    loadMeasurementsSubscribedResult,
    loadMeasurementsSuccess,
    loadMeasurementsFailure,
    deleteDevice,
    deleteDeviceSuccess,
    disconnectDevice,
    disconnectDeviceSuccess,
    disconnectDeviceFailure,
    updateLastResults,
  };
}
