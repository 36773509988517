<ng-container *ngIf="!thumbnail" [ngSwitch]="device">
  <img
    *ngSwitchCase="'BM54'"
    aria-label="Abbildung Blutdruckmessgerät BM 54"
    i18n-aria-label
    src="assets/images/bm-54-produktbild.png"
    srcset="
      assets/images/bm-54-produktbild.png    1x,
      assets/images/bm-54-produktbild@2x.png 2x,
      assets/images/bm-54-produktbild@3x.png 3x
    "
  />
  <img
    *ngSwitchCase="'BM57'"
    aria-label="Abbildung Blutdruckmessgerät BM 57"
    i18n-aria-label
    src="assets/images/bm-57-produktbild.png"
    srcset="
      assets/images/bm-57-produktbild.png    1x,
      assets/images/bm-57-produktbild@2x.png 2x,
      assets/images/bm-57-produktbild@3x.png 3x
    "
  />
  <img
    *ngSwitchCase="'BM64'"
    aria-label="Abbildung Blutdruckmessgerät BM 64"
    i18n-aria-label
    src="assets/images/bm-64-produktbild.png"
    srcset="
      assets/images/bm-64-produktbild.png    1x,
      assets/images/bm-64-produktbild@2x.png 2x,
      assets/images/bm-64-produktbild@3x.png 3x
    "
  />
  <img
    *ngSwitchCase="'PO60'"
    aria-label="Abbildung Pulsoximeter PO 60"
    i18n-aria-label
    src="assets/images/po-60-produktbild.jpg"
    srcset="
      assets/images/po-60-produktbild.jpg    1x,
      assets/images/po-60-produktbild@2x.jpg 2x,
      assets/images/po-60-produktbild@3x.jpg 3x
    "
  />
  <img
    *ngSwitchCase="'BF720'"
    aria-label="Abbildung Waage BF 720"
    i18n-aria-label
    src="assets/images/bf-720-produktbild.jpg"
    srcset="
      assets/images/bf-720-produktbild.jpg    1x,
      assets/images/bf-720-produktbild@2x.jpg 2x,
      assets/images/bf-720-produktbild@3x.jpg 3x
    "
  />
</ng-container>

<ng-container *ngIf="thumbnail" [ngSwitch]="device">
  <img
    *ngSwitchCase="'BM54'"
    aria-label="Abbildung Blutdruckmessgerät BM 54"
    i18n-aria-label
    src="assets/images/bm-54-produktbild-thumb.png"
    srcset="
      assets/images/bm-54-produktbild-thumb.png    1x,
      assets/images/bm-54-produktbild-thumb@2x.png 2x,
      assets/images/bm-54-produktbild-thumb@3x.png 3x
    "
  />
  <img
    *ngSwitchCase="'BM57'"
    aria-label="Abbildung Blutdruckmessgerät BM 57"
    i18n-aria-label
    src="assets/images/bm-57-produktbild-thumb.png"
    srcset="
      assets/images/bm-57-produktbild-thumb.png    1x,
      assets/images/bm-57-produktbild-thumb@2x.png 2x,
      assets/images/bm-57-produktbild-thumb@3x.png 3x
    "
  />
  <img
    *ngSwitchCase="'BM64'"
    aria-label="Abbildung Blutdruckmessgerät BM 64"
    i18n-aria-label
    src="assets/images/bm-64-produktbild-thumb.png"
    srcset="
      assets/images/bm-64-produktbild-thumb.png    1x,
      assets/images/bm-64-produktbild-thumb@2x.png 2x,
      assets/images/bm-64-produktbild-thumb@3x.png 3x
    "
  />
  <img
    *ngSwitchCase="'PO60'"
    aria-label="Abbildung Pulsoximeter PO 60"
    i18n-aria-label
    src="assets/images/po-60-produktbild-thumb.png"
    srcset="
      assets/images/po-60-produktbild-thumb.png    1x,
      assets/images/po-60-produktbild-thumb@2x.png 2x,
      assets/images/po-60-produktbild-thumb@3x.png 3x
    "
  />
  <img
    *ngSwitchCase="'BF720'"
    aria-label="Abbildung Waage BF 720"
    i18n-aria-label
    src="assets/images/bf-720-produktbild-thumb.png"
    srcset="
      assets/images/bf-720-produktbild-thumb.png    1x,
      assets/images/bf-720-produktbild-thumb@2x.png 2x,
      assets/images/bf-720-produktbild-thumb@3x.png 3x
    "
  />
</ng-container>
<ng-content></ng-content>
