import { EntityState } from '@ngrx/entity';
import { ResultValue, ResultBloodPressure, DeviceInformation } from 'app/models';

export { State as Bm54State } from 'store/devices-store/bm-store/bm54-store/bm54.reducer';
export { State as Bm57State } from 'store/devices-store/bm-store/bm57-store/bm57.reducer';
export { State as Bm64State } from 'store/devices-store/bm-store/bm64-store/bm64.reducer';
export { State as Po60State } from 'store/devices-store/po60-store/po60.reducer';
export { State as Bf720State } from 'store/devices-store/bf720-store/bf720.reducer';

export type DeviceLocationStatus =
  | 'GRANTED'
  | 'LOCATION_DISABLED'
  | 'PERMISSION_DENIED'
  | 'DENIED_FINALLY'
  | 'UNKNOWN';

export enum DeviceType {
  'BloodPressureMonitor' = 'BloodPressureMonitorType',
  'PulseOximeter' = 'PulseOximeterType',
  'Scale' = 'ScaleType',
}

export interface Device {
  address: string;
  name: string;
  results: Array<ResultValue | ResultBloodPressure>;
  lastResults: Array<ResultValue | ResultBloodPressure>;
  userIndex?: number;
  code?: number;
  transferring: boolean;
  scanning: boolean;
}

export interface UserListB720 {
  index: number;
  initials: string;
  birthdate: string;
  height: number;
  gender: number;
  activityLevel: number;
}

export interface DeviceEntityState<T> extends EntityState<T> {
  address: string | null;
  name: string;
  type: DeviceType;
  lastResults: T[];
  transferring: boolean;
  scanning: boolean;
  deviceInformation: DeviceInformation | null;
  error: Error | string | null;
}

export interface Po60ResultValues {
  type: 'po60';
  device_time: string;
  heartRate: { type: 'heart_rate'; value: number; device_time: string };
  oxygenSaturation: { type: 'oxygen_saturation'; value: number; device_time: string };
}

export interface BmResultBloodPressure extends ResultBloodPressure {
  user: 1 | 2;
}
