import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'pro-app-subscriptions-invalid-code-info',
  templateUrl: './app-subscriptions-invalid-code-info.component.html',
  styleUrls: ['./app-subscriptions-invalid-code-info.component.scss'],
})
export class AppSubscriptionsInvalidCodeInfoComponent {
  constructor(public popoverController: PopoverController) {}
}
