import { TestDataFactory, RANDOM_STRING, RANDOM_NUMBER } from './test-data.factory';
import { Message } from 'app/models';

export class MessageFactory extends TestDataFactory<Message> {
  constructor() {
    const today = new Date();
    const date = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    const generatorFunction = (): Message => {
      return {
        id: RANDOM_NUMBER.getOne(30, 220),
        category: 'communication_other',
        subject: RANDOM_STRING.getOne(5),
        body: RANDOM_STRING.getOne(25),
        received_at: date + 'T16:13:58',
        opened_at: date + 'T16:15:58',
        created_at: date + 'T16:13:57',
        link: null,
      };
    };

    super(generatorFunction);
  }
}
