import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromTermsOfService from 'store/terms-of-service-store/terms-of-service.reducer';
import * as TermsOfServiceActions from 'store/terms-of-service-store/terms-of-service.actions';

@Injectable({
  providedIn: 'root',
})
export class TermsOfServiceFacade {
  termsOfService$ = this.store.pipe(select(fromTermsOfService.selectTermsOfService));
  termsOfServiceLoading$ = this.store.pipe(select(fromTermsOfService.selectLoading));
  termsOfServiceError$ = this.store.pipe(select(fromTermsOfService.selectError));
  termsOfServiceAdding$ = this.store.pipe(select(fromTermsOfService.selectAdding));

  constructor(private store: Store<fromTermsOfService.State>) {}

  loadTermsOfService() {
    this.store.dispatch(TermsOfServiceActions.loadTermsOfService());
  }

  acceptTermsOfService(url: string) {
    this.store.dispatch(TermsOfServiceActions.acceptTermsOfService({ url }));
  }
}
