<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons
      *ngIf="!showResultContent && started"
      slot="start"
      (click)="confirmCancellation()"
      data-cy="survey-back-btn"
      role="button"
      aria-label="Zurück"
      i18n-aria-label
    >
      <ion-back-button
        mode="ios"
        disabled="true"
        defaultHref=""
        aria-hidden="true"
      ></ion-back-button>
    </ion-buttons>
    <ng-container *ngIf="!showResultContent && !started">
      <ion-buttons slot="start" data-cy="survey-back-btn">
        <ion-back-button
          mode="ios"
          defaultHref=""
          role="button"
          aria-label="Zurück"
          i18n-aria-label
        ></ion-back-button>
      </ion-buttons>
    </ng-container>
    <ion-title *ngIf="!showResultContent" mode="md">{{ surveyTitle }}</ion-title>
    <ion-title *ngIf="showResultContent" mode="ios" class="title-result"
      ><span class="padding-l">{{ surveyTitle }}</span></ion-title
    >
  </ion-toolbar>
</ion-header>
<ion-content>
  <div *ngIf="started" class="step-indicator">
    <pro-step-indicator
      *ngIf="pageCount && !showResultContent"
      [current]="currentPageNo"
      [total]="pageCount"
    ></pro-step-indicator>
  </div>

  <pro-survey
    [config]="config$ | async"
    [model]="{ showTitle: false }"
    [customCss]="{
      container:
        surveyId === 'ess' ? 'pro-sv-container pro-sv-container-rating' : 'pro-sv-container'
    }"
    [htmlLabel]="surveyId === 'ess'"
    (currentPageChangedEvent)="currentPageChanged($event)"
    (completeEvent)="getSurveyResults($event)"
    (startedEvent)="setStarted()"
  ></pro-survey>
  <pro-questionnaire-completed
    *ngIf="showResultContent"
    [result]="result"
    [measurementsLoading]="measurementsLoading$ | async"
    [measurementsError]="measurementsError$ | async"
    [documentsLoading]="loadingDocuments$ | async"
  >
    <ion-button
      pro-questionnaire-result-button
      (click)="showResult(result)"
      expand="block"
      data-cy="complete-sv-btn"
      i18n
      >Zum Testergebnis
    </ion-button>

    <ion-button pro-questionnaire-try-again-button (click)="addMeasurements()" expand="block" i18n
      >Erneut versuchen
    </ion-button>

    <ion-button
      pro-questionnaire-cancel-button
      class="margin-b-0 margin-t-l"
      expand="block"
      fill="outline"
      (click)="confirmCancellation()"
      i18n
    >
      Abbrechen
    </ion-button>
  </pro-questionnaire-completed>

  <p
    *ngIf="!showResultContent && !started && surveyId !== 'wellbeing'"
    class="link bold"
    routerLink="/app/questionnaire/modal/information"
    i18n
  >
    Mehr Info zum <span *ngIf="surveyId === 'eq5d'">EQ—5D-5L-</span>Fragebogen
  </p>

  <ng-container
    *ngIf="
      !showResultContent && surveyLegalInfo[surveyId] && surveyLegalInfo[surveyId] as legalInfo
    "
  >
    <div class="logo-wrapper" *ngIf="!started && legalInfo.logos && legalInfo.logos.length > 0">
      <p class="small" i18n>Mit freundlicher Unterstützung von:</p>
      <img *ngFor="let logo of legalInfo.logos" [src]="logo.src" [srcset]="logo.srcset" />
    </div>

    <div
      *ngIf="
        legalInfo.copyright &&
        ((legalInfo.startOnly === true && !started) || legalInfo.startOnly === false)
      "
      class="copyright"
    >
      <span [innerHtml]="legalInfo.copyright"></span>
    </div>
  </ng-container>
</ion-content>
