import { FormOptions } from 'app/models';
import { TestDataFactory } from './test-data.factory';

export class FormOptionsFactory extends TestDataFactory<FormOptions> {
  constructor() {
    const generatorFunction = (): FormOptions => {
      return {
        patient: {
          genders: [
            {
              id: 'male',
              name: 'Männlich',
            },
            {
              id: 'female',
              name: 'Weiblich',
            },
            {
              id: 'diverse',
              name: 'Divers',
            },
          ],
          relationship_status: [
            {
              id: 'in_relation',
              name: 'In einer Beziehung',
            },
            {
              id: 'single',
              name: 'Alleinstehend',
            },
            {
              id: 'widowed',
              name: 'Verwitwet',
            },
            {
              id: 'married',
              name: 'Verheiratet',
            },
          ],
          smoking_type: [
            {
              id: 'none_smoker',
              name: 'Nein',
            },
            {
              id: 'smoker',
              name: 'Ja',
            },
          ],
          blood_groups: [
            {
              id: 'a+',
              name: 'A+',
            },
            {
              id: 'a-',
              name: 'A-',
            },
            {
              id: 'b+',
              name: 'B+',
            },
            {
              id: 'b-',
              name: 'B-',
            },
            {
              id: '0+',
              name: '0+',
            },
            {
              id: '0-',
              name: '0-',
            },
            {
              id: 'ab+',
              name: 'AB+',
            },
            {
              id: 'ab-',
              name: 'AB-',
            },
          ],
        },
        medical_contact: {
          physician_types: [
            {
              id: 'family_practice_physician',
              name: 'Allgemeinmedizin / Hausarzt',
            },
            {
              id: 'cardiologist',
              name: 'Kardiologie',
            },
            {
              id: 'gynecologist',
              name: 'Gynokologie',
            },
            {
              id: 'psychiatrist',
              name: 'Psychiatrie',
            },
            {
              id: 'dermatologist',
              name: 'Dermatologie',
            },
            {
              id: 'endocrinologist',
              name: 'Endokrinologie',
            },
            {
              id: 'neurologist',
              name: 'Neurologie',
            },
            {
              id: 'radiologist',
              name: 'Radiologie',
            },
            {
              id: 'oncologist',
              name: 'Onkologie',
            },
            {
              id: 'anesthesiologist',
              name: 'Anästhesie',
            },
            {
              id: 'pneumologist',
              name: 'Pneumologie',
            },
          ],
          contact_types: [
            {
              id: 'physician',
              name: 'Arzt',
            },
            {
              id: 'family',
              name: 'Freunde und Familie',
            },
            {
              id: 'hospital',
              name: 'Medizinische Einrichtung',
            },
          ],
        },
        document: {
          categories: [
            {
              id: 'report_stationary',
              name: 'Krankenhausbrief',
            },
            {
              id: 'report_ambulant',
              name: 'Arztbrief',
            },
            {
              id: 'laboratory',
              name: 'Laborbericht',
            },
            {
              id: 'ecg',
              name: 'EKG',
            },
            {
              id: 'imaging',
              name: 'Bildgebung',
            },
            {
              id: 'other',
              name: 'Sonstige Dokumente',
            },
            {
              id: 'procarement',
              name: 'ProCarement',
            },
            {
              id: 'questionnaire',
              name: 'Fragebogen',
            },
            {
              id: 'rights_and_disposals',
              name: 'Vollmachten und Verfügungen',
            },
          ],
        },
        medication: {
          units: [
            {
              id: 'mcg',
              name: 'Mikrogramm (µg)',
            },
            {
              id: 'mg',
              name: 'Milligramm (mg)',
            },
            {
              id: 'g',
              name: 'Gramm (g)',
            },
            {
              id: 'ml',
              name: 'Milliliter (ml)',
            },
            {
              id: '%',
              name: 'Prozent',
            },
            {
              id: 'IU',
              name: 'Internationale Einheit (IU)',
            },
            {
              id: 'mCi',
              name: 'Melliturie (mCi)',
            },
            {
              id: 'mEq',
              name: 'Milliäquivalent (mVal)',
            },
          ],
          units_short: [
            {
              id: 'mcg',
              name: 'µg',
            },
            {
              id: 'mg',
              name: 'mg',
            },
            {
              id: 'g',
              name: 'g',
            },
            {
              id: 'ml',
              name: 'ml',
            },
            {
              id: '%',
              name: '%',
            },
            {
              id: 'IU',
              name: 'IU',
            },
            {
              id: 'mCi',
              name: 'mCi',
            },
            {
              id: 'mEq',
              name: 'mVal',
            },
          ],
          forms: [
            {
              id: 'tablet',
              name: 'Tablette',
            },
            {
              id: 'injectable',
              name: 'Injektion',
            },
            {
              id: 'capsule',
              name: 'Kapsel',
            },
            {
              id: 'solution',
              name: 'Lösung',
            },
            {
              id: 'cream',
              name: 'Creme',
            },
            {
              id: 'drops',
              name: 'Tropfen',
            },
            {
              id: 'spray',
              name: 'Spray',
            },
          ],
          routes: [
            {
              id: 'oral',
              name: 'Oral',
            },
            {
              id: 'injection',
              name: 'Injektion',
            },
            {
              id: 'topical',
              name: 'Topisch',
            },
            {
              id: 'intravenous',
              name: 'Intravenös',
            },
            {
              id: 'subcutaneous',
              name: 'Subkutan',
            },
            {
              id: 'inhalation',
              name: 'Inhalation',
            },
          ],
        },
        phone_number: {
          types: [
            {
              id: 'home',
              name: 'Zuhause',
            },
            {
              id: 'work',
              name: 'Arbeit',
            },
            {
              id: 'mobile',
              name: 'Mobil',
            },
          ],
        },
        patient_diagnosis: {
          nyha: [
            {
              id: '1',
              name: 'NYHA I',
            },
            {
              id: '2',
              name: 'NYHA II',
            },
            {
              id: '3',
              name: 'NYHA III',
            },
            {
              id: '4',
              name: 'NYHA IV',
            },
          ],
        },
      };
    };
    super(generatorFunction);
  }
}
