import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { SerializedRouterStateSnapshot } from '@ngrx/router-store';
import * as AppActions from './app.actions';

export const appFeatureKey = 'app';

export interface State {
  animation: 'page' | 'modal';
  previousRouteSnapshot: SerializedRouterStateSnapshot;
}

export const initialState: State = {
  animation: 'page',
  previousRouteSnapshot: null,
};

export const appReducer = createReducer(
  initialState,
  on(AppActions.changeRouterState, (state, action) => ({
    ...state,
    animation: action.animation,
    previousRouteSnapshot: action.previousRouteSnapshot,
  }))
);

export const selectFeature = createFeatureSelector<State>('app');

export function reducer(state: State | undefined, action: Action) {
  return appReducer(state, action);
}

export const selectAnimation = createSelector(selectFeature, (state) => state.animation);

export const selectPreviousRoute = createSelector(
  selectFeature,
  (state) => state.previousRouteSnapshot?.url.split('?')[0]
);

export const selectPreviosQueryParams = createSelector(
  selectFeature,
  (state) => state.previousRouteSnapshot.root.queryParams
);
