import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  OnDestroy,
  ViewChildren,
  QueryList,
  Input,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  Validators,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  FormBuilder,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ControlErrorDirective } from 'shared/form-error/form-error';
import { has } from 'lodash-es';

const checkAgainstRegEx = (str: string, regex: RegExp) => (regex.exec(str || '') || []).length > 0;

export interface ConfirmPasswordInput {
  password: string;
  password_confirmation: string;
}

@Component({
  selector: 'pro-password-form-group',
  templateUrl: './password-form-group.component.html',
  styleUrls: ['./password-form-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordFormGroupComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordFormGroupComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordFormGroupComponent implements OnDestroy, ControlValueAccessor {
  ngUnsubscribe$: Subject<void> = new Subject<void>();

  @ViewChildren(ControlErrorDirective) formErrors!: QueryList<ControlErrorDirective>;

  @Input() passwordLabel = $localize`Passwort`;

  @Input() passwordConfirmLabel = $localize`Passwort bestätigen`;

  @Input() hidePassword = true;

  @Input() displayTogglePassword = false;

  value: ConfirmPasswordInput;

  confirmPasswordForm: FormGroup<{
    password: FormControl<string | null>;
    password_confirmation: FormControl<string | null>;
  }>;

  passwordState: Record<string, boolean> = {
    hasSpecialOrDigit: null,
    hasLowerCase: null,
    hasUpperCase: null,
    hasMinLength: null,
  };

  constructor(private fb: FormBuilder) {}

  /*
   * ControlValueAccessor-Interface Implementation
   */
  onChangeFn: any = () => {};
  onTouchedFn: any = () => {};

  writeValue(obj: ConfirmPasswordInput): void {
    const trimedInput = {
      password: obj.password.trim(),
      password_confirmation: obj.password_confirmation.trim(),
    };

    this.onChangeFn(trimedInput);
    this.value = trimedInput;

    if (!this.confirmPasswordForm) {
      this.confirmPasswordForm = this.fb.group({
        password: [obj.password, [Validators.required]],
        password_confirmation: [obj.password_confirmation, [Validators.required]],
      });

      this.confirmPasswordForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((e) => {
        this.writeValue({
          password: e.password,
          password_confirmation: e.password_confirmation,
        });
      });
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  setDisabledState?(): void {}

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  validate({ value }: FormControl) {
    let isValid = false;

    if (has(value, 'password') && has(value, 'password_confirmation')) {
      const password: string = value.password;
      const password_confirmation: string = value.password_confirmation;

      this.analyzePassword(password);

      const isStrongPw = this.isStrongPassword();
      const equalPw = value.password === password_confirmation;

      isValid = isStrongPw && equalPw;

      // display missmatch-error?
      if (equalPw === false) {
        this.confirmPasswordForm.controls.password_confirmation.setErrors({
          passwordMissmatch: true,
        });
      } else {
        this.confirmPasswordForm.controls.password_confirmation.setErrors(null);
      }

      // invoke form-errors update
      if (this.formErrors) {
        this.formErrors.map((formError) => formError.update());
      }
    }

    return (
      !isValid && {
        invalid: true,
      }
    );
  }

  /**
   * checks what conditions are fullfilled by the provided password
   *
   * @param pw password to analayze
   */
  analyzePassword(pw: string) {
    const digitCount = checkAgainstRegEx(pw, /[0-9]/);
    // eslint-disable-next-line no-useless-escape
    const specialCharCount = checkAgainstRegEx(pw, /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\_\?]/);

    this.passwordState = {
      hasSpecialOrDigit: digitCount || specialCharCount,
      hasLowerCase: checkAgainstRegEx(pw, /[a-z]/),
      hasUpperCase: checkAgainstRegEx(pw, /[A-Z]/),
      hasMinLength: pw.length > 7,
    };
  }

  /**
   * sets password-strengh, based on fullfilled password conditions
   */
  isStrongPassword(): any {
    const p = this.passwordState;
    return p.hasSpecialOrDigit && p.hasMinLength && p.hasLowerCase && p.hasUpperCase;
  }

  /**
   * displays / hides passworts
   */
  togglePassword() {
    this.hidePassword = !this.hidePassword;
  }
}
