import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Article } from 'app/models';
import * as ArticlesActions from './articles.actions';

export const articlesFeatureKey = 'articles';

export interface State extends EntityState<Article> {
  loading: boolean;
}

export const adapter: EntityAdapter<Article> = createEntityAdapter<Article>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
});

const articlesReducer = createReducer(
  initialState,
  on(ArticlesActions.loadArticles, (state) => ({
    ...state,
    loading: true,
  })),
  on(ArticlesActions.loadArticlesSuccess, (state, action) =>
    adapter.upsertMany(action.articles, state)
  ),
  on(ArticlesActions.loadArticlesSuccess, ArticlesActions.loadArticlesFailure, (state) => ({
    ...state,
    loading: false,
  }))
);

export const selectFeature = createFeatureSelector<State>('articles');

export function reducer(state: State | undefined, action: Action) {
  return articlesReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);
