import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ElementRef,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'pro-result-icon',
  templateUrl: './result-icon.component.html',
  styleUrls: ['./result-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultIconComponent implements OnInit {
  @Input() score: number;
  @Input() type: 'single' | 'multiple' = 'multiple';
  @Input() surveyId: 'kccq12' | 'eq5d' | 'ess' | '2ft' | 'who5' | 'wellbeing' = 'kccq12';

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (this.type === 'single') {
      this.renderer.addClass(this.element.nativeElement, 'single');
    }
  }
}
