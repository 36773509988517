import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromDocumentCategories from './document-categories.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DocumentCategoriesEffects } from './document-categories.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromDocumentCategories.documentCategoriesFeatureKey,
      fromDocumentCategories.reducer
    ),
    EffectsModule.forFeature([DocumentCategoriesEffects]),
  ],
})
export class DocumentCategoriesStoreModule {}
