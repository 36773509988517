import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { UploadRequest } from 'app/models';
import * as UploadRequestActions from './upload-request.actions';

export const uploadRequestsFeatureKey = 'uploadRequests';

export type State = EntityState<UploadRequest>;

export const adapter: EntityAdapter<UploadRequest> = createEntityAdapter<UploadRequest>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const uploadRequestReducer = createReducer(
  initialState,
  on(
    UploadRequestActions.addUploadRequest,
    UploadRequestActions.addEcgResultUploadRequest,
    (state, action) =>
      adapter.upsertOne(
        { ...action.request, requesting: true, canceled: false, error: null },
        state
      )
  ),
  on(UploadRequestActions.addUploadRequestPartialSuccess, (state, action) =>
    adapter.updateOne(
      {
        ...action.update,
        changes: {
          ...action.update.changes,
          requesting: true,
        },
      },
      state
    )
  ),
  on(
    UploadRequestActions.addUploadRequestSuccess,
    UploadRequestActions.addEcgResultUploadRequestSuccess,
    (state, action) => ({
      ...state,
      entities: {
        ...state.entities,
        [action.id]: { ...state.entities[action.id], progress: 100, requesting: false },
      },
    })
  ),
  on(UploadRequestActions.addUploadRequestFailure, (state, action) =>
    adapter.updateOne(
      {
        ...action.update,
        changes: {
          ...action.update.changes,
          requesting: false,
          canceled: true,
        },
      },
      state
    )
  ),
  on(
    UploadRequestActions.loadDocumentsSuccess,
    UploadRequestActions.deleteUploadRequest,
    UploadRequestActions.cancelUploadRequest,
    (state, action) => adapter.removeOne(action.id, state)
  )
);

export function reducer(state: State | undefined, action: Action) {
  return uploadRequestReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('uploadRequests');

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectEcgUploadRequest = createSelector(selectAll, (documents) =>
  documents.find((d) => d.category === 'ecg_result')
);

export const selectUploadRequest = createSelector(selectAll, (documents) => documents[0]);
