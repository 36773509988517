import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  OnDestroy,
  ViewChild,
  ViewChildren,
  QueryList,
  Input,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  NG_VALIDATORS,
  UntypedFormControl,
  UntypedFormArray,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, filter, first, map } from 'rxjs/operators';
import { IonSelect, IonInput } from '@ionic/angular';
import { PhoneType, FormOptions, PhoneNumber } from 'app/models';
import { AppStoreFacade } from 'app/app-store/app-store-facade/app-store.facade';

@Component({
  selector: 'pro-phone-form-group',
  templateUrl: './phone-form-group.component.html',
  styleUrls: ['./phone-form-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneFormGroupComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PhoneFormGroupComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneFormGroupComponent implements OnDestroy, ControlValueAccessor {
  ngUnsubscribe$: Subject<void> = new Subject<void>();
  formOptions: FormOptions;

  @Input() numberRequired = true;

  @ViewChild('phoneTypeSelect') phoneTypeSelect: IonSelect;

  @ViewChildren(IonInput) phoneInputs!: QueryList<IonInput>;

  value: any;
  phoneForm: UntypedFormGroup;
  isInvalid = true;

  phoneTypes$ = this.facade.formOptions$.pipe(
    filter((o) => o !== null),
    map((oo) => oo.phone_number.types),
    first()
  );

  phoneNumbersArr: {
    id: string;
    type: PhoneType;
    number: string;
  }[];

  constructor(private fb: UntypedFormBuilder, private facade: AppStoreFacade) {}

  /*
   * ControlValueAccessor-Interface Implementation
   */
  onChangeFn: any = () => {};
  onTouchedFn: any = () => {};

  writeValue(obj: PhoneNumber[]): void {
    if (obj === null) {
      obj = [];
    }

    this.onChangeFn(obj);
    this.value = obj;

    if (!this.phoneForm) {
      this.phoneForm = this.fb.group({
        phone_numbers: new UntypedFormArray([]),
      });
      // init phone-numbers array
      this.phoneNumbersArr = obj.map((p, i) => {
        return { id: `phone_${i}`, type: p.type, number: p.number };
      });

      this.createPhoneNumbersForm();
      this.watchFormChanges();
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  setDisabledState?(): void {}

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  /**
   * create form for phone-numbers editing
   */
  createPhoneNumbersForm(autofocusLast = false) {
    // get phone-numbers array
    const phoneFormArray = this.phoneForm.get('phone_numbers') as UntypedFormArray;
    // clean form array
    phoneFormArray.clear();
    // add phone numbers to form-array
    this.phoneNumbersArr.map((v) => {
      phoneFormArray.push(new UntypedFormControl(v.number, Validators.required));
    });
    // update component value
    this.writeValue(this.phoneNumbersArr.map((e) => ({ type: e.type, number: e.number })));
    // set focus to last input
    if (autofocusLast === true) {
      setTimeout(() => {
        if (this.phoneInputs.last) {
          this.phoneInputs.last.setFocus();
        }
      }, 750);
    }
  }

  /**
   * adds a new empty phone number and recreates form
   *
   * @param event event holding the phone-type to be added
   */
  addPhoneNumber(event: CustomEvent) {
    if (event && event.detail && event.detail.value) {
      this.phoneNumbersArr = this.updateNumbersArray();

      this.phoneNumbersArr.push({
        id: `phone_${this.phoneNumbersArr.length}`,
        type: event.detail.value,
        number: '',
      });
      // in order to select same phone-type twice, select has to be reset
      if (this.phoneTypeSelect) {
        this.phoneTypeSelect.value = null;
      }
      this.createPhoneNumbersForm(true);
    }
  }

  /**
   * removes a phone-number, and recreates form-array
   *
   * @param index index in phone-array to remove
   */
  removePhoneNumber(index: number) {
    // renew array-values, detele entry and update form-array
    this.phoneNumbersArr = this.updateNumbersArray()
      .filter((n) => n.id !== `phone_${index}`)
      .map((p, i) => {
        return {
          ...p,
          id: `phone_${i}`,
        };
      });

    this.createPhoneNumbersForm();
  }

  /**
   * update phone-array with actual form-values
   */
  updateNumbersArray() {
    return this.phoneNumbersArr.map((p, i) => {
      return {
        ...p,
        number: this.phoneForm.value.phone_numbers[i],
      };
    });
  }

  /**
   * trim input-values on blur to avoid empty inputs. ie. spaces only
   */
  formatInputs() {
    const phoneFormArray = this.phoneForm.get('phone_numbers') as UntypedFormArray;
    this.updateNumbersArray()
      // eslint-disable-next-line
      .map((n) => ({ ...n, number: n.number.replace(/[^\d\+]/g, ' ') }))
      .map((n) => ({ ...n, number: n.number.trim() }))
      .map((t, i) => {
        phoneFormArray.at(i).setValue(t.number);
      });
  }

  /**
   * set form invalid if no valid number is present
   */
  validate({ value }: UntypedFormControl) {
    if (this.numberRequired === false) {
      this.isInvalid = false;
    } else {
      this.isInvalid =
        !value ||
        value.filter((e: any) => e.number !== '').length === 0 ||
        this.phoneNumbersArr.length === 0;
    }

    return (
      this.isInvalid && {
        invalid: true,
      }
    );
  }

  /**
   * subscribe for form changes and update component value
   */
  watchFormChanges() {
    this.phoneForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      // update component value
      this.writeValue(this.updateNumbersArray().map((e) => ({ type: e.type, number: e.number })));
    });
  }
}
