import { createAction, props } from '@ngrx/store';
import { ResultWeight } from 'app/models';
import * as fromBf720 from './bf720.reducer';
import { DeviceInformation } from 'app/models';
import { UserListB720 } from '../devices.model';

export const addState = createAction(
  '[BF720/Store] Add State',
  props<{ state: fromBf720.State }>()
);

export const addStateSuccess = createAction('[BF720/Store] Add State Success');

export const startScanning = createAction('[BF720/Store] Start Scanning');

export const startScanningSuccess = createAction(
  '[BF720/Store] Start Scanning Success',
  props<{ name: string; address: string }>()
);

export const loadUserList = createAction(
  '[BF720/Storage] Load User List',
  props<{ address: string }>()
);

export const loadUserListSuccess = createAction(
  '[BF720/Storage] Load User List Success',
  props<{ userList: UserListB720[] }>()
);

export const loadUserListFailure = createAction(
  '[BF720/Storage] Load User List Failure',
  props<{ error: any }>()
);

export const showConsentCode = createAction(
  '[BF720/Storage] Show Consend Code',
  props<{ userIndex: number }>()
);

export const showConsentCodeSuccess = createAction('[BF720/Storage] Show Consend Code Success');

export const showConsentCodeFailure = createAction(
  '[BF720/Storage] Show Consend Code Failure',
  props<{ error: any }>()
);

export const createUser = createAction('[BF720/Storage] Create User', props<{ code: number }>());

export const createUserSuccess = createAction(
  '[BF720/Storage] Create User Success',
  props<{ userIndex: number; code: number; responseValue: string }>()
);

export const createUserFailed = createAction(
  '[BF720/Storage] Create User Failed',
  props<{ responseValue: string }>()
);

export const createUserFailure = createAction(
  '[BF720/Storage] Create User Failure',
  props<{ error: any }>()
);

export const createUserDisconnected = createAction('[BF720/API] Create User Disconnected');

export const loginCurrentUser = createAction('[BF720/Storage] Login Current User');

export const loginUser = createAction(
  '[BF720/Storage] Login User',
  props<{ userIndex: number; code: number }>()
);

export const loginUserSuccess = createAction(
  '[BF720/Storage] Login Device User Success',
  props<{ userIndex: number; code: number; responseValue: string }>()
);

export const loginUserFailed = createAction(
  '[BF720/Storage] Login Device User Failed',
  props<{ responseValue: string }>()
);

export const loginUserFailure = createAction(
  '[BF720/Storage] Login Device User Failure',
  props<{ error: any }>()
);

export const loginUserDisconnected = createAction('[BF720/API] Login Device User Disconnected');

export const writeCurrentTimeSuccess = createAction('[BF720/Storage] Write Current Time Success');

export const writeCurrentTimeFailure = createAction(
  '[BF720/Storage] Write Current Time Failure',
  props<{ error: any }>()
);

export const writeCurrentTimeDisconnected = createAction(
  '[BF720/API] Write Current Time Disconnected'
);

export const writeScaleSettingsSuccess = createAction(
  '[BF720/Storage] Write Scale Settings Success'
);

export const writeScaleSettingsFailure = createAction(
  '[BF720/Storage] Write Scale Settings Failure',
  props<{ error: any }>()
);

export const writeScaleSettingsDisconnected = createAction(
  '[BF720/API] Write Scale Settings Disconnected'
);

export const writeGenderSuccess = createAction('[BF720/Storage] Write Gender Success');

export const writeGenderFailure = createAction(
  '[BF720/Storage] Write Gender Failure',
  props<{ error: any }>()
);

export const writeGenderDisconnected = createAction('[BF720/API] Write Gender Disconnected');

export const changeDatabaseIncrementSuccess = createAction(
  '[BF720/Storage] Change Database Success'
);

export const changeDatabaseIncrementFailure = createAction(
  '[BF720/Storage] Change Database Failure',
  props<{ error: any }>()
);

export const changeDatabaseIncrementDisconnected = createAction(
  '[BF720/API] Change Database Disconnected'
);

export const loadDeviceInformation = createAction('[BF720/API] Load Device Information');

export const loadDeviceInformationSuccess = createAction(
  '[BF720/API] Load Device Information Success',
  props<{ deviceInformation: DeviceInformation }>()
);

export const loadDeviceInformationFailure = createAction(
  '[BF720/API] Load Device Information Failure',
  props<{ error: any }>()
);

export const loadMeasurements = createAction('[BF720/API] Load Measurements');

export const loadMeasurementsSubscribed = createAction(
  '[BF720/API] Load Measurements Subscribed',
  props<{ results: ResultWeight[]; deviceInformation: DeviceInformation }>()
);

export const loadMeasurementsSubscribedResult = createAction(
  '[BF720/API] Load Measurements Subscribed Results'
);

export const loadMeasurementsSuccess = createAction(
  '[BF720/API] Load Measurements Success',
  props<{
    results: ResultWeight[];
    lastResults: ResultWeight[];
    deviceInformation: DeviceInformation;
  }>()
);

export const loadMeasurementsFailure = createAction(
  '[BF720/API] Load Measurements Failure',
  props<{ error: any }>()
);

export const loadMeasurementsDisconnected = createAction(
  '[BF720/API] Load Measurements Disconnected'
);

export const deleteDevice = createAction('[BF720/API] Delete Device');

export const deleteDeviceSuccess = createAction(
  '[BF720/API] Delete Device Success',
  props<{ address: string }>()
);

export const disconnectDevice = createAction(
  '[BF720/API] Disconnect Device',
  props<{ address: string }>()
);

export const disconnectDeviceSuccess = createAction('[BF720/API] Disconnect Device Success');

export const disconnectDeviceFailure = createAction(
  '[BF720/API] Disconnect Device Failure',
  props<{ error: any }>()
);

export const initialisingError = createAction(
  '[BF720/API] Initialising Error',
  props<{ error: any }>()
);

export const transferringError = createAction(
  '[BF720/API] Transferring Error',
  props<{ error: any }>()
);

export const updateLastResults = createAction(
  '[BF720/API] Update Last Results',
  props<{ lastResults: ResultWeight[] }>()
);
