import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MeasurementTransferComponent } from './measurement-transfer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'shared/shared.module';
import { MeasurementTransferTutorialComponent } from './measurement-transfer-tutorial/measurement-transfer-tutorial.component';
import { DevicesStoreSharedModule } from 'store/devices-store/devices-store-shared/devices-store-shared.module';

@NgModule({
  declarations: [MeasurementTransferComponent, MeasurementTransferTutorialComponent],
  imports: [
    CommonModule,
    IonicModule,
    BrowserAnimationsModule,
    SharedModule,
    DevicesStoreSharedModule,
  ],
  exports: [MeasurementTransferComponent],
})
export class MeasurementTransferModule {}
