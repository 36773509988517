import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  forwardRef,
  Input,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { PickerColumn } from '@ionic/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PickerController } from '@ionic/angular';
import { DecimalPipe } from '@angular/common';

interface PickerReturnValue {
  columnIndex: number;
  text: string;
  value: number;
}

@Component({
  selector: 'pro-value-picker',
  templateUrl: './value-picker.component.html',
  styleUrls: ['./value-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValuePickerComponent),
      multi: true,
    },
    DecimalPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValuePickerComponent implements ControlValueAccessor, AfterViewInit {
  @Input() type: 'height' | 'weight' = 'height';

  /**
   * basic column settings for ion-picker
   * values for min/max can be defined here
   */
  columns: {
    [type: string]: PickerColumn[];
  } = {
    weight: [
      {
        name: 'weightKilo',
        suffix: ',',
        suffixWidth: '5px',
        columnWidth: '50px',
        options: this.createRangeArray(0, 600),
        selectedIndex: null,
      },
      {
        name: 'weightGramm',
        suffix: 'kg',
        suffixWidth: '24px',
        columnWidth: '46px',
        options: this.createRangeArray(0, 9),
        selectedIndex: null,
      },
    ],
    height: [
      {
        name: 'height',
        suffix: 'cm',
        columnWidth: '110px',
        options: this.createRangeArray(20, 260),
        selectedIndex: null,
      },
    ],
  };

  value: number; // actual form-value
  formattedValue: string; // displayed value
  placeholder = '';

  constructor(
    private pickerController: PickerController,
    private decimalPipe: DecimalPipe,
    private cdRef: ChangeDetectorRef,
    private el: ElementRef
  ) {}

  ngAfterViewInit(): void {
    /*
    try to find the wrapping ion-item-element
    register click-handler if available. use component-element if no ion-item was found
    */
    const clickElement =
      this.el.nativeElement.parentElement?.nodeName === 'ION-ITEM'
        ? this.el.nativeElement.parentElement
        : this.el.nativeElement;

    clickElement.addEventListener('click', () => {
      this.showPicker();
    });

    if (this.type) {
      this.placeholder =
        this.type === 'height'
          ? $localize`Wähle hier Deine Größe aus`
          : $localize`Wähle hier aus, wie viel Du wiegst, wenn Du keine Wassereinlagerungen hast`;

      this.cdRef.detectChanges();
    }
  }

  /*
   * ControlValueAccessor-Interface Implementation
   */
  onChangeFn: any = () => {};
  onTouchedFn: any = () => {};

  writeValue(value: any): void {
    this.onChangeFn(value);
    if (value) {
      this.value = +value;

      this.formattedValue =
        this.type === 'weight'
          ? `${this.decimalPipe.transform(this.value, '1.1-1')} kg`
          : `${this.value} cm`;
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  setDisabledState?(): void {}

  /**
   * gets column-content and displays ion-picker
   *
   * @param type picker-type to open
   */
  async showPicker() {
    const picker = await this.pickerController.create({
      buttons: [
        {
          text: $localize`Abbrechen`,
          role: 'cancel',
        },
        {
          text: $localize`Übernehmen`,
          handler: (value: {
            weightKilo?: PickerReturnValue;
            weightGramm?: PickerReturnValue;
            height?: PickerReturnValue;
          }) => {
            // update value
            if (this.type === 'height' && value?.height) {
              this.writeValue(value.height.value);
            } else if (this.type === 'weight' && value?.weightKilo && value?.weightGramm) {
              this.writeValue(value.weightKilo.value + value.weightGramm.value / 10);
            }
            this.cdRef.detectChanges();
          },
        },
      ],
      columns: this.getPickerColumns(this.type),
    });
    await picker.present();
  }

  /**
   * fetches desired column(s), finds and sets current selected index
   *
   * @param type column-type to show
   */
  getPickerColumns(type: 'height' | 'weight'): PickerColumn[] {
    if (type === 'weight') {
      // sets default values if necessary
      const weightParts = this.value?.toFixed(1).split('.') || [62, 0];

      return [
        {
          ...this.columns.weight[0],
          selectedIndex: this.columns.weight[0].options.findIndex(
            (x) => x.value === +weightParts[0]
          ),
        },
        {
          ...this.columns.weight[1],
          selectedIndex: this.columns.weight[1].options.findIndex(
            (x) => x.value === +weightParts[1]
          ),
        },
      ];
    }

    if (type === 'height') {
      // sets default value if necessary
      const heightValue = +this.value || 165;

      return [
        {
          ...this.columns.height[0],
          selectedIndex: this.columns.height[0].options.findIndex((x) => x.value === heightValue),
        },
      ];
    }
  }

  /**
   * picker helpers
   */
  /**
   * gets a from- and to-value. Fills an array with {text: string, value: number} objects between those boundaries
   *
   * @param from first element in array
   * @param to last element in array
   */
  createRangeArray(from: number, to: number): { text: string; value: number }[] {
    return [...Array(to - from + 1).keys()].map((e) => ({
      text: (e + from).toString(),
      value: e + from,
    }));
  }
}
