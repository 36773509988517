import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MedicalContact } from 'app/models';
import * as MedicalContactsActions from './medical-contacts.actions';

export const medicalContactsFeatureKey = 'medicalContacts';

export interface State extends EntityState<MedicalContact> {
  loading: boolean;
  loaded: boolean;
}

export const adapter: EntityAdapter<MedicalContact> = createEntityAdapter<MedicalContact>();

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
});

const medicalContactsReducer = createReducer(
  initialState,
  on(
    MedicalContactsActions.loadMedicalContacts,
    MedicalContactsActions.updateMedicalContact,
    MedicalContactsActions.createMedicalContact,
    MedicalContactsActions.deleteMedicalContact,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(MedicalContactsActions.loadMedicalContactsSuccess, (state, action) =>
    adapter.upsertMany(action.medicalContacts, state)
  ),
  on(MedicalContactsActions.updateMedicalContactSuccess, (state, action) =>
    adapter.upsertOne(action.medicalContact, state)
  ),
  on(MedicalContactsActions.createMedicalContactSuccess, (state, action) =>
    adapter.addOne(action.medicalContact, state)
  ),
  on(MedicalContactsActions.deleteMedicalContactSuccess, (state, action) =>
    adapter.removeOne(action.medicalContactId, state)
  ),

  on(
    MedicalContactsActions.loadMedicalContactsSuccess,
    MedicalContactsActions.loadMedicalContactsFailure,
    MedicalContactsActions.updateMedicalContactSuccess,
    MedicalContactsActions.updateMedicalContactFailure,
    MedicalContactsActions.createMedicalContactSuccess,
    MedicalContactsActions.createMedicalContactFailure,
    MedicalContactsActions.deleteMedicalContactSuccess,
    MedicalContactsActions.deleteMedicalContactFailure,
    (state) => ({
      ...state,
      loading: false,
      loaded: true,
    })
  )
);

export const selectFeature = createFeatureSelector<State>('medicalContacts');

export function reducer(state: State | undefined, action: Action) {
  return medicalContactsReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);

export const selectLoaded = createSelector(selectFeature, (state) => state.loaded);
