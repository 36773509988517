import {
  HttpProgressEvent,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpResponse,
  HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, filter, takeUntil } from 'rxjs/operators';
import { ApiService } from 'store/api/api.service';
import { ResponseObject, Upload, UploadRequest } from 'app/models';
import * as UploadRequestActions from './upload-request.actions';
import * as dayjs from 'dayjs';

const isHttpProgressEvent = (
  event:
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpResponse<any>
    | HttpProgressEvent
    | HttpUserEvent<any>
): event is HttpProgressEvent => {
  return (event as HttpProgressEvent).loaded !== undefined;
};

@Injectable()
export class UploadRequestEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  addUpload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UploadRequestActions.addUpload, UploadRequestActions.addEcgResultUpload),
      map((action) =>
        action.type === UploadRequestActions.addUpload.type
          ? UploadRequestActions.addUploadRequest({ request: this.generateRequest(action) })
          : UploadRequestActions.addEcgResultUploadRequest({
              request: this.generateRequest(action.upload),
              heartRate: action.heartRate,
              determination: action.determination,
              device: action.device,
            })
      )
    );
  });

  addUploadRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UploadRequestActions.addUploadRequest, UploadRequestActions.addEcgResultUploadRequest),
      mergeMap((action) =>
        this.apiService
          .uploadDocuments(
            action.request.description,
            action.request.original_date,
            action.request.category,
            action.request.files
          )
          .pipe(
            filter((e) => isHttpProgressEvent(e) || e instanceof HttpResponse),
            map((data: HttpProgressEvent | HttpResponse<ResponseObject>) => {
              if (
                data instanceof HttpResponse &&
                action.type === UploadRequestActions.addEcgResultUploadRequest.type
              ) {
                const locationHeaderArr =
                  data instanceof HttpResponse ? data.headers.get('location')?.split('/') : null;
                const documentId = locationHeaderArr
                  ? parseInt(locationHeaderArr[locationHeaderArr.length - 1], 0)
                  : action.request.id;
                return UploadRequestActions.addEcgResultUploadRequestSuccess({
                  id: action.request.id,
                  results: {
                    results: [
                      {
                        type: 'ecg',
                        document_id: documentId,
                        device_time: action.request.original_date,
                        heart_rate: action.heartRate,
                        determination: action.determination,
                      },
                    ],
                    device: action.device,
                  },
                });
              }

              return data instanceof HttpResponse
                ? UploadRequestActions.addUploadRequestSuccess({ id: action.request.id })
                : UploadRequestActions.addUploadRequestPartialSuccess({
                    update: {
                      id: action.request.id,
                      changes: data.hasOwnProperty('total')
                        ? {
                            progress: Math.round((100 * data.loaded) / data.total) - 1,
                          }
                        : {},
                    },
                  });
            }),
            catchError((error) =>
              of(
                UploadRequestActions.addUploadRequestFailure({
                  update: {
                    id: action.request.id,
                    changes: { error },
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(UploadRequestActions.cancelUploadRequest)))
          )
      )
    );
  });

  loadDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UploadRequestActions.addUploadRequestSuccess,
        UploadRequestActions.addEcgResultUploadRequestSuccess
      ),
      mergeMap((action) =>
        this.apiService.getDocuments({ from: dayjs().format('YYYY-MM-DD') }).pipe(
          map((data) =>
            UploadRequestActions.loadDocumentsSuccess({ id: action.id, documents: data.results })
          ),
          catchError((error) => of(UploadRequestActions.loadDocumentsFailure({ error })))
        )
      )
    );
  });

  generateId() {
    return Date.now();
  }

  private generateRequest(upload: Upload): UploadRequest {
    return {
      id: this.generateId(),
      description: upload.description,
      original_date: upload.original_date,
      category: upload.category,
      files: upload.files,
      requesting: false,
      progress: 0,
      canceled: false,
      error: null,
    };
  }
}
