<form
  *ngIf="addressForm"
  [formGroup]="addressForm"
>
  <ion-item lines="full">
    <ion-label
      position="stacked"
      i18n
    >Straße & Hausnummer<span class="optional">optionales Feld</span></ion-label>
    <ion-input
      formControlName="street"
      type="text"
      inputmode="text"
      proControlError
    ></ion-input>
  </ion-item>

  <ion-item lines="full">
    <ion-label
      position="stacked"
      i18n
    >Adresszusatz<span class="optional">optionales Feld</span></ion-label>
    <ion-input
      formControlName="street_2"
      type="text"
      inputmode="text"
    ></ion-input>
  </ion-item>

  <ion-item lines="full">
    <ion-label
      position="stacked"
      i18n
    >Postleitzahl<span class="optional">optionales Feld</span></ion-label>
    <ion-input
      formControlName="zip_code"
      type="text"
      inputmode="numeric"
      proControlError
    ></ion-input>
  </ion-item>

  <ion-item lines="full">
    <ion-label
      position="stacked"
      i18n
    >Stadt<span class="optional">optionales Feld</span></ion-label>
    <ion-input
      formControlName="city"
      type="text"
      inputmode="text"
      proControlError
    ></ion-input>
  </ion-item>
</form>