import { createAction, props } from '@ngrx/store';
import { Activation, ActivationStatus } from 'app/models';

export const loadActivations = createAction('[Activations] Load Activations');

export const loadActivationsSuccess = createAction(
  '[Activations] Load Activations Success',
  props<{ activations: Activation[]; currentStatus: ActivationStatus }>()
);

export const loadActivationsFailure = createAction(
  '[Activations] Load Activations Failure',
  props<{ error: any }>()
);

export const addActivation = createAction(
  '[Activations] Add Activation',
  props<{ code: string; cleanPatientData: boolean }>()
);

export const addActivationSuccess = createAction(
  '[Activations] Add Activation Success',
  props<{ activation: Activation }>()
);

export const addActivationFailure = createAction(
  '[Activations] Add Activation Failure',
  props<{ error: any }>()
);
