import { Injectable } from '@angular/core';
import { BmEffects } from '../bm.effects';
import * as Bm54Actions from './bm54.actions';
import * as fromBm54 from './bm54.reducer';

@Injectable()
export class Bm54Effects {
  addState$ = this.bmEffects.createAddState$(Bm54Actions);
  loadDeviceInformation$ = this.bmEffects.createLoadDeviceInformation$(Bm54Actions);
  loadMeasurements$ = this.bmEffects.createLoadMeasurements$(Bm54Actions, fromBm54);
  deleteDevice$ = this.bmEffects.createDeleteDevice$(Bm54Actions, fromBm54);
  disconnectDevice$ = this.bmEffects.createDisconnectDevice$(Bm54Actions, fromBm54);

  constructor(private bmEffects: BmEffects) {}
}
