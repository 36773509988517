import { Component, Input, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'pro-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent {
  @Input() pdfSrc: Blob | string = null;

  @Input() zoom = 1;

  pdfError = false;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  onError(error: any) {
    this.pdfError = true;
    throw new Error(error as string);
  }

  /**
   * allow scroll by setting pdf-container position to relative
   * need to wait until pages were loaded
   */
  pdfInitialized() {
    const pdfContainer = this.elRef.nativeElement.querySelector('.ng2-pdf-viewer-container');

    if (pdfContainer) {
      this.renderer.setStyle(pdfContainer, 'position', 'relative');
    }
  }
}
