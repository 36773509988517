import { AnimationBuilder, createAnimation } from '@ionic/angular';
import { Animation } from '@ionic/core';

/**
 * customized animation for alert-modals
 */
export function alertEnterAnimation(baseEl: HTMLElement) {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector('ion-backdrop'))
    .fromTo('opacity', 0.01, 0.3)
    .beforeStyles({
      ['pointer-events']: 'none',
    })
    .afterClearStyles(['pointer-events']);

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelectorAll('.alert-wrapper'))
    .beforeStyles({ opacity: 1 })
    .fromTo('transform', 'translateY(100vh)', 'translateY(0vh)');

  return createAnimation()
    .addElement(baseEl)
    .easing('cubic-bezier(0.32,0.72,0,1)')
    .duration(400)
    .addAnimation([backdropAnimation, wrapperAnimation]);
}

export function alertLeaveAnimation(baseEl: HTMLElement) {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector('ion-backdrop'))
    .fromTo('opacity', 0.3, 0.01)
    .beforeStyles({
      ['pointer-events']: 'none',
    })
    .afterClearStyles(['pointer-events']);

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelectorAll('.alert-wrapper'))
    .beforeStyles({ opacity: 1 })
    .fromTo('transform', 'translateY(0vh)', 'translateY(100vh)');

  return createAnimation()
    .addElement(baseEl)
    .easing('cubic-bezier(0.32,0.72,0,1)')
    .duration(400)
    .addAnimation([backdropAnimation, wrapperAnimation]);
}

/**
 * customized animation for page-transitions
 */

export type NavDirection = 'back' | 'forward';
export type Mode = 'ios' | 'md';

export interface RouterOutletOptions {
  animated?: boolean;
  animationBuilder?: AnimationBuilder;
  duration?: number;
  easing?: string;
  showGoBack?: boolean;
  direction?: NavDirection;
  deepWait?: boolean;
  mode?: Mode;
  keyboardClose?: boolean;
  skipIfBusy?: boolean;
  progressAnimation?: boolean;
}

export interface FrameworkDelegate {
  attachViewToDom(
    container: any,
    component: any,
    propsOrDataObj?: any,
    cssClasses?: string[]
  ): Promise<HTMLElement>;
  removeViewFromDom(container: any, component: any): Promise<void>;
}

export interface NavOptions extends RouterOutletOptions {
  progressAnimation?: boolean;
  updateURL?: boolean;
  delegate?: FrameworkDelegate;
  viewIsReady?: (enteringEl: HTMLElement) => Promise<any>;
}

export interface TransitionOptions extends NavOptions {
  progressCallback?: (ani: Animation | undefined) => void;
  baseEl: any;
  enteringEl: HTMLElement;
  leavingEl: HTMLElement | undefined;
}

export const getIonPageElement = (element: HTMLElement) => {
  if (element.classList.contains('ion-page')) {
    return element;
  }

  const ionPage = element.querySelector(':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs');
  if (ionPage) {
    return ionPage;
  }
  return element;
};

export function pageTransition(_: HTMLElement, opts: TransitionOptions) {
  const DURATION = 240;

  // root animation with common setup for the whole transition
  const rootTransition = createAnimation()
    .duration(opts.duration || DURATION)
    .easing('cubic-bezier(0.3,0,0.66,1)');

  // ensure that the entering page is visible from the start of the transition
  const enteringPage = createAnimation()
    .addElement(getIonPageElement(opts.enteringEl))
    .beforeRemoveClass('ion-page-invisible');

  // create animation for the leaving page
  const leavingPage = createAnimation().addElement(getIonPageElement(opts.leavingEl));

  // customized animation
  if (opts.direction === 'forward') {
    enteringPage.fromTo('transform', 'translateX(100%)', 'translateX(0)');
    leavingPage
      .fromTo('opacity', '1', '0.25')
      .fromTo('transform', 'translateX(0)', 'translateX(-30%)');
  } else {
    leavingPage
      .fromTo('transform', 'translateX(0)', 'translateX(100%)')
      .afterClearStyles(['z-index']); // clear styles to prevent swipe flickering
    enteringPage
      .fromTo('opacity', '0.25', '1')
      .delay(50)
      .fromTo('transform', 'translateX(-30%)', 'translateX(0)');
  }

  // include animations for both pages into the root animation
  rootTransition.addAnimation(enteringPage);
  rootTransition.addAnimation(leavingPage);
  return rootTransition;
}

export function modalTransition(element: HTMLElement, opts: TransitionOptions) {
  const DURATION = 240;

  // root animation with common setup for the whole transition
  const rootTransition = createAnimation().easing('cubic-bezier(0.3,0,0.66,1)').duration(DURATION);

  // ensure that the entering page is visible from the start of the transition
  const enteringPage = createAnimation()
    .addElement(getIonPageElement(opts.enteringEl))
    .beforeRemoveClass('ion-page-invisible');

  // create animation for the leaving page
  const leavingPage = createAnimation().addElement(getIonPageElement(opts.leavingEl));

  // customized animation
  if (opts.direction === 'forward') {
    enteringPage
      .fromTo('transform', 'translateY(100%)', 'translateY(0)')
      .easing('ease-out')
      .duration(DURATION);
  } else {
    leavingPage
      .fromTo('transform', 'translateY(0)', 'translateY(100%)')
      .afterClearStyles(['z-index']); // clear styles to prevent swipe flickering
  }

  // include animations for both pages into the root animation
  rootTransition.addAnimation(enteringPage);
  rootTransition.addAnimation(leavingPage);
  return rootTransition;
}
