/* eslint-disable @typescript-eslint/no-this-alias */
import {
  Component,
  ElementRef,
  OnChanges,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { isEqual } from 'lodash-es';
import { BaseChartComponent } from '../base-chart/base-chart.component';
import { ChartPage, ChartPageItem } from '../chart.component';
import * as d3 from 'd3';

@Component({
  selector: 'pro-icon-chart',
  templateUrl: './icon-chart.component.html',
  styleUrls: ['./icon-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconChartComponent extends BaseChartComponent implements OnChanges {
  @Output() navigateToDocument: EventEmitter<number> = new EventEmitter();

  iconBase64data = {
    ecg: `data:image/svg+xml;base64,PHN2ZwogICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgd2lkdGg9IjI0IgogICAgaGVpZ2h0PSIyNCIKICAgIHZpZXdCb3g9IjAgMCAyNCAyNCIKPgogICAgPGcgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cGF0aAogICAgICAgICAgICBkPSJNMTguOCAxLjE3NWMxLjY3IDAgMy4wMjUgMS4zNTQgMy4wMjUgMy4wMjV2MTUuNmMwIDEuNjctMS4zNTQgMy4wMjUtMy4wMjUgMy4wMjVINS4yYy0xLjY3IDAtMy4wMjUtMS4zNTQtMy4wMjUtMy4wMjVWNC4yYzAtMS42NyAxLjM1NC0zLjAyNSAzLjAyNS0zLjAyNXptMCAxLjY1SDUuMmMtLjc2IDAtMS4zNzUuNjE2LTEuMzc1IDEuMzc1djE1LjZjMCAuNzYuNjE2IDEuMzc1IDEuMzc1IDEuMzc1aDEzLjZjLjc2IDAgMS4zNzUtLjYxNiAxLjM3NS0xLjM3NVY0LjJjMC0uNzYtLjYxNi0xLjM3NS0xLjM3NS0xLjM3NXptLTcuOTgzIDMuODgybC4wMzIuMTExIDEuNDc0IDYuNTM4LjkwMi0zLjc2NWMuMTczLS43MjEgMS4xMTgtLjg1MiAxLjQ5NS0uMjU3bC4wNTUuMSAxLjA2NSAyLjI4MWgxLjY2NWMuNDIgMCAuNzY4LjMxNS44MTkuNzIybC4wMDYuMTAzYzAgLjQyMS0uMzE1Ljc2OC0uNzIyLjgxOWwtLjEwMy4wMDZoLTIuMTljLS4yOCAwLS41MzktLjE0Mi0uNjktLjM3MmwtLjA1Ny0uMTAzLS4yODktLjYxNy0xLjE3NyA0LjkyYy0uMTk0LjgxLTEuMzA2Ljg0LTEuNTc0LjFsLS4wMzMtLjExMS0xLjQ3My02LjUzMi0uNDk1IDIuMDgyYy0uMDguMzM0LS4zNTcuNTgtLjY5LjYyNmwtLjExMy4wMDdINi41MDVjLS40NTYgMC0uODI1LS4zNjktLjgyNS0uODI1IDAtLjQyLjMxNS0uNzY3LjcyMi0uODE4bC4xMDMtLjAwN2gxLjU2N2wxLjE3LTQuOTA2Yy4xOTMtLjgxMiAxLjMwNy0uODQyIDEuNTc1LS4xMDJ6IgogICAgICAgICAgICB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDUgLTQxMCkgdHJhbnNsYXRlKDI1IDE1NykgdHJhbnNsYXRlKDAgMjI4KSB0cmFuc2xhdGUoMjAgMjUpIgogICAgICAgIC8+CiAgICA8L2c+Cjwvc3ZnPg==`,
  };

  constructor(elRef: ElementRef) {
    super(elRef);

    this.conf = {
      ...this.conf,
      rightAxisWitdh: 14,
    };
  }

  /**
   * Adds a click-handler to the chart-svg to close the active-tooltip
   */
  initSVG() {
    const that = this;
    super.initSVG();

    // Adds a click-handler to the chart-svg to close the active tooltip
    d3.select('#chartSVG').on('click', function () {
      that.hideTooltip();
    });
  }

  /**
   * Invokes methods to draw a single page, except the page is drawn already
   * Adds a new page to the stored pages array
   * @param chartPage page to draw
   */
  drawPage(chartPage: ChartPage) {
    super.drawPage(chartPage);

    if (!isEqual(chartPage, this.renderedPages[chartPage.pageNumber])) {
      this.renderedPages[chartPage.pageNumber] = chartPage;
      this.drawPageBasics(chartPage);
      this.drawIcons(chartPage);
    }
  }

  /**
   * Adds svg-image-elements to the corresponding page-group.
   * Updates and deletes icons if page-data change
   * @param chartPage Page to draw the icons from
   */
  drawIcons(chartPage: ChartPage) {
    const stepWidth = this.conf.graphWidth / this.itemsPerPage;
    const centerX = stepWidth / 2;

    this.pagesGroup
      .select(`.page-${chartPage.pageNumber} .graph-container`)
      .selectAll('.document-icon')
      .data(chartPage.items)
      .join(
        (enter) => {
          const icon = enter
            .append('svg:image')
            .attr('class', 'document-icon')
            .attr('width', (d) => (d.showItem ? 28 : 0))
            .attr('x', (_, i) => i * stepWidth + centerX - 14)
            .attr('y', this.conf.svgHeight / 2)
            .attr('href', this.iconBase64data.ecg)
            .style('cursor', 'pointer')
            .on('click', (event: PointerEvent, item) => {
              event.preventDefault();
              event.stopPropagation();
              this.handleIconClick(event.offsetX, item);
            });

          icon.append('svg:title').text($localize`Hier klicken für weitere Details...`);

          return icon;
        },
        (update) => {
          return update
            .attr('width', (d) => (d.showItem ? 28 : 0))
            .transition()
            .duration(this.conf.defaultAnimationDuration)
            .style('opacity', 1);
        },
        (exit) => {
          return exit
            .transition()
            .duration(this.conf.defaultAnimationDuration)
            .style('opacity', 0)
            .remove();
        }
      );
  }

  /**
   * Navigates to target-document if only one document-id is available
   * Opens tooltip for multiple document-ids
   * @param xPosition X-Position the click event was invoked
   * @param item Page-Item that belongs to clicked position
   */
  handleIconClick(xPosition: number, item: ChartPageItem) {
    const clickedIndex = Math.floor(xPosition / (this.conf.graphWidth / this.itemsPerPage));

    // Ignores click on same icon twice
    if (this.activeTooltip === clickedIndex) {
      return;
    }

    // Emits navigation, if there is only one value in the current item
    if (item.labels.length === 1) {
      this.hideTooltip();
      this.navigateToDocument.emit(+item.labels[0].documentId);
      return;
    }

    this.showTooltip(clickedIndex);
  }

  /**
   * Displays a tooltip including date info and a list of document-ids
   * Invokes navigation on label-click
   * @param index Page-item-index the tooltip should get opened for
   */
  async showTooltip(index: number) {
    const currentPage = this.renderedPages[this.currentPage];
    const clickedItem = currentPage.items[index];

    await this.hideTooltip();
    this.activeTooltip = index;

    const tooltip = d3.select(this.hostElement).select('.tooltip') as any;
    tooltip.selectAll('.links span').remove();
    tooltip.select('.info').text(clickedItem.title);

    tooltip
      .select('.labels')
      .selectAll('span')
      .data(clickedItem.labels)
      .join('span')
      .text((label: { text: string; documentId: number }) => label.text)
      .style('font-weight', 'bold')
      .style('display', 'block')
      .on('click', (ev: PointerEvent, clickedLabel: { text: string; documentId: number }) => {
        this.navigateToDocument.emit(clickedLabel.documentId);
        this.hideTooltip();
      });

    // calc tooltip x-position
    const labelDimendions = tooltip.node().getBoundingClientRect();
    let tooltipXPosition = Math.round(this.xScale(index + 0.5));
    // tooltip cuts left border
    if (tooltipXPosition < 20) {
      tooltipXPosition = 20;
    }

    // tooltip cuts right border
    if (tooltipXPosition + +labelDimendions.width > this.conf.svgWidth) {
      tooltipXPosition = this.conf.svgWidth - labelDimendions.width - 20;
    }
    // shows tooltip
    tooltip
      .style('left', `${tooltipXPosition}px`)
      .transition()
      .style('opacity', '1')
      .duration(this.conf.defaultAnimationDuration);
  }
}
