<ng-content></ng-content>
<div
  class="actions"
  role="group"
>
  <button
    (click)="emitDelete()"
    data-cy="action-delete"
    aria-label="löschen"
    i18n-aria-label
    *ngIf="hideDelete === false"
  >
    <ion-icon
      name="trash-outline"
      color="primary"
      aria-hidden="true"
    ></ion-icon>
  </button>

  <button
    (click)="emitEdit()"
    data-cy="action-edit"
    aria-label="bearbeiten"
    i18n-aria-label
    *ngIf="hideEdit === false"
  >
    <ion-icon
      color="primary"
      src="assets/svgs/edit-pen.svg"
      aria-hidden="true"
    ></ion-icon>
  </button>

  <ng-content select="ion-icon"></ng-content>
</div>