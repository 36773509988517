import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Platform } from '@ionic/angular';
import { MonitoringGoal, ObservationResult } from 'app/models';
import { ValueFormatPipe } from 'shared/pipe/value-format.pipe';
import * as dayjs from 'dayjs';

@Component({
  selector: 'pro-monitoring-goal-tile',
  templateUrl: './monitoring-goal-tile.component.html',
  styleUrls: ['./monitoring-goal-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ValueFormatPipe],
})
export class MonitoringGoalTileComponent {
  view: {
    type: string;
    name: string;
    hasValidResult: boolean;
    label: string;
    result: ObservationResult;
  };
  isIos = this.platform.is('ios');

  @Input() small = false;

  @Input() checkIfTodaysMeasurement = true;

  @Input() lines = 'none';

  @Input() detail = false;

  @Input()
  set goal(monitoringGoal: MonitoringGoal) {
    /**
     * checkIfTodaysMeasurement: true
     * show only results from today (dashboard)
     * checkIfTodaysMeasurement: false
     * check if any results are available (calendar)
     */
    const lastResult = monitoringGoal.last_results[0];

    const hasActiveResult = this.checkIfTodaysMeasurement
      ? !!lastResult && dayjs().isSame(lastResult.device_time, 'd')
      : !!lastResult;

    let hasValidResult = hasActiveResult;

    if (monitoringGoal.type === 'EcgGoal' && hasActiveResult) {
      hasValidResult = ['NORMAL', 'AFIB', 'UNCLASSIFIED', 'TACHYCARDIA', 'BRADYCARDIA'].includes(
        monitoringGoal.last_results[0].determination
      );
    }

    this.view = {
      type: monitoringGoal.type,
      name: monitoringGoal.name,
      hasValidResult,
      label: hasActiveResult
        ? this.getLastResultLabel(monitoringGoal)
        : this.getEmptyLabel(monitoringGoal),
      result: hasActiveResult ? lastResult : null,
    };
  }

  constructor(private valueFormat: ValueFormatPipe, private platform: Platform) {}

  private getLastResultLabel(goal: MonitoringGoal) {
    const lastResult = goal?.last_results[0];

    if (!lastResult || lastResult.type === 'survey') {
      return '-';
    }

    if (lastResult.type === 'blood_pressure') {
      return `${this.valueFormat.transform(
        lastResult.systolic,
        goal.type
      )}/${this.valueFormat.transform(lastResult.diastolic, goal.type)} ${goal.result_unit}`;
    } else {
      return lastResult.type === 'ecg'
        ? $localize`gemessen`
        : `${this.valueFormat.transform(lastResult.value, goal.type)} ${goal.result_unit}`;
    }
  }

  private getEmptyLabel(goal: MonitoringGoal) {
    return goal.type === 'BloodPressureGoal' ? `-/- ${goal.result_unit}` : `- ${goal.result_unit}`;
  }
}
