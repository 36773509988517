import {
  Component,
  ChangeDetectionStrategy,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { trigger, animate, state, transition, style, sequence } from '@angular/animations';
import { Platform } from '@ionic/angular';
import { OnInit } from '@angular/core';

export enum TutorialLevels {
  intro,
  ctaInfo,
  prepareTileInfo,
  bluetoothInfo,
  prepareManualMeasurementInfo,
  manualMeasurementInfo,
  cancelTutorial,
  finishTutorial,
  tutorialCanceled,
}

@Component({
  selector: 'pro-measurement-transfer-tutorial',
  templateUrl: './measurement-transfer-tutorial.component.html',
  styleUrls: ['./measurement-transfer-tutorial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('backdropAnimation', [
      state('hidden', style({ opacity: 0 })),
      state('visible', style({ opacity: 0.3 })),
      transition('hidden => visible', [
        style({ opacity: 0 }),
        animate('400ms {{delay}}ms ease', style({ opacity: 0.3 })),
      ]),
      transition('visible => hidden', [
        style({ opacity: 0.3 }),
        animate('400ms ease', style({ opacity: 0 })),
      ]),
    ]),
    trigger('fadeInOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('450ms {{delay}}ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [style({ opacity: 1 }), animate('450ms', style({ opacity: 0 }))]),
    ]),
    trigger('infoBoxAnimation', [
      transition('hidden => visible', [
        style({ opacity: 0, transform: 'translateY(-14px)' }),
        sequence([
          animate('250ms {{delay}}ms ease', style({ opacity: 1, transform: 'translateY(5px)' })),
          animate('250ms ease', style({ opacity: 1, transform: 'translateY(0)' })),
        ]),
      ]),
      transition('visible => hidden', [
        style({ opacity: 1 }),
        animate('400ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class MeasurementTransferTutorialComponent implements OnInit {
  private TUTORIAL_LEVEL: TutorialLevels;

  @Input() set tutorialLevel(level: TutorialLevels) {
    if (!this.cta) return;

    this.prepareAnimationDelays(level);
    this.prepareBackdrop(level);
    // Adds current info boxes to DOM
    this.TUTORIAL_LEVEL = level === undefined ? null : level;
    this.cdRef.detectChanges();
    this.hideAllInfoBoxes();

    switch (level) {
      case TutorialLevels.intro:
        this.backdropVisibility = 'visible';
        this.showIntro();
        break;
      case TutorialLevels.ctaInfo:
        this.backdropVisibility = 'visible';
        this.showCtaInfo();
        break;
      case TutorialLevels.prepareTileInfo:
        this.backdropVisibility = 'hidden';
        break;
      case TutorialLevels.bluetoothInfo:
        this.backdropVisibility = 'visible';
        this.showBluetoothInfo();
        break;
      case TutorialLevels.manualMeasurementInfo:
        this.backdropVisibility = 'visible';
        this.showManualMeasurementInfo();
        break;
      case TutorialLevels.cancelTutorial:
        this.backdropVisibility = 'visible';
        this.showStartAgainInfo();
        break;
      default:
        break;
    }
  }

  get tutorialLevel() {
    return this.TUTORIAL_LEVEL;
  }

  @Input() autoplay = false;

  @Output() tutorialLevelChanged: EventEmitter<number> = new EventEmitter();
  @Output() measurementTransferClicked: EventEmitter<any> = new EventEmitter();

  cta: HTMLElement;
  tutorialLevels = TutorialLevels;

  backdropAnimationDelay = 0;
  infoboxAnimationDelay = 0;

  backdropVisibility: 'visible' | 'hidden' = 'hidden';

  infoBoxesVisibilities: Record<string, 'visible' | 'hidden'> = {
    intro: 'hidden',
    cta: 'hidden',
    bluetooth: 'hidden',
    manual: 'hidden',
    reopen: 'hidden',
  };

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private platform: Platform,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initTutorialTrigger();
  }

  setTutorialLevel(level: TutorialLevels) {
    this.tutorialLevelChanged.emit(level);
  }

  getElementHeight(element: Element) {
    const styles = window.getComputedStyle(element);
    return +styles.height.replace('px', '');
  }

  infoboxAnimationDone(triggerEvent: any) {
    if (triggerEvent.toState === 'hidden') {
      this.renderer.removeStyle(triggerEvent.element, 'top');
    }
  }

  hideAllInfoBoxes() {
    Object.keys(this.infoBoxesVisibilities).map((infoKey) => {
      this.infoBoxesVisibilities[infoKey] = 'hidden';
    });
  }

  showIntro() {
    // Shows intro infobox
    const introElement: Element = this.elRef.nativeElement.querySelector(`.intro`);
    this.renderer.setStyle(
      introElement,
      'top',
      `${this.cta?.offsetTop - this.getElementHeight(introElement) - 22}px`
    );

    this.infoBoxesVisibilities.intro = 'visible';
  }

  showCtaInfo() {
    // Shows cta infobox
    const ctaInfoElement: Element = this.elRef.nativeElement.querySelector(`.cta-info`);
    this.renderer.setStyle(
      ctaInfoElement,
      'top',
      `${this.cta?.offsetTop - this.getElementHeight(ctaInfoElement) - 22}px`
    );
    // Shows finger-img
    const fingerElement = this.elRef.nativeElement.querySelector(`.trigger-finger`);
    this.renderer.setStyle(
      fingerElement,
      'top',
      `${this.cta.offsetTop + this.cta.offsetHeight / 2 + 10}px`
    );
    this.renderer.setStyle(fingerElement, 'left', `${this.platform.width() / 2 + 42}px`);
    this.infoBoxesVisibilities.cta = 'visible';
  }

  showBluetoothInfo() {
    const btInfo: Element = this.elRef.nativeElement.querySelector(`.bt-info`);
    const bluetoothButton = this.elRef.nativeElement.parentElement.querySelector(
      `[data-cy="btn-open-devices-level"]`
    );
    // Set bt-info position
    this.renderer.setStyle(
      btInfo,
      'top',
      `${bluetoothButton.offsetTop - this.getElementHeight(btInfo) - 22}px`
    );

    this.infoBoxesVisibilities.bluetooth = 'visible';
  }

  showManualMeasurementInfo() {
    const manualMeasurementButton = this.elRef.nativeElement.parentElement.querySelector(
      `[data-cy="btn-open-manual-measurement-input"]`
    );
    const manualMeasurementInfo: Element =
      this.elRef.nativeElement.querySelector(`.manual-measurement-info`);

    // Set bt-info position
    this.renderer.setStyle(
      manualMeasurementInfo,
      'top',
      `${manualMeasurementButton.offsetTop - this.getElementHeight(manualMeasurementInfo) - 22}px`
    );
    this.infoBoxesVisibilities.manual = 'visible';
  }

  showStartAgainInfo() {
    // Shows 'start tutorial again' info
    const reopenElement: Element = this.elRef.nativeElement.querySelector(`.reopen-info`);
    const trigger = this.elRef.nativeElement.querySelector(`.tutorial-trigger`);

    this.renderer.addClass(trigger, 'focus');
    this.renderer.setStyle(
      reopenElement,
      'top',
      `${this.cta.offsetTop - this.getElementHeight(reopenElement) - 44}px`
    );
    this.infoBoxesVisibilities.reopen = 'visible';
  }

  hideStartAgainInfo() {
    this.infoBoxesVisibilities.reopen = 'hidden';
    const trigger = this.elRef.nativeElement.querySelector(`.tutorial-trigger`);
    this.renderer.removeClass(trigger, 'focus');
    this.setTutorialLevel(null);
  }

  // Moves backdrop to manual button
  adjustBackdropForManualMessurementButton(triggerEvent: any) {
    if (triggerEvent?.fromState === 'visible' && triggerEvent?.toState === 'hidden') {
      if (this.tutorialLevel === TutorialLevels.prepareManualMeasurementInfo) {
        this.setTutorialLevel(TutorialLevels.manualMeasurementInfo);
      }
      this.infoboxAnimationDone(triggerEvent);
    }
  }

  // Handels clicks on backdrop
  backdropClicked() {
    this.tutorialLevel === TutorialLevels.cancelTutorial
      ? this.hideStartAgainInfo()
      : this.setTutorialLevel(TutorialLevels.cancelTutorial);
  }

  // Emits to open the manual-measurement tiles
  openMeasurementTransfer() {
    this.measurementTransferClicked.emit();
  }

  prepareAnimationDelays(level: TutorialLevels) {
    this.backdropAnimationDelay = 0;
    this.infoboxAnimationDelay = 0;

    switch (level) {
      case TutorialLevels.intro:
        this.backdropAnimationDelay = 300;
        this.infoboxAnimationDelay = 900;
        break;
      case TutorialLevels.ctaInfo:
        this.infoboxAnimationDelay = 900;
        break;
      case TutorialLevels.bluetoothInfo:
        this.backdropAnimationDelay = 1200;
        this.infoboxAnimationDelay = 1500;
        break;
      case TutorialLevels.manualMeasurementInfo:
        this.infoboxAnimationDelay = 600;
        break;
      case TutorialLevels.cancelTutorial:
        this.infoboxAnimationDelay = 600;
        break;
      default:
        break;
    }
  }

  /**
   * Calculates and sets styles for backdrops, depending on provided tutorial-level
   * @param level current tutorial level
   */
  prepareBackdrop(level: TutorialLevels) {
    let backdropTopHeight = 0;
    let backdropBottomTopPosition = 50000;

    const backdropTop = this.elRef.nativeElement.querySelector(`.backdrop.top`);
    const backdropBottom = this.elRef.nativeElement.querySelector(`.backdrop.bottom`);
    this.renderer.removeClass(backdropTop, 'animated');
    this.renderer.removeClass(backdropBottom, 'animated');

    // Highlights CTA
    if (level === TutorialLevels.intro || level === TutorialLevels.ctaInfo) {
      backdropTopHeight = this.cta.offsetTop;
      backdropBottomTopPosition = backdropTopHeight + this.cta.offsetHeight;
    }

    // Highlights bluetooth button
    if (
      level === TutorialLevels.bluetoothInfo ||
      level === TutorialLevels.prepareManualMeasurementInfo
    ) {
      const bluetoothButton = this.elRef.nativeElement.parentElement.querySelector(
        `[data-cy="btn-open-devices-level"]`
      );

      this.renderer.addClass(backdropTop, 'animated');
      this.renderer.addClass(backdropBottom, 'animated');
      backdropTopHeight = bluetoothButton.offsetTop;
      backdropBottomTopPosition = backdropTopHeight + +bluetoothButton.offsetHeight;
    }

    // Highlights manual measurement button
    if (level === TutorialLevels.manualMeasurementInfo || level === TutorialLevels.finishTutorial) {
      const manualMeasurementButton = this.elRef.nativeElement.parentElement.querySelector(
        `[data-cy="btn-open-manual-measurement-input"]`
      );

      this.renderer.addClass(backdropTop, 'animated');
      this.renderer.addClass(backdropBottom, 'animated');
      backdropTopHeight = manualMeasurementButton.offsetTop;
      backdropBottomTopPosition = backdropTopHeight + +manualMeasurementButton.offsetHeight;
    }

    // Shows full-screen backdrop
    if (level === TutorialLevels.cancelTutorial) {
      backdropTopHeight = 50000;
    }

    // Applies styles
    this.renderer.setStyle(backdropTop, 'height', `${backdropTopHeight}px`);
    this.renderer.setStyle(backdropBottom, 'top', `${backdropBottomTopPosition}px`);
  }

  /**
   * Checks if turorial-trigger needs to be shown.
   * Sets trigger-position based on parent transfer-cta.
   */
  initTutorialTrigger() {
    this.TUTORIAL_LEVEL = null;
    this.cta = this.elRef.nativeElement.parentElement.querySelector(
      `[data-cy="manual-measurement-cta"]`
    );

    const screenWidth = this.platform.width();
    const trigger = this.elRef.nativeElement.querySelector(`.tutorial-trigger`);
    let triggerLeftPosition = (screenWidth - this.cta.offsetWidth) / 2 + this.cta.offsetWidth + 4; // 4px margin right of cta
    let triggerTopPosition = this.cta.offsetTop + (this.cta.offsetHeight - 44) / 2; // trigger height is 44px

    // checks if there is enough space right of the cta to place the info-button
    if ((screenWidth - this.cta.offsetWidth) / 2 < 48) {
      // to little space. places the info-button right top to the cta
      triggerLeftPosition = screenWidth - 48;
      triggerTopPosition = this.cta.offsetTop - 44;
    }

    this.renderer.setStyle(trigger, 'left', `${triggerLeftPosition}px`);
    this.renderer.setStyle(trigger, 'top', `${triggerTopPosition}px`);

    // Starts autoplay after 800ms
    if (this.autoplay === true) {
      setTimeout(() => {
        this.setTutorialLevel(TutorialLevels.intro);
      }, 800);
    }
  }
}
