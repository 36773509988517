import { Injectable } from '@angular/core';
import { CanActivateChild, Router, UrlTree } from '@angular/router';
import { StorageFacade } from 'storage-store-facade/storage.facade';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
  constructor(private storage: StorageFacade, private router: Router) {}

  canActivateChild():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.storage.get('auth').then(
      (authAvailable) => (authAvailable ? true : this.router.parseUrl('welcome')),
      () => this.router.parseUrl('welcome')
    );
  }
}
