import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  NG_VALIDATORS,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, startWith, pairwise } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { ControlErrorDirective } from 'shared/form-error/form-error';

@Component({
  selector: 'pro-address-form-group',
  templateUrl: './address-form-group.component.html',
  styleUrls: ['./address-form-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressFormGroupComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AddressFormGroupComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormGroupComponent implements OnDestroy, ControlValueAccessor {
  ngUnsubscribe$: Subject<void> = new Subject<void>();

  @ViewChildren(ControlErrorDirective) formErrors!: QueryList<ControlErrorDirective>;

  value: any;
  addressForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {}

  /*
   * ControlValueAccessor-Interface Implementation
   */
  onChangeFn: any = () => {};
  onTouchedFn: any = () => {};

  writeValue(obj: any): void {
    const trimedInput = {
      city: obj.city.trim(),
      street: obj.street.trim(),
      street_2: obj.street_2.trim(),
      zip_code: obj.zip_code.trim(),
    };

    this.onChangeFn(trimedInput);
    this.value = trimedInput;

    if (!this.addressForm) {
      this.addressForm = this.fb.group({
        city: [obj.city],
        street: [obj.street],
        street_2: [obj.street_2],
        zip_code: [obj.zip_code, [Validators.pattern(/^(\d{5})$/)]],
      });
      this.validateAddress();
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  setDisabledState?(): void {}

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  validate({ value }: UntypedFormControl) {
    const isValid =
      this.addressForm.valid &&
      ((value.city === '' && value.street === '' && value.zip_code === '') ||
        (value.city !== '' && value.street !== '' && value.zip_code !== ''));

    return (
      !isValid && {
        invalid: true,
      }
    );
  }

  validateAddress() {
    this.addressForm.valueChanges
      .pipe(
        startWith({
          city: this.value.city,
          street: this.value.street,
          zip_code: this.value.zip_code,
        }),
        pairwise(),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(([p, c]) => {
        if (isEqual(p, c) === false) {
          const controls = this.addressForm.controls;

          if (c.city.trim() !== '' || c.street.trim() !== '' || c.zip_code.trim() !== '') {
            if (c.street.trim() === '') {
              controls.street.setErrors({
                custom: { message: $localize`Adresse unvollständig` },
              });
            }

            if (c.city.trim() === '') {
              controls.city.setErrors({ custom: { message: $localize`Adresse unvollständig` } });
            }

            if (c.zip_code.trim() === '') {
              controls.zip_code.setErrors({
                custom: { message: $localize`Adresse unvollständig` },
              });
            }
          }

          if (c.city.trim() === '' && c.street.trim() === '' && c.zip_code.trim() === '') {
            if (p.city.trim() !== '' || p.street.trim() !== '' || p.zip_code.trim() !== '') {
              controls.street.setErrors(null);
              controls.city.setErrors(null);
              controls.zip_code.setErrors(null);
            }
          }
          // invoke form-errors update
          if (this.formErrors) {
            this.formErrors.map((formError) => formError.update());
          }

          this.writeValue(this.addressForm.value);
        }
      });
  }
}
