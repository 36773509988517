import {
  Action,
  createReducer,
  on,
  createFeatureSelector,
  createSelector,
  createSelectorFactory,
  resultMemoize,
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import { MonitoringGoal, MonitoringGoalType, EcgGoal, SurveyGoal } from 'app/models';
import * as MeasurementActions from 'store/measurements-store/measurements.actions';
import * as MonitoringGoalsActions from './monitoring-goals.actions';
import { isEqual } from 'lodash-es';
import { AnyFn } from '@ngrx/store/src/selector';

export const monitoringGoalsFeatureKey = 'monitoringGoals';

const selectIdKey = (a: MonitoringGoal) => {
  return a.type;
};

export interface State extends EntityState<MonitoringGoal> {
  loading: boolean;
}

export const adapter: EntityAdapter<MonitoringGoal> = createEntityAdapter<MonitoringGoal>({
  selectId: selectIdKey,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
});

const monitoringGoalsReducer = createReducer(
  initialState,
  on(
    MonitoringGoalsActions.loadMonitoringGoals,
    MeasurementActions.addMeasurementsPartialSuccess,
    MeasurementActions.addEcgMeasurementsPartialSuccess,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    MonitoringGoalsActions.loadMonitoringGoalsSuccess,
    MeasurementActions.addMeasurementsSuccess,
    MeasurementActions.addEcgMeasurementsSuccess,
    (state, action) => adapter.upsertMany(action.entities, state)
  ),

  on(
    MonitoringGoalsActions.loadMonitoringGoalsSuccess,
    MonitoringGoalsActions.loadMonitoringGoalsFailure,
    MonitoringGoalsActions.loadMonitoringGoalsComplete,
    MeasurementActions.addMeasurementsSuccess,
    MeasurementActions.addEcgMeasurementsSuccess,
    (state) => ({
      ...state,
      loading: false,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return monitoringGoalsReducer(state, action);
}

export const customMemoized = (projectorFn: AnyFn) => resultMemoize(projectorFn, isEqual);

export const selectFeature = createFeatureSelector<State>('monitoringGoals');

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);

export const selectMonitoringGoal = (props: { type: MonitoringGoalType }) =>
  createSelector(selectEntities, (entities) =>
    entities[props.type] ? entities[props.type] : null
  );

export const selectOpenMeasurements = (props: { type: MonitoringGoalType }) =>
  createSelector(selectMonitoringGoal({ type: props.type }), (goal) => goal?.open_measurements);

export const selectAllMonitoringGoalsWithoutSurveys = createSelector(selectAll, (entities) =>
  entities.filter((e) => e.type !== 'SurveyGoal')
);

export const selectEcgMonitoringGoal = createSelectorFactory<State, EcgGoal>(customMemoized)(
  selectEntities,
  (entities: Dictionary<MonitoringGoal>) => entities['EcgGoal']
);

export const selectSurveyMonitoringGoal = createSelectorFactory<State, SurveyGoal>(customMemoized)(
  selectAll,
  (entities: MonitoringGoal[]) => entities.find((e) => e.type === 'SurveyGoal')
);
