import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { catchComplete } from 'app/utils';
import { ApiService } from 'store/api/api.service';
import * as MeasurementActions from 'store/measurements-store/measurements.actions';
import * as DocumentActions from './document.actions';
import * as fromRouterSelectors from 'store/router-store/router.selectors';
import * as fromDocuments from './document.reducer';
import * as dayjs from 'dayjs';

@Injectable()
export class DocumentEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromDocuments.State | fromRouterSelectors.State>,
    private apiService: ApiService
  ) {}

  loadDocumentsPages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.loadDocumentsPages),
      withLatestFrom(
        this.store.pipe(select(fromDocuments.selectPagesByCategory)),
        this.store.pipe(select(fromRouterSelectors.selectQueryParam('category')))
      ),
      mergeMap(([action, pages, category]) =>
        this.apiService
          .getDocuments(
            {
              categories: [category],
              page: pages?.page < pages?.totalPages ? pages.page + 1 : 1,
              pageSize: 25,
            },
            true
          )
          .pipe(
            map((data) =>
              DocumentActions.loadDocumentsPagesSuccess({
                documents: data.results,
                pages: {
                  page:
                    pages === undefined || pages?.page < pages?.totalPages ? data.page : pages.page,
                  pageSize: data.page_size,
                  totalPages: data.total_pages,
                },
                category,
              })
            ),
            catchError((error) => of(DocumentActions.loadDocumentsFailure({ error }))),
            catchComplete(() => of(DocumentActions.loadDocumentsComplete({ action })))
          )
      )
    );
  });

  loadDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.loadDocuments, MeasurementActions.addMeasurementsSuccess),
      mergeMap((action) =>
        this.apiService
          .getDocuments(
            action.type === MeasurementActions.addMeasurementsSuccess.type
              ? { from: dayjs().format('YYYY-MM-DD'), to: dayjs().format('YYYY-MM-DD') }
              : action.from
              ? {
                  from: action.from,
                  to: action.to || dayjs().format('YYYY-MM-DD'),
                }
              : undefined,
            action.type === DocumentActions.loadDocuments.type && action.from ? true : false,
            action.type === DocumentActions.loadDocuments.type && action.from ? true : false
          )
          .pipe(
            map((data) => DocumentActions.loadDocumentsSuccess({ documents: data.results })),
            catchError((error) => of(DocumentActions.loadDocumentsFailure({ error }))),
            catchComplete(() => of(DocumentActions.loadDocumentsComplete({ action })))
          )
      )
    );
  });

  loadDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.loadDocument),
      switchMap((action) =>
        this.apiService.getDocument(action.id).pipe(
          map((data) => DocumentActions.loadDocumentSuccess({ document: data })),
          catchError((error) => of(DocumentActions.loadDocumentFailure({ error })))
        )
      )
    );
  });

  loadDocumentByRouteId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.loadDocumentByRouteId),
      withLatestFrom(this.store.pipe(select(fromRouterSelectors.selectRouteParam('id')))),
      map(([, routeId]) => DocumentActions.loadDocument({ id: +routeId }))
    );
  });

  deleteDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.deleteDocument, DocumentActions.deleteDocumentFromDatabase),
      mergeMap((action) =>
        this.apiService.deleteDocument(action.id).pipe(
          map(() =>
            action.type === DocumentActions.deleteDocument.type
              ? DocumentActions.deleteDocumentSuccess({ id: action.id })
              : DocumentActions.deleteDocumentFromDatabaseSuccess()
          ),
          catchError((error) => of(DocumentActions.deleteDocumentFailure({ error })))
        )
      )
    );
  });
}
