import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import {
  withLatestFrom,
  switchMap,
  map,
  catchError,
  concatMap,
  mergeMap,
  first,
  takeUntil,
  delay,
} from 'rxjs/operators';
import { BluetoothLE } from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { differenceBy } from 'lodash-es';
import { ResultWeight } from 'app/models';
import { Bf720Service } from './bf720.service';
import { BluetoothHelperService } from './../bluetooth-helper.service';
import * as Bf720Actions from './bf720.actions';
import * as fromBf720 from './bf720.reducer';
import * as fromDevices from '../devices.reducer';
import * as fromInfo from 'store/info-store/info.reducer';

@Injectable()
export class Bf720Effects {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private actions$: Actions,
    private store: Store<fromBf720.State | fromDevices.State | fromInfo.State>,
    private bluetoothLe: BluetoothLE,
    private bluetoothHelper: BluetoothHelperService,
    private bf720: Bf720Service
  ) {}

  addState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Bf720Actions.addState),
      map((action) =>
        !action.state.address
          ? Bf720Actions.addStateSuccess()
          : Bf720Actions.loginUser({
              userIndex: action.state.userIndex,
              code: action.state.code,
            })
      )
    );
  });

  loadUserList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Bf720Actions.loadUserList),
      switchMap((action) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          concatMap(() => this.bluetoothHelper.isConnectedConnectDiscover(action.address)),
          first(),
          concatMap(() => this.bf720.getUserList(action.address)),
          map((userList) =>
            Bf720Actions.loadUserListSuccess({
              userList,
            })
          ),
          catchError((error) => of(Bf720Actions.loadUserListFailure({ error }))),
          takeUntil(this.ngUnsubscribe$)
        )
      )
    );
  });

  showConsentCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Bf720Actions.showConsentCode),
      mergeMap((action) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(fromBf720.selectAddress))))
      ),
      switchMap(([action, address]) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          concatMap(() => this.bluetoothHelper.isConnectedConnectDiscover(address)),
          first(),
          concatMap(() => this.bf720.showConsentCode(address, action.userIndex)),
          map(() => Bf720Actions.showConsentCodeSuccess()),
          catchError((error) => of(Bf720Actions.showConsentCodeFailure({ error }))),
          takeUntil(this.ngUnsubscribe$)
        )
      )
    );
  });

  createUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Bf720Actions.createUser),
      withLatestFrom(this.store.select(fromBf720.selectAddress)),
      switchMap(([action, address]) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          concatMap(() => this.bluetoothHelper.isConnectedConnectDiscover(address)),
          switchMap(() =>
            this.bf720.createUser(address, action.code).pipe(
              map((r) =>
                r.value === 'Success'
                  ? Bf720Actions.createUserSuccess({
                      userIndex: r.userIndex,
                      code: action.code,
                      responseValue: r.value,
                    })
                  : Bf720Actions.createUserFailed({ responseValue: r.value })
              )
            )
          ),
          catchError((e) =>
            of(e).pipe(
              withLatestFrom(this.store.pipe(select(fromBf720.selectTransferring))),
              map(([error, isTransferring]) =>
                isTransferring
                  ? Bf720Actions.createUserFailure({ error })
                  : Bf720Actions.createUserDisconnected()
              )
            )
          ),
          takeUntil(this.ngUnsubscribe$)
        )
      )
    );
  });

  loginUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Bf720Actions.loginUser, Bf720Actions.createUserSuccess, Bf720Actions.loginCurrentUser),
      withLatestFrom(
        this.store.select(fromBf720.selectAddress),
        this.store.select(fromBf720.selectUserIndex),
        this.store.select(fromBf720.selectCode)
      ),
      mergeMap(([action, address, userIndex, code]) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          concatMap(() => this.bluetoothHelper.isConnectedConnectDiscover(address)),
          switchMap(() =>
            this.bf720
              .userLogin(
                address,
                action.type === Bf720Actions.loginCurrentUser.type ? userIndex : action.userIndex,
                action.type === Bf720Actions.loginCurrentUser.type ? code : action.code
              )
              .pipe(
                map((result) =>
                  result.value === 'Success'
                    ? Bf720Actions.loginUserSuccess({
                        userIndex:
                          action.type === Bf720Actions.loginCurrentUser.type
                            ? userIndex
                            : action.userIndex,
                        code:
                          action.type === Bf720Actions.loginCurrentUser.type ? code : action.code,
                        responseValue: result.value,
                      })
                    : Bf720Actions.loginUserFailed({ responseValue: result.value })
                )
              )
          ),
          catchError((e) =>
            of(e).pipe(
              withLatestFrom(this.store.pipe(select(fromBf720.selectTransferring))),
              map(([error, isTransferring]) =>
                isTransferring
                  ? Bf720Actions.loginUserFailure({ error })
                  : Bf720Actions.loginUserDisconnected()
              )
            )
          ),
          takeUntil(this.ngUnsubscribe$)
        )
      )
    );
  });

  writeCrntTime$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Bf720Actions.loginUserSuccess),
      withLatestFrom(this.store.select(fromBf720.selectAddress)),
      mergeMap(([, address]) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          switchMap(() =>
            this.bf720.writeCrntTime(address).pipe(
              map(() => Bf720Actions.writeCurrentTimeSuccess()),
              catchError((e) =>
                of(e).pipe(
                  withLatestFrom(this.store.pipe(select(fromBf720.selectTransferring))),
                  map(([error, isTransferring]) =>
                    isTransferring
                      ? Bf720Actions.writeCurrentTimeFailure({ error })
                      : Bf720Actions.writeCurrentTimeDisconnected()
                  )
                )
              )
            )
          ),
          takeUntil(this.ngUnsubscribe$)
        )
      )
    );
  });

  writeScaleSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Bf720Actions.writeCurrentTimeSuccess),
      withLatestFrom(this.store.select(fromBf720.selectAddress)),
      mergeMap(([, address]) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          switchMap(() =>
            this.bf720
              .writeScaleSettings(address, 25)
              .pipe(map(() => Bf720Actions.writeScaleSettingsSuccess()))
          ),
          catchError((e) =>
            of(e).pipe(
              withLatestFrom(this.store.pipe(select(fromBf720.selectTransferring))),
              map(([error, isTransferring]) =>
                isTransferring
                  ? Bf720Actions.writeScaleSettingsFailure({ error })
                  : Bf720Actions.writeScaleSettingsDisconnected()
              )
            )
          ),
          takeUntil(this.ngUnsubscribe$)
        )
      )
    );
  });

  writeGender$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Bf720Actions.writeScaleSettingsSuccess),
      withLatestFrom(
        this.store.select(fromBf720.selectAddress),
        this.store.select(fromInfo.selectGender)
      ),
      mergeMap(([, address, gender]) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          switchMap(() =>
            this.bf720
              .writeGender(address, gender === 'male' ? 0 : 1)
              .pipe(map(() => Bf720Actions.writeGenderSuccess()))
          ),
          catchError((e) =>
            of(e).pipe(
              withLatestFrom(this.store.pipe(select(fromBf720.selectTransferring))),
              map(([error, isTransferring]) =>
                isTransferring
                  ? Bf720Actions.writeGenderFailure({ error })
                  : Bf720Actions.writeGenderDisconnected()
              )
            )
          ),
          takeUntil(this.ngUnsubscribe$)
        )
      )
    );
  });

  changeDatabaseIncrement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Bf720Actions.writeGenderSuccess),
      withLatestFrom(this.store.select(fromBf720.selectAddress)),
      mergeMap(([, address]) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          switchMap(() =>
            this.bf720
              .changeDatabaseIncrement(address)
              .pipe(map(() => Bf720Actions.changeDatabaseIncrementSuccess()))
          ),
          catchError((e) =>
            of(e).pipe(
              withLatestFrom(this.store.pipe(select(fromBf720.selectTransferring))),
              map(([error, isTransferring]) =>
                isTransferring
                  ? Bf720Actions.changeDatabaseIncrementFailure({ error })
                  : Bf720Actions.changeDatabaseIncrementDisconnected()
              )
            )
          ),
          takeUntil(this.ngUnsubscribe$)
        )
      )
    );
  });

  loadDeviceInformation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Bf720Actions.loadDeviceInformation),
      withLatestFrom(this.store.select(fromBf720.selectAddress)),
      mergeMap(([, address]) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          concatMap(() => this.bluetoothHelper.isConnectedConnectDiscover(address)),
          switchMap(() =>
            this.bluetoothHelper.getDeviceInformation(address).pipe(
              map((deviceInformation) =>
                Bf720Actions.loadDeviceInformationSuccess({
                  deviceInformation,
                })
              )
            )
          ),
          catchError((error) => of(Bf720Actions.loadDeviceInformationFailure({ error }))),
          takeUntil(this.ngUnsubscribe$)
        )
      )
    );
  });

  loadMeasurements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Bf720Actions.changeDatabaseIncrementSuccess, Bf720Actions.loadMeasurements),
      withLatestFrom(this.store.select(fromBf720.selectAddress)),
      mergeMap(([, address]) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          concatMap(() => this.bluetoothHelper.isConnectedConnectDiscover(address)),
          mergeMap(() =>
            this.bf720.getMeasurementData(address).pipe(
              withLatestFrom(this.store.pipe(select(fromBf720.selectAll))),
              map(([r, entities]) => {
                const diff = differenceBy(r.data, entities, (m: ResultWeight) => {
                  return m.device_time;
                });
                return r.status !== 'subscribedResult'
                  ? Bf720Actions.loadMeasurementsSubscribed({
                      results: r.data,
                      deviceInformation: {
                        manufacturer: 'Beurer',
                        model: 'BF720',
                        software_version: '',
                        hardware_version: '',
                        firmware_version: '',
                      },
                    })
                  : Bf720Actions.loadMeasurementsSuccess({
                      results: r.data,
                      lastResults: diff,
                      deviceInformation: {
                        manufacturer: 'Beurer',
                        model: 'BF720',
                        software_version: '',
                        hardware_version: '',
                        firmware_version: '',
                      },
                    });
              })
            )
          ),
          catchError((e) =>
            of(e).pipe(
              withLatestFrom(this.store.pipe(select(fromBf720.selectTransferring))),
              map(([error, isTransferring]) =>
                isTransferring
                  ? Bf720Actions.loadMeasurementsFailure({ error })
                  : Bf720Actions.loadMeasurementsDisconnected()
              )
            )
          ),
          takeUntil(this.ngUnsubscribe$)
        )
      )
    );
  });

  disconnectError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        Bf720Actions.loadUserListFailure,
        Bf720Actions.showConsentCodeFailure,
        Bf720Actions.createUserFailure,
        Bf720Actions.loginUserFailure,
        Bf720Actions.writeCurrentTimeFailure,
        Bf720Actions.writeScaleSettingsFailure,
        Bf720Actions.loadDeviceInformationFailure,
        Bf720Actions.loadMeasurementsFailure
      ),
      withLatestFrom(this.store.pipe(select(fromDevices.selectInitialising))),
      map(([action, initialising]) =>
        initialising
          ? Bf720Actions.initialisingError({ error: action.error })
          : Bf720Actions.transferringError({ error: action.error })
      )
    );
  });

  deleteDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Bf720Actions.deleteDevice),
      withLatestFrom(this.store.select(fromBf720.selectAddress)),
      map(([, address]) => {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        return Bf720Actions.deleteDeviceSuccess({ address });
      })
    );
  });

  disconnectDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        Bf720Actions.deleteDeviceSuccess,
        Bf720Actions.disconnectDevice,
        Bf720Actions.writeCurrentTimeDisconnected,
        Bf720Actions.writeScaleSettingsDisconnected,
        Bf720Actions.writeGenderDisconnected,
        Bf720Actions.changeDatabaseIncrementDisconnected,
        Bf720Actions.loadMeasurementsDisconnected,
        Bf720Actions.loginUserDisconnected
      ),
      withLatestFrom(this.store.pipe(select(fromBf720.selectAddress))),
      delay(3000),
      switchMap(([action, address]) =>
        this.bluetoothHelper.disconnectClose(address).pipe(
          map(() =>
            action.type === Bf720Actions.writeCurrentTimeDisconnected.type ||
            action.type === Bf720Actions.writeScaleSettingsDisconnected.type ||
            action.type === Bf720Actions.writeGenderDisconnected.type ||
            action.type === Bf720Actions.changeDatabaseIncrementDisconnected.type ||
            action.type === Bf720Actions.loadMeasurementsDisconnected.type ||
            action.type === Bf720Actions.loginUserDisconnected.type
              ? Bf720Actions.startScanning()
              : Bf720Actions.disconnectDeviceSuccess()
          ),
          catchError((error) => of(Bf720Actions.disconnectDeviceFailure({ error })))
        )
      )
    );
  });
}
