import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromMeasurements from './measurements.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MeasurementsEffects } from './measurements.effects';
import { MeasurementResultsStoreModule } from './measurement-results-store/measurement-results-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromMeasurements.measurementsFeatureKey, fromMeasurements.reducer),
    EffectsModule.forFeature([MeasurementsEffects]),
    MeasurementResultsStoreModule,
  ],
})
export class MeasurementsStoreModule {}
