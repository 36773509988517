import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Document, UploadRequest, Upload, ObservationResults, DeviceInformation } from 'app/models';

export const addUpload = createAction('[UploadRequest] Add Upload', props<Upload>());

export const addEcgResultUpload = createAction(
  '[UploadRequest] Add Ecg Result Upload',
  props<{
    upload: Upload;
    heartRate: number;
    determination: string;
    device: DeviceInformation;
  }>()
);

export const addUploadRequest = createAction(
  '[UploadRequest/API] Add UploadRequest',
  props<{ request: UploadRequest }>()
);

export const addUploadRequestPartialSuccess = createAction(
  '[UploadRequest/API] Add UploadRequest Partial Success',
  props<{ update: Update<UploadRequest> }>()
);

export const addUploadRequestSuccess = createAction(
  '[UploadRequest/API] Add UploadRequest Success',
  props<{ id: number }>()
);

export const addEcgResultUploadRequest = createAction(
  '[UploadRequest/API] Add Ecg Result UploadRequest',
  props<{
    request: UploadRequest;
    heartRate: number;
    determination: string;
    device: DeviceInformation;
  }>()
);

export const addEcgResultUploadRequestSuccess = createAction(
  '[UploadRequest/API] Add Ecg Result UploadRequest Success',
  props<{ id: number; results: ObservationResults }>()
);

export const deleteUploadRequest = createAction(
  '[UploadRequest] Delete UploadRequest',
  props<{ id: number }>()
);

export const cancelUploadRequest = createAction(
  '[UploadRequest] Cancel UploadRequest',
  props<{ id: number }>()
);

export const addUploadRequestFailure = createAction(
  '[UploadRequest/API] Add UploadRequest Failure',
  props<{ update: Update<UploadRequest> }>()
);

export const loadDocumentsSuccess = createAction(
  '[UploadRequests/API] Load Documents Success',
  props<{ id: number; documents: Document[] }>()
);

export const loadDocumentsFailure = createAction(
  '[UploadRequests/API] Load Documents Failure',
  props<{ error: any }>()
);
