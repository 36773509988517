import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from 'store/api/api.service';
import * as InfoActions from './info.actions';
import * as fromInfo from './info.reducer';
import { select, Store } from '@ngrx/store';

@Injectable()
export class InfoEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<fromInfo.State>
  ) {}

  loadInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InfoActions.loadInfo),
      mergeMap(() =>
        this.apiService.getInfos().pipe(
          map((data) => InfoActions.loadInfoSuccess({ data })),
          catchError((error) => of(InfoActions.loadInfoFailure({ error })))
        )
      )
    );
  });

  updateInfoStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InfoActions.updateInfo),
      mergeMap((action) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(fromInfo.selectInfo))))
      ),
      map(([action, data]) =>
        InfoActions.updateInfoPartialSuccess({ data, updatedData: action.data })
      )
    );
  });

  updateInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InfoActions.updateInfoPartialSuccess),
      switchMap((action) =>
        this.apiService.updateInfos(action.updatedData).pipe(
          map(() => InfoActions.updateInfoSuccess()),
          catchError(() => of(InfoActions.updateInfoFailure({ data: action.data })))
        )
      )
    );
  });
}
