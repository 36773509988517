import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { BluetoothLE } from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { EMPTY, of, from, combineLatest, throwError, BehaviorSubject, zip } from 'rxjs';
import { catchError, concatMap, filter, expand, map, first } from 'rxjs/operators';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { isOfType } from 'app/utils';
import { DeviceLocationStatus } from 'store/devices-store/devices.model';

@Injectable()
export class BluetoothHelperService {
  isLocationEnabledAndAvailable$ = new BehaviorSubject(true);
  isBluetoothEnabled$ = new BehaviorSubject(true);

  constructor(
    private bluetoothLe: BluetoothLE,
    private platform: Platform,
    private diagnostic: Diagnostic
  ) {
    this.platform.ready().then(() => {
      if (this.platform.is('hybrid') && (this.platform.is('android') || this.platform.is('ios'))) {
        // observe bluetooth-state change
        this.setBluetoothAvailability();
        this.diagnostic.registerBluetoothStateChangeHandler(() => this.setBluetoothAvailability());

        // observe location-state change
        this.checkAndroidLocationPermissions();
        this.diagnostic.registerLocationStateChangeHandler(() =>
          this.checkAndroidLocationPermissions()
        );
      }
    });
  }

  /**
   * Get device information like manufacturer, model, firmware, hardware and software.
   */
  getDeviceInformation(address: string) {
    return combineLatest([
      from(
        this.bluetoothLe.read({
          address,
          service: '180A',
          characteristic: '2A29', // manufacturer
        })
      ),
      from(
        this.bluetoothLe.read({
          address,
          service: '180A',
          characteristic: '2A24', // model
        })
      ),
      from(
        this.bluetoothLe.read({
          address,
          service: '180A',
          characteristic: '2A26', // firmware
        })
      ),
      from(
        this.bluetoothLe.read({
          address,
          service: '180A',
          characteristic: '2A27', // hardware
        })
      ),
      from(
        this.bluetoothLe.read({
          address,
          service: '180A',
          characteristic: '2A28', // software
        })
      ),
    ]).pipe(
      map(([manufacturer, model, firmware, hardware, software]) => {
        const getString = (typedArr: Uint8Array) => {
          let name = '';
          typedArr.forEach((item) => {
            name = item > 0 ? name + String.fromCharCode(item) : name + '';
          });
          return name;
        };
        const manufacturerName = getString(
          this.bluetoothLe.encodedStringToBytes(manufacturer.value)
        );
        const modelNumber = getString(this.bluetoothLe.encodedStringToBytes(model.value));
        const firmwareVersion = getString(this.bluetoothLe.encodedStringToBytes(firmware.value));
        const hardwareVersion = getString(this.bluetoothLe.encodedStringToBytes(hardware.value));
        const softwareVersion = getString(this.bluetoothLe.encodedStringToBytes(software.value));
        return {
          manufacturer: manufacturerName,
          model: modelNumber,
          firmware_version: firmwareVersion,
          hardware_version: hardwareVersion,
          software_version: softwareVersion,
        };
      })
    );
  }

  /**
   * Connect or reconnect previously connected device and discover all the devices services, characteristics and descriptors.
   * Invokes BluetoothLE methods in the following order isConnected, connect, reconnect, isDiscover, discover.
   */
  connectReconnectDiscover(address: string) {
    return from(this.bluetoothLe.isConnected({ address })).pipe(
      catchError(() =>
        // catch error when device was never connected
        of({ isConnected: false })
      ),
      concatMap((r) =>
        !r.isConnected
          ? from(this.bluetoothLe.connect({ address, autoConnect: true })).pipe(
              catchError((e) =>
                // catch error when device was previously connected and reconnect it
                e.error === 'connect' ? of({ status: 'disconnected' }) : throwError(e)
              ),
              expand((re) =>
                // reconnect when device gets disconnected
                re.status === 'disconnected' ? this.bluetoothLe.reconnect({ address }) : EMPTY
              ),
              filter((d) => d.status === 'connected')
            )
          : of({ status: 'connected' })
      ),
      concatMap(() =>
        from(this.bluetoothLe.isDiscovered({ address })).pipe(
          catchError(() => of({ isDiscovered: false }))
        )
      ),
      concatMap((r) => (!r.isDiscovered ? this.bluetoothLe.discover({ address }) : of({})))
    );
  }

  /**
   * Connect to a none connected device and discover all the devices services, characteristics and descriptors.
   * Invokes BluetoothLE methods in the following order isConnected, connect, isDiscoverd, discover.
   * Invokes  @see disconnectClose in case of an connect error and connect again afterwards.
   */
  isConnectedConnectDiscover(address: string) {
    return from(this.bluetoothLe.isConnected({ address })).pipe(
      catchError(() =>
        // catch error when device was never connected
        of({ isConnected: false })
      ),
      concatMap((r) =>
        !r.isConnected
          ? from(this.bluetoothLe.connect({ address, autoConnect: true })).pipe(
              catchError(
                (e) => of(e) // catch error connect
              ),
              concatMap((re) =>
                !re.hasOwnProperty('error')
                  ? of(re)
                  : re.error === 'connect'
                  ? of({}).pipe(
                      concatMap(() => this.disconnectClose(re.address)),
                      concatMap(() => this.bluetoothLe.connect({ address: re.address }))
                    )
                  : of(re)
              ),
              filter((d) => d.status === 'connected')
            )
          : of({ status: 'connected' })
      ),
      concatMap(() =>
        from(this.bluetoothLe.isDiscovered({ address })).pipe(
          catchError(() => of({ isDiscovered: false }))
        )
      ),
      concatMap((r) => (!r.isDiscovered ? this.bluetoothLe.discover({ address }) : of({})))
    );
  }

  /**
   * Initialize bluetooth on the device when it's not already initialized
   * Invokes BluetoothLE methods in the following order isInitialized, initialize.
   */
  isInitializedInitialize() {
    return from(this.bluetoothLe.isInitialized()).pipe(
      concatMap((r) =>
        !r.isInitialized ? this.bluetoothLe.initialize({ restoreKey: 'proBluetoothle' }) : of({})
      )
    );
  }

  /**
   * Disconnet existing bluetooth connection and close/dispose device afterwards.
   * Invokes BluetoothLE methods in the following order isInitialized, initialize, isConnected, wasConnected, disconnect, close.
   */
  disconnectClose(address: string) {
    return this.isInitializedInitialize().pipe(
      concatMap(() =>
        from(this.bluetoothLe.isConnected({ address })).pipe(
          catchError(() => of({ isConnected: false })), // catch error when device was never connected
          concatMap((r) =>
            r.isConnected ? of({ wasConnected: true }) : this.bluetoothLe.wasConnected({ address })
          ),
          concatMap((r) =>
            r.wasConnected && this.platform.is('ios')
              ? this.bluetoothLe.disconnect({ address })
              : of(r)
          ),
          catchError((e) => of(e)), // catch error if device is disconnected already
          concatMap(() => this.bluetoothLe.close({ address })),
          catchError((e) => of(e)) // catch error if connection is closed already
        )
      )
    );
  }

  /**
   * Request Bluetooth scanning privileges since scanning for unpaired devices requires it in Android API 31.
   * Invokes BluetoothLE methods in the following order hasPermissionBtScan, requestPermissionBtScan.
   */
  hasPermissionBtScanRequestPermissionBtScan() {
    if (this.platform.is('hybrid') === false || this.platform.is('android') === false) {
      return of({ hasPermission: true });
    }
    return zip(from(this.bluetoothLe.hasPermissionBtScan()), from(Device.getInfo())).pipe(
      concatMap(([hasPermissionResponse, deviceInfo]) =>
        parseInt(deviceInfo.osVersion, 0) < 12
          ? of({ requestPermission: true }) // because permission is not needed on android < 12, just return true
          : !hasPermissionResponse.hasPermission
          ? from(this.bluetoothLe.requestPermissionBtScan())
          : of(hasPermissionResponse)
      ),
      map((r) =>
        isOfType<{ requestPermission: boolean }>(r, 'requestPermission')
          ? { hasPermission: r.requestPermission }
          : r
      )
    );
  }

  /**
   * Request Bluetooth connect privileges since connecting to unpaired devices requires it in Android API 31.
   * Invokes BluetoothLE methods in the following order hasPermissionBtConnect, requestPermissionBtConnect.
   */
  hasPermissionBtConnectRequestPermissionBtConnect() {
    if (this.platform.is('hybrid') === false || this.platform.is('android') === false) {
      return of({ hasPermission: true });
    }
    return zip(from(this.bluetoothLe.hasPermissionBtConnect()), from(Device.getInfo())).pipe(
      concatMap(([hasPermissionResponse, deviceInfo]) =>
        parseInt(deviceInfo.osVersion, 0) < 12
          ? of({ requestPermission: true }) // because permission is not needed on android < 12, just return true
          : !hasPermissionResponse.hasPermission
          ? from(this.bluetoothLe.requestPermissionBtConnect())
          : of(hasPermissionResponse)
      ),
      map((r) =>
        isOfType<{ requestPermission: boolean }>(r, 'requestPermission')
          ? { hasPermission: r.requestPermission }
          : r
      )
    );
  }

  async checkAndroidLocationPermissions() {
    if (this.platform.is('hybrid') === false || this.platform.is('android') === false) {
      this.isLocationEnabledAndAvailable$.next(true);
      return true;
    }

    const isEnabled =
      (await this.bluetoothLe.isLocationEnabled()).isLocationEnabled &&
      (await this.bluetoothLe.hasPermission()).hasPermission;

    this.isLocationEnabledAndAvailable$.next(isEnabled);
    return isEnabled;
  }

  // to use bluetooth on android devices users permission for accessing location is needed
  requestLocationPermission(): Promise<DeviceLocationStatus> {
    /* eslint-disable @typescript-eslint/no-misused-promises */
    /* eslint-disable @typescript-eslint/no-unsafe-return */
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<any>(async (resolve) => {
      // set start for resume-duration
      const start = new Date().getTime();

      if (this.platform.is('hybrid') === false || this.platform.is('android') === false) {
        return resolve('GRANTED');
      }

      let requestLocation = true;
      const locationEnabledInfo = (await this.bluetoothLe.isLocationEnabled()).isLocationEnabled;

      if (!locationEnabledInfo) {
        requestLocation = (await this.bluetoothLe.requestLocation()).requestLocation;
      }

      if (!requestLocation) {
        this.checkAndroidLocationPermissions();
        return resolve('LOCATION_DISABLED');
      }

      this.bluetoothLe.hasPermission().then(async (locationPermission) => {
        if (!locationPermission.hasPermission) {
          const requestPermission = (await this.bluetoothLe.requestPermission()).requestPermission;
          // There is no reliable way to find out, if the user denied the location-permission finally.
          // In that case, no native pop-up will be shown again, but the app will be in background-mode for a short time period.
          // This timespan is used to evaluate, if a native pop-up was shown or not
          this.platform.resume.pipe(first()).subscribe(() => {
            const duration = new Date().getTime() - start;

            this.checkAndroidLocationPermissions();

            // no native pop-up was shown
            if (duration < 350) {
              return resolve('DENIED_FINALLY');
            }

            return requestPermission ? resolve('GRANTED') : resolve('PERMISSION_DENIED');
          });
        } else {
          this.checkAndroidLocationPermissions();
          return resolve('GRANTED');
        }
      });
    });
    /* eslint-enable @typescript-eslint/no-misused-promises */
    /* eslint-enable @typescript-eslint/no-unsafe-return */
  }

  setBluetoothAvailability() {
    this.diagnostic
      .isBluetoothAvailable()
      .then((isBluetoothAvailable) => this.isBluetoothEnabled$.next(isBluetoothAvailable));
  }
}
