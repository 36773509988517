import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { loadFile } from 'app/utils/native-polyfills';
import { Renderer2, Output, EventEmitter } from '@angular/core';
import { WriteFileResult, Filesystem, Directory } from '@capacitor/filesystem';

@Component({
  selector: 'pro-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePreviewComponent {
  src: string | SafeUrl = null;
  contentType: string = null;
  fileName: string = null;
  loading = true;
  storedFile: WriteFileResult = null;
  readerResult = '';

  @Input() set file(value: File) {
    this.setFile(value);

    // Initializes immediately if the file is neither an image nor a PDF
    if (!value.type.includes('image') && !value.type.includes('pdf')) {
      this.initialized();
    }
  }

  @Input() thumbnail = false;

  @Input() zoom = 1;

  @Output() filePreviewLoaded: EventEmitter<boolean> = new EventEmitter();

  loadFileRef = loadFile; // reference for unit-test mocking

  constructor(
    private domSanitizer: DomSanitizer,
    public elRef: ElementRef,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef
  ) {}

  private async setFile(file: File) {
    this.readerResult = await this.loadFileRef(file);
    this.contentType = file.type;
    this.fileName = file.name;

    this.src = file.type.includes('image')
      ? this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
      : this.readerResult;

    // pdf-viewer not loading properly with async src-input
    setTimeout(() => {
      this.cdRef.detectChanges();
    }, 450);
  }

  async getStoredFile() {
    if (!this.storedFile) {
      // writes file to filesystem
      this.storedFile = await Filesystem.writeFile({
        path: this.adjustFilename(),
        data: this.readerResult,
        directory: Directory.External,
      });
    }

    return this.storedFile;
  }

  initialized() {
    this.loading = false;
    this.filePreviewLoaded.emit(true);
    const pdfContainer = this.elRef.nativeElement.querySelector('.ng2-pdf-viewer-container');

    if (pdfContainer) {
      this.renderer.setStyle(pdfContainer, 'position', 'relative');
    }
  }

  onPdfError(error: any) {
    throw new Error(error as string);
  }

  /**
   * replace 'umlaute', spaces and other none regular characters from filename
   * adds missing content-type
   */
  adjustFilename() {
    // removes file-extension
    const index = this.fileName.lastIndexOf('.');
    const filenameCut = this.fileName.substring(0, index > 0 ? index : this.fileName.length);

    let modifiedFilename = filenameCut
      .replace(/ /g, '_') // replaces spaces
      // replaces umlauts
      .replace(/\u00dc/g, 'Ue')
      .replace(/\u00fc/g, 'ue')
      .replace(/\u00c4/g, 'Ae')
      .replace(/\u00e4/g, 'ae')
      .replace(/\u00d6/g, 'Oe')
      .replace(/\u00f6/g, 'oe')
      .replace(/\u00df/g, 'ss')
      // replaces none regular characters
      // eslint-disable-next-line
      .replace(/[^\.a-zA-Z0-9_-]/g, '');

    const fileType = this.contentType.split('/').pop();

    if (modifiedFilename === '') {
      modifiedFilename = Date.now().toString();
    }

    // adds file-extention
    modifiedFilename = `${modifiedFilename}.${fileType}`;
    return modifiedFilename;
  }
}
