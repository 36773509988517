import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { BluetoothHelperService } from 'store/devices-store/bluetooth-helper.service';
import * as fromPo60 from './po60.reducer';
import { EffectsModule } from '@ngrx/effects';
import { Po60Effects } from './po60.effects';
import { Po60Service } from './po60.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromPo60.po60sFeatureKey, fromPo60.reducer),
    EffectsModule.forFeature([Po60Effects]),
  ],
  providers: [Po60Service, BluetoothHelperService],
})
export class Po60StoreModule {}
