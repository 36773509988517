import {
  Component,
  ElementRef,
  HostListener,
  ChangeDetectionStrategy,
  Input,
  Host,
  EventEmitter,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

const chevronAnimation = trigger('chevron', [
  state(
    'inactive',
    style({
      transform: 'rotate(0deg)',
    })
  ),
  state(
    'active',
    style({
      transform: 'rotate(180deg)',
    })
  ),
  transition('inactive => active', animate('180ms ease-in')),
  transition('active => inactive', animate('180ms ease-in')),
]);

@Component({
  selector: 'pro-expansion-header',
  template: `<div class="expansion-header-content">
    <ng-content></ng-content>
    <div class="expansion-icon">
      <ion-icon
        [ngClass]="chevronColor"
        [@chevron]="(panel.activeEvent | async) || panel.active ? 'active' : 'inactive'"
        name="chevron-down-outline"
      ></ion-icon>
    </div>
  </div>`,
  styles: [
    `
      :host {
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
      }

      .expansion-header-content {
        display: flex;
        padding-right: 19.5px;
      }

      .expansion-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
      }

      .expansion-icon ion-icon {
        min-width: 20px;
        font-size: 20px;
      }

      .expansion-icon ion-icon.dark {
        color: var(--ion-text-color);
      }

      .expansion-icon ion-icon.light {
        color: var(--pro-color-white);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [chevronAnimation],
})
export class ExpansionHeaderComponent {
  @Input() chevronColor: 'dark' | 'light' = 'dark';

  constructor(@Host() public panel: ExpansionPanelComponent) {}

  @HostListener('click') onClick() {
    this.panel.toggleActive();
  }
}

@Component({
  selector: 'pro-expansion-content',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionContentComponent {}

@Component({
  selector: 'pro-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expand', [
      state(
        'inactive',
        style({
          height: '0',
          visibility: 'hidden',
        })
      ),
      state(
        'active',
        style({
          height: '*',
          visibility: 'visible',
        })
      ),
      transition('inactive => active', animate('200ms ease-out')),
      transition('active => inactive', animate('200ms ease-in')),
    ]),
    chevronAnimation,
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    tabindex: '0',
  },
})
export class ExpansionPanelComponent {
  private ACTIVE: boolean;
  @Input() set active(value: boolean) {
    this.ACTIVE = value;
    this.activeEvent.emit(this.ACTIVE);
  }

  get active() {
    return this.ACTIVE;
  }

  @Input() headerHeight: string;
  @Input() expand = true;
  expansionContent: ElementRef;
  activeEvent: EventEmitter<boolean> = new EventEmitter(this.active);

  @HostListener('click') onClick() {
    if (this.expand) {
      this.toggleActive();
    }
  }

  toggleActive() {
    this.ACTIVE = !this.ACTIVE;
    this.activeEvent.emit(this.ACTIVE);
  }
}
