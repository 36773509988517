import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasOwnProperty',
})
export class HasOwnPropertyPipe implements PipeTransform {
  transform(value: Record<string, any>, arg: string): any {
    return value.hasOwnProperty(arg);
  }
}
