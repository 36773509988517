import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageFacade } from 'storage-store-facade/storage.facade';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { map, catchError, switchMap, tap } from 'rxjs/operators';
import { ApiService } from 'store/api/api.service';
import { TermsOfService } from 'app/models';
import * as TermsOfServiceActions from './terms-of-service.actions';

@Injectable()
export class TermsOfServiceEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private storage: StorageFacade,
    private router: Router
  ) {}

  loadTermsOfService$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        TermsOfServiceActions.loadTermsOfService,
        TermsOfServiceActions.acceptTermsOfServiceSuccess
      ),
      switchMap(() =>
        this.apiService.getTermsOfService().pipe(
          map((data) =>
            TermsOfServiceActions.loadTermsOfServiceSuccess({
              data,
            })
          ),
          catchError((error) => of(TermsOfServiceActions.loadTermsOfServiceFailure({ error })))
        )
      )
    );
  });

  setStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TermsOfServiceActions.loadTermsOfServiceSuccess),
      switchMap((action) =>
        from(this.storage.set('tos', action.data)).pipe(
          map(() => TermsOfServiceActions.setStorageSuccess())
        )
      )
    );
  });

  addTermsOfService$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TermsOfServiceActions.addTermsOfService),
      switchMap(() =>
        from(this.storage.get('tos')).pipe(
          map((data: TermsOfService) =>
            data
              ? TermsOfServiceActions.addTermsOfServiceSuccess({ data })
              : TermsOfServiceActions.addTermsOfServiceFailure()
          )
        )
      )
    );
  });

  acceptTermsOfService$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TermsOfServiceActions.acceptTermsOfService),
      switchMap((action) =>
        this.apiService.acceptTermsOfService().pipe(
          map(() =>
            TermsOfServiceActions.acceptTermsOfServiceSuccess({
              url: action.url,
            })
          ),
          catchError((error) => of(TermsOfServiceActions.acceptTermsOfServiceFailure({ error })))
        )
      )
    );
  });

  redirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TermsOfServiceActions.acceptTermsOfServiceSuccess),
        tap((action) => {
          this.router.navigateByUrl(action.url, { replaceUrl: true });
        })
      );
    },
    { dispatch: false }
  );

  ngrxOnInitEffects(): any {
    return TermsOfServiceActions.addTermsOfService();
  }
}
