import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { BluetoothHelperService } from 'store/devices-store/bluetooth-helper.service';
import * as fromBf720 from './bf720.reducer';
import { EffectsModule } from '@ngrx/effects';
import { Bf720Effects } from './bf720.effects';
import { Bf720Service } from './bf720.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromBf720.bf720sFeatureKey, fromBf720.reducer),
    EffectsModule.forFeature([Bf720Effects]),
  ],
  providers: [Bf720Service, BluetoothHelperService],
})
export class Bf720StoreModule {}
