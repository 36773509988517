import { MonitoringGoal } from 'app/models';
import { TestDataFactory } from './test-data.factory';

export class MonitoringGoalFactory extends TestDataFactory<MonitoringGoal> {
  constructor() {
    const generatorFunction = (): MonitoringGoal => {
      return {} as MonitoringGoal;
    };
    super(generatorFunction);
  }

  getOne(override: any = {}): MonitoringGoal {
    let basicValues = {};

    const goalType = override?.type;

    if (!goalType || goalType === 'TemperatureGoal') {
      basicValues = {
        min: 35.5,
        max: 37.4,
        critical_min: 34.5,
        critical_max: 38.4,
        result_unit: '°C',
        name: 'Temperatur',
        open_measurements: [],
        next_measurements: [],
        last_results: [],
      };
    } else if (goalType === 'BloodPressureGoal') {
      basicValues = {
        critical_min_systolic: 100,
        min_systolic: 110,
        max_systolic: 134,
        critical_max_systolic: 189,
        critical_min_diastolic: 60,
        min_diastolic: 70,
        max_diastolic: 89,
        critical_max_diastolic: 99,
        type: 'BloodPressureGoal',
        result_unit: 'mmHg',
        name: 'Blutdruck',
        open_measurements: [],
        next_measurements: [],
        last_results: [],
      };
    } else if (goalType === 'OxygenSaturationGoal') {
      basicValues = {
        min: 95,
        critical_min: 91,
        type: 'OxygenSaturationGoal',
        result_unit: '%',
        name: 'Sauerstoffsättigung',
        open_measurements: [],
        next_measurements: [],
        last_results: [],
      };
    } else if (goalType === 'WeightGoal') {
      basicValues = {
        type: 'WeightGoal',
        result_unit: 'kg',
        name: 'Gewicht',
        open_measurements: [],
        next_measurements: [],
        last_results: [],
      };
    } else if (goalType === 'HeartRateGoal') {
      basicValues = {
        min: 60,
        max: 99,
        critical_min: 50,
        critical_max: 124,
        type: 'HeartRateGoal',
        result_unit: 'bpm',
        name: 'Herzfrequenz',
        open_measurements: [],
        next_measurements: [],
        last_results: [],
      };
    } else if (goalType === 'EcgGoal') {
      basicValues = {
        name: 'EKG',
        type: 'EcgGoal',
        result_unit: '',
        open_measurements: [],
        next_measurements: [],
        last_results: [],
      };
    } else if (goalType === 'SurveyGoal') {
      basicValues = {
        name: 'Fragebogen',
        type: 'SurveyGoal',
        result_unit: '',
        open_measurements: [],
        next_measurements: [],
        last_results: [],
      };
    }
    return super.getOne({ ...basicValues, ...override } as MonitoringGoal);
  }

  getAllMonitoringGoals() {
    return this.getMany(7, [
      { type: 'TemperatureGoal' },
      { type: 'BloodPressureGoal' },
      { type: 'OxygenSaturationGoal' },
      { type: 'WeightGoal' },
      { type: 'HeartRateGoal' },
      { type: 'EcgGoal' },
      { type: 'SurveyGoal' },
    ]);
  }
}
