import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ApiService } from 'store/api/api.service';
import * as ActivationsActions from 'app/app-store/activations-store/activations.actions';
import * as fromActivations from 'store/activations-store/activations.reducer';

@Injectable({
  providedIn: 'root',
})
export class ActivationsFacade {
  activationsCurrentState$ = this.store.pipe(select(fromActivations.selectFeature));
  activations$ = this.store.pipe(select(fromActivations.selectAll));
  activationsLoading$ = this.store.pipe(select(fromActivations.selectLoading));
  activationsCurrentCategory$ = this.store.pipe(select(fromActivations.selectCurrentCategory));
  activationsError$ = this.store.pipe(select(fromActivations.selectError));
  activationCurrent$ = this.store.pipe(select(fromActivations.selectCurrentActivation));

  constructor(private store: Store<fromActivations.State>, private apiService: ApiService) {}

  loadActivations() {
    this.store.dispatch(ActivationsActions.loadActivations());
  }

  addActivation(code: string, cleanPatientData: boolean) {
    this.store.dispatch(ActivationsActions.addActivation({ code, cleanPatientData }));
  }

  checkAppSubscriptionCode(code: string) {
    return this.apiService.checkAppSubscriptionCode(code);
  }
}
