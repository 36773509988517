import { createAction, props } from '@ngrx/store';
import { Medication } from 'app/models';

export const loadMedications = createAction('[Medications/API] Load Medications');

export const loadMedicationsSuccess = createAction(
  '[Medications/API] Load MedicationsSuccess',
  props<{ medications: Medication[] }>()
);

export const loadMedicationsFailure = createAction(
  '[Medications/API] Load MedicationsFailure',
  props<{ error: any }>()
);

export const updateMedication = createAction(
  '[Medication/API] Update Medication',
  props<{ medication: Medication }>()
);

export const updateMedicationSuccess = createAction(
  '[Medication/API] Update Medication Success',
  props<{ medication: Medication }>()
);

export const updateMedicationFailure = createAction(
  '[Medication/API] Update Medication Failure',
  props<{ error: any }>()
);

export const createMedication = createAction(
  '[Medication/API] Create Medication',
  props<{ medication: Medication }>()
);

export const createMedicationSuccess = createAction(
  '[Medication/API] Create Medication Success',
  props<{ medication: Medication }>()
);

export const createMedicationFailure = createAction(
  '[Medication/API] Create Medication Failure',
  props<{ error: any }>()
);

export const deleteMedication = createAction(
  '[Medication/API] Delete Medication',
  props<{ medicationId: number }>()
);

export const deleteMedicationSuccess = createAction(
  '[Medication/API] Delete Medication Success',
  props<{ medicationId: number }>()
);

export const deleteMedicationFailure = createAction(
  '[Medication/API] Delete Medication Failure',
  props<{ error: any }>()
);
