import { TestDataFactory, RANDOM_STRING } from './test-data.factory';

export class DeviceFactory extends TestDataFactory<any> {
  constructor() {
    const generatorFunction = () => {
      const deviceName = RANDOM_STRING.getOne(8);

      return {
        address: null as any,
        name: deviceName,
        type: 'device',
        lastResults: [] as any[],
        transferring: false,
        deviceInformation: {
          manufacturer: 'Beurer',
          model: deviceName,
          software_version: '',
          hardware_version: '',
          firmware_version: '',
        },
        error: null as any,
      };
    };
    super(generatorFunction);
  }
}
