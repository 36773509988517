import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pro-step-indicator',
  template: `
    <span
      *ngFor="let step of repeatArray; index as index; last as isLast"
      [ngClass]="{ active: index < current, last: isLast }"
    ></span>
  `,
  styles: [
    `
      :host {
        display: flex;
        margin-right: 6.6%;
        margin-bottom: 19px;
      }
    `,
    `
      span {
        display: block;
        flex: 1;
        height: 8px;
        border-radius: 4px;
        background-color: var(--ion-color-medium);
        margin-right: 5px;
      }
    `,
    `
      span.active {
        background-color: var(--ion-color-secondary);
      }
    `,
    `
      span.last {
        margin: 0;
      }
    `,
  ],
})
export class StepIndicatorComponent implements OnInit {
  @Input() total = 5;
  @Input() current = 1;
  repeatArray: any[];
  constructor() {}

  ngOnInit() {
    this.repeatArray = Array(this.total);
  }
}
