<section class="notice offline-notice" *ngIf="offlineStatus === true" slot="top">
  <div class="tile">
    <div class="content">
      <span class="title" i18n>Keine Internetverbindung</span>
      <span i18n>Funktionalität eingeschränkt</span>
    </div>
    <div class="info" (click)="showOfflineInfoModal = true">
      <ion-icon color="white" src="assets/svgs/warning.svg"></ion-icon>
    </div>
  </div>
</section>

<section
  class="notice bluetooth-notice"
  *ngIf="bluetoothDisabled === true && showBluetoothNotices === true"
  slot="top"
>
  <div class="tile">
    <div class="content">
      <span class="bold" i18n>Bluetooth ist deaktiviert!</span>
    </div>

    <div class="info" proInfo="BluetoothInfo">
      <ion-icon color="white" src="assets/svgs/warning.svg"></ion-icon>
    </div>
  </div>
</section>

<section
  class="notice location-notice"
  *ngIf="locationDisabled === true && showBluetoothNotices === true"
  slot="top"
>
  <div class="tile">
    <div class="content">
      <span class="bold" i18n>Standortzulassung nicht erteilt!</span>
    </div>

    <div class="info">
      <pro-android-location-info
        [iconOnly]="true"
        [showLocationInfo]="true"
        [permissionStatus]="permissionStatus"
        (requestPermission)="requestPermission()"
      ></pro-android-location-info>
    </div>
  </div>
</section>

<section class="notice subscription-notice" *ngIf="activationExpired === true" slot="top">
  <div class="tile">
    <div class="content">
      <span class="bold ion-margin-bottom" [innerHtml]="activationNoticeHeadline"></span>
      <span i18n>Gib jetzt Deinen Freischalt-/Aktivierungscode ein</span>
    </div>

    <div class="info" [routerLink]="['/app/subscriptions-activation']">
      <ion-icon color="black" src="assets/svgs/arrow-right.svg"></ion-icon>
    </div>
  </div>
</section>

<section class="info-overlay" *ngIf="showOfflineInfoModal === true" [@inOutModalAnimation]>
  <div class="backdrop" (click)="showOfflineInfoModal = false"></div>
  <div class="overlay-content">
    <div class="scroll">
      <div class="info-icon">
        <ion-icon src="assets/svgs/warning.svg" color="secondary"></ion-icon>
      </div>
      <h3 class="ion-margin-vertical">
        <ion-text color="secondary" i18n>
          ProHerz Funktionen mit und ohne Internetverbindung
        </ion-text>
      </h3>
      <p i18n>
        Die App ist ohne Internetverbindung nicht vollständig funktionsfähig. Messungen werden
        <b>nicht</b> an das ProCarement CareCenter übermittelt oder auf dem Handy
        zwischengespeichert. Es kann
        <b>keine Kommunikation mit dem ProCarement CareCenter</b> stattfinden, das Dir im Normalfall
        Nachrichten, Erinnerungen und eventuell kritische Grenzwerte Deiner Vitalparameter
        zurückliefert. Ebenso kannst Du keine Daten hoch- oder herunterladen.
      </p>

      <h3 class="ion-margin-vertical">
        <ion-text color="secondary" i18n> Internetverbindung in den Handy-Einstellungen </ion-text>
      </h3>
      <p i18n>
        Ob Deine Internetverbindung aktiv ist, kannst Du in den Handy-Einstellungen sehen (je nach
        Handy z.B. unter „Netzwerk“, „W-Lan“). Kontrolliere außerdem, ob Du eventuell den
        Flugzeugmodus eingestellt hast, der automatisch Bluetooth und Internet ausstellt.
      </p>

      <h3 class="ion-margin-vertical">
        <ion-text color="secondary" i18n> App-Einstellungen außerhalb des W-Lans </ion-text>
      </h3>
      <p i18n>
        In den App-Einstellungen der Handyeinstellungen (dort zu finden in der Auflistung aller
        Apps) können verschiedene Berechtigungen eingestellt und verändert werden. Dort kann z.B.
        festgelegt sein, dass die ProHerz App sich nur mit W-Lan verbinden darf, nicht aber mit dem
        mobilen Internet. Solltest Du die App regelmäßig außerhalb Deines W-Lans nutzen, solltest Du
        diese Einstellung ändern.
      </p>

      <ion-button expand="block" (click)="showOfflineInfoModal = false" i18n>Ok</ion-button>
    </div>
  </div>
</section>
