import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromTermsOfService from './terms-of-service.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TermsOfServiceEffects } from './terms-of-service.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromTermsOfService.termsOfServiceFeatureKey, fromTermsOfService.reducer),
    EffectsModule.forFeature([TermsOfServiceEffects]),
  ],
})
export class TermsOfServiceStoreModule {}
