import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import * as Model from 'app/models';
import { NavigationExtras } from '@angular/router';

export const authenticate = createAction(
  '[Auth/API] Authenticate',
  props<{
    email: string;
    password: string;
    navigation: { commands: any[]; extras?: NavigationExtras };
  }>()
);

export const authenticateSuccess = createAction(
  '[Auth/API] Authenticate Success',
  props<{
    data: Model.SignInData;
    navigation: { commands: any[]; extras?: NavigationExtras };
  }>()
);

export const authenticateFailure = createAction(
  '[Auth/API] Authenticate Failure',
  props<{ error: HttpErrorResponse }>()
);

export const authenticateWithQR = createAction(
  '[Auth/API] Authenticate With QR Code',
  props<{ qr_token: string; navigation: { commands: any[]; extras?: NavigationExtras } }>()
);

export const setStorageSuccess = createAction(
  '[Auth/Storage] Set Storage Success',
  props<{ navigation: { commands: any[]; extras?: NavigationExtras } }>()
);

export const addSignInData = createAction('[Auth] Add Sign In Data');

export const addSignInDataSuccess = createAction(
  '[Auth/Storage] Add Sign In Data Success',
  props<{ data: Model.SignInData }>()
);

export const addSignInDataFailure = createAction('[Auth/Storage] Add Sign In Data Failure');

export const signOut = createAction(
  '[Auth/API] Sign Out',
  props<{ navigation: { commands: any[]; extras?: NavigationExtras } }>()
);

export const signOutSuccess = createAction(
  '[Auth/API] Sign Out Success',
  props<{ navigation: { commands: any[]; extras?: NavigationExtras } }>()
);

export const signOutFailure = createAction(
  '[Auth/API] Sign Out Failure',
  props<{ error: HttpErrorResponse }>()
);

export const deleteAuthStorage = createAction('[Auth/API] Delete Auth Storage');

export const deleteAuthStorageSuccess = createAction(
  '[Auth/API] Delete Auth Storage Success',
  props<{ navigation: { commands: any[]; extras?: NavigationExtras } }>()
);

export const deleteUser = createAction(
  '[Auth/API] Delete User',
  props<{ exportData: string; navigation: { commands: any[]; extras?: NavigationExtras } }>()
);

export const deleteUserSuccess = createAction(
  '[Auth/API] Delete User Success',
  props<{ navigation: { commands: any[]; extras?: NavigationExtras } }>()
);

export const deleteUserFailure = createAction(
  '[Auth/API] Delete User Failure',
  props<{ error: HttpErrorResponse }>()
);

export const removeStorageSuccess = createAction(
  '[Auth/Storage] Remove Storage Success',
  props<{ navigation: { commands: any[]; extras?: NavigationExtras } }>()
);

export const redirect = createAction(
  '[Auth/API] Redirect',
  props<{ navigation: { commands: any[]; extras?: NavigationExtras } }>()
);

export const addUserIdSuccess = createAction('[Auth/Storage] Add User Id Success');

export const clearState = createAction(
  '[Auth] Clear State',
  props<{ navigation: { commands: any[]; extras?: NavigationExtras } }>()
);

export const clearStateSuccess = createAction(
  '[Auth] Clear State Success',
  props<{ navigation: { commands: any[]; extras?: NavigationExtras } }>()
);

export const authenticateTokenFailure = createAction(
  '[Auth/Storage] Authenticate Token Failure',
  props<{ error: any }>()
);
