import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BmResultBloodPressure } from 'app/app-store/devices-store/devices.model';
import {
  ObservationResultValue,
  ObservationResultBloodPressure,
  ResultValue,
  ResultBloodPressure,
} from 'app/models';
import { Po60ResultValues } from 'app/app-store/devices-store/devices.model';

@Component({
  selector: 'pro-device-result-item',
  templateUrl: './device-result-item.component.html',
  styleUrls: ['./device-result-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceResultItemComponent {
  @Input() observationResult: ObservationResultValue | ObservationResultBloodPressure;
  @Input() result: ResultValue | ResultBloodPressure | Po60ResultValues | BmResultBloodPressure;
  @Input() lines: 'full' | 'inset' | 'none' | undefined = 'full';

  constructor() {}
}
