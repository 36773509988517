import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'pro-intakes-badge',
  templateUrl: './intakes-badge.component.html',
  styleUrls: ['./intakes-badge.component.scss'],
})
export class IntakesBadgeComponent implements OnInit, OnChanges {
  @ViewChild('levelIndicator', { static: true }) levelIndicator: ElementRef;

  @Input() small = false;

  @Input() current: number = null;

  @Input() total: number = null;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.setProgress();
  }

  ngOnChanges() {
    this.setProgress();
  }

  setProgress() {
    if (this.levelIndicator) {
      if (this.total !== null && this.current !== null) {
        const height = this.small ? 40 : 68;
        const percent = this.total === 0 ? height : height - (this.current / this.total) * height;
        const newClip = `rect(${percent}px, 68px, 68px, 0)`;
        this.renderer.setStyle(this.levelIndicator.nativeElement, 'clip', newClip);
      }
    }
  }
}
