import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { TermsOfService } from 'app/models';
import * as Actions from './terms-of-service.actions';

export const termsOfServiceFeatureKey = 'termsOfService';

export interface State {
  data: TermsOfService | null;
  loading: boolean;
  adding: boolean;
  error: any | null;
}

export const initialState: State = {
  data: null,
  loading: false,
  adding: false,
  error: null,
};

const termsOfServiceReducer = createReducer(
  initialState,
  on(Actions.loadTermsOfService, Actions.acceptTermsOfService, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.loadTermsOfServiceSuccess, Actions.addTermsOfServiceSuccess, (state, action) => ({
    ...state,
    data: action.data,
    loading: false,
    error: null,
  })),
  on(Actions.loadTermsOfServiceFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(Actions.acceptTermsOfServiceSuccess, (state) => ({
    ...state,
    data: { ...state.data, accepted: true },
    loading: false,
  })),
  on(Actions.acceptTermsOfServiceFailure, (state) => ({ ...state, loading: false })),
  on(Actions.loadTermsOfServiceSuccess, Actions.addTermsOfService, (state) => ({
    ...state,
    adding: true,
  })),
  on(
    Actions.setStorageSuccess,
    Actions.addTermsOfServiceSuccess,
    Actions.addTermsOfServiceFailure,
    (state) => ({ ...state, adding: false })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return termsOfServiceReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('termsOfService');

export const selectTermsOfService = createSelector(selectFeature, (state) => state.data);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);

export const selectError = createSelector(selectFeature, (state): any => state.error);

export const selectAdding = createSelector(selectFeature, (state) => state.adding);
