import { Action, createReducer, createSelector, createFeatureSelector, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DeviceInformation } from 'app/models';
import { Po60ResultValues } from 'store/devices-store/devices.model';
import { DeviceEntityState, DeviceType } from '../devices.model';
import * as Po60Actions from './po60.actions';
import * as DeviceActions from '../devices.actions';

export const po60sFeatureKey = 'po60';

export interface State extends DeviceEntityState<Po60ResultValues> {
  address: string | null;
  name: 'PO60';
  type: DeviceType;
  lastResults: Po60ResultValues[];
  transferring: boolean;
  scanning: boolean;
  deviceInformation: DeviceInformation | null;
  error: Error | string | null;
}

export function selectResultId(r: Po60ResultValues): string {
  return `${r.type}_${r.device_time}`;
}

export const adapter: EntityAdapter<Po60ResultValues> = createEntityAdapter<Po60ResultValues>({
  selectId: selectResultId,
});

export const initialState: State = adapter.getInitialState({
  address: null,
  name: 'PO60',
  type: DeviceType.PulseOximeter,
  lastResults: [],
  transferring: false,
  scanning: false,
  deviceInformation: {
    manufacturer: 'Beurer',
    model: 'PO60',
    software_version: '',
    hardware_version: '',
    firmware_version: '',
  },
  error: null,
});

const po60Reducer = createReducer(
  initialState,
  on(Po60Actions.addState, (state, action) => ({
    ...action.state,
    type: DeviceType.PulseOximeter,
  })),
  on(Po60Actions.startScanning, (state) => ({ ...state, scanning: true })),
  on(DeviceActions.startScanManualMeasurements, (state) => ({
    ...state,
    transferring: true,
  })),
  on(Po60Actions.loadDataStorage, Po60Actions.loadMeasurements, (state, action) => ({
    ...state,
    address: action.address,
    transferring: true,
  })),
  on(
    Po60Actions.loadDataStorageSubscribed,
    Po60Actions.loadDataStorageSubscribedResult,
    Po60Actions.loadMeasurementsSubscribedResult,
    (state) => ({ ...state, transferring: true })
  ),
  on(Po60Actions.loadMeasurementsSuccess, (state, action) =>
    adapter.upsertMany(action.results, {
      ...state,
      lastResults: action.lastResults,
      transferring: false,
      deviceInformation: action.deviceInformation,
    })
  ),
  on(Po60Actions.deleteDeviceSuccess, () => initialState),
  on(
    Po60Actions.loadDeviceInformation,
    Po60Actions.loadDataStorage,
    Po60Actions.loadMeasurementsSuccess,
    Po60Actions.loadMeasurements,
    Po60Actions.loadDataStorageSubscribedResult,
    Po60Actions.setTimeSuccess,
    DeviceActions.startScan,
    (state) => ({
      ...state,
      error: null,
    })
  ),
  on(Po60Actions.initialisingError, (state, action) => ({
    ...initialState,
    error: action.error,
  })),
  on(Po60Actions.transferringError, (state, action) => ({
    ...state,
    error: action.error,
  })),
  on(Po60Actions.updateLastResults, (state, action) => ({
    ...state,
    lastResults: action.lastResults,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return po60Reducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('po60');

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectAddress = createSelector(selectFeature, (state) => state.address);

export const selectTransferring = createSelector(selectFeature, (state) => state.transferring);

export const selectError = createSelector(selectFeature, (state) => state.error);
