import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Bm54StoreModule } from './bm54-store/bm54-store.module';
import { Bm57StoreModule } from './bm57-store/bm57-store.module';
import { Bm64StoreModule } from './bm64-store/bm64-store.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, Bm54StoreModule, Bm57StoreModule, Bm64StoreModule],
})
export class BmStoreModule {}
