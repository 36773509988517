import { Component, Input } from '@angular/core';
import { MonitoringGoalType } from 'app/models';

@Component({
  selector: 'pro-icon-badge',
  templateUrl: './icon-badge.component.html',
  styleUrls: ['./icon-badge.component.scss'],
})
export class IconBadgeComponent {
  @Input() type: MonitoringGoalType | 'appointment' | 'document' | 'medication' | 'device';

  @Input() active = false;

  @Input() small = false;
}
