import { Pipe, PipeTransform } from '@angular/core';
import { MonitoringGoalType } from 'app/models';

/**
 * Pipe takes numeric value and transforms it to a comma-separated string
 * Adds or removes decimal places if necessary
 */
@Pipe({
  name: 'valueFormat',
})
export class ValueFormatPipe implements PipeTransform {
  decimalPlacesByGoalType: Partial<Record<MonitoringGoalType, number>> = {
    ['BloodPressureGoal']: 0,
    ['HeartRateGoal']: 0,
    ['OxygenSaturationGoal']: 0,
    ['TemperatureGoal']: 1,
    ['WeightGoal']: 1,
  };

  transform(
    value: number | string,
    type: MonitoringGoalType | null,
    decimalPlaces?: number
  ): string {
    const valueAsNumber = +value;
    // not a numeric value -> return original input-value
    if (isNaN(valueAsNumber) === true) {
      return value as string;
    }
    // no type but decimal places given -> force decimal places
    if (type === null && (decimalPlaces || decimalPlaces === 0)) {
      value = valueAsNumber.toFixed(decimalPlaces);
    }
    // valid type given -> ignore eventually given decimal places. Assigns defined number of decimal places
    if (type !== null && this.decimalPlacesByGoalType[type] !== undefined) {
      value = valueAsNumber.toFixed(this.decimalPlacesByGoalType[type]);
    }
    // replace dot with comma and return string
    return value.toString().replace('.', ',');
  }
}
