import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuestionnaireGuard } from './questionnaire.guard';
import { QuestionnaireListComponent } from './questionnaire-list/questionnaire-list.component';
import { QuestionnaireSurveyComponent } from './questionnaire-survey/questionnaire-survey.component';
import { QuestionnaireResultComponent } from './questionnaire-result/questionnaire-result.component';
import { QuestionnaireInformationComponent } from './questionnaire-information/questionnaire-information.component';
import { HelplineComponent } from './helpline/helpline.component';

const routes: Routes = [
  {
    path: '',
    component: QuestionnaireListComponent,
    canActivate: [QuestionnaireGuard],
  },
  {
    path: ':surveyId',
    component: QuestionnaireSurveyComponent,
    data: { androidBackButtonIgnore: true },
  },
  {
    path: ':surveyId/result',
    component: QuestionnaireResultComponent,
    data: { androidBackButtonIgnore: true },
  },
  {
    path: 'modal/information',
    component: QuestionnaireInformationComponent,
  },
  {
    path: 'modal/helpline',
    component: HelplineComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuestionnaireRoutingModule {}
