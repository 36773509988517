import { createAction, props } from '@ngrx/store';
import {
  LocalNotificationDescriptor,
  LocalNotificationSchema,
} from '@capacitor/local-notifications';
import { ILocalNotificationType } from './notifications.model';

export const initOneSignal = createAction('[Notifications] Initialise One Signal');

export const initOneSignalSuccess = createAction('[Notifications] Initialise One Signal Success');

export const initBadgeSuccess = createAction('[Notifications] Initialise Badge Success');

export const removeOneSignalExternalUserIdSuccess = createAction(
  '[Notifications] Remove One Signal External User Id Success'
);

export const loadNotificationsSuccess = createAction(
  '[Notifications/API] Load Notifications Success',
  props<{ notifications: LocalNotificationSchema[] }>()
);

export const deleteNotificationsSuccess = createAction(
  '[Notifications/API] Delete Notifications Success',
  props<{ notifications?: ILocalNotificationType[] }>()
);

export const deleteNotificationsFailure = createAction(
  '[Notifications/API] Delete Notifications Failure',
  props<{ error: any }>()
);

export const addPendingNotifications = createAction(
  '[Notifications/API] Add Pending Notifications'
);

export const addPendingNotificationsSuccess = createAction(
  '[Notifications/API] Add Pending Notifications Success',
  props<{ notifications: LocalNotificationSchema[] }>()
);

export const addPendingNotificationsFailure = createAction(
  '[Notifications/API] Add Pending Notifications Failure',
  props<{ error: any }>()
);

export const addCustomNotifications = createAction(
  '[Notifications/API] Add Custom Notifications',
  props<{ notifications: ILocalNotificationType[] }>()
);

export const deleteCustomNotifications = createAction(
  '[Notifications/API] Delete Custom Notifications',
  props<{ notifications: LocalNotificationDescriptor[] }>()
);

export const deleteCustomNotificationsSuccess = createAction(
  '[Notifications/API] Delete Custom Notifications Success',
  props<{ notifications: LocalNotificationDescriptor[] }>()
);

export const deleteCustomNotificationsFailure = createAction(
  '[Notifications/API] Delete Custom Notifications Failure',
  props<{ error: any }>()
);
