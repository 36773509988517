import { convertToParamMap } from '@angular/router';
import {
  Action,
  createReducer,
  on,
  createFeatureSelector,
  createSelector,
  createSelectorFactory,
  resultMemoize,
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import { AnyFn } from '@ngrx/store/src/selector';
import { isEqual } from 'lodash-es';
import { Message, MessageCategoryType } from 'app/models';
import { isArrIncludedInArr } from 'app/utils';
import * as fromRouter from 'store/router-store/router.selectors';
import * as MessagesActions from './messages.actions';
import * as fromRouterSelectors from 'store/router-store/router.selectors';
import * as dayjs from 'dayjs';

export const messagesFeatureKey = 'messages';

export interface State extends EntityState<Message> {
  loading: boolean;
  polling: boolean;
  learningMessageCategories: string[];
}

export const adapter: EntityAdapter<Message> = createEntityAdapter<Message>({
  sortComparer: (a: Message, b: Message) =>
    new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
});

export const initialState: State = adapter.getInitialState({
  ids: [],
  entities: {},
  loading: false,
  polling: false,
  learningMessageCategories: [],
});

const messagesReducer = createReducer(
  initialState,
  on(
    MessagesActions.loadMessagesSuccess,
    MessagesActions.loadPollingMessagesSuccess,
    (state, action) =>
      adapter.addMany(action.messages, {
        ...state,
        learningMessageCategories: action.learningMessageCategories,
      })
  ),
  on(MessagesActions.loadMessageSuccess, (state, action) =>
    adapter.upsertOne(action.message, state)
  ),
  on(MessagesActions.loadMessages, MessagesActions.loadMessage, (state) => ({
    ...state,
    loading: true,
  })),
  on(MessagesActions.loadMessageSuccess, (state, action) =>
    adapter.upsertOne(action.message, state)
  ),
  on(
    MessagesActions.loadMessagesSuccess,
    MessagesActions.loadMessageSuccess,
    MessagesActions.loadMessagesFailure,
    MessagesActions.loadMessageSuccess,
    MessagesActions.loadMessageFailure,
    (state) => ({
      ...state,
      loading: false,
    })
  ),
  on(MessagesActions.startPollingMessages, (state) => ({ ...state, polling: true })),
  on(MessagesActions.stopPollingMessages, (state) => ({ ...state, polling: false }))
);

export const customMemoized = (projectorFn: AnyFn) => resultMemoize(projectorFn, isEqual);

export const selectFeature = createFeatureSelector<State>('messages');

export function reducer(state: State | undefined, action: Action) {
  return messagesReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);

export const selectPolling = createSelector(selectFeature, (state) => state.polling);

export const selectMessage = (props: { id: number }) =>
  createSelector(selectEntities, (en: Dictionary<Message>) => en[props.id]);

export const selectMessageById = createSelector(
  selectEntities,
  fromRouterSelectors.selectRouteParam('id'),
  (entities: Dictionary<Message>, id: string) => entities[id]
);

export const selectUnreadMessages = createSelector(selectAll, (en) =>
  en.filter((e) => !e.opened_at)
);

export const selectUnreadMessagesTotal = createSelector(selectUnreadMessages, (en) => en.length);

export const selectMessagesByCategories = (categories: MessageCategoryType[]) =>
  createSelector(selectAll, (entities) => entities.filter((e) => categories.includes(e.category)));

export const selectRouteParamCategories = createSelector(fromRouter.selectQueryParams, (params) =>
  convertToParamMap(params).getAll('categories')
);

export const selectRouteParamLearningMessagesCategories = createSelector(
  fromRouter.selectQueryParams,
  (params) => convertToParamMap(params).getAll('learningMessageCategories')
);

export const selectMessagesByCategoriesAndLearningMessageCategories = createSelector(
  selectAll,
  selectRouteParamCategories,
  selectRouteParamLearningMessagesCategories,
  fromRouter.selectQueryParam('operator'),
  (entities, categories, learningMessageCategories, operator) =>
    categories.length > 0 && learningMessageCategories.length > 0 && operator === 'AND'
      ? entities.filter((e) =>
          e.learning_message_categories !== undefined
            ? categories.includes(e.category) &&
              isArrIncludedInArr(learningMessageCategories, e.learning_message_categories)
            : false
        )
      : categories.length > 0 && learningMessageCategories.length > 0
      ? entities.filter((e) =>
          e.learning_message_categories !== undefined
            ? categories.includes(e.category) ||
              isArrIncludedInArr(learningMessageCategories, e.learning_message_categories)
            : categories.includes(e.category)
        )
      : categories.length > 0 && learningMessageCategories.length === 0
      ? entities.filter((e) => categories.includes(e.category))
      : categories.length === 0 && learningMessageCategories.length > 0
      ? entities.filter(
          (e) =>
            e.learning_message_categories !== undefined &&
            isArrIncludedInArr(learningMessageCategories, e.learning_message_categories)
        )
      : entities
);

export const selectMessagesFiltered = createSelector(
  selectMessagesByCategoriesAndLearningMessageCategories,
  fromRouter.selectQueryParams,
  (entities, params) => {
    // filter unread messages
    let messages =
      params?.unread === 'true' ? entities.filter((e) => e.opened_at === null) : entities;

    // exclude messages which contain categories from param excludeLearningMessageCategories
    if (params?.excludeLearningMessageCategories) {
      messages = messages.filter(
        (message) =>
          !isArrIncludedInArr(
            convertToParamMap(params).getAll('excludeLearningMessageCategories'),
            message.learning_message_categories || []
          )
      );
    }

    // filter messages between from and to
    return params?.from !== undefined
      ? messages.filter((message) =>
          dayjs(message.created_at).isBetween(
            params?.from,
            params?.to !== undefined ? params?.to : params?.from,
            'day',
            '[]'
          )
        )
      : messages;
  }
);

export const selectMessagesCareCenter = createSelector(
  selectMessagesByCategories([
    'communication_measurement',
    'communication_survey',
    'communication_recommendation',
    'communication_other',
  ]),
  (entities) => entities
);

export const selectMessagesGoodToKnow = createSelector(
  selectMessagesByCategories(['learning', 'communication_knowledge']),
  (entities) =>
    entities.filter(
      (e) =>
        e.learning_message_categories === undefined ||
        !isArrIncludedInArr(['Onboarding', 'App'], e.learning_message_categories)
    )
);

export const selectMessagesMeasurements = createSelector(
  selectMessagesByCategories(['measurement_request', 'measurement_reminder']),
  (entities) => entities
);

export const selectMessagesAboutTheApp = createSelector(selectAll, (entities) =>
  entities.filter(
    (e) => e.category === 'change' || e.learning_message_categories?.includes('Onboarding')
  )
);

export const selectLearningMessageCategories = createSelectorFactory<State, string[]>(
  customMemoized
)(selectFeature, (state: State) => state.learningMessageCategories);
