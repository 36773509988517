<div class="loading-container">
  <div class="rectangle">
    <div class="icon-container">
      <ng-content select="[top-icon]"></ng-content>
    </div>
  </div>
  <div class="animation shift-top">
    <div [class.dot-gap]="text">
      <div class="dot" *ngFor="let i of ' '.repeat(14).split('')"></div>
    </div>
    <div class="animation-text">
      <ng-content select="[proTransferText]" #text></ng-content>
    </div>
  </div>
  <div class="rectangle shift-bottom">
    <div class="icon-container">
      <ng-content select="[bottom-icon]"></ng-content>
    </div>
  </div>
</div>
