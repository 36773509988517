import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { handleError } from 'app/services/error-tracking.service';
import { IGNORE_HTTP_ERROR } from 'app/app-store/api/api.service';

class HttpError extends Error {
  constructor(errorResponse: HttpErrorResponse) {
    super(errorResponse.name);
    this.name = errorResponse.name;
    this.message = errorResponse.message;
  }
}

@Injectable()
export class ErrorTrackingInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const ignoredHttpErrors = request?.context?.get(IGNORE_HTTP_ERROR);

        if (!ignoredHttpErrors?.includes(error.status) && error.status !== 401) {
          this.handleError(error);
        }

        return throwError(() => error);
      })
    );
  }

  private handleError(e: HttpErrorResponse) {
    const error = new HttpError(e);
    handleError(error);
  }
}
