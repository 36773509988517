import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { GlobalNoticeComponent } from './global-notice.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'shared/shared.module';

@NgModule({
  declarations: [GlobalNoticeComponent],
  imports: [CommonModule, IonicModule, BrowserAnimationsModule, SharedModule],
  exports: [GlobalNoticeComponent],
})
export class GlobalNoticeModule {}
