<div class="scroll">
  <div class="content">
    <div class="ion-margin-bottom ion-text-center">
      <ion-icon
        src="assets/svgs/icon-status-help.svg"
        color="secondary"
        class="head-icon"
      ></ion-icon>
    </div>

    <h5 i18n>Wie aktiviere ich Bluetooth?</h5>
    <p i18n>
      Bluetooth ist eine Funktechnologie zur Datenübertragung und wird durch folgendes Zeichen
      symbolisiert.
    </p>

    <div class="ion-margin-bottom ion-text-center">
      <ion-icon
        src="assets/svgs/icon-section-bluetooth.svg"
        color="secondary"
        class="content-icon"
      ></ion-icon>
    </div>

    <p i18n>
      ProHerz nutzt Bluetooth zur Übertragung der Messwerte von den medizinischen Messgeräten zur
      App. Deshalb muss Bluetooth dazu stets
      <strong>in den Handyeinstellungen aktiviert</strong> sein.
    </p>
    <p i18n>
      Die <strong>Handyeinstellungen</strong> sind oft mit einem Zahnrad symbolisiert und auf dem
      Startbildschirm des Handys zu finden.
    </p>
    <p i18n>
      Bluetooth ist standardmäßig angestellt und kostet auch nicht sehr viel mehr Akku.<br />
      Wenn aber der <strong>Flugmodus</strong> aktiviert ist, ist sowohl die Internetverbindung als
      auch Bluetooth automatisch deaktiviert.
    </p>
  </div>
</div>
<footer>
  <ion-button
    class="left-top-border-radius"
    (click)="popoverController.dismiss()"
    i18n
  >OK</ion-button>
</footer>