import { Action } from '@ngrx/store';
import * as fromBm from '../bm.reducer';
import * as Bm64Actions from './bm64.actions';

export const featureKey = 'bm64';

export type State = fromBm.State;

export const initialState = fromBm.getInitialState('BM64');

const bm54Reducer = fromBm.getReducer(Bm64Actions, initialState);

export function reducer(state: State | undefined, action: Action) {
  return bm54Reducer(state, action);
}

export const { selectFeature, selectIds, selectEntities, selectAll, selectTotal, selectAddress } =
  fromBm.getSelectors(featureKey);
