<ng-container *ngIf="type === 'image'">
  <div class="content">
    <h5
      class="margin-t-0"
      i18n
    >6-Kanal-EKG wählen</h5>
    <p i18n>
      Bitte wähle <b>“6-Kanal-EKG”</b>, da dies detailliertere Informationen über die elektrische
      Aktivität Deines Herzens liefert.
    </p>
    <pro-image
      src="assets/images/kardia-mobile-select-gradient.png"
      height="226px"
    ></pro-image>
  </div>
  <footer>
    <ion-button
      class="left-top-border-radius"
      (click)="dismiss('close')"
      i18n
    >Ok</ion-button>
  </footer>
</ng-container>
<ng-container *ngIf="type === 'checkbox'">
  <div class="content">
    <h5
      class="margin-t-0"
      i18n
    >6-Kanal-EKG wählen</h5>
    <p i18n>
      Bitte denke daran, <b>“6-Kanal-EKG”</b> zu wählen und durchzuführen, denn dadurch ist eine
      genauere Auswertung möglich.
    </p>
    <ion-item lines="none">
      <ion-checkbox
        color="primary"
        mode="md"
        [(ngModel)]="hideEcgInfo"
      ></ion-checkbox>
      <ion-label i18n>nicht mehr anzeigen</ion-label>
    </ion-item>
  </div>
  <footer class="buttons">
    <ion-button
      fill="clear"
      (click)="dismiss('close')"
      i18n
    >Zurück</ion-button>
    <ion-button
      type="submit"
      class="left-top-border-radius"
      (click)="dismiss('confirm')"
      data-cy="save-document-btn"
      i18n
    >Weiter
    </ion-button>
  </footer>
</ng-container>