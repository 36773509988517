<ion-item
  [lines]="lines"
  [id]="trigger"
>
  <ng-content select="ion-label"></ng-content>
  <ion-modal
    class="datetime-modal"
    [trigger]="!disabled ? trigger : ''"
    [initialBreakpoint]="0.35"
    [keepContentsMounted]="true"
  >
    <ng-template>
      <ion-content>
        <ion-datetime
          #datetime
          [min]="min"
          [max]="max"
          presentation="date"
          [yearValues]="yearValues"
          size="cover"
          [preferWheel]="true"
          (ionChange)="select($event)"
          (ionCancel)="ionCancel.emit($event)"
          [value]="date"
          [locale]="locale"
        >
          <ion-buttons slot="buttons">
            <ion-button
              class="body-text"
              (click)="cancel()"
            ><span>{{ cancelText }}</span></ion-button>
            <ion-button
              class="body-text"
              (click)="confirm()"
            ><span>{{ doneText }}</span></ion-button>
          </ion-buttons>
        </ion-datetime>
      </ion-content>
    </ng-template>
  </ion-modal>
  <ng-content></ng-content>
  <ng-content select="ion-icon"></ng-content>
</ion-item>