import {
  Action,
  createReducer,
  on,
  createFeatureSelector,
  createSelector,
  resultMemoize,
  createSelectorFactory,
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import { AnyFn } from '@ngrx/store/src/selector';
import { isEqual } from 'lodash-es';
import { MedicationIntake } from 'app/models';
import * as dayjs from 'dayjs';
import * as fromRouterSelectors from 'store/router-store/router.selectors';
import * as MedicationIntakesActions from './medication-intakes.actions';

export const medicationsFeatureKey = 'medicationIntakes';

const selectIdKey = (a: MedicationIntake) => {
  return a.uuid;
};

const sortByKey = (a: MedicationIntake, b: MedicationIntake) => {
  return a.uuid.localeCompare(b.uuid);
};

export interface State extends EntityState<MedicationIntake> {
  loading: boolean;
}

export const adapter: EntityAdapter<MedicationIntake> = createEntityAdapter<MedicationIntake>({
  selectId: selectIdKey,
  sortComparer: sortByKey,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
});

const medicationIntakesReducer = createReducer(
  initialState,
  on(
    MedicationIntakesActions.loadMedicationIntakes,
    MedicationIntakesActions.loadTodaysMedicationIntakes,
    MedicationIntakesActions.updateMedicationIntake,
    MedicationIntakesActions.updateMedicationIntakes,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    MedicationIntakesActions.loadMedicationIntakesSuccess,
    MedicationIntakesActions.loadMedicationIntakesFailure,
    MedicationIntakesActions.loadMedicationIntakesComplete,
    MedicationIntakesActions.updateMedicationIntakeSuccess,
    MedicationIntakesActions.updateMedicationIntakeFailure,
    MedicationIntakesActions.updateMedicationIntakesSuccess,
    MedicationIntakesActions.updateMedicationIntakesFailure,
    MedicationIntakesActions.loadTodaysMedicationIntakesSuccess,
    MedicationIntakesActions.loadTodaysMedicationIntakesFailure,
    (state) => ({
      ...state,
      loading: false,
    })
  ),
  on(
    MedicationIntakesActions.updateMedicationIntakeFailure,
    MedicationIntakesActions.updateMedicationIntakesFailure,
    (state, action) => adapter.upsertOne(action.medicationIntake, state)
  ),
  on(MedicationIntakesActions.loadMedicationIntakesSuccess, (state, action) =>
    adapter.upsertMany(action.medicationIntakes, state)
  ),
  on(MedicationIntakesActions.updateMedicationIntakePartialSuccess, (state, action) =>
    adapter.upsertOne(action.updatedMedicationIntake, state)
  ),
  on(MedicationIntakesActions.updateMedicationIntakesPartialSuccess, (state, action) =>
    adapter.upsertMany(action.updatedMedicationIntakes, state)
  ),
  on(MedicationIntakesActions.loadTodaysMedicationIntakesSuccess, (state, action) => {
    const today = dayjs().format('YYYY-MM-DD');
    return adapter.upsertMany(
      action.medicationIntakes,
      adapter.removeMany((entity) => entity.date === today, state)
    );
  }),
  on(MedicationIntakesActions.updateFirstFetchDate, (state, action) => ({
    ...state,
    firstFetch: action.firstFetch,
  }))
);

export const selectFeature = createFeatureSelector<State>('medicationIntakes');

export function reducer(state: State | undefined, action: Action) {
  return medicationIntakesReducer(state, action);
}

export const customMemoized = (projectorFn: AnyFn) => resultMemoize(projectorFn, isEqual);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);

export const selectMedicationIntakeByUuid = (props: { uuid: string }) =>
  createSelector(selectEntities, (entities: Dictionary<MedicationIntake>) =>
    entities[props.uuid] ? entities[props.uuid] : null
  );

export const selectMedicationIntakesByUuid = (props: { uuids: string[] }) =>
  createSelector(selectAll, (entities: MedicationIntake[]) =>
    entities.filter((e) => props.uuids.includes(e.uuid))
  );

export const selectMedicationIntakeByDate = (props: { date: string }) =>
  createSelector(selectAll, (entities: MedicationIntake[]) =>
    entities.filter((e) => e.date === props.date)
  );

export const selectMedicationIntakesFromToday = createSelector(selectAll, (en) =>
  en.filter((e) => e.date === dayjs().format('YYYY-MM-DD') && e.rate !== 'on_demand')
);

export const selectMedicationIntakesFromYesterday = createSelector(selectAll, (en) =>
  en.filter((e) => e.date === dayjs().subtract(1, 'day').format('YYYY-MM-DD'))
);

export const selectMedicationIntakesFromYesterdayWithoutOnDemand = createSelector(
  selectMedicationIntakesFromYesterday,
  (entities) => entities.filter((e) => e.rate !== 'on_demand')
);

export const selectMedicationIntakes = createSelector(
  selectAll,
  fromRouterSelectors.selectQueryParam('date'),
  (entities, date) =>
    date
      ? entities.filter((e) => e.date === date)
      : entities.filter((e) => e.date === dayjs().format('YYYY-MM-DD'))
);

export const selectMedicationIntakesByDuration = (props: { duration: number }) =>
  createSelector(
    selectAll,
    fromRouterSelectors.selectQueryParam('date'),
    (entities, date: string) =>
      date
        ? entities.filter((e) =>
            dayjs(e.date).isBetween(
              dayjs(date),
              dayjs(date).subtract(props.duration, 'day'),
              'day',
              '[]'
            )
          )
        : entities.filter((e) =>
            dayjs(e.date).isBetween(dayjs(), dayjs().subtract(props.duration, 'day'), 'day', '[]')
          )
  );

export const selectMedicationIntakesWeekly = createSelectorFactory<State, MedicationIntake[]>(
  customMemoized
)(selectMedicationIntakesByDuration({ duration: 1 }), (entities: MedicationIntake[]) =>
  entities.filter((e) => e.rate === 'weekly')
);

export const selectMedicationIntakesMonthly = createSelectorFactory<State, MedicationIntake[]>(
  customMemoized
)(selectMedicationIntakesByDuration({ duration: 2 }), (entities: MedicationIntake[]) =>
  entities.filter((e) => e.rate === 'monthly')
);

export const selectMedicationIntakesWithoutOnDemand = createSelectorFactory<
  State,
  MedicationIntake[]
>(customMemoized)(selectMedicationIntakes, (entities: MedicationIntake[]) =>
  entities.filter((e) => e.rate !== 'on_demand')
);

export const selectMedicationIntakesAllWithoutOnDemand = createSelectorFactory<
  State,
  MedicationIntake[]
>(customMemoized)(selectAll, (entities: MedicationIntake[]) =>
  entities.filter((e) => e.rate !== 'on_demand')
);

export const selectMedicationIntakesDaily = createSelectorFactory<State, MedicationIntake[]>(
  customMemoized
)(selectMedicationIntakes, (entities: MedicationIntake[]) =>
  entities.filter((e) => e.rate === 'daily')
);

export const selectMedicationIntakesDailyWeeklyMonthly = createSelectorFactory<
  State,
  MedicationIntake[]
>(customMemoized)(
  selectMedicationIntakesDaily,
  selectMedicationIntakesWeekly,
  selectMedicationIntakesMonthly,
  (
    medicationIntakesDaily: MedicationIntake[],
    medicationIntakesWeekly: MedicationIntake[],
    medicationIntakesMonthly: MedicationIntake[]
  ): MedicationIntake[] => [
    ...medicationIntakesDaily,
    ...medicationIntakesWeekly,
    ...medicationIntakesMonthly,
  ]
);

export const selectMedicationIntakesMorning = createSelectorFactory<State, MedicationIntake[]>(
  customMemoized
)(selectMedicationIntakesWithoutOnDemand, (entities: MedicationIntake[]) =>
  entities.filter((e) => e.part_of_day === 'morning')
);

export const selectMedicationIntakesNoon = createSelectorFactory<State, MedicationIntake[]>(
  customMemoized
)(selectMedicationIntakesWithoutOnDemand, (entities: MedicationIntake[]) =>
  entities.filter((e) => e.part_of_day === 'noon')
);

export const selectMedicationIntakesEvening = createSelectorFactory<State, MedicationIntake[]>(
  customMemoized
)(selectMedicationIntakesWithoutOnDemand, (entities: MedicationIntake[]) =>
  entities.filter((e) => e.part_of_day === 'evening')
);

export const selectMedicationIntakesNight = createSelectorFactory<State, MedicationIntake[]>(
  customMemoized
)(selectMedicationIntakesWithoutOnDemand, (entities: MedicationIntake[]) =>
  entities.filter((e) => e.part_of_day === 'night')
);
