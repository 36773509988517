import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
@Component({
  selector: 'pro-physician-number-info',
  templateUrl: './physician-number-info.component.html',
  styleUrls: ['./physician-number-info.component.scss'],
})
export class PhysicianNumberInfoComponent {
  constructor(public popoverController: PopoverController) {}
}
