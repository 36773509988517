import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'pro-sdk-about-screen',
  templateUrl: './sdk-about-screen.component.html',
  styleUrls: ['./sdk-about-screen.component.scss'],
})
export class SdkAboutScreenComponent {
  src = `assets/images/${
    this.platform.is('android') ? 'sdk_android_about_screen.jpg' : 'sdk_ios_about_screen.jpg'
  }`;

  constructor(private platform: Platform) {}
}
