<ng-content select="[pro-expansion-sub-header]"></ng-content>
<ng-content select="pro-expansion-header"></ng-content>
<div
  *ngIf="expand"
  class="expansion-header"
  [style.height]="headerHeight"
>
  <div class="expansion-header-content">
    <ng-content select="[pro-expansion-header]"></ng-content>
    <div class="expansion-icon">
      <ion-icon
        [@chevron]="active ? 'active' : 'inactive'"
        name="chevron-down-outline"
        aria-hidden="true"
      ></ion-icon>
    </div>
  </div>
</div>
<div
  class="expansion-content"
  [@expand]="active ? 'active' : 'inactive'"
>
  <ng-content select="pro-expansion-content"></ng-content>
</div>