import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as cordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { firstValueFrom, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageFacade {
  private STORAGE: Storage | null = null;
  private STORAGE_CREATED = new Subject();

  constructor(private storage: Storage) {
    this.init();

    if (window['Cypress' as any]) {
      window['storageFacade' as any] = this as any;
    }
  }

  async set(key: string, value: any) {
    await this.storageCreated();
    return this.STORAGE?.set(key, value);
  }

  async get(key: string) {
    await this.storageCreated();
    return this.STORAGE?.get(key);
  }

  async remove(key: string) {
    await this.storageCreated();
    return this.STORAGE?.remove(key);
  }

  async clear() {
    await this.storageCreated();
    return this.STORAGE?.clear();
  }

  private async init() {
    await this.storage.defineDriver(cordovaSQLiteDriver);
    this.STORAGE = await this.storage.create();
    this.STORAGE_CREATED.next(true);
  }

  private async storageCreated() {
    if (!this.STORAGE) {
      await firstValueFrom(this.STORAGE_CREATED);
    }
  }
}
