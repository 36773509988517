import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';
import * as fromAuth from 'store/auth-store/auth.reducer';
import * as AuthActions from 'store/auth-store/auth.actions';
import * as fromInfo from 'store/info-store/info.reducer';
import { ApiService } from 'store/api/api.service';
import { RegistrationUser } from 'app/models';
import { first, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  authLoading$ = this.store.pipe(select(fromAuth.selectLoading));
  authError$ = this.store.pipe(select(fromAuth.selectError));
  patientId$ = this.store.pipe(select(fromAuth.selectUserId));
  acceptedTermsOfService$ = this.store.pipe(select(fromAuth.selectAcceptedTermsOfService));
  authInfo$ = this.store.pipe(select(fromAuth.selectData));

  constructor(private store: Store<fromAuth.State>, private api: ApiService) {
    // exposes the store and this facade when running in cypress
    if (window['Cypress' as any]) {
      window['store' as any] = this.store as any;
      window['authStoreFacade' as any] = this as any;
    }
  }

  authenticate(
    email: string,
    password: string,
    navigation: { commands: any[]; extras?: NavigationExtras }
  ) {
    this.store.dispatch(AuthActions.authenticate({ email, password, navigation }));
  }

  authenticateWithQR(qrToken: string, navigation: { commands: any[]; extras?: NavigationExtras }) {
    this.store.dispatch(AuthActions.authenticateWithQR({ qr_token: qrToken, navigation }));
  }

  signOut(navigation: { commands: any[]; extras?: NavigationExtras }) {
    this.store.dispatch(AuthActions.signOut({ navigation }));
  }

  deleteAuthStorage() {
    this.store.dispatch(AuthActions.deleteAuthStorage());
  }

  deleteUser(exportData = '0', navigation: { commands: any[]; extras?: NavigationExtras }) {
    this.store.dispatch(AuthActions.deleteUser({ exportData, navigation }));
  }

  clearState(navigation: { commands: any[]; extras?: NavigationExtras }) {
    this.store.dispatch(AuthActions.clearState({ navigation }));
  }

  changeEmail(email: string, password: string) {
    return this.api.changeEmail(email, password);
  }

  changePassword(currentPassword: string, password: string, passwordConfirmation: string) {
    return this.api.changePassword(currentPassword, password, passwordConfirmation);
  }

  /**
   * no endpoint for verifying password. use sign_in instead
   * @param password current password
   */
  checkPassword(password: string) {
    return this.store.pipe(select(fromInfo.selectInfo)).pipe(
      first(),
      switchMap((patientInfo) => this.api.signIn({ email: patientInfo.email, password }))
    );
  }

  /**
   * registration
   */
  registerUser(user: RegistrationUser) {
    return this.api.registerUser(user);
  }

  confirmEmail(email: string, code: string) {
    return this.api.confirmEmail(email, code);
  }

  resetPassword(email: string) {
    return this.api.resetPassword(email);
  }
}
