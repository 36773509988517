import { Renderer2, ComponentRef, ChangeDetectorRef } from '@angular/core';
import {
  Component,
  Directive,
  ViewContainerRef,
  Input,
  ComponentFactoryResolver,
  OnInit,
  ChangeDetectionStrategy,
  HostListener,
} from '@angular/core';

@Component({
  template: `
    <div class="error-icon" *ngIf="isVisible">
      <ion-icon name="information-circle-outline"></ion-icon>
    </div>
    <div class="error" *ngIf="isVisible">
      {{ info }}
    </div>
  `,
  styles: [
    `
      .error-icon {
        position: absolute;
        right: 7px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 29px;
        color: var(--ion-color-secondary);
      }

      ion-icon {
        font-size: 29px;
      }

      .error {
        min-height: 24px;
        font-size: 0.82rem;
        font-weight: bold;
        line-height: 0.97rem;
        color: var(--ion-color-secondary);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInfoComponent {
  info: string;
  isVisible = false;

  @Input()
  set text(value: string) {
    if (!this.info) {
      this.info = value;
      this.cdRef.detectChanges();
    }
  }

  @Input()
  set visible(value: boolean) {
    this.isVisible = value;
    this.cdRef.detectChanges();
  }

  constructor(private cdRef: ChangeDetectorRef) {}
}

@Directive({
  selector: '[proFormInfo]',
})
export class FormInfoDirective implements OnInit {
  @Input() proFormInfo: string;

  @Input() proFormInfoStable = false; // never hide icon and info

  ref: ComponentRef<FormInfoComponent>;

  @HostListener('focusout') onBlur() {
    if (this.proFormInfoStable === false) {
      this.ref.instance.visible = false;
    }
  }

  @HostListener('focusin') onFocus() {
    this.ref.instance.visible = true;
    this.vcr.element.nativeElement.setFocus();
  }

  constructor(
    private vcr: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    const factory = this.cfr.resolveComponentFactory(FormInfoComponent);
    this.ref = this.vcr.createComponent(factory);
    this.ref.instance.text = this.proFormInfo;
    this.renderer.addClass(this.vcr.element.nativeElement, 'readonly');

    if (this.proFormInfoStable === true) {
      this.ref.instance.visible = true;
    }
  }
}
