import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pro-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @Input() src: string;
  @Input() height: 'auto';
  @Output() imageLoaded: EventEmitter<any> = new EventEmitter();
  loaded = false;

  constructor() {}

  ionImgDidLoad() {
    this.loaded = true;
    this.imageLoaded.emit();
  }
}
