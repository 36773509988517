<ng-container [ngSwitch]="category">
  <ion-icon
    *ngSwitchCase="'report_stationary'"
    src="assets/svgs/report_stationary.svg"
    color="secondary"
  ></ion-icon>
  <ion-icon
    *ngSwitchCase="'report_ambulant'"
    src="assets/svgs/report_ambulant.svg"
    color="secondary"
  ></ion-icon>
  <ion-icon
    *ngSwitchCase="'laboratory'"
    src="assets/svgs/report_laboratory.svg"
    color="secondary"
  ></ion-icon>
  <ion-icon
    *ngSwitchCase="'ecg'"
    src="assets/svgs/report_ecg.svg"
    color="secondary"
  ></ion-icon>
  <ion-icon
    *ngSwitchCase="'ecg_result'"
    src="assets/svgs/report_ecg.svg"
    color="secondary"
  ></ion-icon>
  <ion-icon
    *ngSwitchCase="'imaging'"
    src="assets/svgs/report_imaging.svg"
    color="secondary"
  ></ion-icon>
  <ion-icon
    *ngSwitchCase="'other'"
    src="assets/svgs/icon-diagnoses.svg"
    color="secondary"
  ></ion-icon>
  <ion-icon
    *ngSwitchCase="'procarement'"
    src="assets/svgs/logo.svg"
    color="secondary"
  ></ion-icon>
  <ion-icon
    *ngSwitchCase="'questionnaire'"
    src="assets/svgs/questionnaire-check.svg"
    color="secondary"
  ></ion-icon>
  <ion-icon
    *ngSwitchCase="'rights_and_disposals'"
    src="assets/svgs/rights_and_disposals.svg"
    color="secondary"
  ></ion-icon>
</ng-container>