import { Pipe, PipeTransform } from '@angular/core';

export enum Plurals {
  'Tablette' = 'Tablette',
  'Injektion' = 'Injektion',
  'Kapsel' = 'Kapsel',
  'Lösung' = 'Kapsel',
  'Creme' = 'Creme',
  'Tropfen' = 'Tropfen',
  'Spray' = 'Spray',
  'Datei' = 'Datei',
}

@Pipe({
  name: 'staticPlural',
})
export class StaticPluralPipe implements PipeTransform {
  plurals = {
    [Plurals.Tablette]: $localize`Tabletten`,
    [Plurals.Injektion]: $localize`Injektionen`,
    [Plurals.Kapsel]: $localize`Kapseln`,
    [Plurals.Lösung]: $localize`Lösungen`,
    [Plurals.Creme]: $localize`Creme`,
    [Plurals.Tropfen]: $localize`Tropfen`,
    [Plurals.Spray]: $localize`Sprays`,
    [Plurals.Datei]: $localize`Dateien`,
  };

  constructor() {}

  transform(v: string, amount: number | string): string {
    return !+amount || +amount === 1 || (this.plurals as Record<string, string>)[v] === undefined
      ? v
      : (this.plurals as Record<string, string>)[v];
  }
}
