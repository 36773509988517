import { skipWhile, first } from 'rxjs/operators';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivationsFacade } from 'store/activations-store/activations.facade';
import { MonitoringGoalsFacade } from 'store/monitoring-goals-store/monitoring-goals.facade';
import { SurveyFacade } from 'store/survey-store/survey.facade';

@Component({
  selector: 'pro-questionnaire-list',
  templateUrl: './questionnaire-list.component.html',
  styleUrls: ['./questionnaire-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnaireListComponent {
  constructor(
    private activationsFacade: ActivationsFacade,
    private monitoringGoalsFacade: MonitoringGoalsFacade,
    public surveyFacade: SurveyFacade,
    private router: Router
  ) {}

  surveys$ = this.monitoringGoalsFacade.getOpenMeasurements('SurveyGoal');
  activationCategory$ = this.activationsFacade.activationsCurrentCategory$;
  selectedSurveyId: string | null;

  selectSurvey(surveyId: string) {
    this.selectedSurveyId = surveyId;
    this.surveyFacade.loadSurvey(surveyId);
    if (this.surveyFacade.getSurvey(surveyId)) {
      this.router.navigate([`/app/questionnaire/${surveyId}`]);
    } else {
      this.surveyFacade.loading$
        .pipe(
          skipWhile((r) => r),
          first()
        )
        .subscribe(() => {
          this.selectedSurveyId = null;
          if (this.surveyFacade.getSurvey(surveyId)) {
            this.router.navigate([`/app/questionnaire/${surveyId}`]);
          }
        });
    }
  }
}
