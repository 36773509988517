<ion-header mode="ios">
  <ion-toolbar>
    <ion-back-button
      slot="start"
      mode="ios"
      defaultHref="app/overview/dashboard"
      role="button"
      icon="close"
      color="black"
      aria-label="Schließen"
      i18n-aria-label
    ></ion-back-button>

    <ion-title mode="md" i18n>Fragebögen</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <h2 i18n>Fragebögen</h2>

  <h3 i18n>
    Fragebogen zur Lebensqualität bei Herzinsuffizienz - Kansas City Cardiomyopathy Questionnaire
    (KCCQ-12)
  </h3>
  <p i18n>
    Dieser Fragebogen ist ein standardisiertes Verfahren zur Beurteilung Ihrer Lebensqualität mit
    Herzschwäche. Er wurde durch Studienergebnisse geprüft und darf entsprechend eingesetzt werden.
    Der Fragebogen ist geistiges Eigentum des Lizenzgebers. Dieser stellt weitere Informationen zur
    Nutzung und Interpretation zur Verfügung.
  </p>
  <ul>
    <li>
      <a
        href="https://www.sciencedirect.com/science/article/pii/S0735109720372326#:~:text=As%20described%2C%20all%20KCCQ%20scores,to%20100%3A%20good%20to%20excellent"
        proLink
        i18n
        >Veröffentlichtes, wissenschaftliches Paper (externer Link)</a
      >
    </li>
  </ul>

  <p i18n>In dieser App verwenden wir die Kurzform mit 12 Fragen.</p>
  <ul>
    <li i18n>Frage 1-3 bezieht sich auf die körperliche Leistungsfähigkeit</li>
    <li i18n>Frage 4-7 bezieht sich auf die Häufigkeit der Symptome</li>
    <li i18n>Frage 8-9 bezieht sich auf die Lebensqualität</li>
    <li i18n>Frage 10-12 bezieht sich auf soziale Einschränkungen</li>
  </ul>
  <p i18n>
    Jede dieser Kategorien bildet ein Ergebnis. Die Antwortmöglichkeiten sind dabei von oben (sehr
    beeinträchtigt) nach unten (überhaupt nicht beeinträchtigt) dargestellt. Bei der Ergebnisbildung
    werden Punkte von oben nach unten vergeben und mit denen der gleichen Kategorie addiert. Dann
    werden diese Ergebnisse mit der möglichen Maximalpunktzahl ins Verhältnis gesetzt und ein Wert
    zwischen 0 (schlechtestes Ergebnis) bis 100 (bestes Ergebnis, keine Einschränkungen des
    Lebensbereiches durch die Herzschwäche) erzeugt. In Abhängigkeit dieser Werte können wir Tipps
    geben, um einer Verschlechterung der Herzinsuffizienz vorzubeugen.
  </p>

  <h3 i18n>Fragebogen zur seelischen Gesundheit - 2-Fragentest</h3>
  <p i18n>
    Dieser Fragebogen ist ein standardisiertes Verfahren, um das Risiko einer seelischen/psychischen
    Erkrankung abzuschätzen.
  </p>
  <p i18n>Die University of California San Francisco gibt dazu folgende Informationen:</p>
  <ul>
    <li>
      <a href="https://whooleyquestions.ucsf.edu/" proLink i18n
        >https://whooleyquestions.ucsf.edu/ (externer Link)</a
      >
    </li>
  </ul>
  <p i18n>
    Wenn beide Fragen mit „Nein“ beantwortet werden, haben Sie kein Risiko. Wenn eine Frage mit „Ja“
    beantwortet wurde, besteht ebenfalls kein erhöhtes Risiko. Wir werden dann allerdings den Test
    von Zeit zu Zeit wiederholen. Sollten beide Fragen mit „Ja“ beantwortet werden, ist dies ein
    erster Hinweis auf eine mögliche psychische Problematik.
  </p>

  <h3 i18n>Fragebogen zum Wohlbefinden - WHO-5-Wohlbefindens-Index (WHO-5)</h3>
  <p i18n>
    Dieser Fragebogen ist ein standardisiertes Verfahren zur Beurteilung Ihres Wohlbefindens, auch
    mit chronischer Erkrankung.
  </p>
  <p i18n>
    Fünf Fragen umfasst der Fragebogen. Er wurde von der WHO (Weltgesundheitsorganisation)
    vorgeschlagen, um gegebenenfalls weitere Probleme mit und zusätzlich zur Erkrankung zu erkennen.
    In der medizinischen Leitlinie für unipolare Depressionen wird dieser Fragebogen ebenfalls zur
    Früherkennung empfohlen.
  </p>
  <p i18n>
    Jede der fünf Fragen kann Werte von 0 bis 5 annehmen. Durch die Bildung der Summe aller Werte
    wird ein Gesamtwert gebildet. Niedrige Werte sprechen dabei für ein niedriges Wohlbefinden und
    hohe Werte sprechen für ein hohes Wohlbefinden. Ein Grenzwert unter 13 Punkten kann dabei auf
    eine Depression hinweisen und sollte im Zweifel weiter abgeklärt werden.
  </p>
  <p i18n>
    Auch Verlaufswerte, also der Vergleich von WHO-5 Fragebögen eines Patienten zu verschiedenen
    Zeitpunkten, kann gute Hinweise auf die Entwicklung der Person geben.
  </p>
  <p i18n>
    Der Test lässt sich nicht nur bei Erwachsenen, sondern auch bei Kindern und Jugendlichen
    anwenden.
  </p>
  <p i18n>Die Bearbeitungszeit liege bei etwa einer Minute.</p>
</ion-content>
