import { createAction, props } from '@ngrx/store';
import { TermsOfService } from 'app/models';

export const loadTermsOfService = createAction('[TermsOfService/API] Load TermsOfServices');

export const loadTermsOfServiceSuccess = createAction(
  '[TermsOfService/API] Load TermsOfServices Success',
  props<{ data: TermsOfService }>()
);

export const loadTermsOfServiceFailure = createAction(
  '[TermsOfService/API] Load TermsOfServices Failure',
  props<{ error: any }>()
);

export const setStorageSuccess = createAction('[TermsOfService/Storage] Set Storage Success');

export const addTermsOfService = createAction('[TermsOfService] Add Terms of Service');

export const addTermsOfServiceSuccess = createAction(
  '[TermsOfService/Storage] Add Terms of Service Success',
  props<{ data: TermsOfService }>()
);

export const addTermsOfServiceFailure = createAction(
  '[TermsOfService/Storage] Add Terms of Service Failure'
);

export const acceptTermsOfService = createAction(
  '[TermsOfService/API] Accept TermsOfServices',
  props<{ url: string }>()
);

export const acceptTermsOfServiceSuccess = createAction(
  '[TermsOfService/API] Accept TermsOfServices Success',
  props<{ url: string }>()
);

export const acceptTermsOfServiceFailure = createAction(
  '[TermsOfService/API] Accept TermsOfServices Failure',
  props<{ error: any }>()
);
