<ion-img
  [src]="src"
  (ionImgDidLoad)="ionImgDidLoad()"
></ion-img>
<div
  *ngIf="!loaded"
  class="image-loader"
  [ngStyle]="{ height: height }"
>
  <ion-spinner
    name="crescent"
    color="primary"
  ></ion-spinner>
</div>