import { Component, ContentChild, Directive } from '@angular/core';

@Directive({ selector: '[proTransferText]' })
export class TransferTextDirective {}

@Component({
  selector: 'pro-transfer-loading',
  templateUrl: './transfer-loading.component.html',
  styleUrls: ['./transfer-loading.component.scss'],
})
export class TransferLoadingComponent {
  @ContentChild(TransferTextDirective, { static: true }) text: TransferTextDirective;
}
