import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BluetoothHelperService } from 'store/devices-store/bluetooth-helper.service';
import * as fromBm57 from './bm57.reducer';
import { Bm57Effects } from './bm57.effects';
import { BmService } from '../bm.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromBm57.featureKey, fromBm57.reducer),
    EffectsModule.forFeature([Bm57Effects]),
  ],
  providers: [BmService, BluetoothHelperService],
})
export class Bm57StoreModule {}
