import { Injectable, InjectionToken, Injector } from '@angular/core';
import { HttpClient, HttpBackend, HttpInterceptor } from '@angular/common/http';
import { HttpCustomHandler } from './http-custom-handler';

export const HTTP_CUSTOM_INTERCEPTORS = new InjectionToken<HttpInterceptor[]>(
  'HTTP_CUSTOM_INTERCEPTORS'
);

export const HTTP_REFRESH_TOKEN_INTERCEPTORS = new InjectionToken<HttpInterceptor[]>(
  'HTTP_REFRESH_TOKEN_INTERCEPTORS'
);

@Injectable({
  providedIn: 'root',
})
export class HttpCustomService extends HttpClient {
  constructor(private backend: HttpBackend, private injector: Injector) {
    super(new HttpCustomHandler(backend, injector, HTTP_CUSTOM_INTERCEPTORS));
  }
}

@Injectable({
  providedIn: 'root',
})
export class HttpRefreshTokenService extends HttpClient {
  constructor(private backend: HttpBackend, private injector: Injector) {
    super(new HttpCustomHandler(backend, injector, HTTP_REFRESH_TOKEN_INTERCEPTORS));
  }
}
