import { Injectable } from '@angular/core';
import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { AlertController, Platform, ModalController } from '@ionic/angular';
import { StorageFacade } from 'storage-store-facade/storage.facade';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ConfirmPasswordComponent } from 'app/shared/confirm-password/confirm-password.component';
import { InfoFacade } from 'store/info-store/info.facade';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BiometricService {
  userHasStoredBiometricSecret$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  biometricIsAvailable$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  biometricsType$: BehaviorSubject<'Face ID' | 'Touch ID'> = new BehaviorSubject(null);

  resumeSubscription: Subscription;

  constructor(
    private fingerprint: FingerprintAIO,
    private platform: Platform,
    private alertController: AlertController,
    private modalController: ModalController,
    private storage: StorageFacade,
    private infoFacade: InfoFacade
  ) {}

  /**
   * check if biometric is available
   */
  async biometricsAvailable(): Promise<boolean> {
    await this.platform.ready();

    if (this.platform.is('hybrid')) {
      return this.fingerprint
        .isAvailable()
        .then((isAvailable) => {
          if (isAvailable === 'face') {
            this.biometricsType$.next('Face ID');
          } else {
            this.biometricsType$.next('Touch ID');
          }

          this.biometricIsAvailable$.next(true);
          return true;
        })
        .catch(() => {
          this.storage.remove('useBiometric');
          this.biometricIsAvailable$.next(false);
          this.userHasStoredBiometricSecret$.next(false);
          return false;
        });
    }

    this.biometricIsAvailable$.next(false);
    this.userHasStoredBiometricSecret$.next(false);
    return false;
  }

  /**
   * check if biometric is available and user has enabled biometric
   */
  async checkUserHasStoredBiometricSecret() {
    const biometricsIsAvailable = await this.biometricsAvailable();
    const useBiometric = await this.storage.get('useBiometric');

    this.userHasStoredBiometricSecret$.next(
      biometricsIsAvailable === true && useBiometric === true
    );
  }

  /**
   * ask user to use biometrical login.
   * return if user was asked already or no biometrics are available.
   */
  async confirmStoreBiometrics(email: string, password: string) {
    return new Promise<any>((res) => {
      Promise.all([this.biometricsAvailable(), this.storage.get('useBiometric')]).then(
        async ([biometricsIsAvailable, useBiometric]) => {
          if (biometricsIsAvailable === false || useBiometric !== null) {
            res(false);
          } else {
            const ionAlert = await this.alertController.create({
              header: $localize`Möchtest Du ${this.biometricsType$.value} aktivieren, um Dich noch schneller anzumelden?`,
              cssClass: 'pro-alert',
              backdropDismiss: false,
              buttons: [
                {
                  text: $localize`Nein`,
                  handler: () => {
                    // only set after first confirm
                    if (useBiometric === null) {
                      this.storage.set('useBiometric', false);
                      this.userHasStoredBiometricSecret$.next(false);
                    }
                    res(false);
                  },
                },
                {
                  text: $localize`Ja`,
                  handler: async () => {
                    ionAlert.dismiss();
                    await ionAlert.onDidDismiss();
                    const attemptResult = await this.attemptSecretRegistration(email, password);

                    if (attemptResult === true) {
                      this.showBiometricInfoAlert(
                        $localize`${this.biometricsType$.value} wurde aktiviert.`
                      );
                    }

                    res(attemptResult);
                  },
                },
              ],
            });

            await ionAlert.present();
          }
        }
      );
    });
  }

  /**
   * attempt to register secret.
   * handle error cases
   */
  async attemptSecretRegistration(email: string, password: string) {
    return new Promise<any>((res) => {
      this.registerBiometricSecret(email, password).then(async (registrationResult) => {
        if (registrationResult?.code === -102) {
          // scan failed, retry
          const registrationAttempt = await this.attemptSecretRegistration(email, password);
          res(registrationAttempt);
        } else if (registrationResult?.code === -108) {
          // canceled by user. ask again to use biometrics
          await this.confirmStoreBiometrics(email, password);
          res(false);
        } else if (registrationResult?.code === -111 || registrationResult?.code === -112) {
          // too many attempts or biometrics locked by device
          this.showBiometricInfoAlert(registrationResult?.message);
          res(false);
        } else {
          // on IOs the users permission for biometrics-use is needed.
          // trigger fingerprint-show to get that permission right after registration.
          if (this.platform.is('ios')) {
            await this.fingerprint
              .show({ description: 'Face ID', disableBackup: true })
              .catch(async () => {
                // user denied access for biometrics.
                // check availability again to update correct settings.
                await this.biometricsAvailable();
                res(false);
              });
          }
          res(true);
        }
      });
    });
  }

  /**
   * store credentials behind biometrics
   */
  async registerBiometricSecret(
    email: string,
    password: string
  ): Promise<{
    code: number;
    message: string;
  }> {
    return this.fingerprint
      .registerBiometricSecret({
        secret: JSON.stringify({ email, password }),
        invalidateOnEnrollment: true,
        disableBackup: true,
        title: `${this.biometricsType$.value}`,
        subtitle: '',
        description:
          this.biometricsType$.value === 'Face ID'
            ? $localize`Bitte das Gesicht scannen...`
            : $localize`Bitte den Finger auflegen...`,
        fallbackButtonTitle: '',
        cancelButtonTitle: $localize`Abbrechen`,
      })
      .then(() => {
        this.storage.set('useBiometric', true);
        this.userHasStoredBiometricSecret$.next(true);
        return {
          code: null,
          message: '',
        };
      })
      .catch((err) => {
        return {
          code: err?.code,
          message: err?.message,
        };
      });
  }

  /**
   * load stored secret and return credetials
   */
  async loadBiometricSecret(): Promise<{
    email: string;
    password: string;
  }> {
    return this.fingerprint
      .loadBiometricSecret({
        title: `${this.biometricsType$.value}`,
        subtitle: '',
        description:
          this.biometricsType$.value === 'Face ID'
            ? $localize`Bitte das Gesicht scannen...`
            : $localize`Bitte den Finger auflegen...`,
        fallbackButtonTitle: '',
        cancelButtonTitle: $localize`Abbrechen`,
        disableBackup: true,
      })
      .then((results) => {
        const creds = JSON.parse(results);

        if (creds?.email && creds?.password) {
          return {
            email: creds.email,
            password: creds.password,
          };
        }
      })
      .catch(async (err: { code: number; message: string }) => {
        if (err?.code === -102) {
          // scan failed, retry
          return await this.loadBiometricSecret();
        } else if (err?.code === -106 || err?.code === -113) {
          // "BIOMETRIC_NOT_ENROLLED" -106
          // this may happen after user deactivated or removed biometric from device
          // BIOMETRIC_NO_SECRET_FOUND -113
          // this may happen after user changed biometrics on device. e.g. by adding a fingerprint
          // reset secret and ask user to register secret again
          await this.resetBiometric();
        } else if (err?.code === -111 || err?.code === -112) {
          // too many attempts or biometrics locked by device
          this.showBiometricInfoAlert(err.message);
        }
        return null;
      });
  }

  /**
   * change current biometric status
   */
  async toggleBiometricStatus() {
    if ((await this.biometricsAvailable()) === false) {
      // may happen if user is logged in and deactivates biometrics on device
      this.showBiometricInfoAlert($localize`${this.biometricsType$.value} wurde deaktiviert.`);
      return;
    }

    if ((await this.storage.get('useBiometric')) === true) {
      // deactivation attemp
      if ((await this.confirmBiometricDeactivation()) === true) {
        this.showBiometricInfoAlert($localize`${this.biometricsType$.value} wurde deaktiviert.`);
      }
    } else {
      // activation attemp, check password
      if ((await this.presentPasswordModal()) === true) {
        this.showBiometricInfoAlert($localize`${this.biometricsType$.value} wurde aktiviert.`);
      }
    }
  }

  /**
   * displays password-input modal to user
   */
  async presentPasswordModal() {
    return new Promise<any>((res) => {
      this.userHasStoredBiometricSecret$.next(true);
      // set to null to invoke correct secret-registration
      this.storage.set('useBiometric', null);

      this.modalController
        .create({
          component: ConfirmPasswordComponent,
          cssClass: 'floating-modal',
          backdropDismiss: false,
          componentProps: {
            textContent: $localize`Gib bitte Dein Passwort ein, um ${this.biometricsType$.value} zu aktivieren.`,
          },
        })
        .then(async (modal) => {
          // modal was closed. check if password is present.
          modal.onDidDismiss<{ password: string }>().then((dataFromModal) => {
            if (dataFromModal?.data?.password) {
              this.infoFacade.patientInfo$.pipe(first()).subscribe((patientInfo) => {
                this.attemptSecretRegistration(patientInfo.email, dataFromModal.data.password).then(
                  (registrationResult) => {
                    res(registrationResult);
                  }
                );
              });
            } else {
              this.userHasStoredBiometricSecret$.next(false);
              this.storage.set('useBiometric', false);
              res(false);
            }
          });

          await modal.present();
        });
    });
  }
  /**
   * displays deactivation confirmation-alert
   */
  async confirmBiometricDeactivation() {
    return new Promise<any>((res) => {
      this.userHasStoredBiometricSecret$.next(false);

      this.alertController
        .create({
          header: $localize`Möchtest Du ${this.biometricsType$.value} deaktivieren?`,
          cssClass: 'pro-alert',
          backdropDismiss: false,
          buttons: [
            {
              text: $localize`Ja`,
              handler: async () => {
                await this.alertController.dismiss();
                const deactivationResult = await this.deactivateBiometric();
                res(deactivationResult);
              },
            },
            {
              text: $localize`Nein`,
              handler: () => {
                this.userHasStoredBiometricSecret$.next(true);
                res(false);
              },
            },
          ],
        })
        .then(async (ionAlert) => {
          await ionAlert.present();
        });
    });
  }

  /**
   * deactivate and reset biometric settings.
   */
  async deactivateBiometric(): Promise<boolean> {
    try {
      await this.fingerprint.show({
        title: `${this.biometricsType$.value}`,
        subtitle: '',
        description:
          this.biometricsType$.value === 'Face ID'
            ? $localize`Bitte das Gesicht scannen...`
            : $localize`Bitte den Finger auflegen...`,
        fallbackButtonTitle: '',
        cancelButtonTitle: $localize`Abbrechen`,
        disableBackup: true,
      });

      this.storage.set('useBiometric', false);
      this.userHasStoredBiometricSecret$.next(false);

      return true;
    } catch (err) {
      if (err?.code === -102) {
        // scan failed, retry
        return await this.deactivateBiometric();
      } else {
        this.userHasStoredBiometricSecret$.next(true);
        return false;
      }
    }
  }

  /**
   * displays info-alert after biometric-status change
   */
  async showBiometricInfoAlert(message: string) {
    const ionAlert = await this.alertController.create({
      header: message,
      cssClass: 'pro-alert',
      buttons: [
        {
          text: $localize`Ok`,
          handler: () => {},
        },
      ],
    });

    await ionAlert.present();
  }

  /**
   * reset stored secret
   */
  resetBiometric() {
    return new Promise<any>((res) => {
      this.storage.remove('useBiometric');
      this.userHasStoredBiometricSecret$.next(false);

      this.alertController
        .create({
          header: $localize`${this.biometricsType$.value} wurde deaktiviert.`,
          // tslint:disable-next-line: max-line-length
          message: $localize`Bitte melde Dich mit Deiner E-Mail-Adresse und Deinem Passwort an.<br>Danach kannst Du ${this.biometricsType$.value} wieder aktivieren.`,
          cssClass: 'pro-alert',
          backdropDismiss: false,
          buttons: [
            {
              text: $localize`Ok`,
              handler: () => {
                res(true);
              },
            },
          ],
        })
        .then(async (ionAlert) => {
          await ionAlert.present();
        });
    });
  }
}
