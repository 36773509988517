import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as DeviceActions from './devices.actions';
import * as Bm54Actions from './bm-store/bm54-store/bm54.actions';
import * as Bm57Actions from './bm-store/bm57-store/bm57.actions';
import * as Po60Actions from './po60-store/po60.actions';
import * as Bf720Actions from './bf720-store/bf720.actions';

class DeviceError extends Error {
  constructor(action: Action, error: string) {
    super(action.type);
    this.name = action.type;
    this.message = error;
  }
}

@Injectable()
export class DevicesErrorEffects {
  constructor(private actions$: Actions) {}

  error$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          DeviceActions.startScanFailure,
          DeviceActions.stopScanFailure,
          DeviceActions.dismissAliveCorFailure,
          Bm54Actions.loadDeviceInformationFailure,
          Bm54Actions.loadMeasurementsFailure,
          Bm54Actions.disconnectDeviceFailure,
          Bm57Actions.loadDeviceInformationFailure,
          Bm57Actions.loadMeasurementsFailure,
          Bm57Actions.disconnectDeviceFailure,
          Po60Actions.loadDeviceInformationFailure,
          Po60Actions.loadDataStorageFailure,
          Po60Actions.setTimeFailure,
          Po60Actions.loadMeasurementsFailure,
          Po60Actions.unsubscribeFailure,
          Po60Actions.disconnectDeviceFailure,
          Bf720Actions.loadUserListFailure,
          Bf720Actions.showConsentCodeFailure,
          Bf720Actions.createUserFailure,
          Bf720Actions.loginUserFailure,
          Bf720Actions.writeCurrentTimeFailure,
          Bf720Actions.writeScaleSettingsFailure,
          Bf720Actions.writeGenderFailure,
          Bf720Actions.changeDatabaseIncrementFailure,
          Bf720Actions.loadDeviceInformationFailure,
          Bf720Actions.loadMeasurementsFailure,
          Bf720Actions.disconnectDeviceFailure
        ),
        tap((action) => {
          throw new DeviceError(action, action.error);
        })
      );
    },
    { dispatch: false }
  );
}
