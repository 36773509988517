import { Injectable } from '@angular/core';
import { BmEffects } from '../bm.effects';
import * as Bm57Actions from './bm57.actions';
import * as fromBm57 from './bm57.reducer';

@Injectable()
export class Bm57Effects {
  addState$ = this.bmEffects.createAddState$(Bm57Actions);
  loadDeviceInformation$ = this.bmEffects.createLoadDeviceInformation$(Bm57Actions);
  loadMeasurements$ = this.bmEffects.createLoadMeasurements$(Bm57Actions, fromBm57);
  deleteDevice$ = this.bmEffects.createDeleteDevice$(Bm57Actions, fromBm57);
  disconnectDevice$ = this.bmEffects.createDisconnectDevice$(Bm57Actions, fromBm57);

  constructor(private bmEffects: BmEffects) {}
}
