import { Pipe, PipeTransform } from '@angular/core';

export enum Determinations {
  'NORMAL' = 'NORMAL',
  'AFIB' = 'AFIB',
  'UNCLASSIFIED' = 'UNCLASSIFIED',
  'TACHYCARDIA' = 'TACHYCARDIA',
  'BRADYCARDIA' = 'BRADYCARDIA',
  'SHORT' = 'SHORT',
  'LONG' = 'LONG',
  'UNREADABLE' = 'UNREADABLE',
  'NO_ANALYSIS' = 'NO_ANALYSIS',
}

@Pipe({
  name: 'ecgDetermination',
})
export class EcgDeterminationPipe implements PipeTransform {
  determinations: Record<string, string> = {
    [Determinations.NORMAL]: $localize`Normal`,
    [Determinations.AFIB]: $localize`Mögliches Vorhofflimmern`,
    [Determinations.UNCLASSIFIED]: $localize`Unklassifiziert`,
    [Determinations.TACHYCARDIA]: $localize`Tachykardie`,
    [Determinations.BRADYCARDIA]: $localize`Bradykardie`,
    [Determinations.SHORT]: $localize`Keine Analyse`,
    [Determinations.LONG]: $localize`Keine Analyse`,
    [Determinations.UNREADABLE]: $localize`Nicht lesbar`,
    [Determinations.NO_ANALYSIS]: $localize`Keine Analyse`,
  };

  transform(v: string): string {
    return this.determinations[v] || v;
  }
}
