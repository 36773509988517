import { createAction, props } from '@ngrx/store';
import { Diagnosis } from 'app/models';

export const loadDiagnoses = createAction('[Diagnoses/API] Load Diagnoses');

export const loadDiagnosesSuccess = createAction(
  '[Diagnoses/API] Load DiagnosesSuccess',
  props<{ diagnoses: Diagnosis[] }>()
);

export const loadDiagnosesFailure = createAction(
  '[Diagnoses/API] Load DiagnosesFailure',
  props<{ error: any }>()
);

export const createDiagnosis = createAction(
  '[Diagnosis/API] Create Diagnosis',
  props<{ icd_code: string; nyha_status?: number }>()
);

export const createDiagnosisSuccess = createAction(
  '[Diagnosis/API] Create Diagnosis Success',
  props<{ diagnosis: Diagnosis }>()
);

export const createDiagnosisFailure = createAction(
  '[Diagnosis/API] Create Diagnosis Failure',
  props<{ error: any }>()
);

export const deleteDiagnosis = createAction(
  '[Diagnosis/API] Delete Diagnosis',
  props<{ icd_code: string }>()
);

export const deleteDiagnosisSuccess = createAction(
  '[Diagnosis/API] Delete Diagnosis Success',
  props<{ icd_code: string }>()
);

export const deleteDiagnosisFailure = createAction(
  '[Diagnosis/API] Delete Diagnosis Failure',
  props<{ error: any }>()
);
