import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasOwnPropertyPipe } from './has-own-property.pipe';
import { HasValuePipe } from './has-value.pipe';
import { FormOptionsTranslatePipe } from './form-options-translate.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ValueFormatPipe } from './value-format.pipe';
import { StaticPluralPipe } from './static-plural.pipe';
import { FractionPipe } from './fraction.pipe';
import { DocumentCategoryPipe } from './document-category.pipe';
import { TruncatePipe } from './truncate.pipe';
import { EcgDeterminationPipe } from './ecg-determination.pipe';
import { SubscriptionCategoryPipe } from './subscription-category.pipe';

@NgModule({
  declarations: [
    HasOwnPropertyPipe,
    HasValuePipe,
    SafeHtmlPipe,
    FormOptionsTranslatePipe,
    ValueFormatPipe,
    StaticPluralPipe,
    FractionPipe,
    DocumentCategoryPipe,
    TruncatePipe,
    EcgDeterminationPipe,
    SubscriptionCategoryPipe,
  ],
  imports: [CommonModule],
  exports: [
    HasOwnPropertyPipe,
    HasValuePipe,
    SafeHtmlPipe,
    FormOptionsTranslatePipe,
    ValueFormatPipe,
    StaticPluralPipe,
    FractionPipe,
    DocumentCategoryPipe,
    TruncatePipe,
    EcgDeterminationPipe,
    SubscriptionCategoryPipe,
  ],
  providers: [ValueFormatPipe, SubscriptionCategoryPipe],
})
export class PipeModule {}
