<div
  class="preview"
  [ngClass]="{ thumbnail: thumbnail }"
>
  <ion-spinner
    *ngIf="loading"
    name="circles"
  ></ion-spinner>

  <ng-container *ngIf="contentType">
    <!-- image types -->
    <img
      *ngIf="contentType.includes('image')"
      [src]="src"
      (load)="initialized()"
      [ngStyle]="{'width': (zoom * 100) + '%', 'max-width': (zoom * 100) + '%'}"
    >

    <!-- PDFs -->
    <ng-container *ngIf="contentType.includes('pdf')">
      <div
        class="pdf-thumbnail"
        *ngIf="thumbnail === true"
      >
        <pdf-viewer
          [src]="src"
          [render-text]="false"
          [original-size]="false"
          [show-all]="false"
          [page]="1"
          [show-borders]="true"
          (pages-initialized)="initialized()"
          (error)="onPdfError($event)"
        ></pdf-viewer>
      </div>

      <pdf-viewer
        *ngIf="thumbnail === false"
        [src]="src"
        [original-size]="true"
        [fit-to-page]="true"
        [zoom]="zoom"
        [render-text]="true"
        [render-text-mode]="1"
        [autoresize]="true"
        [show-borders]="true"
        (pages-initialized)="initialized()"
        (error)="onPdfError($event)"
      ></pdf-viewer>
    </ng-container>

    <!-- unsupported mime-types -->
    <ng-container *ngIf="!contentType.includes('image') && !contentType.includes('pdf')">
      <div class="unsupported-type-preview">
        <ion-icon
          src="assets/svgs/document.svg"
          color="secondary"
        ></ion-icon>
      </div>
      <ion-text
        *ngIf="thumbnail === false"
        color="secondary"
      >
        <h3>{{ fileName }}</h3>
      </ion-text>
    </ng-container>
  </ng-container>
</div>