import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as TelemedicineCentersActions from './telemedicine-centers.actions';
import * as fromTelemedicineCenters from './telemedicine-centers.reducer';

@Injectable({
  providedIn: 'root',
})
export class TelemedicineCentersFacade {
  loading$ = this.store.pipe(select(fromTelemedicineCenters.selectLoading));
  telemedicineCenters$ = this.store.pipe(select(fromTelemedicineCenters.selectCenters));

  constructor(private store: Store<fromTelemedicineCenters.State>) {}

  loadTelemedicineCenters() {
    this.store.dispatch(TelemedicineCentersActions.loadTelemedicineCenters());
  }
}
