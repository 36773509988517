import {
  Component,
  Directive,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DocumentRequest } from 'app/models';
import { DatePipe } from '@angular/common';

enum LabelType {
  Category = 'category',
  Date = 'date',
}

@Directive()
abstract class DocumentItem {
  @Input() document: DocumentRequest;
  @Input() lines: 'full' | 'inset' | 'none' | undefined;
  @Input() labelType: LabelType = LabelType.Date;

  constructor() {}
}

@Component({
  selector: 'pro-document-item-content',
  styles: [
    `
      .emphasize {
        font-size: 1rem;
        line-height: 1.24rem;
        text-transform: none;
      }

      .smaller {
        font-size: 0.82rem;
        line-height: 0.97rem;
      }
    `,
  ],
  template: `
    <ion-item [lines]="lines" role="button" [detail]="detail" [attr.aria-label]="ariaLabel">
      <ion-avatar slot="start" class="ion-no-margin ion-margin-end">
        <pro-document-icon [category]="document.category"></pro-document-icon>
      </ion-avatar>

      <ion-label position="stacked" class="no-text-transform">
        <span [class.emphasize]="labelType === labelTypeCategory">{{ document.description }}</span>
      </ion-label>
      <ng-container *ngIf="labelType === 'date'">
        <ion-label position="stacked">
          {{ document.original_date | date : 'mediumDate' }}
        </ion-label>
      </ng-container>
      <ng-container *ngIf="labelType === 'category'">
        <ion-label position="stacked">
          <span class="smaller">
            {{ document.category | formOptionsTranslate : 'document' : 'categories' | async }}
            <span class="separator">| </span>
            {{ document.files.length }}
            <ng-container i18n>
              {document.files.length, plural, =1 {Datei} other {Dateien}}
            </ng-container>
          </span>
        </ion-label>
      </ng-container>
    </ion-item>
  `,
  providers: [DatePipe],
})
export class DocumentItemContentComponent extends DocumentItem implements OnInit {
  @Input() detail: boolean;
  labelTypeCategory = LabelType.Category;
  ariaLabel = '';

  constructor(private datePipe: DatePipe) {
    super();
  }

  ngOnInit() {
    if (this.document) {
      this.ariaLabel = $localize`${this.document.description} vom ${this.datePipe.transform(
        this.document.original_date,
        'mediumDate'
      )}`;
    }
  }
}

@Component({
  selector: 'pro-document-item',
  templateUrl: './document-item.component.html',
  styleUrls: ['./document-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentItemComponent extends DocumentItem {
  @Output() retryUploadEvent: EventEmitter<DocumentRequest> = new EventEmitter();
  @Output() deleteUploadEvent: EventEmitter<number> = new EventEmitter();

  constructor() {
    super();
  }
}
