<ion-header mode="ios">
  <ion-toolbar>
    <ion-back-button
      slot="start"
      mode="ios"
      defaultHref="app/overview/dashboard"
      role="button"
      icon="close"
      aria-label="Schließen"
      i18n-aria-label
    ></ion-back-button>

    <ion-title
      mode="md"
      i18n
    >Seelsorge anrufen</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <h3 i18n>Telefonseelsorge Deutschland</h3>
  <p i18n>Du kannst die Telefonseelsorge Deutschland unter den folgenden Nummern erreichen:</p>

  <h2>
    <a
      href="tel:+498001110111"
      i18n
    >+49 800 111 0 111</a>
  </h2>

  <h2>
    <a
      href="tel:+498001110222"
      i18n
    >+49 800 111 0 222</a>
  </h2>
  <h2>
    <a
      href="tel:+49116123"
      i18n
    >+49 116 123</a>
  </h2>

  <p i18n>
    oder per Mail und Chat unter<br />
    <a
      proLink
      href="https://online.telefonseelsorge.de/"
    >online.telefonseelsorge.de</a>
  </p>

  <h3 i18n>ProCarement CareCenter</h3>
  <p i18n>Oder ruf bei uns im CareCenter an:</p>

  <h2>
    <a
      href="tel:+4991919504311"
      i18n
    >+49 9191 950 431-1</a>
  </h2>
</ion-content>