import { BluetoothLE } from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { Injectable, NgZone } from '@angular/core';
import { of, zip, from } from 'rxjs';
import {
  map,
  first,
  filter,
  skipWhile,
  switchMap,
  take,
  concatMap,
  mergeMap,
} from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { ResultWeight } from 'app/models/api.model';
import { UserListB720 } from 'app/app-store/devices-store/devices.model';

@Injectable()
export class Bf720Service {
  serviceUserData = '181C';
  serviceCustom = 'FFFF';
  serviceWeight = '181D';
  serviceCrntTime = '1805';
  serviceCustomUser = 'FAA0';
  characteristicCustomStart = '0001';
  characteristicTakeMeasurement = '0006';
  characteristicWeightIndicate = '2A9D';
  characteristicCrntTime = '2A2B';
  characteristicCP = '2A9F';

  constructor(private bluetoothLe: BluetoothLE, private zone: NgZone) {}

  /* eslint-disable no-bitwise */
  readUser(bytes: Uint8Array) {
    const index = bytes[1];
    const initials =
      String.fromCharCode(bytes[2]) + String.fromCharCode(bytes[3]) + String.fromCharCode(bytes[4]);
    const yearOfBirth = (bytes[5] & 255) + ((bytes[6] & 255) << 8);
    const monthOfBirth = bytes[7];
    const dayOfBirth = bytes[8];
    const height = bytes[9];
    const gender = bytes[10];
    const activityLevel = bytes[11];
    const birthdate = new Date(yearOfBirth, monthOfBirth - 1, dayOfBirth);

    return {
      index,
      initials,
      birthdate: dayjs(birthdate).format('YYYY-MM-DD'),
      height,
      gender,
      activityLevel,
    };
  }

  readMeasurement(bytes: Uint8Array): ResultWeight {
    const weight = Number(((((bytes[1] & 255) + ((bytes[2] & 255) << 8)) / 2) * 0.01).toFixed(1));
    const year = (bytes[3] & 255) + ((bytes[4] & 255) << 8);
    const month = bytes[5];
    const day = bytes[6];
    const hour = bytes[7];
    const minute = bytes[8];
    const second = bytes[9];
    const date = new Date(year, month - 1, day, hour, minute, second);
    return {
      type: 'weight',
      value: weight,
      device_time: dayjs(date).format('YYYY-MM-DDTHH:mm:ss'),
    };
  }

  readInitials(bytes: Uint8Array) {
    const initials =
      String.fromCharCode(bytes[0]) + String.fromCharCode(bytes[1]) + String.fromCharCode(bytes[2]);
    return initials;
  }

  readReferenceWeight(bytes: Uint8Array) {
    const weight = Number(((((bytes[0] & 255) + ((bytes[1] & 255) << 8)) / 2) * 0.01).toFixed(1));
    return weight;
  }

  readScaleSettings(bytes: Uint8Array) {
    const unit = bytes[1] === 0 ? 'kg' : bytes[1] === 1 ? 'lb' : 'st';
    const weightThresold = ((bytes[4] & 255) + ((bytes[5] & 255) << 8)) * 0.1;
    return { unit, weightThresold };
  }

  readUserCreateResponse(bytes: Uint8Array) {
    const value =
      bytes[2] === 1
        ? 'Success'
        : bytes[2] === 2
        ? 'OP Code not supported'
        : bytes[2] === 3
        ? 'Invalid Parameter'
        : 'Operation failed';
    const userIndex = bytes[3] !== undefined ? bytes[3] : null;
    return userIndex ? { value, userIndex } : { value };
  }

  readUserLoginResponse(bytes: Uint8Array) {
    const value =
      bytes[2] === 1
        ? 'Success'
        : bytes[2] === 2
        ? 'OP Code not supported'
        : bytes[2] === 3
        ? 'Invalid Parameter'
        : bytes[2] === 4
        ? 'Operation failed'
        : 'User not Authorized';
    return { value };
  }

  subscribe(address: string, service: string, characteristic: string, value: string) {
    return this.bluetoothLe.subscribe({ address, service, characteristic }).pipe(
      switchMap((r) =>
        r.status === 'subscribed'
          ? this.bluetoothLe.write({
              address,
              service,
              characteristic,
              value,
            })
          : of(r)
      )
    );
  }

  getUserList(address: string) {
    const buffer = new Uint8Array([0]);
    const base64 = this.bluetoothLe.bytesToEncodedString(buffer);
    const userList: UserListB720[] = [];
    return this.subscribe(address, this.serviceCustom, this.characteristicCustomStart, base64).pipe(
      filter((r) => r.status === 'subscribedResult'),
      concatMap((r) => {
        const bytes = this.bluetoothLe.encodedStringToBytes(r.value);
        if (bytes.byteLength > 1) {
          const user = this.readUser(bytes);
          userList.push(user);
        }
        return of(bytes);
      }),
      skipWhile((r) => r.length > 1),
      map(() => userList),
      take(1)
    );
  }

  showConsentCode(address: string, code: number) {
    const buffer = new Uint8Array([16 + code]);
    const base64 = this.bluetoothLe.bytesToEncodedString(buffer);
    return from(
      this.bluetoothLe.write({
        address,
        service: this.serviceCustom,
        characteristic: this.characteristicCustomStart,
        value: base64,
      })
    ).pipe(first());
  }

  createUser(address: string, code: number) {
    const buffer = new Uint8Array([1, 0, 0]);
    buffer[1] = code & 255;
    buffer[2] = (code >> 8) & 255;
    const base64 = this.bluetoothLe.bytesToEncodedString(buffer);
    return this.subscribe(address, this.serviceUserData, this.characteristicCP, base64).pipe(
      filter((r) => r.status === 'subscribedResult'),
      concatMap((r) => {
        const bytes = this.bluetoothLe.encodedStringToBytes(r.value);
        const response = this.readUserCreateResponse(bytes);
        return of(response);
      }),
      first(),
      concatMap((r) =>
        zip(
          of(r),
          this.bluetoothLe.unsubscribe({
            address,
            service: this.serviceUserData,
            characteristic: this.characteristicCP,
          })
        )
      ),
      map((r) => r[0])
    );
  }

  deleteUser(address: string) {
    const buffer = new Uint8Array([3]);
    const base64 = this.bluetoothLe.bytesToEncodedString(buffer);
    return this.subscribe(address, this.serviceUserData, this.characteristicCP, base64).pipe(
      concatMap((r) => {
        if (r.status === 'subscribedResult') {
          const bytes = this.bluetoothLe.encodedStringToBytes(r.value);
          const response = this.readUserLoginResponse(bytes);
          return of(response);
        } else {
          return of(r);
        }
      })
    );
  }

  userLogin(address: string, userIndex: number, code: number) {
    const buffer = new Uint8Array([2, userIndex, 0, 0]);
    buffer[2] = code & 255;
    buffer[3] = (code >> 8) & 255;
    const base64 = this.bluetoothLe.bytesToEncodedString(buffer);
    return this.subscribe(address, this.serviceUserData, this.characteristicCP, base64).pipe(
      filter((r) => r.status === 'subscribedResult'),
      map((r) => {
        const bytes = this.bluetoothLe.encodedStringToBytes(r.value);
        const response = this.readUserLoginResponse(bytes);
        return response;
      }),
      concatMap((r) =>
        zip(
          of(r),
          this.bluetoothLe.unsubscribe({
            address,
            service: this.serviceUserData,
            characteristic: this.characteristicCP,
          })
        )
      ),
      map((r) => r[0])
    );
  }

  getGender(address: string) {
    return from(
      this.bluetoothLe.read({
        address,
        service: this.serviceUserData,
        characteristic: '2A8C',
      })
    ).pipe(
      map((r) => {
        return r;
      })
    );
  }

  writeGender(address: string, gender: 0 | 1 | 2) {
    const buffer = new Uint8Array([gender]);
    const base64 = this.bluetoothLe.bytesToEncodedString(buffer);
    return from(
      this.bluetoothLe.write({
        address,
        service: this.serviceUserData,
        characteristic: '2A8C',
        value: base64,
      })
    ).pipe(first());
  }

  changeDatabaseIncrement(address: string) {
    const buffer = new Uint8Array([1]);
    const base64 = this.bluetoothLe.bytesToEncodedString(buffer);
    return from(
      this.bluetoothLe.write({
        address,
        service: this.serviceUserData,
        characteristic: '2A99',
        value: base64,
      })
    ).pipe(first());
  }

  getReferenceWeight(address: string) {
    return from(
      this.bluetoothLe.read({
        address,
        service: this.serviceCustom,
        characteristic: '000B',
      })
    ).pipe(
      first(),
      map((r) => {
        const bytes = this.bluetoothLe.encodedStringToBytes(r.value);
        return this.readReferenceWeight(bytes);
      })
    );
  }

  getScaleSettings(address: string) {
    return from(
      this.bluetoothLe.read({
        address,
        service: this.serviceCustom,
        characteristic: '0000',
      })
    ).pipe(
      first(),
      map((r) => {
        const bytes = this.bluetoothLe.encodedStringToBytes(r.value);
        return this.readScaleSettings(bytes);
      })
    );
  }

  writeScaleSettings(address: string, thresholdWeight: number) {
    const thresoldWeightByte1 = (thresholdWeight / 0.1) & 255;
    const thresoldWeightByte2 = ((thresholdWeight / 0.1) >> 8) & 255;
    const buffer = new Uint8Array([
      255,
      0,
      255,
      255,
      thresoldWeightByte1,
      thresoldWeightByte2,
      255,
      255,
    ]);
    const base64 = this.bluetoothLe.bytesToEncodedString(buffer);
    return from(
      this.bluetoothLe.write({
        address,
        service: this.serviceCustom,
        characteristic: '0000',
        value: base64,
      })
    ).pipe(first());
  }

  getInitials(address: string) {
    return from(
      this.bluetoothLe.read({
        address,
        service: this.serviceCustom,
        characteristic: '0002',
      })
    ).pipe(
      first(),
      map((r) => {
        const bytes = this.bluetoothLe.encodedStringToBytes(r.value);
        return this.readInitials(bytes);
      })
    );
  }

  writeInitials(address: string, initials: string) {
    const strArr = initials.split('');
    const buffer = new Uint8Array([
      strArr[0].charCodeAt(0),
      strArr[1].charCodeAt(0),
      strArr[2].charCodeAt(0),
    ]);
    const base64 = this.bluetoothLe.bytesToEncodedString(buffer);
    return from(
      this.bluetoothLe.write({
        address,
        service: this.serviceCustom,
        characteristic: '0002',
        value: base64,
      })
    ).pipe(first());
  }

  writeCrntTime(address: string) {
    const crntTime = new Date(Date.now());
    const buffer = new Uint8Array(10);
    buffer[0] = crntTime.getFullYear() & 255;
    buffer[1] = (crntTime.getFullYear() >> 8) & 255;
    buffer[2] = crntTime.getMonth() + 1;
    buffer[3] = crntTime.getDate();
    buffer[4] = crntTime.getHours();
    buffer[5] = crntTime.getMinutes();
    buffer[6] = crntTime.getSeconds();
    const base64 = this.bluetoothLe.bytesToEncodedString(buffer);
    return from(
      this.bluetoothLe.write({
        address,
        service: this.serviceCrntTime,
        characteristic: '2A2B',
        value: base64,
      })
    ).pipe(first());
  }

  getMeasurementData(address: string) {
    const buffer = new Uint8Array([0]);
    const base64 = this.bluetoothLe.bytesToEncodedString(buffer);
    return this.bluetoothLe
      .subscribe({
        address,
        service: this.serviceWeight,
        characteristic: this.characteristicWeightIndicate,
      })
      .pipe(
        mergeMap((r) =>
          r.status === 'subscribed'
            ? this.bluetoothLe.write({
                address,
                service: this.serviceCustom,
                characteristic: this.characteristicTakeMeasurement,
                value: base64,
              })
            : of(r)
        ),
        map((result) => {
          if (result.status === 'subscribedResult') {
            const bytes = this.bluetoothLe.encodedStringToBytes(result.value);
            return { status: result.status, data: [this.readMeasurement(bytes)] };
          } else {
            return { status: result.status, data: [] };
          }
        })
      );
  }
}
