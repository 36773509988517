import {
  Component,
  Directive,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  HostListener,
  Injector,
} from '@angular/core';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, isObservable, Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ConfirmAlertConfig<T = any> {
  title?: string;
  message?: string;
  cancelLabel?: string;
  confirmLabel?: string;
  theme?: string;
  misc?: T; // free to use in parent-component
}

export type ConfirmAlertType = 'default' | 'delete' | 'leaveUnsaved' | 'saveSuccess';

export const defaultConfig: {
  [type in ConfirmAlertType]: ConfirmAlertConfig;
} = {
  default: {
    title: '',
    message: null,
    cancelLabel: $localize`Abbrechen`,
    confirmLabel: $localize`Ja`,
    theme: '',
  },
  delete: {
    title: $localize`Wirklich löschen?`,
    message: $localize`Dieser Vorgang kann nicht rückgängig gemacht werden.`,
    cancelLabel: $localize`Zurück`,
    confirmLabel: $localize`Ja, löschen`,
    theme: 'warning',
  },
  leaveUnsaved: {
    title: $localize`Möchtest Du zurück und Deine Änderungen verwerfen?`,
    message: $localize`Du verlässt damit den Bearbeitungsmodus. Deine bisherigen Änderungen werden nicht gespeichert.`,
    cancelLabel: $localize`Nein`,
    confirmLabel: $localize`Ja, zurück`,
    theme: 'warning',
  },
  saveSuccess: {
    title: $localize`Deine Änderungen wurden gespeichert!`,
    message: '<ion-icon src="assets/svgs/change-success.svg" class="alert-icon"></ion-icon>',
    cancelLabel: $localize`Nein`,
    confirmLabel: $localize`Ok`,
    theme: 'success',
  },
};

@Directive()
// tslint:disable-next-line
abstract class ConfirmationAlert {
  alertController: AlertController;

  constructor(injector: Injector) {
    this.alertController = injector.get(AlertController);
  }

  @Input() visible: BehaviorSubject<boolean>;

  @Input() type: ConfirmAlertType = 'default';

  @Input() conf: ConfirmAlertConfig = {};

  @Input() confirmOnly = false; // if true, only one button is shown

  @Input() backdropDismiss = true;

  @Output() confirmAction: EventEmitter<void> = new EventEmitter();

  @Output() cancelAction: EventEmitter<void> = new EventEmitter();

  config: ConfirmAlertConfig;

  async presentPopover() {
    const buttons = [
      {
        text: this.config.cancelLabel,
        cssClass: 'cy-cancel-btn',
        handler: () => {
          this.cancelAction.emit();
        },
      },
      {
        text: this.config.confirmLabel,
        cssClass: 'cy-confirm-btn',
        handler: () => {
          this.confirmAction.emit();
        },
      },
    ];

    if (this.confirmOnly === true) {
      buttons.shift();
    }

    const ionAlert = await this.alertController.create({
      header: this.config.title,
      message: this.config.message,
      cssClass: `pro-alert ${this.config.theme}`,
      backdropDismiss: this.backdropDismiss,
      buttons,
    });

    await ionAlert.present();
  }
}

@Component({
  selector: 'pro-confirmation-alert',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationAlertComponent extends ConfirmationAlert implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    if (isObservable(this.visible)) {
      this.visible.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((v) => {
        if (v === true) {
          // make sure changes are applied before presenting alert
          setTimeout(() => {
            this.config = defaultConfig[this.type]
              ? defaultConfig[this.type]
              : defaultConfig.default;

            if (this.conf) {
              this.config = {
                ...this.config,
                ...this.conf,
              };
            }
            this.presentPopover();
          }, 10);
        } else {
          /**
           * controller tries to close top overlay on dismiss and throws an error, if none exists
           * check if overlay exists, before closing
           */
          this.alertController.getTop().then((hasOverlay) => {
            if (hasOverlay) {
              this.alertController.dismiss();
            }
          });
        }
      });
    } else {
      console.error('Must use a BehaviorSubject<boolean> as @Input()');
      return throwError('Must use a BehaviorSubject<boolean> as @Input()');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}

@Directive({
  selector: '[proConfirmationAlert]',
})
export class ConfirmationAlertDirective extends ConfirmationAlert {
  @Input('proConfirmationAlert') set alertType(value: ConfirmAlertType) {
    this.type = value;
    this.config = defaultConfig[value];
  }

  constructor(injector: Injector) {
    super(injector);
  }

  @HostListener('click') onClick() {
    this.presentPopover();
  }
}
