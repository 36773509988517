import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import * as ActivationsActions from './activations.actions';
import { Activation, ActivationStatus } from 'app/models';
import { HttpErrorResponse } from '@angular/common/http';

export const activationsFeatureKey = 'activations';

const selectIdKey = (a: Activation) => {
  return a.code;
};

const sortBy = (a: Activation, b: Activation): number => {
  return a.code.localeCompare(b.code);
};

export interface State extends EntityState<Activation> {
  loading: boolean;
  currentStatus: ActivationStatus;
  error: HttpErrorResponse | null;
}

export const adapter: EntityAdapter<Activation> = createEntityAdapter<Activation>({
  selectId: selectIdKey,
  sortComparer: sortBy,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  currentStatus: null,
  error: null,
});

export const activationsReducer = createReducer(
  initialState,
  on(ActivationsActions.loadActivations, ActivationsActions.addActivation, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ActivationsActions.loadActivationsSuccess, (state, action) =>
    adapter.upsertMany(action.activations, { ...state, currentStatus: action.currentStatus })
  ),
  on(ActivationsActions.addActivationSuccess, (state, action) =>
    adapter.addOne(action.activation, state)
  ),
  on(
    ActivationsActions.loadActivationsSuccess,
    ActivationsActions.addActivationSuccess,
    (state) => ({
      ...state,
      loading: false,
    })
  ),
  on(
    ActivationsActions.loadActivationsFailure,
    ActivationsActions.addActivationFailure,
    (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return activationsReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('activations');

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectLoading = createSelector(selectFeature, (state: State) => state.loading);

export const selectHasValidActivation = createSelector(selectFeature, (state: State) =>
  state?.currentStatus ? ['active', 'in_grace'].includes(state.currentStatus) : null
);

export const selectCurrentCategory = createSelector(
  selectAll,
  (subscriptions) =>
    subscriptions.filter((subscription) => subscription.status === 'active')[0]?.category ?? null
);

export const selectError = createSelector(selectFeature, (state) => state.error);

export const selectCurrentActivation = createSelector(selectAll, (subscriptions) =>
  subscriptions.find((subscription) => subscription.status === 'active')
);
