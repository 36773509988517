<div class="scroll">
  <div class="content">
    <div class="ion-margin-bottom ion-text-center">
      <ion-icon
        src="assets/svgs/warning-defi.svg"
        color="secondary"
        class="head-icon"
      ></ion-icon>
    </div>
    <h5
      class="warning"
      i18n
    >Achtung: Nutzung der Waage mit Defibrillator</h5>
    <p i18n>
      Wenn Du einen Defibrillator oder Herzschrittmacher (auch Kombigeräte) trägst, nutze die Waage
      bitte nicht mit nackten Füßen!
    </p>
    <p i18n>
      Da die Waage Parameter wie Körperfett oder Muskelanteil durch elektrische Impulse misst,
      solltest Du leichte Hausschuhe oder dicke Socken tragen.
    </p>
  </div>
</div>
<footer class="padding-0">
  <ion-button
    class="left-top-border-radius"
    color="warning"
    (click)="popoverController.dismiss({ confirm: true })"
    i18n
  >Verstanden</ion-button>
</footer>