import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AuthActions from './auth.actions';
import { environment } from 'environments/environment';

@Injectable()
export class AuthErrorEffects {
  constructor(private actions$: Actions, private toastController: ToastController) {}

  error$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.authenticateTokenFailure),
        tap((action) => {
          this.presentToast(
            $localize`Hoppla! Es ist ein Fehler aufgetreten. (V${environment.version})`,
            $localize`Eine Anmeldung ist derzeit nicht möglich. Der Support kümmert sich bereits um das Problem. Bitte probiere es zu einem späteren Zeitpunkt noch einmal.`
          );
          return throwError(action.error);
        })
      );
    },
    { dispatch: false }
  );

  private async presentToast(header: string, message: string) {
    const toast = await this.toastController.create({
      header,
      message,
      position: 'top',
      color: 'warning',
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }
}
