import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { GraceSubscriptionInfoComponent } from './grace-subscription-info/grace-subscription-info.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [GraceSubscriptionInfoComponent],
  exports: [GraceSubscriptionInfoComponent],
})
export class AppStoreSharedModule {}
