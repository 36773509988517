import * as BmActions from '../bm.actions';

export const {
  addState,
  addStateSuccess,
  startScanning,
  startScanningSuccess,
  loadDeviceInformation,
  loadDeviceInformationFailure,
  loadMeasurements,
  loadMeasurementsSubscribed,
  loadMeasurementsSubscribedResult,
  loadMeasurementsSuccess,
  loadMeasurementsFailure,
  deleteDevice,
  deleteDeviceSuccess,
  disconnectDevice,
  disconnectDeviceSuccess,
  disconnectDeviceFailure,
  updateLastResults,
} = BmActions.getActions(BmActions.BmName.BM54);
