import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'pro-defi-info',
  templateUrl: './defi-info.component.html',
  styleUrls: ['./defi-info.component.scss'],
})
export class DefiInfoComponent {
  constructor(public popoverController: PopoverController) {}
}
