import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BluetoothHelperService } from 'store/devices-store/bluetooth-helper.service';
import * as fromBm54 from './bm54.reducer';
import { Bm54Effects } from './bm54.effects';
import { BmService } from '../bm.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromBm54.bm54sFeatureKey, fromBm54.reducer),
    EffectsModule.forFeature([Bm54Effects]),
  ],
  providers: [BmService, BluetoothHelperService],
})
export class Bm54StoreModule {}
