import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromMedicalContacts from './medical-contacts.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MedicalContactsEffects } from './medical-contacts.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromMedicalContacts.medicalContactsFeatureKey,
      fromMedicalContacts.reducer
    ),
    EffectsModule.forFeature([MedicalContactsEffects]),
  ],
})
export class MedicalContactsStoreModule {}
