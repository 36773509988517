<div class="scroll">
  <div class="content">
    <h5 class="margin-t-0" i18n>Fehlende Berechtigung für Geräte in der Nähe</h5>
    <p i18n>Du hast die Berechtigung für Geräte in der Nähe abgelehnt.</p>
    <p i18n>
      Diese Berechtigung ist nötig, um Deine Messwerte via Bluetooth zu übertragen. Bitte erlaube
      der App ProHerz den Zugriff auf Geräte in der Nähe in den Systemeinstellungen Deines
      Mobilgerätes.
    </p>
  </div>
</div>
<footer>
  <ion-button class="left-top-border-radius" (click)="popoverController.dismiss()" i18n
    >OK</ion-button
  >
</footer>
