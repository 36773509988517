import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PipeModule } from 'app/shared/pipe/pipe.module';
import * as fromNotifications from './notifications.reducer';
import { NotificationsEffects } from './notifications.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromNotifications.notificationsFeatureKey, fromNotifications.reducer),
    EffectsModule.forFeature([NotificationsEffects]),
    PipeModule,
  ],
})
export class NotificationsStoreModule {}
