<ng-container *ngIf="type === 'multiple'; else elseBlock" [ngSwitch]="surveyId">
  <!-- kccq12 / eq5d / who -->
  <div
    *ngIf="surveyId === 'kccq12' || surveyId === 'eq5d' || surveyId === 'who5'"
    class="result-icon-row"
  >
    <ion-icon
      *ngIf="surveyId === 'kccq12'"
      [class.large]="score < 0"
      [src]="
        score < 0
          ? 'assets/svgs/questionnaire-undefined-filled.svg'
          : 'assets/svgs/questionnaire-undefined-outline.svg'
      "
    ></ion-icon>
    <ion-icon
      [class.large]="score === 0"
      [src]="
        score === 0
          ? 'assets/svgs/questionnaire-very-sad-filled.svg'
          : 'assets/svgs/questionnaire-very-sad-outline.svg'
      "
    ></ion-icon>
    <ion-icon
      [class.large]="score === 1"
      [src]="
        score === 1
          ? 'assets/svgs/questionnaire-sad-filled.svg'
          : 'assets/svgs/questionnaire-sad-outline.svg'
      "
    ></ion-icon>
    <ion-icon
      [class.large]="score === 2"
      [src]="
        score === 2
          ? 'assets/svgs/questionnaire-neutral-filled.svg'
          : 'assets/svgs/questionnaire-neutral-outline.svg'
      "
    ></ion-icon>
    <ion-icon
      [class.large]="score === 3"
      [src]="
        score === 3
          ? 'assets/svgs/questionnaire-happy-filled.svg'
          : 'assets/svgs/questionnaire-happy-outline.svg'
      "
    ></ion-icon>
    <ion-icon
      [class.large]="score === 4"
      [src]="
        score === 4
          ? 'assets/svgs/questionnaire-very-happy-filled.svg'
          : 'assets/svgs/questionnaire-very-happy-outline.svg'
      "
    ></ion-icon>
  </div>

  <!-- ess -->
  <div *ngIf="surveyId === 'ess'" class="result-icon-row">
    <ion-icon
      [class.large]="score === 0"
      [src]="
        score === 0
          ? 'assets/svgs/questionnaire-ess-1-filled.svg'
          : 'assets/svgs/questionnaire-ess-1-outline.svg'
      "
    ></ion-icon>
    <ion-icon
      [class.large]="score === 1"
      [src]="
        score === 1
          ? 'assets/svgs/questionnaire-ess-2-filled.svg'
          : 'assets/svgs/questionnaire-ess-2-outline.svg'
      "
    ></ion-icon>
    <ion-icon
      [class.large]="score === 2"
      [src]="
        score === 2
          ? 'assets/svgs/questionnaire-ess-3-filled.svg'
          : 'assets/svgs/questionnaire-ess-3-outline.svg'
      "
    ></ion-icon>
    <ion-icon
      [class.large]="score === 3"
      [src]="
        score === 3
          ? 'assets/svgs/questionnaire-ess-4-filled.svg'
          : 'assets/svgs/questionnaire-ess-4-outline.svg'
      "
    ></ion-icon>
  </div>
</ng-container>
<ng-template #elseBlock>
  <ng-container [ngSwitch]="score">
    <ion-icon
      *ngSwitchCase="-1"
      class="large"
      src="assets/svgs/questionnaire-undefined-filled.svg"
    ></ion-icon>
    <ion-icon
      *ngSwitchCase="0"
      class="large"
      src="assets/svgs/questionnaire-very-sad-filled.svg"
    ></ion-icon>
    <ion-icon
      *ngSwitchCase="1"
      class="large"
      src="assets/svgs/questionnaire-sad-filled.svg"
    ></ion-icon>
    <ion-icon
      *ngSwitchCase="2"
      class="large"
      src="assets/svgs/questionnaire-neutral-filled.svg"
    ></ion-icon>
    <ion-icon
      *ngSwitchCase="3"
      class="large"
      src="assets/svgs/questionnaire-happy-filled.svg"
    ></ion-icon>
    <ion-icon
      *ngSwitchCase="4"
      class="large"
      src="assets/svgs/questionnaire-very-happy-filled.svg"
    ></ion-icon>
  </ng-container>
</ng-template>
