import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromMessages from './messages.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MessagesEffects } from './messages.effects';
import { MessagesStoreSharedModule } from './messages-store-shared/messages-store-shared.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromMessages.messagesFeatureKey, fromMessages.reducer),
    EffectsModule.forFeature([MessagesEffects]),
    MessagesStoreSharedModule,
  ],
})
export class MessagesStoreModule {}
