export function getFileReader(): any | FileReader {
  const fileReader = new FileReader();
  const zoneOriginalInstance = (fileReader as any).__zone_symbol__originalInstance;
  return zoneOriginalInstance || fileReader;
}

/**
 * requests a file reader and return base64-string result
 *
 * @param file File to load
 */
export function loadFile(file: File | Blob): Promise<string> {
  const reader = getFileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (err: any) => reject(err);
    reader.readAsDataURL(file);
  });
}
