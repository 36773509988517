import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap, mergeMap } from 'rxjs/operators';
import { ApiService } from 'store/api/api.service';
import * as MedicalContactsActions from './medical-contacts.actions';
import { MedicalContact } from 'app/models';

@Injectable()
export class MedicalContactsEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadMedicalContacts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicalContactsActions.loadMedicalContacts),
      switchMap(() =>
        this.apiService.getMedicalContacts().pipe(
          map((data) =>
            MedicalContactsActions.loadMedicalContactsSuccess({
              medicalContacts: data.results.map((result) => this.adjustAddress(result)),
            })
          ),
          catchError((error) => of(MedicalContactsActions.loadMedicalContactsFailure({ error })))
        )
      )
    );
  });

  updateMedicalContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicalContactsActions.updateMedicalContact),
      mergeMap((action) =>
        this.apiService.updateMedicalContact(action.medicalContact.id, action.medicalContact).pipe(
          map((medicalContact: MedicalContact) =>
            MedicalContactsActions.updateMedicalContactSuccess({
              medicalContact: this.adjustAddress(medicalContact),
            })
          ),
          catchError((error) => of(MedicalContactsActions.updateMedicalContactFailure({ error })))
        )
      )
    );
  });

  createMedicalContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicalContactsActions.createMedicalContact),
      switchMap((action) =>
        this.apiService.createMedicalContact(action.medicalContact).pipe(
          map((medicalContact: MedicalContact) =>
            MedicalContactsActions.createMedicalContactSuccess({
              medicalContact: this.adjustAddress(medicalContact),
            })
          ),
          catchError((error) => of(MedicalContactsActions.createMedicalContactFailure({ error })))
        )
      )
    );
  });

  deleteMedicalContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicalContactsActions.deleteMedicalContact),
      switchMap((action) =>
        this.apiService.deleteMedicalContact(action.medicalContactId).pipe(
          map(() =>
            MedicalContactsActions.deleteMedicalContactSuccess({
              medicalContactId: action.medicalContactId,
            })
          ),
          catchError((error) => of(MedicalContactsActions.deleteMedicalContactFailure({ error })))
        )
      )
    );
  });

  // if contact has 'null' as address, expand it with empty strings
  adjustAddress = (medicalContact: MedicalContact): MedicalContact => {
    return medicalContact.address === null
      ? {
          ...medicalContact,
          address: {
            street: '',
            street_2: '',
            zip_code: '',
            city: '',
          },
        }
      : medicalContact;
  };
}
