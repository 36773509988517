import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { ApiService } from 'store/api/api.service';
import * as UploadRequestActions from 'store/documents-store/upload-request.actions';
import * as DocumentActions from 'store/documents-store/document.actions';
import * as DocumentCategoriesActions from 'store/document-categories-store/document-categories.actions';

@Injectable()
export class DocumentCategoriesEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadDocumentCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DocumentCategoriesActions.loadDocumentsCategories,
        UploadRequestActions.addUploadRequestSuccess,
        DocumentActions.deleteDocumentFromDatabaseSuccess
      ),
      switchMap(() =>
        this.apiService.getDocumentCategories().pipe(
          map((data) =>
            DocumentCategoriesActions.loadDocumentsCategoriesSuccess({
              documentsCategories: data.results,
            })
          ),
          catchError((error) =>
            of(DocumentCategoriesActions.loadDocumentsCategoriesFailure({ error }))
          )
        )
      )
    );
  });
}
