import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'pro-toggle-checkbox',
  templateUrl: './toggle-checkbox.component.html',
  styleUrls: ['./toggle-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleCheckboxComponent {
  @Input() value: boolean | null = null;
  @Input() disabled = true;
}
