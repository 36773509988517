import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ObservationResultStateType } from 'app/models';

@Component({
  selector: 'pro-traffic-light',
  templateUrl: './traffic-light.component.html',
  styleUrls: ['./traffic-light.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrafficLightComponent {
  @Input() small = false;

  @Input() state: ObservationResultStateType;
}
