<div
  class="traffic-light"
  [ngClass]="{'small': small}"
>
  <div
    class="signal"
    [ngClass]="{'active': state === 'critical'}"
  ></div>

  <div
    class="signal"
    [ngClass]="{'active': state === 'fair'}"
  ></div>

  <div
    class="signal active"
    [ngClass]="{'active': state === 'good'}"
  ></div>
</div>