import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { DocumentsFacade } from 'store/documents-store/documents.facade';
import { MeasurementsFacade } from 'store/measurements-store/measurements.facade';
import { SurveyFacade } from 'store/survey-store/survey.facade';
import { RouterFacade } from 'store/router-store/router.facade';
import { ObservationResultSurvey } from 'app/models/api.model';
import { isOfType } from 'app/utils';
import { SurveyComponent } from 'pages/questionnaire/survey/survey.component';
import * as dayjs from 'dayjs';

@Component({
  selector: 'pro-questionnaire-survey',
  templateUrl: './questionnaire-survey.component.html',
  styleUrls: ['./questionnaire-survey.component.scss'],
})
export class QuestionnaireSurveyComponent implements OnInit {
  @ViewChild(SurveyComponent, { static: true }) survey: SurveyComponent;
  measurementsLoading$ = this.measurementsFacade.loading$;
  measurementsError$ = this.measurementsFacade.measurementsError$;
  loadingDocuments$ = this.documentsFacade.loadingDocuments$;
  config$ = this.surveyFacade.surveyConfig$;
  surveyId: string;
  showResultContent = false;
  started = false;
  result: ObservationResultSurvey | null;
  surveyTitle: string;
  pageCount: number;
  currentPageNo = 1;
  answers: any;

  surveyLegalInfo: Record<string, any> = {
    eq5d: {
      logos: [
        {
          src: 'assets/images/eq-5d-5l-logo.png',
          srcset:
            'assets/images/eq-5d-5l-logo.png 1x, assets/images/eq-5d-5l-logo@2x.png 2x, assets/images/eq-5d-5l-logo@3x.png 3x',
        },
      ],
      copyright: $localize`© EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol Research Foundation`,
      startOnly: true, // display copyright only on start page
    },
    kccq12: {
      copyright: $localize`Copyright ©2012 John Spertus, MD, MPH KCCQ-12 - German (Germany)`,
      startOnly: true, // display copyright only on start page
    },
    ess: {
      copyright: $localize`ESS © MW Johns 1990-1997. Unter Lizenz verwendet`,
      startOnly: false, // display copyright only on start page
    },
  };

  constructor(
    private documentsFacade: DocumentsFacade,
    private measurementsFacade: MeasurementsFacade,
    private surveyFacade: SurveyFacade,
    private routerFacade: RouterFacade,
    private navController: NavController,
    private alertController: AlertController,
    private router: Router
  ) {}

  ngOnInit() {
    this.config$.pipe(first()).subscribe((c) => {
      if (!c) {
        this.router.navigate(['']);
      }
    });
    this.routerFacade
      .getRouteParam('surveyId')
      .pipe(first())
      .subscribe((id) => {
        this.surveyId = id;
      });
  }

  ionViewWillEnter() {
    this.started = !this.survey.surveyModel.firstPageIsStarted;
    this.surveyTitle = this.survey.surveyModel.description;
    this.pageCount = this.survey.surveyModel.visiblePageCount;
  }

  getSurveyResults(complete: any) {
    this.showResultContent = true;
    this.answers = complete.data;
    this.addMeasurements();
  }

  addMeasurements() {
    this.measurementsFacade
      .addMeasurements({
        results: [
          {
            type: 'survey',
            answers: this.answers,
            survey_id: this.surveyId,
            device_time: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
          },
        ],
      })
      .then((r) => {
        this.result = isOfType<ObservationResultSurvey>(r[0], 'feedbacks') ? r[0] : null;
      });
  }

  setStarted() {
    this.started = true;
  }

  showResult(result: ObservationResultSurvey) {
    this.router.navigate([`/app/questionnaire/${this.surveyId}/result`], {
      state: { result, surveyTitle: this.surveyTitle },
    });
  }

  currentPageChanged(event: any) {
    this.currentPageNo = (event.currentPageNo as number) + 1;
  }

  prevPage() {
    this.survey.surveyModel.prevPage();
  }

  async confirmCancellation() {
    const ionAlert = await this.alertController.create({
      header: $localize`Möchtest Du den Fragebogen wirklich abbrechen?`,
      message: $localize`Bei Abbruch können Deine bisherigen Angaben nicht gespeichert werden. Du kannst den Fragebogen aber später erneut bearbeiten.`,
      cssClass: ['pro-alert', 'warning', 'cy-sv-cancel-alert'],
      buttons: [
        {
          text: $localize`Zurück`,
          handler: () => {},
        },
        {
          text: $localize`Ja, abbrechen`,
          cssClass: 'cy-sv-cancel-confirm-btn',
          handler: async () => {
            await this.navController.pop();
          },
        },
      ],
    });

    await ionAlert.present();
  }
}
