import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromArticles from './articles.reducer';
import * as ArticlesActions from './articles.actions';

@Injectable({
  providedIn: 'root',
})
export class ArticlesFacade {
  loading$ = this.store.pipe(select(fromArticles.selectLoading));
  articles$ = this.store.pipe(select(fromArticles.selectAll));

  constructor(private store: Store<fromArticles.State>) {}

  loadArticles() {
    this.store.dispatch(ArticlesActions.loadArticles());
  }
}
