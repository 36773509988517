import { ComponentFixture } from '@angular/core/testing';
import { By } from '@angular/platform-browser';

export class DOMHelper<T> {
  private fixture: ComponentFixture<T>;

  constructor(fixture: ComponentFixture<T>) {
    this.fixture = fixture;
  }

  find(tagName: string) {
    return this.fixture.debugElement.query(By.css(tagName));
  }

  findAll(tagName: string) {
    return this.fixture.debugElement.queryAll(By.css(tagName));
  }

  findDirective(dir: any) {
    return this.fixture.debugElement.query(By.directive(dir));
  }

  findAllDirectives(dir: any) {
    return this.fixture.debugElement.queryAll(By.directive(dir));
  }

  getDirectiveInstance(dir: any): any {
    return this.fixture.debugElement.query(By.directive(dir)).componentInstance;
  }

  getTextContent(tagName: string): any {
    return this.fixture.debugElement.query(By.css(tagName)).nativeElement.textContent;
  }

  count(tagName: string) {
    return this.findAll(tagName).length;
  }

  countDirectives(dir: any) {
    return this.findAllDirectives(dir).length;
  }

  click(tagName: string) {
    const element = this.find(tagName);
    element.nativeElement.click();
  }
}
