import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlErrorDirective,
  ControlErrorMessageDirective,
  ControlErrorComponent,
} from './form-error';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [ControlErrorDirective, ControlErrorMessageDirective, ControlErrorComponent],
  exports: [ControlErrorDirective, ControlErrorMessageDirective, ControlErrorComponent],
})
export class FormErrorModule {}
