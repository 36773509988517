import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as MessageActions from 'store/messages-store/messages.actions';
import * as fromMessages from 'store/messages-store/messages.reducer';
import * as fromRouter from 'store/router-store/router.selectors';
import Fuse from 'fuse.js';
import { combineLatest, map } from 'rxjs';
import { MeasurementTypeTranslationPipe } from './messages-store-shared/measurement-type-translation.pipe';

@Injectable({
  providedIn: 'root',
})
export class MessagesFacade {
  message$ = this.store.pipe(select(fromMessages.selectMessageById));
  messages$ = this.store.pipe(select(fromMessages.selectAll));
  messagesCareCenter$ = this.store.pipe(select(fromMessages.selectMessagesCareCenter));
  messagesGoodToKnow$ = this.store.pipe(select(fromMessages.selectMessagesGoodToKnow));
  messagesMeasurments$ = this.store.pipe(select(fromMessages.selectMessagesMeasurements));
  messagesAboutTheApp$ = this.store.pipe(select(fromMessages.selectMessagesAboutTheApp));
  messagesFiltered$ = this.store.pipe(select(fromMessages.selectMessagesFiltered));
  loadingMessages$ = this.store.pipe(select(fromMessages.selectLoading));
  unreadMessagesTotal$ = this.store.pipe(select(fromMessages.selectUnreadMessagesTotal));

  constructor(
    private store: Store<fromMessages.State | fromRouter.State>,
    private measurementTranslatePipe: MeasurementTypeTranslationPipe
  ) {}

  loadMessages() {
    this.store.dispatch(MessageActions.loadMessages());
  }

  loadMessage(id: string) {
    this.store.dispatch(MessageActions.loadMessage({ id }));
  }

  getMessage(id: number) {
    return this.store.pipe(select(fromMessages.selectMessage({ id })));
  }

  startPollingMessages() {
    this.store.dispatch(MessageActions.startPollingMessages());
  }

  stopPollingMessages() {
    this.store.dispatch(MessageActions.stopPollingMessages());
  }

  getMessagesSearchResults() {
    return combineLatest([
      this.store.pipe(select(fromMessages.selectAll)),
      this.store.pipe(select(fromRouter.selectQueryParam('searchTerm'))),
    ]).pipe(
      map(([messages, searchTerm]) => {
        if (searchTerm !== undefined) {
          const fuse = new Fuse(messages, {
            keys: [
              { name: 'learning_message_categories', weight: 4 },
              { name: 'measurement_type', weight: 3 },
              { name: 'subject', weight: 2 },
              { name: 'body', weight: 1 },
            ],
            threshold: 0.3,
            includeMatches: true,
            ignoreLocation: true,
          });
          const measurementTypeSearchTerm = this.measurementTranslatePipe.transform(
            searchTerm,
            'reverse'
          );
          const results = fuse.search({
            $or: [
              { learning_message_categories: searchTerm },
              { subject: searchTerm },
              { body: searchTerm },
              { measurement_type: measurementTypeSearchTerm },
            ],
          });
          return results;
        }
        return [];
      })
    );
  }
}
