import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Patient } from 'app/models';
import { of } from 'rxjs';
import * as InfoActions from './info.actions';
import * as fromInfo from './info.reducer';
import { ApiService } from 'store/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class InfoFacade {
  loading$ = this.store.pipe(select(fromInfo.selectLoading));
  firstname$ = this.store.pipe(select(fromInfo.selectFirstname));
  patientInfo$ = this.store.pipe(select(fromInfo.selectInfo));
  patientPrimaryPhysician$ = this.store.pipe(select(fromInfo.selectPrimaryPhysician));

  // Temporary solution for DiGA
  patientHasPhoneNumber$ = of(true);
  // patientHasPhoneNumber$ = this.store.pipe(select(fromInfo.selectUserHasPhoneNumber));

  constructor(private store: Store<fromInfo.State>, private apiService: ApiService) {}

  loadInfo() {
    this.store.dispatch(InfoActions.loadInfo());
  }

  updateInfo(data: Partial<Patient>) {
    this.store.dispatch(InfoActions.updateInfo({ data }));
  }

  changeDigaWarrantyStatus(status: boolean) {
    return this.apiService.changeDigaWarrantyStatus(status);
  }
}
