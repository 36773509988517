import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
@Component({
  selector: 'pro-bluetooth-info',
  templateUrl: './bluetooth-info.component.html',
  styleUrls: ['./bluetooth-info.component.scss'],
})
export class BluetoothInfoComponent {
  constructor(public popoverController: PopoverController) {}
}
