import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BluetoothHelperService } from 'store/devices-store/bluetooth-helper.service';
import * as fromBm64 from './bm64.reducer';
import { Bm64Effects } from './bm64.effects';
import { BmService } from '../bm.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromBm64.featureKey, fromBm64.reducer),
    EffectsModule.forFeature([Bm64Effects]),
  ],
  providers: [BmService, BluetoothHelperService],
})
export class Bm64StoreModule {}
