import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { LocalNotificationSchema } from '@capacitor/local-notifications';
import * as NotificationsActions from './notifications.actions';
import { NotificationType } from './notifications.model';

export const notificationsFeatureKey = 'notifications';

export type State = EntityState<LocalNotificationSchema>;

export const adapter: EntityAdapter<LocalNotificationSchema> =
  createEntityAdapter<LocalNotificationSchema>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const notificationsReducer = createReducer(
  initialState,
  on(
    NotificationsActions.loadNotificationsSuccess,
    NotificationsActions.addPendingNotificationsSuccess,
    (state, action) => adapter.setAll(action.notifications, state)
  ),
  on(NotificationsActions.deleteNotificationsSuccess, (state) =>
    adapter.removeMany(
      Object.keys(state.entities)
        .map((key) => state.entities[key])
        .filter((entity) => entity.extra?.type !== NotificationType.Custom)
        .map((entity) => entity.id),
      state
    )
  ),
  on(NotificationsActions.deleteCustomNotificationsSuccess, (state, action) =>
    adapter.removeMany(
      action.notifications.map((notification) => notification.id),
      state
    )
  )
);

export function reducer(state: State | undefined, action: Action) {
  return notificationsReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>(notificationsFeatureKey);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectCustomNotifications = createSelector(selectAll, (entities) =>
  entities.filter((entity) => entity.extra?.type === NotificationType.Custom)
);
