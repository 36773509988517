import { createAction, props } from '@ngrx/store';
import { DeviceInformation } from 'app/models';
import { Po60ResultValues } from 'store/devices-store/devices.model';
import * as fromPo60 from './po60.reducer';

export const addState = createAction('[PO60/Store] Add State', props<{ state: fromPo60.State }>());

export const addStateSuccess = createAction('[PO60/Store] Add State Success');

export const startScanning = createAction('[PO60/Store] Start Scanning');

export const startScanningSuccess = createAction(
  '[PO60/Store] Start Scanning Success',
  props<{ name: string; address: string }>()
);

export const loadDeviceInformation = createAction(
  '[PO60/API] Load Device Information',
  props<{ address: string }>()
);

export const loadDeviceInformationFailure = createAction(
  '[PO60/API] Load Device Information Failure',
  props<{ error: any }>()
);

export const loadDataStorage = createAction(
  '[PO60/API] Load Data Storage',
  props<{ address: string; deviceInformation?: DeviceInformation }>()
);

export const loadDataStorageSubscribed = createAction('[PO60/API] Load Data Storage Subscribed');

export const loadDataStorageSubscribedResult = createAction(
  '[PO60/API] Load Data Storage Subscribed Result',
  props<{ address: string; deviceInformation?: DeviceInformation }>()
);

export const loadDataStorageFailure = createAction(
  '[PO60/API] Load Data Storage Failure',
  props<{ error: any }>()
);

export const loadDataStorageDisconnected = createAction(
  '[PO60/API] Load Data Storage Disconnected'
);

export const setTimeSuccess = createAction(
  '[PO60/API] Set Time Success',
  props<{ address: string }>()
);

export const setTimeFailure = createAction('[PO60/API] Set Time Failure', props<{ error: any }>());

export const unsubscribeSuccess = createAction('[PO60/API] Unsubscribe Success');

export const unsubscribeFailure = createAction(
  '[PO60/API] Unsubscribe Failure',
  props<{ error: any }>()
);

export const loadMeasurements = createAction(
  '[PO60/API] Load Measurements',
  props<{ address: string }>()
);

export const loadMeasurementsSubscribedResult = createAction(
  '[PO60/API] Load Measurements Subscribed Results'
);

export const loadMeasurementsSuccess = createAction(
  '[PO60/API] Load Measurements Success',
  props<{
    results: Po60ResultValues[];
    lastResults: Po60ResultValues[];
    deviceInformation: DeviceInformation;
  }>()
);

export const loadMeasurementsFailure = createAction(
  '[PO60/API] Load Measurements Failure',
  props<{ error: any }>()
);

export const loadMeasurementsDisconnected = createAction(
  '[PO60/API] Load Measurements Disconnected'
);

export const deleteDevice = createAction('[PO60/API] Delete Device');

export const deleteDeviceSuccess = createAction(
  '[PO60/API] Delete Device Success',
  props<{ address: string }>()
);

export const disconnectDevice = createAction(
  '[PO60/API] Disconnect Device',
  props<{ address: string }>()
);

export const disconnectDeviceSuccess = createAction('[PO60/API] Disconnect Device Success');

export const disconnectDeviceFailure = createAction(
  '[PO60/API] Disconnect Device Failure',
  props<{ error: any }>()
);

export const initialisingError = createAction(
  '[PO60/API] Initialising Error',
  props<{ error: any; address: string }>()
);

export const transferringError = createAction(
  '[PO60/API] Transferring Error',
  props<{ error: any }>()
);

export const updateLastResults = createAction(
  '[PO60/API] Update Last Results',
  props<{ lastResults: Po60ResultValues[] }>()
);
