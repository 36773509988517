import { Action, createAction, props } from '@ngrx/store';
import { MonitoringGoal } from 'app/models';

export const loadMonitoringGoals = createAction('[MonitoringGoals/API] Load Monitoring Goals');

export const loadMonitoringGoalsSuccess = createAction(
  '[MonitoringGoals/API] Load Monitoring Goals Success',
  props<{ entities: MonitoringGoal[] }>()
);

export const loadMonitoringGoalsFailure = createAction(
  '[MonitoringGoals/API] Load Monitoring Goals Failure',
  props<{ error: any }>()
);
export const loadMonitoringGoalsComplete = createAction(
  '[MonitoringGoals/API] Load Monitoring Goals Complete',
  props<{ action: Action }>()
);
