import { Directive, HostListener, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BluetoothInfoComponent } from './bluetooth-info/bluetooth-info.component';
import { BluetoothActivateInfoComponent } from './bluetooth-activate-info/bluetooth-activate-info.component';
import { DeviceHelpComponent } from './device-help/device-help.component';
import { MedicationChangeInfoComponent } from './medication-change-info/medication-change-info.component';
import { PhysicianNumberInfoComponent } from './physician-number-info/physician-number-info.component';
import { AppSubscriptionsInfoComponent } from './app-subscriptions-info/app-subscriptions-info.component';
import { AppSubscriptionsInvalidCodeInfoComponent } from './app-subscriptions-invalid-code-info/app-subscriptions-invalid-code-info.component';

@Directive({
  selector: '[proInfo]',
})
export class InfoDirective {
  @Input('proInfo') type:
    | 'BluetoothInfo'
    | 'BluetoothActivateInfo'
    | 'DeviceHelp'
    | 'MedicationChangeInfo'
    | 'PhysicianNumberInfo'
    | 'SubscriptionsInfo'
    | 'SubscriptionsInfoRegistration'
    | 'SubscriptionsInvalidCodeInfo' = null;
  @Input() componentProps: undefined | { [key: string]: any };

  constructor(private popoverController: PopoverController) {}

  @HostListener('click', ['$event']) onClick(ev: Event) {
    ev.stopPropagation();

    switch (this.type) {
      case 'BluetoothInfo':
        this.presentPopover(BluetoothInfoComponent);
        break;

      case 'BluetoothActivateInfo':
        this.presentPopover(BluetoothActivateInfoComponent);
        break;

      case 'DeviceHelp':
        this.presentPopover(DeviceHelpComponent);
        break;

      case 'MedicationChangeInfo':
        this.presentPopover(MedicationChangeInfoComponent);
        break;

      case 'PhysicianNumberInfo':
        this.presentPopover(PhysicianNumberInfoComponent);
        break;

      case 'SubscriptionsInfo':
        this.presentPopover(AppSubscriptionsInfoComponent, this.componentProps);
        break;

      case 'SubscriptionsInvalidCodeInfo':
        this.presentPopover(AppSubscriptionsInvalidCodeInfoComponent);
        break;

      default:
        break;
    }
  }

  async presentPopover(component: any, componentProps?: undefined | { [key: string]: any }) {
    const popover = await this.popoverController.create({
      component,
      cssClass: ['pro-popover'],
      componentProps,
    });
    return await popover.present();
  }
}
