import { ChangeDetectionStrategy, Component, Directive, Input } from '@angular/core';
import { Message } from 'app/models';
import { MessageService } from 'app/services/message.service';

@Component({
  selector: 'pro-message-card-title',
  template: `<ion-card-title
      *ngIf="message.learning_message_categories?.includes('onboarding'); else elseBlock"
      i18n
      >Erste Schritte mit ProHerz</ion-card-title
    >
    <ng-template #elseBlock>
      <!-- message with learning_message_categories title -->
      <ion-card-title
        *ngIf="
          message.category === 'learning' || message.category === 'communication_knowledge';
          else elseDefaultBlock
        "
      >
        <!-- show learning_message_categories when defined -->
        <ng-container *ngIf="message.learning_message_categories !== undefined">
          <ng-container *ngIf="size === 'small'">
            {{ message.learning_message_categories.join(', ') | truncate : 26 : 'chars' : true }}
          </ng-container>
          <ng-container *ngIf="size === 'large'">
            {{ message.learning_message_categories.join(', ') | truncate : 75 : 'chars' }}
          </ng-container>
        </ng-container>

        <!-- show subject when learning_message_categories are undefined -->
        <ng-container *ngIf="message.learning_message_categories === undefined">
          <ng-container *ngIf="size === 'small'">
            {{ message.subject | truncate : 26 : 'chars' : true }}
          </ng-container>
          <ng-container *ngIf="size === 'large'">
            {{ message.subject }}
          </ng-container>
        </ng-container>
      </ion-card-title>

      <!-- message with subject title -->
      <ng-template #elseDefaultBlock>
        <ng-container [ngSwitch]="size">
          <ion-card-title *ngSwitchCase="'small'"
            >{{ message.subject | truncate : 26 : 'chars' : true }}
          </ion-card-title>
          <ion-card-title *ngSwitchCase="'large'"
            >{{ message.subject | truncate : 65 : 'chars' }},
            {{ message.created_at | date : 'mediumDate' }}</ion-card-title
          >
        </ng-container>
      </ng-template>
    </ng-template>`,
  styles: [],
})
export class MessageCardTitleComponent {
  @Input() message: Message;
  @Input() size: 'small' | 'large';
}

@Directive()
abstract class MessageCardComponent {
  private MESSAGE: Message;
  @Input() set message(value: Message) {
    // add default link
    if (this.link === undefined) {
      this.link = ['/app/message', value.id];
    }

    this.backgroundImage = `background-image: url('/assets/images/${this.messageService.getImage(
      value
    )}')`;
    this.MESSAGE = value;
  }

  get message() {
    return this.MESSAGE;
  }

  @Input() link: any;

  backgroundImage: string;

  constructor(private messageService: MessageService) {}
}

@Component({
  selector: 'pro-message-card-compact',
  template: `<ion-card [routerLink]="link" [class.read]="message.opened_at" class="compact">
    <div class="image" [style]="backgroundImage">
      <ion-card-header>
        <ion-card-subtitle *ngIf="!message.opened_at" class="read-status">
          <div><span class="circle"></span></div>
          <div><span i18n>ungelesen</span></div>
        </ion-card-subtitle>
        <pro-message-card-title [message]="message" size="small"></pro-message-card-title>
      </ion-card-header>
    </div>
  </ion-card>`,
  styleUrls: ['./message-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageCardCompactComponent extends MessageCardComponent {
  constructor(messageService: MessageService) {
    super(messageService);
  }
}

@Component({
  selector: 'pro-message-card-extended',
  template: `<ion-card [routerLink]="link" [class.read]="message.opened_at" class="extended">
    <div class="image" [style]="backgroundImage">
      <ion-card-header>
        <pro-message-card-title [message]="message" size="large"></pro-message-card-title>
      </ion-card-header>
      <ion-card-content [innerHtml]="message.body | truncate : 60 : 'chars'"></ion-card-content>
      <div class="footer">
        <div *ngIf="!message.opened_at" class="read-status">
          <div><span class="circle"></span></div>
          <div><span i18n>ungelesen</span></div>
        </div>
        <ion-button class="small" i18n>Mehr</ion-button>
      </div>
    </div>
  </ion-card>`,
  styleUrls: ['./message-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageCardExtendedComponent extends MessageCardComponent {
  constructor(messageService: MessageService) {
    super(messageService);
  }
}
