import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'pro-bluetooth-activate-info',
  templateUrl: './bluetooth-activate-info.component.html',
  styleUrls: ['./bluetooth-activate-info.component.scss'],
})
export class BluetoothActivateInfoComponent {
  constructor(public popoverController: PopoverController) {}
}
