import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule, ActionReducer, Action } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import {
  AuthInterceptor,
  CACHE,
  CacheInterceptor,
  DeviceInfoInterceptor,
  ErrorInterceptor,
  RefreshTokenInterceptor,
} from 'store/interceptors';
import { environment } from 'environments/environment';
import { AuthStoreModule } from './auth-store/auth-store.module';
import { MedicationsStoreModule } from './medications-store/medications-store.module';
import { MonitoringGoalsStoreModule } from './monitoring-goals-store/monitoring-goals-store.module';
import { MessagesStoreModule } from './messages-store/messages-store.module';
import { InfoStoreModule } from './info-store/info-store.module';
import { DiagnosesStoreModule } from './diagnoses-store/diagnoses-store.module';
import { DocumentsStoreModule } from './documents-store/documents-store.module';
import { DocumentCategoriesStoreModule } from './document-categories-store/document-categories-store.module';
import { TermsOfServiceStoreModule } from './terms-of-service-store/terms-of-service-store.module';
import { MedicalContactsStoreModule } from './medical-contacts-store/medical-contacts-store.module';
import { MeasurementsStoreModule } from './measurements-store/measurements-store.module';
import { MedicationIntakesStoreModule } from './medication-intakes-store/medication-intakes-store.module';
import { NotificationsStoreModule } from './notifications-store/notifications-store.module';
import { DevicesStoreModule } from './devices-store/devices-store.module';
import { ArticlesStoreModule } from './articles-store/articles-store.module';
import { ActivationsStoreModule } from './activations-store/activations-store.module';
import { TelemedicineCentersStoreModule } from './telemedicine-centers-store/telemedicine-centers-store.module';
import { HTTP_CUSTOM_INTERCEPTORS } from './api/http-custom';
import { AppStoreSharedModule } from './app-store-shared/app-store-shared.module';
import * as AuthActions from 'store/auth-store/auth.actions';
import { AppEffects } from './app.effects';
import * as fromApp from './app.reducer';

export function clearStateMetaReducer<State extends Record<string, any>>(
  reducer: ActionReducer<State>
): ActionReducer<State> {
  return function clearStateFn(state: State, action: Action) {
    if (
      action.type === AuthActions.signOutSuccess.type ||
      action.type === AuthActions.clearStateSuccess.type ||
      action.type === AuthActions.deleteUserSuccess.type
    ) {
      CACHE.clear();
      state = {} as State;
    }
    return reducer(state, action);
  };
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    EffectsModule.forRoot([AppEffects]),
    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {
        metaReducers: [clearStateMetaReducer],
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictActionWithinNgZone: false,
          strictActionTypeUniqueness: true,
        },
      }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 100,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forFeature(fromApp.appFeatureKey, fromApp.reducer),
    AuthStoreModule,
    DiagnosesStoreModule,
    MedicationsStoreModule,
    MonitoringGoalsStoreModule,
    MessagesStoreModule,
    InfoStoreModule,
    DocumentsStoreModule,
    DocumentCategoriesStoreModule,
    TermsOfServiceStoreModule,
    MedicalContactsStoreModule,
    MeasurementsStoreModule,
    MedicationIntakesStoreModule,
    NotificationsStoreModule,
    DevicesStoreModule,
    ArticlesStoreModule,
    ActivationsStoreModule,
    TelemedicineCentersStoreModule,
    AppStoreSharedModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_CUSTOM_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DeviceInfoInterceptor, multi: true },
  ],
})
export class AppStoreModule {}
