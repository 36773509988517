import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRouterSelectors from './router.selectors';

@Injectable({
  providedIn: 'root',
})
export class RouterFacade {
  queryParamCategory$ = this.store.pipe(select(fromRouterSelectors.selectQueryParam('category')));
  url$ = this.store.pipe(select(fromRouterSelectors.selectUrl));

  constructor(private store: Store<fromRouterSelectors.State>) {}

  getQueryParam(param: string) {
    return this.store.pipe(select(fromRouterSelectors.selectQueryParam(param)));
  }

  getQueryParams() {
    return this.store.pipe(select(fromRouterSelectors.selectQueryParams));
  }

  getRouteParam(param: string) {
    return this.store.pipe(select(fromRouterSelectors.selectRouteParam(param)));
  }
}
