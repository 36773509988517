import { Pipe, PipeTransform } from '@angular/core';
import { DeviceType } from 'store/devices-store/devices.model';

@Pipe({
  name: 'deviceTypeTranslation',
})
export class DeviceTypeTranslationPipe implements PipeTransform {
  transform(value: DeviceType): string {
    return this.getDevice(value);
  }

  private getDevice(type: DeviceType) {
    switch (type) {
      case DeviceType.BloodPressureMonitor:
        return $localize`Blutdruckmessgerät`;

      case DeviceType.PulseOximeter:
        return $localize`Pulsoximeter`;

      case DeviceType.Scale:
        return $localize`Waage`;

      default:
        return type;
    }
  }
}
