import { TelemedicineCenter } from 'app/models';
import { TestDataFactory, RANDOM_STRING, RANDOM_NUMBER } from './test-data.factory';

export class TelemedicineCenterFactory extends TestDataFactory<TelemedicineCenter> {
  constructor() {
    const generatorFunction = (): TelemedicineCenter => {
      const name = 'TMZ-' + RANDOM_STRING.getOne(8);

      return {
        id: RANDOM_NUMBER.getOne(33),
        name,
        address: {
          city: 'city_' + RANDOM_STRING.getOne(5),
          street: 'street_' + RANDOM_STRING.getOne(5),
          street_2: 'street_2_' + RANDOM_STRING.getOne(5),
          zip_code: 'zip_code_' + RANDOM_STRING.getOne(5),
        },
        phone_numbers: [
          {
            type: 'work',
            number: `${RANDOM_NUMBER.getOne(4)} ${RANDOM_NUMBER.getOne(3)} ${RANDOM_NUMBER.getOne(
              4
            )}`,
          },
        ],
        email: 'email@' + name + '.com',
        physicians: [
          {
            id: RANDOM_NUMBER.getOne(3),
            title: 'Dr.',
            firstname: 'firstname_ ' + RANDOM_STRING.getOne(8),
            surname: 'surname_ ' + RANDOM_STRING.getOne(8),
          },
        ],
        service_level: 'full',
      };
    };

    super(generatorFunction);
  }
}
