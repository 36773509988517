// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Environment, version, reAuthentication } from './constants';

export const environment: Environment = {
  version: `${version} Development`,
  production: false,
  reAuthentication,
  apiUrl: 'https://carecenter.staging.procarement.com/api/v1',
  kardiaCredentials: {
    baseUrl: 'https://kardia-auth-server.staging.procarement.com/',
    partnerId: 'JACkB7w88h5Cjxl78A09cgeumju6ygkg',
    teamId: '3gdAfg6bUH7t5Lo9N6tJcgeumiybl1yw',
    environment: 'STAGING',
  },
  oneSignalAppId: '503dc517-5536-4602-9244-91472ff5f246',
  appSignalKey: 'cebfce68-1825-4abe-974e-e418bb312fa6',
  channel: 'Development',
  e2e: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
