import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pro-document-icon',
  templateUrl: './document-icon.component.html',
  styleUrls: ['./document-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentIconComponent {
  @Input() category:
    | 'report_stationary'
    | 'report_ambulant'
    | 'laboratory'
    | 'ecg'
    | 'ecg_result'
    | 'imaging'
    | 'other'
    | 'rights_and_disposals';

  constructor() {}
}
