<div
  class="checkbox-container"
  [ngClass]="{'disabled': disabled === true, 'checked': value === true, 'unchecked': value === false}"
>
  <ng-container *ngIf="disabled === false">
    <ion-icon
      *ngIf="value === true"
      class="checkmark"
      name="checkmark-outline"
      color="white"
    ></ion-icon>

    <ion-icon
      *ngIf="value === false"
      class="cross"
      name="close-outline"
      color="white"
    ></ion-icon>
  </ng-container>
</div>