import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as MonitoringGoalsActions from './monitoring-goals.actions';
import * as fromMonitoringGoals from 'store/monitoring-goals-store/monitoring-goals.reducer';
import { MonitoringGoalType } from 'app/models';
@Injectable({
  providedIn: 'root',
})
export class MonitoringGoalsFacade {
  monitoringGoals$ = this.store.pipe(
    select(fromMonitoringGoals.selectAllMonitoringGoalsWithoutSurveys)
  );
  loading$ = this.store.pipe(select(fromMonitoringGoals.selectLoading));

  constructor(private store: Store<fromMonitoringGoals.State>) {}

  loadMonitoringGoals() {
    this.store.dispatch(MonitoringGoalsActions.loadMonitoringGoals());
  }

  getMonitoringGoal(type: MonitoringGoalType) {
    return this.store.pipe(select(fromMonitoringGoals.selectMonitoringGoal({ type })));
  }

  getOpenMeasurements(type: MonitoringGoalType) {
    return this.store.pipe(select(fromMonitoringGoals.selectOpenMeasurements({ type })));
  }
}
