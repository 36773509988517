import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasValue',
})
export class HasValuePipe implements PipeTransform {
  transform(values: Record<string, unknown>[] | FileList, key: string, value: string): any {
    if (Array.isArray(values)) {
      return values.some((e) => (e[key] as string).includes(value));
    } else if (typeof values === 'object') {
      const valuesArr = this.fileListToArray(values);
      return valuesArr.some((e: any) => (e[key] as string).includes(value));
    } else {
      console.log(Error, 'values has to be an array of objects or FileList.');
    }
  }

  private fileListToArray(files: FileList): any[] {
    const filesArr = [];
    for (const i in files) {
      if (files[i] instanceof Blob) {
        filesArr.push(files[i]);
      }
    }
    return filesArr;
  }
}
