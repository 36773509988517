import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ApiService } from 'store/api/api.service';
import * as DiagnosesActions from 'store/diagnoses-store/diagnoses.actions';
import * as fromDiagnoses from 'store/diagnoses-store/diagnoses.reducer';

@Injectable({
  providedIn: 'root',
})
export class DiagnosesFacade {
  diagnoses$ = this.store.pipe(select(fromDiagnoses.selectAll));
  diagnosesLoading$ = this.store.pipe(select(fromDiagnoses.selectLoading));

  constructor(private store: Store<fromDiagnoses.State>, private api: ApiService) {}

  loadDiagnoses() {
    this.store.dispatch(DiagnosesActions.loadDiagnoses());
  }

  searchDiagnoses(searchTerm = '') {
    return this.api.searchDiagnoses(searchTerm);
  }

  createDiagnosis(icdCode: string, nyhaStatus: number = null) {
    this.store.dispatch(
      DiagnosesActions.createDiagnosis({ icd_code: icdCode, nyha_status: nyhaStatus })
    );
  }

  deleteDiagnosis(icdCode: string) {
    this.store.dispatch(DiagnosesActions.deleteDiagnosis({ icd_code: icdCode }));
  }
}
