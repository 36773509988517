import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromTelemedicineCenters from './telemedicine-centers.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TelemedicineCentersEffects } from './telemedicine-centers.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromTelemedicineCenters.telemedicineCentersFeatureKey,
      fromTelemedicineCenters.reducer
    ),
    EffectsModule.forFeature([TelemedicineCentersEffects]),
  ],
})
export class TelemedicineCentersStoreModule {}
