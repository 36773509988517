import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import {
  Bf720State,
  Bm54State,
  Bm57State,
  Bm64State,
  DeviceType,
  Po60State,
} from './devices.model';
import * as DevicesActions from './devices.actions';
import * as fromBm54 from './bm-store/bm54-store/bm54.reducer';
import * as Bm54Actions from './bm-store/bm54-store/bm54.actions';
import * as fromBm57 from './bm-store/bm57-store/bm57.reducer';
import * as Bm57Actions from './bm-store/bm57-store/bm57.actions';
import * as fromBm64 from './bm-store/bm64-store/bm64.reducer';
import * as Bm64Actions from './bm-store/bm64-store/bm64.actions';
import * as fromPo60 from './po60-store/po60.reducer';
import * as Po60Actions from './po60-store/po60.actions';
import * as fromBf720 from './bf720-store/bf720.reducer';
import * as Bf720Actions from './bf720-store/bf720.actions';
import * as fromRouterSelectors from 'store/router-store/router.selectors';

export const devicesFeatureKey = 'devices';

export interface State {
  scanning: boolean;
  scanError: Error | string | null;
  pauseScanning: boolean;
  initialising: boolean; // is needed to hide toast when initialising device
}

export const initialState: State = {
  scanning: false,
  scanError: null,
  pauseScanning: false,
  initialising: false,
};

const devicesReducer = createReducer(
  initialState,
  on(
    Bm54Actions.loadMeasurements,
    Bm54Actions.loadDeviceInformation,
    Bm57Actions.loadMeasurements,
    Bm57Actions.loadDeviceInformation,
    Bm64Actions.loadMeasurements,
    Bm64Actions.loadDeviceInformation,
    Po60Actions.loadDataStorage,
    Po60Actions.loadMeasurements,
    Bf720Actions.loadUserList,
    (state) => ({
      ...state,
      scanning: false,
    })
  ),
  on(DevicesActions.stopScanSuccess, (state, action) => ({
    ...state,
    scanning: false,
    scanError: action.status,
    initialising: false,
  })),
  on(DevicesActions.startScan, DevicesActions.startScanManualMeasurements, (state) => ({
    ...state,
    scanning: true,
    scanError: null,
    initialising: true,
  })),
  on(
    DevicesActions.startScanFailure,
    DevicesActions.startScanManualMeasurementsFailure,
    (state, action) => ({
      ...state,
      scanning: false,
      scanError: action.error.error ? action.error.error : action.error,
      initialising: false,
    })
  ),
  on(
    DevicesActions.loadMeasurementsSuccess,
    Bm54Actions.loadDeviceInformationFailure,
    Bm54Actions.loadMeasurementsFailure,
    Bm57Actions.loadDeviceInformationFailure,
    Bm57Actions.loadMeasurementsFailure,
    Bm64Actions.loadDeviceInformationFailure,
    Bm64Actions.loadMeasurementsFailure,
    (state) => ({
      ...state,
      initialising: false,
    })
  ),
  on(DevicesActions.pauseScanning, (state) => ({ ...state, pauseScanning: true })),
  on(DevicesActions.restartScanning, (state) => ({ ...state, pauseScanning: false }))
);

export function reducer(state: State | undefined, action: Action) {
  return devicesReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('devices');

export const selectScanning = createSelector(selectFeature, (state) => state.scanning);

export const selectPauseScanning = createSelector(selectFeature, (state) => state.pauseScanning);

export const selectScanError = createSelector(selectFeature, (state) => state.scanError);

export const selectInitialising = createSelector(selectFeature, (state) => state.initialising);

export const selectDevices = createSelector(
  fromBm54.selectFeature,
  fromBm57.selectFeature,
  fromBm64.selectFeature,
  fromPo60.selectFeature,
  fromBf720.selectFeature,
  (
    bm54State,
    bm57State,
    bm64State,
    po60State,
    bf720State
  ): Array<Bm54State | Bm57State | Bm64State | Po60State | Bf720State> => [
    bm54State,
    bm57State,
    bm64State,
    po60State,
    bf720State,
  ]
);

export const selectDevice = (props: { id: string }) =>
  createSelector(
    fromBm54.selectFeature,
    fromBm57.selectFeature,
    fromBm64.selectFeature,
    fromPo60.selectFeature,
    fromBf720.selectFeature,
    (
      bm54: fromBm54.State,
      bm57: fromBm57.State,
      bm64: fromBm64.State,
      po60: fromPo60.State,
      bf720: fromBf720.State
    ): Bm54State | Bm57State | Bm64State | Po60State | Bf720State =>
      props.id === 'BM54'
        ? bm54
        : props.id === 'BM57'
        ? bm57
        : props.id === 'BM64'
        ? bm64
        : props.id === 'PO60'
        ? po60
        : bf720
  );

export const selectDeviceByName = createSelector(
  selectDevices,
  fromRouterSelectors.selectRouteParam('device'),
  (devices, name) => devices.find((d) => d.name === name)
);

export const selectBloodPressureDevices = createSelector(
  selectDevices,
  (devices): Array<Bm54State | Bm57State | Bm64State> =>
    devices.filter((d) => d.type === DeviceType.BloodPressureMonitor) as Array<
      Bm54State | Bm57State | Bm64State
    >
);

export const selectBloodPressureDevice = createSelector(selectBloodPressureDevices, (devices) =>
  devices.filter((d) => d.address).length > 0 ? devices.filter((d) => d.address)[0] : devices[0]
);

export const selectDevicesOnlyOnePerType = createSelector(
  selectBloodPressureDevice,
  fromPo60.selectFeature,
  fromBf720.selectFeature,
  (bloodPressureDevice, po60State, bf720State) => [bloodPressureDevice, po60State, bf720State]
);

export const selectDevicesNotConnected = createSelector(selectDevicesOnlyOnePerType, (devices) =>
  devices.filter((d) => !d.address)
);

export const selectDevicesConnected = createSelector(selectDevicesOnlyOnePerType, (devices) =>
  devices.filter((d) => d.address)
);

export const selectAllDevicesConnected = createSelector(
  selectDevicesOnlyOnePerType,
  (devices) => devices.filter((d) => d.address).length === devices.length
);

export const selectDevicesConnectedByType = (props: { type: DeviceType }) =>
  createSelector(
    selectDevicesConnected,
    (
      devices: Array<
        fromBm54.State | fromBm57.State | fromBm64.State | fromPo60.State | fromBf720.State
      >
    ): Array<fromBm54.State | fromBm57.State | fromBm64.State | fromPo60.State | fromBf720.State> =>
      devices.filter((d) => d.type === props.type)
  );

export const selectDeviceError = createSelector(selectDeviceByName, (device) => device?.error);

export const selectErrors = createSelector(
  selectScanError,
  selectDeviceError,
  (scanError, deviceError) => (scanError ? scanError : deviceError ? deviceError : null)
);

export const selectScanningDevices = createSelector(selectDevices, (devices) =>
  devices.filter((d) => d.scanning)
);
