import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ObservationResultSurvey } from 'app/models';

@Component({
  selector: 'pro-questionnaire-completed',
  templateUrl: './questionnaire-completed.component.html',
  styleUrls: ['./questionnaire-completed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnaireCompletedComponent {
  @Input() result: ObservationResultSurvey;
  @Input() measurementsLoading: boolean;
  @Input() measurementsError: Error | null;
  @Input() documentsLoading: boolean;

  constructor() {}
}
