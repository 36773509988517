import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'pro-device-switch',
  templateUrl: './device-switch.component.html',
  styleUrls: ['./device-switch.component.scss'],
})
export class DeviceSwitchComponent {
  @Input() device: string;
  @Input() type: string;
  @Input() routePath: string | any[];
  @Input() queryParams: Params;

  constructor() {}
}
