<ion-button
  class="light"
  (click)="emitCancel()"
  data-cy="cancel-btn"
>{{
  cancelLabel
  }}</ion-button>

<ion-button
  [disabled]="disabled === true"
  (click)="emitSave()"
  data-cy="submit-btn"
>
  {{ saveLabel }}
</ion-button>