import { Component, Input } from '@angular/core';

@Component({
  selector: 'pro-device-icon',
  template: `
    <ng-container *ngIf="status === 'result'" [ngSwitch]="deviceName">
      <ion-icon
        *ngSwitchCase="'BM54'"
        src="assets/svgs/mg_blood_pressure_new_value.svg"
        class="result-icon"
      ></ion-icon>

      <ion-icon
        *ngSwitchCase="'BM57'"
        src="assets/svgs/mg_blood_pressure_new_value.svg"
        class="result-icon"
      ></ion-icon>

      <ion-icon
        *ngSwitchCase="'PO60'"
        src="assets/svgs/mg_oxygen_saturation_new_value.svg"
        class="result-icon"
      ></ion-icon>

      <ion-icon
        *ngSwitchCase="'BF720'"
        src="assets/svgs/mg_weight_new_value.svg"
        class="result-icon"
      ></ion-icon>

      <ion-icon
        *ngSwitchCase="'TemperatureGoal'"
        src="assets/svgs/mg_temperature_new_value.svg"
        class="result-icon"
      ></ion-icon>

      <ion-icon
        *ngSwitchCase="'HeartRateGoal'"
        src="assets/svgs/mg_heart_rate_new_value.svg"
        class="result-icon"
      ></ion-icon>
    </ng-container>

    <ng-container *ngIf="status === 'success'" [ngSwitch]="deviceName">
      <ion-icon
        *ngSwitchCase="'BM54'"
        src="assets/svgs/mg_blood_pressure_success.svg"
        class="result-icon"
      ></ion-icon>

      <ion-icon
        *ngSwitchCase="'BM57'"
        src="assets/svgs/mg_blood_pressure_success.svg"
        class="result-icon"
      ></ion-icon>

      <ion-icon
        *ngSwitchCase="'PO60'"
        src="assets/svgs/mg_oxygen_saturation_success.svg"
        class="result-icon"
      ></ion-icon>

      <ion-icon
        *ngSwitchCase="'BF720'"
        src="assets/svgs/mg_weight_success.svg"
        class="result-icon"
      ></ion-icon>

      <ion-icon
        *ngSwitchCase="'TemperatureGoal'"
        src="assets/svgs/mg_temperature_success.svg"
        class="result-icon"
      ></ion-icon>

      <ion-icon
        *ngSwitchCase="'HeartRateGoal'"
        src="assets/svgs/mg_heart_rate_success.svg"
        class="result-icon"
      ></ion-icon>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: block;
        margin: 20px 0;
      }

      ion-icon {
        font-size: 185px;
      }
    `,
  ],
})
export class DeviceIconComponent {
  @Input() deviceName: 'BM54' | 'PO60' | 'BF720';
  @Input() status: 'success' | 'result';
}
