import * as dayjs from 'dayjs';

/**
 * type guard
 * @param varToBeChecked any
 * @param propertyToCheckFor any
 */
export function isOfType<T>(varToBeChecked: any, propertyToCheckFor: any): varToBeChecked is T {
  if (varToBeChecked === null) {
    return false;
  }

  if (Array.isArray(varToBeChecked) && varToBeChecked.length > 0) {
    varToBeChecked = varToBeChecked[0];
  }

  return varToBeChecked[propertyToCheckFor] !== undefined;
}

/**
 * validate a date-string against a format string
 *
 * @param date date-string e.g. '24-08-2018
 * @param format format-string to check against e.g. 'YYYY-MM-DD'
 */
export function validateDateString(date: string, format: string) {
  return !date || !format ? false : dayjs(date, format).format(format) === date;
}
