import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs';
import * as DocumentCategoriesActions from './document-categories.actions';
import * as fromDocumentCategories from './document-categories.reducer';

@Injectable({
  providedIn: 'root',
})
export class DocumentCategoriesFacade {
  reportStationaryCount$ = this.store.pipe(
    select(fromDocumentCategories.selectReportStationaryCategory),
    map((category) => category?.document_count ?? 0)
  );
  ambulantCount$ = this.store.pipe(
    select(fromDocumentCategories.selectReportAmbulantCategory),
    map((category) => category?.document_count ?? 0)
  );
  laboratoryCount$ = this.store.pipe(
    select(fromDocumentCategories.selectLaboratoryCategory),
    map((category) => category?.document_count ?? 0)
  );
  ecgCount$ = this.store.pipe(
    select(fromDocumentCategories.selectEcgCategory),
    map((category) => category?.document_count ?? 0)
  );
  ecgResultCount$ = this.store.pipe(
    select(fromDocumentCategories.selectEcgResultCategory),
    map((category) => category?.document_count ?? 0)
  );
  imagingCount$ = this.store.pipe(
    select(fromDocumentCategories.selectImagingCategory),
    map((category) => category?.document_count ?? 0)
  );
  otherCount$ = this.store.pipe(
    select(fromDocumentCategories.selectOtherCategory),
    map((category) => category?.document_count ?? 0)
  );
  procarementCount$ = this.store.pipe(
    select(fromDocumentCategories.selectProcarementCategory),
    map((category) => category?.document_count ?? 0)
  );
  questionnaireCount$ = this.store.pipe(
    select(fromDocumentCategories.selectQuestionnaireCategory),
    map((category) => category?.document_count ?? 0)
  );
  rightsAndDisposalCount$ = this.store.pipe(
    select(fromDocumentCategories.selectRightsAndDisposalCategory),
    map((category) => category?.document_count ?? 0)
  );
  documentsCount$ = this.store.pipe(select(fromDocumentCategories.selectDocumentsCount));
  loading$ = this.store.pipe(select(fromDocumentCategories.selectLoading));
  loaded$ = this.store
    .pipe(select(fromDocumentCategories.selectAll))
    .pipe(map((categories) => categories.length > 0));

  constructor(private store: Store<fromDocumentCategories.State>) {}

  loadDocumentCategories() {
    this.store.dispatch(DocumentCategoriesActions.loadDocumentsCategories());
  }
}
