import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Diagnosis } from 'app/models';
import * as DiagnosesActions from './diagnoses.actions';

export const diagnosesFeatureKey = 'diagnoses';

const selectIdKey = (a: Diagnosis) => {
  return a.icd_code;
};

const sortBy = (a: Diagnosis, b: Diagnosis): number => {
  return a.icd_code.localeCompare(b.icd_code);
};

export interface State extends EntityState<Diagnosis> {
  loading: boolean;
}

export const adapter: EntityAdapter<Diagnosis> = createEntityAdapter<Diagnosis>({
  selectId: selectIdKey,
  sortComparer: sortBy,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
});

const diagnosesReducer = createReducer(
  initialState,
  on(DiagnosesActions.loadDiagnosesSuccess, (state, action) =>
    adapter.upsertMany(action.diagnoses, state)
  ),
  on(DiagnosesActions.createDiagnosisSuccess, (state, action) =>
    adapter.upsertOne(action.diagnosis, state)
  ),
  on(DiagnosesActions.deleteDiagnosisSuccess, (state, action) =>
    adapter.removeOne(action.icd_code, state)
  ),
  on(
    DiagnosesActions.loadDiagnoses,
    DiagnosesActions.createDiagnosis,
    DiagnosesActions.deleteDiagnosis,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    DiagnosesActions.loadDiagnosesSuccess,
    DiagnosesActions.loadDiagnosesFailure,
    DiagnosesActions.createDiagnosisSuccess,
    DiagnosesActions.createDiagnosisFailure,
    DiagnosesActions.deleteDiagnosisSuccess,
    DiagnosesActions.deleteDiagnosisFailure,
    (state) => ({
      ...state,
      loading: false,
    })
  )
);

export const selectFeature = createFeatureSelector<State>('diagnoses');

export function reducer(state: State | undefined, action: Action) {
  return diagnosesReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors(
  selectFeature
);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);
