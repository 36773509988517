import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[proAutoFocus]',
})
export class AutoFocusDirective {
  constructor(private element: ElementRef) {
    setTimeout(() => {
      if (this.element && this.element.nativeElement) {
        this.element.nativeElement.setFocus();
      }
    }, 350);
  }
}
