interface KardiaCredentials {
  baseUrl: string;
  partnerId: string;
  teamId: string;
  environment: 'PRODUCTION' | 'STAGING';
}

export interface Environment {
  version: string;
  production: boolean;
  reAuthentication: boolean;
  apiUrl: string;
  kardiaCredentials: KardiaCredentials;
  oneSignalAppId: string;
  appSignalKey: string; // AppSignal Front-end monitoring key
  channel: string;
  e2e: boolean;
}

function readFile(path: string) {
  const file = new XMLHttpRequest();
  let text: string;
  file.open('GET', path, false);
  file.onreadystatechange = () => {
    if (file.readyState === 4 && file.status === 200) {
      text = file.responseText;
    }
  };
  file.send(null);
  return text;
}

export const version = readFile('assets/version.txt');
export const timestamp = readFile('assets/timestamp.txt');
export const reAuthentication =
  readFile('assets/re-authentication.txt') !== undefined
    ? readFile('assets/re-authentication.txt').trim() === 'true'
    : true;
