import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, first, map, withLatestFrom } from 'rxjs';
import { Survey } from 'app/models';
import * as fromRouterSelectors from 'store/router-store/router.selectors';
import { ApiService } from 'store/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyFacade {
  private SURVEYS$ = new BehaviorSubject<Survey[]>([]);
  private LOADING$ = new BehaviorSubject(false);
  surveys$ = this.SURVEYS$.asObservable();
  loading$ = this.LOADING$.asObservable();
  survey$ = this.store.pipe(
    select(fromRouterSelectors.selectRouteParam('surveyId')),
    withLatestFrom(this.surveys$),
    map(([id, surveys]) => surveys.find((s) => s.id === id))
  );
  surveyConfig$ = this.survey$.pipe(map((s): any => (s ? s.config : null)));

  constructor(private store: Store<fromRouterSelectors.State>, private api: ApiService) {}

  loadSurveys() {
    this.LOADING$.next(true);
    this.api
      .getSurveys()
      .pipe(first())
      .subscribe((r) => {
        this.SURVEYS$.next(r.results);
        this.LOADING$.next(false);
      });
  }

  loadSurvey(id: string) {
    this.LOADING$.next(true);
    this.api
      .getSurvey(id)
      .pipe(first())
      .subscribe({
        next: (r) => {
          if (this.SURVEYS$.value.find((s) => s.id === id)) {
            this.SURVEYS$.next([...this.SURVEYS$.value.map((s) => (s.id !== id ? s : r))]);
          } else {
            this.SURVEYS$.next([...this.SURVEYS$.value, r]);
          }
          this.LOADING$.next(false);
        },
        error: () => this.LOADING$.next(false),
      });
  }

  getSurvey(id: string) {
    return this.SURVEYS$.value.filter((s) => s.config).find((s) => s.id === id);
  }
}
