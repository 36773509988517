import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  ObservationResults,
  ObservationResultValue,
  ObservationResultBloodPressure,
  ObservationResultSurvey,
  ObservationResultWeight,
} from 'app/models';
import * as MeasurementsActions from './measurements.actions';
import * as fromMeasurements from './measurements.reducer';
import * as fromMeasurementResults from './measurement-results-store/measurement-results.reducer';
import { first, skipWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);

@Injectable({
  providedIn: 'root',
})
export class MeasurementsFacade {
  loading$ = this.store.pipe(select(fromMeasurements.selectLoading));
  loaded$ = this.store.pipe(select(fromMeasurements.selectLoaded));
  measurementsError$ = this.store.pipe(select(fromMeasurements.selectError));
  measurementsEntities$ = this.store.pipe(select(fromMeasurements.selectEntities));
  measurementsWithResults$ = this.store.pipe(select(fromMeasurements.selectAllDenormalized));
  measurementResultsEntities$ = this.store.pipe(select(fromMeasurementResults.selectEntities));
  measurementResults$ = this.store.pipe(select(fromMeasurementResults.selectAll));
  measurementMinDate$ = this.store.pipe(select(fromMeasurements.selectMinDate));
  addMeasurementsResponse$ = this.store.pipe(select(fromMeasurements.selectAddMeasurementResponse));

  constructor(private store: Store<fromMeasurements.State>) {}

  loadMeasurements(from: string, to?: string) {
    this.store.dispatch(
      MeasurementsActions.loadMeasurements({
        from,
        to,
      })
    );
  }

  loadTodaysMeasurements() {
    this.store.dispatch(
      MeasurementsActions.loadMeasurements({ from: dayjs().format('YYYY-MM-DD') })
    );
  }

  addMeasurements(results: ObservationResults) {
    this.store.dispatch(MeasurementsActions.addMeasurements({ results }));

    return new Promise<
      (
        | ObservationResultValue
        | ObservationResultWeight
        | ObservationResultBloodPressure
        | ObservationResultSurvey
      )[]
    >((res, rej) => {
      combineLatest([
        this.store.select(fromMeasurements.selectAddMeasurementResponse),
        this.store.select(fromMeasurements.selectError),
      ])
        .pipe(
          skipWhile(([response, error]) => !response && !error),
          first()
        )
        .subscribe(([response, error]) => {
          if (response) {
            res(response.results);
          } else {
            rej(error);
          }
        });
    });
  }
}
