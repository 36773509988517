import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, Injectable, LOCALE_ID } from '@angular/core';
import { loadTranslations } from '@angular/localize';

@Injectable({
  providedIn: 'root',
})
class I18n {
  locale = 'de';

  async setLocale() {
    const userLocale = localStorage.getItem('locale');

    // Demo usage
    // If the user has a preferred language stored in localStorage, use it.
    if (userLocale) {
      this.locale = userLocale;
    } else {
      localStorage.setItem('locale', 'de');
    }

    /**
     * Includes local for dates, numbers, currency, ...
     */
    const localeModule = await import(`/node_modules/@angular/common/locales/${this.locale}.mjs`);

    registerLocaleData(localeModule.default);

    /**
     * Loads translation file
     */
    const translationFileContent = await import(`../locale/${this.locale}.json`);
    const translations: Record<string, string> = translationFileContent?.translations;

    if (translations) {
      // Load translations for the current locale at run-time
      loadTranslations(translations);
    }
  }
}

// Load locale data at app start-up
function setLocale() {
  return {
    provide: APP_INITIALIZER,
    useFactory: (i18n: I18n) => () => i18n.setLocale(),
    deps: [I18n],
    multi: true,
  };
}

// Set the runtime locale for the app
function setLocaleId() {
  return {
    provide: LOCALE_ID,
    useFactory: (i18n: I18n) => i18n.locale,
    deps: [I18n],
  };
}

export const I18nModule = {
  setLocale: setLocale,
  setLocaleId: setLocaleId,
};
