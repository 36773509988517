import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams,
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { CACHE_IT, FETCH_ONCE } from '../api/api.service';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

export const CACHE: Map<string, HttpRequest<unknown>> = new Map();

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown> | any> {
    let updatedRequest = request;

    if (request.context.get(FETCH_ONCE)) {
      const requestWithAdjustedParams = this.getRequestWithAdjustedParams(request);
      if (requestWithAdjustedParams instanceof HttpRequest) {
        updatedRequest = requestWithAdjustedParams;
      } else {
        // return EMPTY when timespan was alreay fetched
        return requestWithAdjustedParams;
      }
    }

    if (updatedRequest.context.get(CACHE_IT)) {
      // add request to cache when it hasn't been added yet
      if (CACHE.get(updatedRequest.urlWithParams) === undefined) {
        CACHE.set(updatedRequest.urlWithParams, updatedRequest);
        return next.handle(updatedRequest);
      } else {
        return EMPTY;
      }
    } else {
      return next.handle(updatedRequest);
    }
  }

  private getRequestWithAdjustedParams(request: HttpRequest<unknown>) {
    // get already fetched time range from - to
    let from: string;
    let to: string;
    CACHE.forEach((value) => {
      if (value.url === request.url) {
        from =
          from === undefined || dayjs(value.params.get('from')).isBefore(from, 'day')
            ? value.params.get('from')
            : from;
        to =
          to === undefined || dayjs(value.params.get('to')).isAfter(to, 'day')
            ? value.params.get('to')
            : to;
      }
    });

    // check if request params are between already fetched timespan
    const isFromBetween =
      from !== undefined
        ? dayjs(request.params.get('from')).isBetween(from, to, 'day', '[]')
        : false;
    const isToBetween =
      to !== undefined ? dayjs(request.params.get('to')).isBetween(from, to, 'day', '[]') : false;

    // cancel request if it only contain already fetched data
    if (isFromBetween && isToBetween) {
      return EMPTY;
    }

    // update to param to fetch data which is newer than the already fetched timespan
    if (!isFromBetween && isToBetween) {
      return request.clone({
        params: new HttpParams()
          .set('from', request.params.get('from'))
          .set('to', dayjs(from).subtract(1, 'day').format('YYYY-MM-DD')),
      });
    }

    // update from param to fetch data which is older than the already fetched timespan
    if (isFromBetween && !isToBetween) {
      return request.clone({
        params: new HttpParams()
          .set('from', dayjs(to).add(1, 'day').format('YYYY-MM-DD'))
          .set('to', request.params.get('to')),
      });
    }

    return request;
  }
}
