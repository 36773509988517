import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen, catchError } from 'rxjs/operators';
import { AppStoreFacade } from 'app/app-store/app-store-facade/app-store.facade';
import { AuthFacade } from 'app/app-store/auth-store/auth.facade';

const retryCount = 4;
const retryWaitMilliSeconds = 3500;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private appStoreFacade: AppStoreFacade, private authFacade: AuthFacade) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen((error) =>
        error.pipe(
          concatMap((err: HttpErrorResponse, count) => {
            if (err.status === 401) {
              this.authFacade.clearState({ commands: [`login`] });
              return throwError(() => err);
            }

            // ignore 403 change_email and change_password, 404, 422 and 429 errors
            if (
              (err.status === 403 &&
                (err.url.includes('change_email') || err.url.includes('change_password'))) ||
              err.status === 404 ||
              err.status === 422 ||
              err.status === 429
            ) {
              return throwError(() => err);
            }

            if (count < retryCount) {
              return of(err);
            }

            return throwError(() => err);
          }),
          delay(retryWaitMilliSeconds)
        )
      ),
      catchError((error: HttpErrorResponse) => {
        this.appStoreFacade.showHttpErrorMessage(error);
        return throwError(() => error);
      })
    );
  }
}
