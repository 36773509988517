import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import * as ApiModel from 'app/models/api.model';
import * as Actions from 'store/auth-store/auth.actions';

export const authFeatureKey = 'auth';

export interface State {
  data: ApiModel.SignInData;
  loading: boolean;
  error: any | null;
}

export const initialState: State = {
  data: {
    id: null,
    email: '',
    surname: '',
    firstname: '',
    notification_uuid: '',
  },
  loading: false,
  error: null,
};

const authReducer = createReducer(
  initialState,
  on(Actions.authenticate, Actions.authenticateWithQR, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.authenticateSuccess, Actions.addSignInDataSuccess, (_, props) => ({
    data: props.data,
    loading: false,
    error: null,
  })),
  on(
    Actions.authenticateFailure,
    Actions.signOutFailure,
    Actions.deleteUserFailure,
    (state, props) => ({
      ...state,
      loading: false,
      error: props.error,
    })
  ),
  on(Actions.signOut, Actions.deleteUser, (state) => ({ ...state, loading: true, error: null })),
  on(Actions.signOutSuccess, Actions.deleteUserSuccess, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('auth');

export const selectLoading = createSelector(selectFeature, (state) => state.loading);

export const selectError = createSelector(selectFeature, (state): any => state.error);

export const selectErrorMessage = createSelector(selectFeature, (state): any => {
  return state.error === null ? '' : state.error.error.errors[0];
});

export const selectNotificationUuid = createSelector(selectFeature, (state) => {
  return state.data ? state.data.notification_uuid : '';
});

export const selectData = createSelector(selectFeature, (state) => state.data);

export const selectAcceptedTermsOfService = createSelector(
  selectData,
  (state) =>
    state.hasOwnProperty('accepted_terms_of_service_version') &&
    state.hasOwnProperty('accepted_terms_of_service_at')
);

export const selectUserId = createSelector(selectData, (state) => state.id);
