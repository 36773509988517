import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ApiService } from 'store/api/api.service';
import * as DiagnosesActions from './diagnoses.actions';
import { Diagnosis } from 'app/models';

@Injectable()
export class DiagnosesEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadDiagnoses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DiagnosesActions.loadDiagnoses),
      switchMap(() =>
        this.apiService.getDiagnoses().pipe(
          map((data) =>
            DiagnosesActions.loadDiagnosesSuccess({
              diagnoses: data.results,
            })
          ),
          catchError((error) => of(DiagnosesActions.loadDiagnosesFailure({ error })))
        )
      )
    );
  });

  createDiagnosis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DiagnosesActions.createDiagnosis),
      switchMap((action) =>
        this.apiService.createDiagnosis(action.icd_code, action.nyha_status).pipe(
          map((diagnosis: Diagnosis) => DiagnosesActions.createDiagnosisSuccess({ diagnosis })),
          catchError((error) => of(DiagnosesActions.createDiagnosisFailure({ error })))
        )
      )
    );
  });

  deleteDiagnosis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DiagnosesActions.deleteDiagnosis),
      switchMap((action) =>
        this.apiService.deleteDiagnosis(action.icd_code).pipe(
          map(() => DiagnosesActions.deleteDiagnosisSuccess({ icd_code: action.icd_code })),
          catchError((error) => of(DiagnosesActions.deleteDiagnosisFailure({ error })))
        )
      )
    );
  });
}
