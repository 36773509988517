import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap, mergeMap } from 'rxjs/operators';
import { ApiService } from 'store/api/api.service';
import * as MedicationActions from './medications.actions';

@Injectable()
export class MedicationsEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadMedications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MedicationActions.loadMedications),
      switchMap(() =>
        this.apiService.getMedications().pipe(
          map(({ results }) => MedicationActions.loadMedicationsSuccess({ medications: results })),
          catchError((error) => of(MedicationActions.loadMedicationsFailure({ error })))
        )
      )
    )
  );

  updateMedication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MedicationActions.updateMedication),
      mergeMap(({ medication }) =>
        this.apiService.updateMedication(medication.id, medication).pipe(
          map((medication) =>
            MedicationActions.updateMedicationSuccess({
              medication,
            })
          ),
          catchError((error) => of(MedicationActions.updateMedicationFailure({ error })))
        )
      )
    )
  );

  createMedication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MedicationActions.createMedication),
      switchMap(({ medication }) =>
        this.apiService.createMedication(medication).pipe(
          map((medication) => MedicationActions.createMedicationSuccess({ medication })),
          catchError((error) => of(MedicationActions.createMedicationFailure({ error })))
        )
      )
    )
  );

  deleteMedication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MedicationActions.deleteMedication),
      switchMap(({ medicationId }) =>
        this.apiService.deleteMedication(medicationId).pipe(
          map(() => MedicationActions.deleteMedicationSuccess({ medicationId })),
          catchError((error) => of(MedicationActions.deleteMedicationFailure({ error })))
        )
      )
    )
  );
}
