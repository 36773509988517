import { Injectable } from '@angular/core';
import { BluetoothLE } from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { of, from, timer, Subject, Observable, throwError } from 'rxjs';
import { map, mergeMap, filter, takeUntil, takeWhile } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { Po60ResultValues } from 'store/devices-store/devices.model';

@Injectable()
export class Po60Service {
  service = 'FF12'; // custom service PO60
  characteristicNotify = 'FF02'; // send write commands to this characteristic (Data in)
  characteristicWrite = 'FF01'; // provides the requested data via notifications (Data out)

  constructor(private bluetoothLe: BluetoothLE) {}

  /* eslint-disable no-bitwise */
  readDataTotal(bytes: Uint8Array) {
    const totalCapacity = (bytes[2] & 255) + (bytes[3] & 255);
    const unreadData = (bytes[4] & 255) + (bytes[5] & 255);
    return { totalCapacity, unreadData };
  }
  /* eslint-enable no-bitwise */

  /* eslint-disable no-bitwise  */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  readMeasurements(bytes: Uint8Array) {
    const startYear = bytes[2] + 2000;
    const startMonth = bytes[3];
    const startDay = bytes[4];
    const startHour = bytes[5];
    const startMinute = bytes[6];
    const startSecond = bytes[7];
    const endYear = bytes[8] + 2000;
    const endMonth = bytes[9];
    const endDay = bytes[10];
    const endHour = bytes[11];
    const endMinute = bytes[12];
    const endSecond = bytes[13];
    const spo2Max = bytes[17];
    const spo2Min = bytes[18];
    const spo2Avg = bytes[19];
    const prMax = ((bytes[14] & 8) << 4) | bytes[20]; // bit 5 of byte 14 represents MSB
    const prMin = ((bytes[14] & 16) << 3) | bytes[21]; // bit 4 of byte 14 represents MSB
    const prAvg = ((bytes[14] & 32) << 2) | bytes[22]; // bit 3 of byte 14 represents MSB
    const startDate = new Date(
      startYear,
      startMonth - 1,
      startDay,
      startHour,
      startMinute,
      startSecond
    );
    const endDate = new Date(endYear, endMonth - 1, endDay, endHour, endMinute, endSecond);
    const result: Po60ResultValues[] = [
      {
        type: 'po60',
        device_time: dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss'),
        oxygenSaturation: {
          type: 'oxygen_saturation',
          value: spo2Avg,
          device_time: dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss'),
        },
        heartRate: {
          type: 'heart_rate',
          value: prAvg,
          device_time: dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss'),
        },
      },
    ];
    return result;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
  /* eslint-enable no-bitwise */

  subscribe(address: string) {
    return this.bluetoothLe.subscribe({
      address,
      service: this.service,
      characteristic: this.characteristicNotify,
    });
  }

  setTime(address: string) {
    const crntTime = new Date(Date.now());
    const buffer = new Uint8Array(10);
    /* eslint-disable no-bitwise */
    buffer[0] = 131;
    buffer[1] = crntTime.getFullYear() - 2000;
    buffer[2] = crntTime.getMonth() + 1;
    buffer[3] = crntTime.getDate();
    buffer[4] = crntTime.getHours();
    buffer[5] = crntTime.getMinutes();
    buffer[6] = crntTime.getSeconds();
    buffer[7] = 0; // milliseconds
    buffer[8] = 0; // milliseconds
    buffer[9] = this.getChecksum(buffer);
    /* eslint-enable no-bitwise */
    const base64 = this.bluetoothLe.bytesToEncodedString(buffer);
    return from(
      this.bluetoothLe.write({
        address,
        service: this.service,
        characteristic: this.characteristicWrite,
        value: base64,
        type: 'noResponse',
      })
    );
  }

  getDataStorageInfo(address: string) {
    const buffer = new Uint8Array([144, 5, 21]);
    const base64 = this.bluetoothLe.bytesToEncodedString(buffer);
    return this.subscribe(address).pipe(
      mergeMap((r) =>
        r.status === 'subscribed'
          ? this.bluetoothLe.write({
              address,
              service: this.service,
              characteristic: this.characteristicWrite,
              value: base64,
              type: 'noResponse',
            })
          : of(r)
      ),
      filter((r) => r.status === 'subscribedResult'),
      map((r) => {
        const bytes = this.bluetoothLe.encodedStringToBytes(r.value);
        if (bytes[0] === 224) {
          return this.readDataTotal(bytes);
        }
      })
    );
  }

  getMeasurementData(
    address: string
  ): Observable<{ status: string; isLastGroup: boolean; data: Po60ResultValues[] }> {
    const bufferStart = new Uint8Array([153, 0, 25]);
    const base64Start = this.bluetoothLe.bytesToEncodedString(bufferStart);
    const bufferNext = new Uint8Array([153, 1, 26]);
    const base64Next = this.bluetoothLe.bytesToEncodedString(bufferNext);
    const packetArr: Uint8Array[] = [];
    let measurements: Po60ResultValues[] = [];
    let isLastGroup = false;

    // timeout$ to stop notification subscription when no measurement is on the device
    const ngUnsubscribe$: Subject<void> = new Subject<void>();
    const timeout$ = timer(3000).pipe(
      map(() => ({ status: 'timeout', isLastGroup: true, data: [] })),
      takeUntil(ngUnsubscribe$)
    );

    return this.subscribe(address).pipe(
      mergeMap((r) =>
        r.status === 'subscribed'
          ? this.bluetoothLe.write({
              address,
              service: this.service,
              characteristic: this.characteristicWrite,
              value: base64Start,
              type: 'noResponse',
            })
          : of(r)
      ),
      mergeMap((r) => {
        if (r.status === 'subscribedResult') {
          ngUnsubscribe$.next();
          ngUnsubscribe$.complete();

          const chunk = this.bluetoothLe.encodedStringToBytes(r.value);
          let transmissionError = null;
          let lastPacket: number;
          let packetNo: number;
          chunk.forEach((byte, i) => {
            // set lastPacket and packetNo when a new measurement header is received
            if (byte === 233) {
              /* eslint-disable no-bitwise */
              lastPacket = chunk[i + 1] >> 6; // bit 6 is set to 1 if the packet is the last one on the device.
              packetNo = chunk[i + 1] & 15; // the 4 LSB are the packet number (0-9).
              /* eslint-enable no-bitwise */
            }
          });

          // add packet chunk to array
          packetArr.push(chunk);

          if (packetNo === 9 || lastPacket) {
            isLastGroup = lastPacket === 1;

            // send next packet
            return this.bluetoothLe.write({
              address,
              service: this.service,
              characteristic: this.characteristicWrite,
              value: base64Next,
              type: 'noResponse',
            });
          }

          if (isLastGroup) {
            const measurementsData = this.getMeasurements(packetArr);
            measurements = measurementsData.measurements;
            transmissionError = measurementsData.transmissionError;
          }

          if (transmissionError) {
            return throwError(transmissionError);
          }
        }
        return r.status === 'written' ? timeout$ : of(r);
      }),
      filter((r) => r.status === 'subscribedResult' || r.status === 'timeout'),
      map((r) =>
        r.status === 'timeout' ? r : { status: r.status, isLastGroup, data: measurements }
      ),
      takeWhile(() => !isLastGroup, true)
    );
  }

  deleteMeasurementData(address: string) {
    const bufferDelete = new Uint8Array([153, 127, 24]);
    const base64Delete = this.bluetoothLe.bytesToEncodedString(bufferDelete);
    return from(
      this.bluetoothLe.write({
        address,
        service: this.service,
        characteristic: this.characteristicWrite,
        value: base64Delete,
        type: 'noResponse',
      })
    );
  }

  private getMeasurements(packetArr: Uint8Array[]) {
    let notificationChunksLength = 0;
    packetArr.forEach((p) => {
      notificationChunksLength += p.byteLength;
    });
    let transmissionError: Error = null;

    const measurementCount = notificationChunksLength / 24; // a measurement consists of 24 byte
    const notificationChunks = new Uint8Array(notificationChunksLength);

    // create notification chunks from packet array
    packetArr.forEach((p, i) => {
      notificationChunks.set(p, i * 20);
    });

    // create measurement chunks from notification chunks
    const measurementChunks: Uint8Array[] = [];
    for (let i = 0; i < measurementCount; i++) {
      const chunk = notificationChunks.slice(i * 24, (i + 1) * 24);
      const checksum = this.getChecksum(chunk);

      // add chunk or transmission error
      if (checksum === chunk[chunk.length - 1]) {
        measurementChunks.push(chunk);
      } else {
        transmissionError = new Error('Transmission error');
      }
    }

    let measurements: Po60ResultValues[] = [];
    measurementChunks.forEach((element) => {
      const measurement = this.readMeasurements(element);
      measurements = measurements.concat(measurement);
    });
    return { measurements, transmissionError };
  }

  private getChecksum(buffer: Uint8Array) {
    let checksum = 0;
    buffer.forEach((e, i) => (i < buffer.length - 1 ? (checksum += e) : checksum));
    /* eslint-disable no-bitwise */
    return checksum & 127;
    /* eslint-enable no-bitwise */
  }
}
