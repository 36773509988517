import { createAction, props } from '@ngrx/store';
import { Article } from 'app/models';

export const loadArticles = createAction('[Articles/API] Load Articles');

export const loadArticlesSuccess = createAction(
  '[Articles/API] Load Articles Success',
  props<{ articles: Article[] }>()
);

export const loadArticlesFailure = createAction(
  '[Articles/API] Load Articles Failure',
  props<{ error: any }>()
);
