<ion-item *ngIf="observationResult; else elseBlock" [lines]="lines">
  <pro-traffic-light
    slot="start"
    [state]="observationResult.state"
    [small]="true"
    class="ion-align-self-center"
  ></pro-traffic-light>

  <pro-device-result-label [result]="observationResult"></pro-device-result-label>
</ion-item>
<ng-template #elseBlock>
  <ion-item *ngIf="result" [lines]="lines">
    <ng-content select="ion-checkbox"></ng-content>
    <ng-content select="ion-radio"></ng-content>
    <pro-device-result-label [result]="result"></pro-device-result-label>
  </ion-item>
</ng-template>
