import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(
    value: string,
    max: number,
    countBy: 'words' | 'chars' = 'words',
    strict = false
  ): unknown {
    if (countBy === 'words') {
      const words = value.split(' ');
      return words.length > max ? words.splice(0, max).join(' ') + '...' : value;
    } else {
      const words = value.split(' ');
      const reducedWords = !strict ? this.reduceWords(words, max) : this.reduceChars(words, max);
      return reducedWords.length < words.length || reducedWords.join(' ').length < value.length
        ? reducedWords.join(' ') + '...'
        : value;
    }
  }

  private reduceWords(words: string[], maxChars: number) {
    let count = 0;
    return words.filter((w, i) => {
      count = count + w.length;
      return count + i <= maxChars;
    });
  }

  private reduceChars(words: string[], maxChars: number) {
    let count = 0;

    return words
      .map((word) => {
        count = count + word.length;

        if (count >= maxChars) {
          // reduce word chars
          return word
            .split('')
            .filter((_, i) => {
              return count - word.length + words.length + i <= maxChars;
            })
            .join('');
        } else {
          return word;
        }
      })
      .filter((word) => word !== '');
  }
}
