import { createAction, props } from '@ngrx/store';
import { TelemedicineCenter } from 'app/models';

export const loadTelemedicineCenters = createAction(
  '[Telemedicine Centers] Load Telemedicine Centers'
);

export const loadTelemedicineCentersSuccess = createAction(
  '[Telemedicine Centers] Load Telemedicine Centers Success',
  props<{ telemedicineCenters: TelemedicineCenter[] }>()
);

export const loadTelemedicineCentersFailure = createAction(
  '[Telemedicine Centers] Load Telemedicine Centers Failure',
  props<{ error: any }>()
);
