import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ApiService } from 'store/api/api.service';
import * as ArticlesActions from './articles.actions';

@Injectable()
export class ArticlesEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadArticles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ArticlesActions.loadArticles),
      switchMap(() =>
        this.apiService.getArticles().pipe(
          map((data) => ArticlesActions.loadArticlesSuccess({ articles: data.results })),
          catchError((error) => of(ArticlesActions.loadArticlesFailure({ error })))
        )
      )
    );
  });
}
