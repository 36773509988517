import { TestDataFactory, RANDOM_NUMBER, RANDOM_STRING } from './test-data.factory';
import { Medication } from 'app/models';

export class MedicationFactory extends TestDataFactory<Medication> {
  constructor() {
    const generatorFunction = (): Medication => {
      return {
        rate: 'daily',
        days: [],
        ends_on: null,
        id: RANDOM_NUMBER.getOne(30, 220),
        normal_name: RANDOM_STRING.getOne(5),
        chemical_agent: RANDOM_STRING.getOne(5),
        dose: RANDOM_NUMBER.getOne(1, 5).toString(),
        form: 'tablet',
        unit: 'mg',
        morning: RANDOM_NUMBER.getOne(1, 5).toString(),
        noon: RANDOM_NUMBER.getOne(1, 5).toString(),
        evening: RANDOM_NUMBER.getOne(1, 5).toString(),
        night: RANDOM_NUMBER.getOne(1, 5).toString(),
        reason: RANDOM_STRING.getOne(1, 5),
      };
    };

    super(generatorFunction);
  }
}
