import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  // eslint-disable-next-line
  selector: 'form',
})
export class FormDirective implements OnInit {
  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    // set action attribute for every form to block webView content-autofill.googleapi.com request
    this.renderer.setAttribute(this.element.nativeElement, 'action', '/search');
  }
}
