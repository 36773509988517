import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpContextToken, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EnvironmentService } from 'app/services/environment.service';
import * as ApiModels from 'app/models/api.model';
import { HttpCustomService } from './http-custom';

export const CACHE_IT = new HttpContextToken<boolean>(() => false);
export const FETCH_ONCE = new HttpContextToken<boolean>(() => false);
export const IGNORE_HTTP_ERROR = new HttpContextToken<number[]>(() => []);

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private httpCustom: HttpCustomService,
    private environment: EnvironmentService
  ) {}

  signIn(body: { email: string; password: string } | { qr_token: string }) {
    return this.httpCustom.post<ApiModels.SignInResponse>(
      `${this.environment.apiUrl}/patient/auth/sign_in`,
      body,
      {
        observe: 'response',
      }
    );
  }

  signOut() {
    return this.http.delete<ApiModels.SignOutResponse>(
      `${this.environment.apiUrl}/patient/auth/sign_out`
    );
  }

  deleteUser(exportData = '0') {
    return this.http.delete<ApiModels.SignOutResponse>(`${this.environment.apiUrl}/patient/auth`, {
      params: {
        data_export: exportData,
      },
    });
  }

  getMeasurements(from: string, to: string = from, cache = false, fetchOnce = false) {
    const params = new HttpParams().set('from', from).set('to', to);
    const context = new HttpContext().set(CACHE_IT, cache).set(FETCH_ONCE, fetchOnce);
    return this.http.get<{
      results: ApiModels.Measurement[];
      min_date: string;
    }>(`${this.environment.apiUrl}/patient/measurements`, { params, context });
  }

  updateMeasurement(id: number, body: ApiModels.ObservationResults) {
    return this.http.put(`${this.environment.apiUrl}/patient/measurements/${id}`, body);
  }

  addMeasurements(body: ApiModels.ObservationResults) {
    return this.http.post<ApiModels.AddMeasurementsResponse>(
      `${this.environment.apiUrl}/patient/measurements`,
      body
    );
  }

  getMonitoringGoals(cache = false) {
    const context = new HttpContext().set(CACHE_IT, cache);
    return this.http.get<ApiModels.MonitoringGoalsResponse>(
      `${this.environment.apiUrl}/patient/monitoring_goals`,
      { context }
    );
  }

  getSurveys() {
    return this.http.get<ApiModels.SurveysResponse>(`${this.environment.apiUrl}/surveys`);
  }

  getSurvey(id: string) {
    return this.http.get<ApiModels.Survey>(`${this.environment.apiUrl}/surveys/${id}`);
  }

  getMessages() {
    return this.http.get<ApiModels.MessagesResponse>(`${this.environment.apiUrl}/patient/messages`);
  }

  getMessage(id: string) {
    return this.http.get<ApiModels.Message>(`${this.environment.apiUrl}/patient/messages/${id}`);
  }

  getDocuments(
    queryParams: {
      from?: string;
      to?: string;
      categories?: string[];
      page?: number;
      pageSize?: 25 | 50 | 100;
    } = {},
    cache = false,
    fetchOnce = false
  ) {
    const context = new HttpContext().set(CACHE_IT, cache).set(FETCH_ONCE, fetchOnce);
    let params = new HttpParams();

    // set params
    Object.entries(queryParams).forEach(([key, value]) => {
      params = params.set(key, `${value}`);
    });

    return this.http.get<ApiModels.DocumentsResponse>(
      `${this.environment.apiUrl}/patient/documents`,
      { context, params }
    );
  }

  getDocument(id: number) {
    return this.http.get<ApiModels.Document>(`${this.environment.apiUrl}/patient/documents/${id}`);
  }

  getFile(url: string) {
    return this.http.get(`${url}`, { responseType: 'blob' }).pipe(map((f) => f));
  }

  deleteDocument(id: number) {
    return this.http.delete<ApiModels.ResponseObject>(
      `${this.environment.apiUrl}/patient/documents/${id}`
    );
  }

  uploadDocuments(description: string, originalDate: string, category: string, files: FileList) {
    const formData = new FormData();
    const keys = Object.keys(files);
    formData.append('description', description);
    formData.append('original_date', originalDate);
    formData.append('category', category);
    keys.forEach((k) => {
      formData.append('files[]', files[k as any]);
    });
    return this.http.post<ApiModels.ResponseObject>(
      `${this.environment.apiUrl}/patient/documents`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  getDocumentCategories() {
    return this.http.get<ApiModels.DocumentsCategoriesResponse>(
      `${this.environment.apiUrl}/patient/document_categories`
    );
  }

  getInfos() {
    return this.http.get<ApiModels.Patient>(`${this.environment.apiUrl}/patient/info`);
  }

  updateInfos(body: Partial<ApiModels.Patient>) {
    return this.http.patch<ApiModels.Patient>(`${this.environment.apiUrl}/patient/info`, body);
  }

  getMedications() {
    return this.http.get<{
      results: ApiModels.Medication[];
      total: number;
    }>(`${this.environment.apiUrl}/patient/medications`);
  }

  updateMedication(id: number, body: ApiModels.Medication) {
    return this.http.put<ApiModels.Medication>(
      `${this.environment.apiUrl}/patient/medications/${id}`,
      body
    );
  }

  createMedication(medication: ApiModels.Medication) {
    return this.http.post<ApiModels.Medication>(
      `${this.environment.apiUrl}/patient/medications`,
      medication
    );
  }

  deleteMedication(medicationId: number) {
    return this.http.delete<{ success: boolean }>(
      `${this.environment.apiUrl}/patient/medications/${medicationId}`
    );
  }

  getDiagnoses() {
    return this.http.get<{
      results: ApiModels.Diagnosis[];
      total: number;
    }>(`${this.environment.apiUrl}/patient/diagnoses`);
  }

  createDiagnosis(icdCode: string, nyhaStatus: number) {
    return this.http.put<ApiModels.Diagnosis>(
      `${this.environment.apiUrl}/patient/diagnoses/${icdCode}`,
      {
        nyha_status: nyhaStatus,
      }
    );
  }

  deleteDiagnosis(icdCode: string) {
    return this.http.delete<{ status: string }>(
      `${this.environment.apiUrl}/patient/diagnoses/${icdCode}`
    );
  }

  searchDiagnoses(search = '') {
    const params = new HttpParams().set('search', search);
    return this.http.get<{
      results: ApiModels.Diagnosis[];
      total: number;
    }>(`${this.environment.apiUrl}/diagnoses`, { params });
  }

  getTermsOfService() {
    return this.http.get<ApiModels.TermsOfService>(
      `${this.environment.apiUrl}/patient/terms_of_service`
    );
  }

  acceptTermsOfService() {
    return this.http.post<ApiModels.AcceptTermsOfServiceResponse>(
      `${this.environment.apiUrl}/patient/terms_of_service/accept`,
      {}
    );
  }

  getFormOptions() {
    return this.httpCustom.get<ApiModels.FormOptions>(`${this.environment.apiUrl}/form_options`);
  }

  getMedicalContacts() {
    return this.http.get<{
      results: ApiModels.MedicalContact[];
      total: number;
    }>(`${this.environment.apiUrl}/patient/medical_contacts`);
  }

  updateMedicalContact(id: number, body: ApiModels.MedicalContact) {
    return this.http.put<ApiModels.MedicalContact>(
      `${this.environment.apiUrl}/patient/medical_contacts/${id}`,
      body
    );
  }

  createMedicalContact(medicalContact: ApiModels.MedicalContact) {
    return this.http.post<ApiModels.MedicalContact>(
      `${this.environment.apiUrl}/patient/medical_contacts`,
      medicalContact
    );
  }

  deleteMedicalContact(medicationId: number) {
    return this.http.delete<ApiModels.ResponseObject>(
      `${this.environment.apiUrl}/patient/medical_contacts/${medicationId}`
    );
  }

  getMedicationIntakes(from: string, to: string = from, cache = false, fetchOnce = false) {
    const params = new HttpParams().set('from', from).set('to', to);
    const context = new HttpContext().set(CACHE_IT, cache).set(FETCH_ONCE, fetchOnce);
    return this.http.get<{
      results: ApiModels.MedicationIntake[];
      min_date: string;
    }>(`${this.environment.apiUrl}/patient/medication_intakes`, { params, context });
  }

  updateMedicationIntake(medicationIntake: ApiModels.MedicationIntake) {
    return this.http.post<ApiModels.MedicationIntake>(
      `${this.environment.apiUrl}/patient/medication_intakes`,
      medicationIntake
    );
  }

  registerUser(user: ApiModels.RegistrationUser) {
    return this.httpCustom.post<{
      status: 'success';
      data: any;
      errors?: { [key: string]: string[] };
    }>(`${this.environment.apiUrl}/patient/auth`, user);
  }

  confirmEmail(email: string, code: string) {
    return this.httpCustom.post<{
      success: boolean;
      message: string;
      errors?: string[];
    }>(`${this.environment.apiUrl}/patient/confirm_email`, { email, code });
  }

  resetPassword(email: string) {
    return this.httpCustom.post<{
      success: boolean;
      message: string;
      errors?: string[];
    }>(`${this.environment.apiUrl}/patient/auth/password`, { email });
  }

  changeEmail(email: string, password: string) {
    return this.http.post<{
      success: boolean;
      message: string;
      errors?: string[];
    }>(`${this.environment.apiUrl}/patient/change_email`, {
      email,
      password,
    });
  }

  changePassword(currentPassword: string, password: string, passwordConfirmation: string) {
    return this.http.post<{
      success: boolean;
      message: string;
      errors?: string[];
    }>(`${this.environment.apiUrl}/patient/change_password`, {
      current_password: currentPassword,
      password,
      password_confirmation: passwordConfirmation,
    });
  }

  changeDigaWarrantyStatus(status: boolean) {
    return this.http.patch<{
      accepted: boolean;
      accepted_at: string;
    }>(`${this.environment.apiUrl}/patient/diga_warranty`, {
      accepted: status,
    });
  }

  getArticles() {
    return this.http.get<{
      results: ApiModels.Article[];
      total: number;
    }>(`${this.environment.apiUrl}/patient/articles`);
  }

  createMedicalReport() {
    return this.http.post<{
      document: { id: number };
      status: string;
    }>(`${this.environment.apiUrl}/patient/medical_reports`, {});
  }

  getActivations() {
    return this.http.get<{
      total: number;
      results: ApiModels.Activation[];
      current_status: ApiModels.ActivationStatus;
    }>(`${this.environment.apiUrl}/patient/activations`);
  }

  addActivation(code: string, clean_patient_data: boolean) {
    const context = new HttpContext().set(IGNORE_HTTP_ERROR, [404, 422]);

    return this.http.post<ApiModels.Activation>(
      `${this.environment.apiUrl}/patient/activations`,
      { code, clean_patient_data },
      { context }
    );
  }

  checkAppSubscriptionCode(code: string) {
    const context = new HttpContext().set(IGNORE_HTTP_ERROR, [404]);

    return this.http.get<{
      category: string;
      code: string;
    }>(`${this.environment.apiUrl}/activation_codes/${code}`, { context });
  }

  requestDataExport() {
    return this.http.post<{
      status: string;
    }>(`${this.environment.apiUrl}/patient/data_exports`, {});
  }

  getThirdPartyLicenses() {
    return this.httpCustom.get('/3rdpartylicenses.txt', { responseType: 'text' });
  }

  getTelemedicineCenters() {
    return this.http.get<{
      total: number;
      results: ApiModels.TelemedicineCenter[];
    }>(`${this.environment.apiUrl}/patient/telemedicine_centers`);
  }

  getVerificationPublicKey() {
    return this.httpCustom.get('/assets/verification-key.txt', { responseType: 'text' });
  }

  getAppConfig() {
    return this.httpCustom.get<{
      auto_update: boolean;
      timestamp: string;
      signature: string;
    }>(`${this.environment.apiUrl}/app_config`);
  }
}
