import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fraction',
})
export class FractionPipe implements PipeTransform {
  transform(value: string): string {
    const arr = `${value}`.split('.');
    const fraction = arr[1] !== undefined ? this.getFraction(arr[1]) : null;
    return `${arr[0] === '0' && fraction ? '' : arr[0]}${fraction ? ' ' + fraction : ''}`;
  }

  private getFraction(fraction: string) {
    switch (fraction) {
      case '25':
        return '&frac14';

      case '5':
        return '&frac12';

      case '75':
        return '&frac34';

      default:
        return fraction;
    }
  }
}
