import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  OnDestroy,
  ChangeDetectorRef,
  Inject,
  LOCALE_ID,
  Input,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { Activation, ActivationStatus } from 'app/models';
import { State as ActivationState } from 'store/activations-store/activations.reducer';
import * as dayjs from 'dayjs';
import * as LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { SubscriptionCategoryPipe } from 'app/shared/pipe/subscription-category.pipe';
import { EventEmitter } from '@angular/core';

dayjs.extend(LocalizedFormat);
require('dayjs/locale/de');
require('dayjs/locale/fr');

@Component({
  selector: 'pro-global-notice',
  templateUrl: './global-notice.component.html',
  styleUrls: ['./global-notice.component.scss'],
  animations: [
    trigger('inOutModalAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.3s ease', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('0.3s ease', style({ opacity: 0 }))]),
    ]),
  ],
  providers: [SubscriptionCategoryPipe],
})
export class GlobalNoticeComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  @Input() set isOffline(value: boolean) {
    this.offlineStatus = value;
    this.setDisplayNoticeStatus();
  }

  @Input() set activationState(state: ActivationState) {
    this.currentActivations = Object.values(state.entities);
    this.currentActivationStatus = state.currentStatus;
    this.checkAppSubscriptions();
    this.setBluetoothNoticeVisibility();
  }

  @Input() set isBluetoothEnabled(value: boolean) {
    this.bluetoothDisabled = !value;
    this.setBluetoothNoticeVisibility();
  }

  @Input() set isLocationEnabledAndAvailable(value: boolean) {
    this.locationDisabled = !value;
    this.setBluetoothNoticeVisibility();
  }

  @Input() set hasDevicesConnected(value: boolean) {
    this.deviceConnected = value;
    this.setBluetoothNoticeVisibility();
  }

  @Input() set locationPermission(value: string) {
    this.permissionStatus = value;
  }

  @Output() requestLocationPermission: EventEmitter<void> = new EventEmitter();

  offlineStatus = true;
  showOfflineInfoModal = true;

  activationExpired = false;
  currentActivations: Activation[] = [];
  currentActivationStatus: ActivationStatus;
  activationNoticeHeadline = '';

  bluetoothDisabled = false;
  locationDisabled = false;
  showBluetoothNotices = false;
  deviceConnected = false;
  permissionStatus = '';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private subscriptionCategoryPipe: SubscriptionCategoryPipe,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit(): void {
    // In order to preload assets for global notice, add and remove offline-notice and-modal from DOM
    this.cdRef.detectChanges();
    this.offlineStatus = false;
    this.showOfflineInfoModal = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  requestPermission() {
    this.requestLocationPermission.emit();
  }

  private checkAppSubscriptions() {
    this.activationExpired = false;

    if (this.currentActivations?.length && this.currentActivationStatus !== undefined) {
      if ([null, 'active', 'enqueued'].includes(this.currentActivationStatus)) {
        if (this.currentActivationStatus === 'active') {
          const activeActivation = this.currentActivations.find(
            (activation) => activation.status === 'active'
          );
          const { category } = activeActivation;

          if (activeActivation) {
            // ignores certain statuses
            if (
              ['telemedicine_center', 'telemedicine_center_test', 'tmc_eheart'].includes(category)
            ) {
              return;
            }

            // checks for enqueued app-subscriptions
            const enqueuedActivation = this.currentActivations.find(
              (activation) => activation.status === 'enqueued'
            );

            if (enqueuedActivation) {
              return;
            }

            // shows a notice for the last three days in a active test-subscription and for the last 7 days for other categories
            const differenceToCheck = category === 'test' ? 3 : 7;
            const daysLeft = dayjs(activeActivation.end_date, 'YYYY-MM-DD').diff(dayjs(), 'd');

            if (daysLeft < differenceToCheck) {
              this.activationExpired = true;
              this.activationNoticeHeadline = $localize`${this.subscriptionCategoryPipe.transform(
                category
              )} läuft ab`;
            }
          }
        }
      } else {
        this.activationNoticeHeadline = $localize`Dein Abo ist abgelaufen!`;
        this.activationExpired = true;
      }
    }

    this.setDisplayNoticeStatus();
  }

  private setBluetoothNoticeVisibility() {
    // bluetooth and location infos are only displayed for logged-in users with connected devices
    this.showBluetoothNotices =
      this.currentActivationStatus !== null && this.deviceConnected === true;
    this.setDisplayNoticeStatus();
  }

  private setDisplayNoticeStatus() {
    let showNotice = false;

    if (
      this.activationExpired === true ||
      this.offlineStatus === true ||
      (this.bluetoothDisabled === true && this.showBluetoothNotices) ||
      (this.locationDisabled === true && this.showBluetoothNotices)
    ) {
      showNotice = true;
    } else {
      showNotice = false;
    }

    this.cdRef.detectChanges();
    let RouterOutletElement: Node;
    const siblings = this.el.nativeElement.parentNode.childNodes as NodeList;
    const newTopPos: number = this.el.nativeElement.offsetHeight
      ? this.el.nativeElement.offsetHeight
      : 96;

    siblings.forEach((c) => {
      if (c.nodeName.includes('ROUTER-OUTLET')) {
        RouterOutletElement = c;
      }
    });

    if (RouterOutletElement) {
      if (showNotice === true) {
        this.renderer.setStyle(RouterOutletElement, 'top', `${newTopPos}px`);
      } else {
        this.renderer.setStyle(RouterOutletElement, 'top', '0px');
      }
    }

    if (showNotice === true) {
      this.renderer.addClass(this.el.nativeElement, 'is-visible');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'is-visible');
    }
    this.cdRef.detectChanges();
  }
}
