<div class="scroll">
  <div class="content">
    <div class="ion-margin-bottom ion-text-center">
      <ion-icon
        src="assets/svgs/icon-status-help.svg"
        color="secondary"
        class="head-icon"
      ></ion-icon>
    </div>

    <h5 i18n>Dein Aktivierungscode zu ProHerz</h5>
    <p i18n>
      ProHerz ist derzeit in der Premiumversion nur mit einem Abo nutzbar. Zur Freischaltung
      benötigst Du einen Aktivierungscode.
    </p>

    <h5 i18n>Wo bekomme ich einen Aktivierungscode?</h5>
    <p i18n>
      Über unsere <a proLink="https://procarement.com/proherz">Webseite</a> kannst Du
      einen Aktivierungscode für ein Abo mit verschiedenen Laufzeiten erwerben.
    </p>
    <p i18n>
      Ist Dein aktuelles Abo abgelaufen, bekommst Du auf unserer
      <a proLink="https://procarement.com/proherz">Webseite</a> oder über unseren
      <a href="tel:+499191 95 04 311">Support</a> einen neuen Aktivierungscode.
    </p>
    <p
      *ngIf="!isAuthenticated; else elseBlock"
      i18n
    >
      Zur Verlängerung deines Abos kannst Du den Aktivierungscode direkt in der App unter “Mehr -
      Mein Benutzerkonto: Abos” eingeben.
    </p>
    <ng-template #elseBlock>
      <p i18n>
        Zur Verlängerung deines Abos kannst Du den Aktivierungscode
        <a
          [routerLink]="['/app/subscriptions-activation']"
          (click)="popoverController.dismiss()"
        >hier</a>
        eingeben.
      </p>
    </ng-template>

    <h5 i18n>„Ungültiger Aktivierungscode - was tun?</h5>
    <ul>
      <li i18n>
        Bitte überprüfe den von Dir eingegebenen Aktivierungscode auf die angegebene Gültigkeit und
        auf Tippfehler.
      </li>
      <li i18n>
        Ist der Aktivierungscode gültig und es wird dennoch ein Fehler ausgegeben, dann wende Dich
        bitte an unseren <a href="tel:+499191 95 04 311">Support</a>.
      </li>
    </ul>
  </div>
</div>
<footer>
  <ion-button
    class="left-top-border-radius"
    (click)="popoverController.dismiss()"
    i18n
  >OK</ion-button>
</footer>