import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as TelemedicineCenterActions from './telemedicine-centers.actions';
import * as ActivationsActions from 'app/app-store/activations-store/activations.actions';
import { ApiService } from 'store/api/api.service';

@Injectable()
export class TelemedicineCentersEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadTelemedicineCenters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TelemedicineCenterActions.loadTelemedicineCenters,
        ActivationsActions.addActivationSuccess
      ),
      switchMap(() =>
        this.apiService.getTelemedicineCenters().pipe(
          map((centersResponse) =>
            TelemedicineCenterActions.loadTelemedicineCentersSuccess({
              telemedicineCenters: centersResponse.results,
            })
          ),
          catchError((error) =>
            of(TelemedicineCenterActions.loadTelemedicineCentersFailure({ error }))
          )
        )
      )
    )
  );
}
