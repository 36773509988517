import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  environment = environment;
  apiUrl = environment.apiUrl;

  constructor() {}

  load() {
    const urlParams = new URLSearchParams(document.location.search);
    return of(urlParams.get('apiUrl'))
      .pipe(
        map((r) => {
          this.apiUrl = r ? r : this.apiUrl;
          return r;
        })
      )
      .toPromise();
  }
}
