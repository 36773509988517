<div class="scroll">
  <div class="content">
    <div class="ion-margin-bottom ion-text-center">
      <ion-icon
        src="assets/svgs/icon-status-help.svg"
        color="secondary"
        class="head-icon"
      ></ion-icon>
    </div>

    <h5 i18n>Die Verbindung der Geräte schlägt fehl</h5>
    <p i18n>
      Wenn die Verbindung zwischen App und Messgeräten trotz genauer Befolgung der Anleitung nicht
      funktioniert, überprüfe folgende Punkte:
    </p>

    <div class="ion-margin-bottom ion-text-center">
      <ion-icon
        src="assets/svgs/icon-section-bluetooth.svg"
        color="secondary"
        class="content-icon"
      ></ion-icon>
    </div>

    <ul>
      <li i18n>
        Überprüfe, ob die Batterien der Messgeräte noch ausreichen
      </li>
      <li i18n>
        Überprüfe, ob Bluetooth aktiviert ist
      </li>
      <li i18n>
        Deine App sollte geöffnet sein während der Messung.
      </li>
      <li i18n>
        Deine Standortzulassung für die App muss aktiviert sein.
      </li>
      <li i18n>
        Die Entfernung zwischen Deinem Handy und dem Messgerät darf nicht zu groß sein.
      </li>
    </ul>
    <a
      href="tel:+4991919504311"
      class="ion-margin-bottom"
    >
      <ng-container i18n>Wir helfen Dir gerne weiter</ng-container>
      <ion-icon
        name="call-outline"
        color="primary"
        class="ion-margin-start"
      ></ion-icon>
    </a>
  </div>
</div>
<footer>
  <ion-button
    class="left-top-border-radius"
    (click)="popoverController.dismiss()"
    i18n
  >OK</ion-button>
</footer>