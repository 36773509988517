import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line
  selector: 'ion-input',
})
export class ScrollOnFocusDirective {
  @HostListener('focusin') onFocus() {
    const targetElement = this.element?.nativeElement?.closest('ion-item');

    // waits until the keyboard was opened to get the current view-height
    if (targetElement) {
      setTimeout(() => {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }, 550);
    }
  }

  constructor(private element: ElementRef) {}
}
