<div class="scroll">
  <div class="content">
    <div class="ion-margin-bottom ion-text-center">
      <ion-icon
        src="assets/svgs/icon-status-help.svg"
        color="primary"
        class="head-icon"
      ></ion-icon>
    </div>

    <h5 i18n>"Ungültiger Code" - Was tun?</h5>
    <p i18n>Bitte unternimm folgende Schritte:</p>
    <ul>
      <li i18n>Überprüfe den eingegebenen Aktivierungscode auf <b>Tippfehler</b>.</li>
      <li i18n>Entferne <b>überflüssige Leerzeichen</b> im eingegebenen Freischaltcode.</li>
      <li i18n>Überprüfe die Gültigkeit des Codes.</li>
    </ul>

    <p i18n>Führen die angegebenen Punkte nicht zum Erfolg, wende Dich bitte an unseren <a
        [routerLink]="['/app/modal/support']"
        (click)="popoverController.dismiss()"
      >Support</a>.</p>
  </div>
</div>
<footer>
  <ion-button
    class="left-top-border-radius"
    (click)="popoverController.dismiss()"
    i18n
  >OK</ion-button>
</footer>