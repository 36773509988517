export class TestDataFactory<T> {
  constructor(private generatorFn: () => T) {}

  getOne(override = {}): T {
    return {
      ...this.generatorFn(),
      ...override,
    } as T;
  }

  getMany(length = 20, overrides: any[] = []): T[] {
    return Array.from(new Array(length)).map((_, i) => this.getOne(overrides[i]));
  }

  extend(newGeneratorFn: (baseData: T) => Partial<T>) {
    return new TestDataFactory<any>(() => newGeneratorFn(this.generatorFn()));
  }
}

/**
 * General test-data helper
 */
class RandomDataFactory<T> {
  constructor(private generatorFn: (...args: any[]) => T) {}

  getOne(...args: any[]): T {
    return this.generatorFn(...args);
  }

  getMany(length = 20, ...args: any[]): T[] {
    return Array.from(new Array(length)).map(() => this.getOne(...args));
  }
}

export const RANDOM_STRING = new RandomDataFactory<string>((length = 4) => {
  const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return Array.from(new Array(length))
    .map(() => Math.floor(Math.random() * 52))
    .reduce((word, i) => word + letters[i], '');
});

export const RANDOM_NUMBER = new RandomDataFactory<number>((min = 0, max = 100) => {
  /* eslint-disable @typescript-eslint/restrict-plus-operands */
  return Math.floor(Math.random() * (max - min + 1) + +min);
  /* eslint-enable @typescript-eslint/restrict-plus-operands */
});

export const RANDOM_BOOLEAN = new RandomDataFactory<boolean>(
  (falseThreshold = 0.5) => Math.random() > falseThreshold
);

export const RANDOM_OBJECT = new RandomDataFactory<any>(
  (propCount = 4): Record<string, string | number | boolean>[] => {
    return Array.from(new Array(propCount)).reduce((obj, _, i): any => {
      const fieldName = `field${i}_${RANDOM_STRING.getOne()}`;
      const fieldType = [RANDOM_STRING, RANDOM_NUMBER, RANDOM_BOOLEAN][RANDOM_NUMBER.getOne(0, 2)];

      const isArray = RANDOM_BOOLEAN.getOne();

      obj[fieldName] = isArray
        ? fieldType.getMany(RANDOM_NUMBER.getOne(0, 20))
        : fieldType.getOne();
      return obj;
    }, {});
  }
);
