import { Pipe, PipeTransform } from '@angular/core';
import { MeasurementType } from 'app/models';

@Pipe({
  name: 'measurementTypeTranslation',
})
export class MeasurementTypeTranslationPipe implements PipeTransform {
  transform(
    value: MeasurementType | 'survey' | string,
    type: 'default' | 'reverse' = 'default'
  ): string {
    return type === 'default'
      ? this.measurmentTypeTranslation(value)
      : this.measurmentTypeTranslationReverse(value);
  }

  private measurmentTypeTranslation(type: MeasurementType | 'survey' | string) {
    switch (type) {
      case 'blood_pressure':
        return $localize`:@@bloodPressure:Blutdruck`;

      case 'ecg':
        return $localize`:@@ecg:EKG`;

      case 'heart_rate':
        return $localize`:@@heartRate:Herzfrequenz`;

      case 'oxygen_saturation':
        return $localize`:@@oxygenSaturation:Sauerstoffsättigung`;

      case 'temperature':
        return $localize`:@@temperature:Temperatur`;

      case 'weight':
        return $localize`:@@weight:Gewicht`;

      case 'survey':
        return $localize`:@@survey:Fragebogen`;

      default:
        return type;
    }
  }

  private measurmentTypeTranslationReverse(value: string) {
    switch (value.toLowerCase()) {
      case $localize`:@@bloodPressure_lowerCase:blutdruck`:
        return 'blood_pressure';

      case $localize`:@@ecg_lowerCase:ekg`:
        return 'ecg';

      case $localize`:@@heartRate_lowerCase:herzfrequenz`:
        return 'heart_rate';

      case $localize`:@@oxygenSaturation_lowerCase:sauerstoffsättigung`:
        return 'oxygen_saturation';

      case $localize`:@@temperature_lowerCase:temperatur`:
        return 'temperature';

      case $localize`:@@weight_lowerCase:gewicht`:
        return 'weight';

      case $localize`:@@survey_lowerCase:fragebogen`:
        return 'survey';

      default:
        return value;
    }
  }
}
