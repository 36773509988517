import {
  Action,
  createReducer,
  on,
  createFeatureSelector,
  createSelector,
  resultMemoize,
  createSelectorFactory,
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import { isEqual } from 'lodash-es';
import { Medication, MedicationIntake, MedicationWithIntakes } from 'app/models';
import * as fromRouterSelectors from 'store/router-store/router.selectors';
import * as dayjs from 'dayjs';
import * as fromMedicationIntakes from 'store/medication-intakes-store/medication-intakes.reducer';
import * as MedicationsActions from './medications.actions';
import { AnyFn } from '@ngrx/store/src/selector';

export const medicationsFeatureKey = 'medications';

export interface State extends EntityState<Medication> {
  loading: boolean;
  error: any | null;
}

export const adapter: EntityAdapter<Medication> = createEntityAdapter<Medication>();

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});

const medicationsReducer = createReducer(
  initialState,
  on(
    MedicationsActions.loadMedications,
    MedicationsActions.updateMedication,
    MedicationsActions.createMedication,
    MedicationsActions.deleteMedication,
    (state) => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(MedicationsActions.loadMedicationsSuccess, (state, action) =>
    adapter.setAll(action.medications, state)
  ),
  on(MedicationsActions.updateMedicationSuccess, (state, action) =>
    adapter.upsertOne(action.medication, state)
  ),
  on(MedicationsActions.createMedicationSuccess, (state, action) =>
    adapter.addOne(action.medication, state)
  ),
  on(MedicationsActions.deleteMedicationSuccess, (state, action) =>
    adapter.removeOne(action.medicationId, state)
  ),
  on(
    MedicationsActions.loadMedicationsSuccess,
    MedicationsActions.loadMedicationsFailure,
    MedicationsActions.updateMedicationSuccess,
    MedicationsActions.updateMedicationFailure,
    MedicationsActions.createMedicationSuccess,
    MedicationsActions.createMedicationFailure,
    MedicationsActions.deleteMedicationSuccess,
    MedicationsActions.deleteMedicationFailure,
    (state) => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),
  on(
    MedicationsActions.loadMedicationsFailure,
    MedicationsActions.updateMedicationFailure,
    MedicationsActions.createMedicationFailure,
    MedicationsActions.deleteMedicationFailure,
    (state, action) => ({ ...state, error: action.error })
  )
);

export const selectFeature = createFeatureSelector<State>('medications');

export function reducer(state: State | undefined, action: Action) {
  return medicationsReducer(state, action);
}

export const customMemoized = (projectorFn: AnyFn) => resultMemoize(projectorFn, isEqual);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);

export const selectError = createSelector(selectFeature, (state): any => state.error);

export const selectMedication = (props: { id: number }) =>
  createSelector(selectEntities, (entities: Dictionary<Medication>) => entities[props.id]);

export const selectMorningMedications = createSelector(selectAll, (entities) =>
  entities.filter((e) => +e.morning > 0)
);

export const selectNoonMedications = createSelector(selectAll, (entities) =>
  entities.filter((e) => +e.noon > 0)
);

export const selectEveningMedications = createSelector(selectAll, (entities) =>
  entities.filter((e) => +e.evening > 0)
);

export const selectNightMedications = createSelector(selectAll, (entities) =>
  entities.filter((e) => +e.night > 0)
);

export const selectOnDemandMedications = createSelector(selectAll, (entities) =>
  entities.filter((e) => e.rate === 'on_demand').length > 0
    ? entities.filter((e) => e.rate === 'on_demand')
    : null
);

export const selectMedicationByIdFromQueryParam = createSelectorFactory(customMemoized)(
  selectEntities,
  fromRouterSelectors.selectRouteParam('id'),
  (entities: Dictionary<Medication>, id: string) => entities[+id]
);

export const selectOnDemandMedicationsWithIntakes = createSelectorFactory<
  State,
  MedicationWithIntakes[]
>(customMemoized)(
  selectOnDemandMedications,
  fromRouterSelectors.selectQueryParam('date'),
  fromMedicationIntakes.selectEntities,
  (medications: Medication[], date: string, entities: Dictionary<MedicationIntake>) => {
    return medications?.length > 0
      ? medications.map((medication) => {
          const idPrefix = `${medication.id}|${date || dayjs().format('YYYY-MM-DD')}`;
          return {
            ...medication,
            intakes: {
              morning: entities[`${idPrefix}|morning`],
              noon: entities[`${idPrefix}|noon`],
              evening: entities[`${idPrefix}|evening`],
              night: entities[`${idPrefix}|night`],
            },
          };
        })
      : null;
  }
);
