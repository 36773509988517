import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Medication } from 'app/models';
import * as MedicationsActions from './medications.actions';
import * as fromMedications from './medications.reducer';

@Injectable({
  providedIn: 'root',
})
export class MedicationsFacade {
  loading$ = this.store.pipe(select(fromMedications.selectLoading));
  error$ = this.store.pipe(select(fromMedications.selectError));
  medications$ = this.store.pipe(select(fromMedications.selectAll));
  medicationByIdFromQueryParam$ = this.store.pipe(
    select(fromMedications.selectMedicationByIdFromQueryParam)
  );
  medicationsOnDemand$ = this.store.pipe(select(fromMedications.selectOnDemandMedications));
  medicationsOnDemandWithIntakes$ = this.store.pipe(
    select(fromMedications.selectOnDemandMedicationsWithIntakes)
  );

  constructor(private store: Store<fromMedications.State>) {}

  loadMedications() {
    this.store.dispatch(MedicationsActions.loadMedications());
  }

  updateMedication(medication: Medication) {
    this.store.dispatch(MedicationsActions.updateMedication({ medication }));
  }

  createMedication(medication: Medication) {
    this.store.dispatch(MedicationsActions.createMedication({ medication }));
  }

  deleteMedication(medicationId: number) {
    this.store.dispatch(MedicationsActions.deleteMedication({ medicationId }));
  }

  getMedication(id: number) {
    return this.store.pipe(select(fromMedications.selectMedication({ id })));
  }
}
