/* eslint-disable @typescript-eslint/no-this-alias */
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  SimpleChanges,
  OnChanges,
  ElementRef,
} from '@angular/core';
import * as d3 from 'd3';
import * as d3Selection from 'd3-selection';
import { ChartPage } from '../chart.component';
import { OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'pro-base-chart',
  templateUrl: './base-chart.component.html',
  styleUrls: ['./base-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseChartComponent implements OnInit, OnChanges, OnDestroy {
  hostElement: HTMLElement;
  svgChart: d3Selection.Selection<d3Selection.BaseType, any, HTMLElement, any>;
  backgroundGroup: d3Selection.Selection<d3Selection.BaseType, any, HTMLElement, any>;
  pagesGroup: d3Selection.Selection<d3Selection.BaseType, any, HTMLElement, any>;
  svgRightAxis: d3Selection.Selection<d3Selection.BaseType, any, HTMLElement, any>;
  rightAxisGroup: d3Selection.Selection<d3Selection.BaseType, any, HTMLElement, any>;
  activeTooltip: number = null; // page-item-index of currently opened tooltip

  conf = {
    defaultAnimationDuration: 350,
    fontColor: 'var(--pro-color-zircon-night)',
    gridColor: 'var(--ion-color-medium)',
    graphStrokeColor: 'var(--pro-color-zircon-night)',
    graphStrokeWidth: 3,
    svgBackgroundColor: 'var(--pro-color-hawkes-blue)',
    svgWidth: 350,
    svgHeight: 250,
    rightAxisWitdh: 0,
    graphWidth: 0,
    markerBackgroundColor: 'var(--pro-color-hawkes-blue)',
    markerDefaultColor: 'var(--pro-color-zircon-night)',
    markerSuccessColor: 'var(--ion-color-secondary)',
  };

  // Store already rendered pages to avoid unnecessary redraws
  renderedPages: ChartPage[] = [];

  @Input() pages: ChartPage[] = [];

  @Input() totalPages: number;

  @Input() itemsPerPage = 7;

  @Input() currentPage = 1;

  @Input() identifier: string;

  xScale = d3.scaleLinear().range([0, this.conf.svgWidth]);
  yScale = d3.scaleLinear().range([this.conf.svgHeight - 30, 30]);

  lineGenerator = d3
    .line<number[]>()
    .x((d) => this.xScale(d[0]))
    .y((d) => this.yScale(d[1]));

  // Bind method-call to remove the event-listener correctly
  orientationChangeBinding: () => void = this.orientationChanged.bind(this);

  constructor(private elRef: ElementRef) {
    this.hostElement = this.elRef.nativeElement;
  }

  ngOnInit(): void {
    window.addEventListener('orientationchange', this.orientationChangeBinding, true);
  }

  ngOnDestroy(): void {
    window.removeEventListener('orientationchange', this.orientationChangeBinding, true);
  }

  ngOnChanges(changes: SimpleChanges) {
    // First init or time-range changed
    // Re-Init chart and draw pages
    if (
      changes?.identifier?.currentValue &&
      changes.identifier.currentValue !== changes.identifier?.previousValue
    ) {
      this.setupChart();
    }

    // Current page changed. Invoke scroll and hide tooltip
    if (
      changes?.currentPage?.currentValue &&
      changes.currentPage.currentValue !== changes.currentPage?.previousValue
    ) {
      // No animation if first load or page-gap is bigger than one (happens if a specific date was loaded or on time-range change)
      !changes?.currentPage?.previousValue ||
      Math.abs(changes?.currentPage?.currentValue - changes?.currentPage?.previousValue) > 1
        ? this.scrollToPage(0)
        : this.scrollToPage();

      this.hideTooltip();
    }
    // Pages to draw changed. redraw new pages
    if (changes?.pages?.currentValue) {
      this.updateScaleFunctions();

      this.pages.map((page) => {
        this.drawPage(page);
      });
    }
  }

  /**
   * Handles a device orientation change.
   * Windows width is not immediately updated after orientation changed.
   * Wait for resize-event to get the current window-width.
   */
  orientationChanged() {
    const that = this;
    const resizeHandler = () => {
      window.removeEventListener('resize', resizeHandler, true);
      that.setupChart();
    };
    window.addEventListener('resize', resizeHandler, true);
  }

  /**
   * Invoke methods to redraw/init the chart completely
   */
  setupChart() {
    d3.select(this.hostElement).selectAll('svg').remove();
    this.renderedPages = [];

    this.initSVG();
    this.updateScaleFunctions();

    this.hideTooltip(0);
    this.scrollToPage(0);

    this.pages.map((page) => {
      this.drawPage(page);
    });

    this.svgChart?.transition().duration(this.conf.defaultAnimationDuration).style('opacity', 1);
  }

  /**
   * Creates a svg to host the chart.
   * Chart width depends on the current screen size.
   * Appends common (svg)groups
   */
  initSVG() {
    this.conf.svgWidth = Math.floor(window.innerWidth - window.innerWidth * 0.066) - 1;
    this.conf.graphWidth = this.conf.svgWidth - this.conf.rightAxisWitdh;

    /**
     * Selects component as host-element and append root SVG
     */
    this.svgChart = d3
      .select(this.hostElement)
      .append('svg')
      .attr('id', 'chartSVG')
      .attr('width', this.conf.graphWidth)
      .attr('height', this.conf.svgHeight)
      .attr('viewBox', `0 0 ${this.conf.graphWidth} ${this.conf.svgHeight}`)
      .style('opacity', 0);

    /**
     * Appends group for background elements
     */
    this.backgroundGroup = this.svgChart
      .append('g')
      .attr('class', 'background-group')
      .attr('transform', 'translate(0, 0)');

    this.backgroundGroup.append('g').attr('class', 'lines');
    this.backgroundGroup.append('g').attr('class', 'areas');

    /**
     * Appends group for pages
     */
    this.pagesGroup = this.svgChart
      .append('g')
      .attr('class', 'pages-group')
      .attr('width', this.conf.graphWidth * this.totalPages)
      .attr('transform', 'translate(0, 0)');
  }

  /**
   * Updates d3js-functions to calc x-positions depending on the current graph-width
   */
  updateScaleFunctions() {
    this.xScale.range([0, this.conf.graphWidth]);
    this.xScale.domain([0, this.itemsPerPage]);
  }

  /**
   * Invokes methods to draw a single chart-page
   * @param chartPage page to draw
   */
  drawPage(chartPage: ChartPage) {
    this.drawPageBasics(chartPage);
  }

  /**
   * Draws basic page elements such as vertical background lines and legend labels
   * Ignores already existing pages
   * @param chartPage page to draw
   */
  drawPageBasics(chartPage: ChartPage) {
    // Draws legend and vertical lines
    if (this.pagesGroup.select(`.page-${chartPage.pageNumber}`).empty()) {
      const that = this;

      const verticalLineGenerator = d3
        .line<number[]>()
        .x((d) => this.xScale(d[0]))
        .y((d) => d[1]);

      // X-position for the page-group within the pages-group
      const pagePosition = -((chartPage.pageNumber - 1) * this.conf.graphWidth);

      const page = this.pagesGroup
        .append('g')
        .attr('class', `page-${chartPage.pageNumber}`)
        .attr('transform', `translate(${pagePosition}, 0)`);

      // Creates and appends vertical background lines
      const verticalLines = page.append('g').attr('class', 'vertical-lines');
      verticalLines
        .selectAll('.vertical-line')
        .data(chartPage.items)
        .join('path')
        .attr('d', (d, i) =>
          verticalLineGenerator([
            [i + 0.5, that.conf.svgHeight - 15],
            [i + 0.5, 0],
          ])
        )
        .attr('class', 'vertical-line')
        .attr('stroke', this.conf.gridColor)
        .attr('fill', 'none');

      // Create and appends legend-labels
      const legends = page.append('g').attr('class', 'legend-container');
      legends
        .selectAll('.legend-label')
        .data(chartPage.items)
        .join('text')
        .attr('class', 'legend-item')
        .attr('fill', (d) => (d.showLegend ? this.conf.fontColor : 'transparent'))
        .attr('text-anchor', 'middle')
        .attr('x', (d, i) => that.xScale(i + 0.5))
        .attr('y', that.conf.svgHeight - 4)
        .attr('font-family', 'sans-serif')
        .attr('font-size', '9px')
        .attr('font-weight', 'bold')
        .text((d) => d.legend);

      // Appends Groups for markers and chart lines
      page.append('g').attr('class', 'graph-container');
      page.append('g').attr('class', 'marker-container');
    }
  }

  /**
   * Moves (scrolls) the chart-group to the current page position
   * @param duration Time in ms the scroll-animation should take
   */
  scrollToPage(duration = this.conf.defaultAnimationDuration) {
    const offset = this.conf.graphWidth * (this.currentPage - 1);

    this.pagesGroup.transition().duration(duration).attr('transform', `translate(${offset}, 0)`);
  }

  /**
   * Method-Skeleton for creating and displaying a tooltip
   * @param x Index of the clicked item within the given page
   * @param y Y-Position where the click-event did happen
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showTooltip(index: number, yPosition: number) {}

  /**
   * Hides the tooltip
   * Resets active marker
   */
  hideTooltip(duration = this.conf.defaultAnimationDuration) {
    return new Promise((resolve) => {
      if (this.activeTooltip === null) {
        resolve(true);
      }
      this.activeTooltip = null;

      this.pagesGroup
        .selectAll(`.page-${this.currentPage} .marker-container .tooltip-active`)
        .classed('tooltip-active', false);

      this.pagesGroup
        .selectAll(`.page-${this.currentPage} .marker-container .inner-marker`)
        .transition()
        .duration(duration)
        .style('fill', this.conf.markerDefaultColor);

      const tooltip = d3.select(this.hostElement).select('.tooltip');

      tooltip
        .transition()
        .style('opacity', 0)
        .duration(duration)
        .on('end', () => {
          tooltip.style('left', '-500px');
          tooltip.selectAll('.labels span').remove();
          resolve(true);
        });
    });
  }
}
