<div class="period-selection">
  <ul class="range-list">
    <li
      class="pointer"
      [ngClass]="{'active': selectedItemRange === 'day'}"
      (click)="changeTimeRange('day');"
      i18n
    >1 Woche
    </li>
    <li
      class="pointer"
      [ngClass]="{'active': selectedItemRange === 'week'}"
      (click)="changeTimeRange('week');"
      i18n
    >3 Monate
    </li>
    <li
      class="pointer"
      [ngClass]="{'active': selectedItemRange === 'month'}"
      (click)="changeTimeRange('month');"
      i18n
    >1 Jahr
    </li>
  </ul>
</div>

<div class="chart-container">
  <ng-container *ngIf="chartData">
    <pro-line-chart
      *ngIf="chartData.monitoringGoal.type !== 'EcgGoal'"
      [pages]="pagesToDraw"
      [totalPages]="totalPages"
      [itemsPerPage]="timeRangeSettings[selectedItemRange].itemsPerPage"
      [currentPage]="currentPage"
      [extremas]="resultsSummary"
      [indicators]="pageIndicators"
      [identifier]="selectedItemRange"
    ></pro-line-chart>

    <pro-icon-chart
      *ngIf="chartData.monitoringGoal.type === 'EcgGoal'"
      [pages]="pagesToDraw"
      [totalPages]="totalPages"
      [itemsPerPage]="timeRangeSettings[selectedItemRange].itemsPerPage"
      [currentPage]="currentPage"
      [identifier]="selectedItemRange"
      (navigateToDocument)="navigateToDocument($event)"
    ></pro-icon-chart>
  </ng-container>
</div>

<div class="date-range-nav">
  <ng-container *ngIf="pagesToDraw.length">
    <span
      class="nav-trigger prev pointer"
      role="button"
      aria-label="Zeitraum zurück"
      i18n-aria-label
    ><span
        *ngIf="showPrev === true"
        (click)="navigateChart('prev')"
      >
        <ion-icon
          name="chevron-back-outline"
          color="primary"
        ></ion-icon>
      </span></span>
    <span class="current-range">{{ displayedRange }}</span>

    <span
      class="nav-trigger next pointer"
      role="button"
      aria-label="Zeitraum vor"
      i18n-aria-label
    ><span
        *ngIf="showNext === true"
        (click)="navigateChart('next')"
      >
        <ion-icon
          name="chevron-forward-outline"
          color="primary"
        ></ion-icon>
      </span></span>
  </ng-container>
</div>