import { MedicalContact } from 'app/models';
import { TestDataFactory, RANDOM_NUMBER, RANDOM_STRING, RANDOM_BOOLEAN } from './test-data.factory';

export class MedicalContactFactory extends TestDataFactory<MedicalContact> {
  constructor() {
    const generatorFunction = () => {
      return {} as MedicalContact;
    };
    super(generatorFunction);
  }

  getOne(override: Partial<MedicalContact> = {}): MedicalContact {
    const firstname = 'firstname_' + RANDOM_STRING.getOne(8);

    const basicValues = {
      id: RANDOM_NUMBER.getOne(1, 124),
      // firstname: 'name ' + RANDOM_STRING.getOne(8),
      name: 'name_' + RANDOM_STRING.getOne(8),
      title: 'title_' + RANDOM_STRING.getOne(8),
      address: {
        city: 'city_' + RANDOM_STRING.getOne(5),
        street: 'street_' + RANDOM_STRING.getOne(5),
        street_2: 'street_2_' + RANDOM_STRING.getOne(5),
        zip_code: RANDOM_NUMBER.getOne(10000, 99999).toString(),
      },
      phone_numbers: [
        {
          type: 'mobile',
          number: `${RANDOM_NUMBER.getOne(2000, 9999)} ${RANDOM_NUMBER.getOne(
            20,
            99
          )} ${RANDOM_NUMBER.getOne(2000, 9999)}`,
        },
      ],
    };

    const contactType = override?.contact_type;

    if (!contactType || contactType === 'family') {
      return super.getOne({
        ...basicValues,
        contact_type: 'family',
        firstname,
        relationship_type: 'relation_' + RANDOM_STRING.getOne(5),
        legal_advisor: RANDOM_BOOLEAN.getOne(),
        power_of_attorney: RANDOM_BOOLEAN.getOne(),
        ...override,
      });
    } else if (contactType === 'physician') {
      return super.getOne({
        ...basicValues,
        contact_type: 'physician',
        firstname,
        enable_data_sharing: RANDOM_BOOLEAN.getOne(),
        lanr: '',
        ...override,
      });
    } else if (contactType === 'hospital') {
      return super.getOne({
        ...basicValues,
        contact_type: 'hospital',
        ...override,
      });
    }
  }
}
