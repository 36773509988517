import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { ObservationResultSurvey } from 'app/models/api.model';

@Component({
  selector: 'pro-questionnaire-result',
  templateUrl: './questionnaire-result.component.html',
  styleUrls: ['./questionnaire-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnaireResultComponent implements OnInit {
  result: ObservationResultSurvey;
  surveyTitle: string;
  averageScore: number;

  constructor(private router: Router) {}

  ngOnInit() {
    if (history.state.result && history.state.surveyTitle) {
      this.result = history.state.result;
      this.surveyTitle = history.state.surveyTitle;
      this.averageScore = this.getAverageScore(this.result);
    } else {
      this.router.navigate(['']);
    }
  }

  getAverageScore(result: ObservationResultSurvey): number {
    let scoreSum = 0;
    result.feedbacks
      .map((f) => f.score)
      .forEach((score) => {
        scoreSum = scoreSum + score;
      });
    return Math.round(scoreSum / result.feedbacks.length);
  }
}
