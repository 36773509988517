import { createAction, props } from '@ngrx/store';
import { MedicalContact } from 'app/models';

export const loadMedicalContacts = createAction('[MedicalContacts/API] Load Medical Contacts');

export const loadMedicalContactsSuccess = createAction(
  '[MedicalContacts/API] Load MedicalContactsSuccess',
  props<{ medicalContacts: MedicalContact[] }>()
);

export const loadMedicalContactsFailure = createAction(
  '[MedicalContacts/API] Load MedicalContactsFailure',
  props<{ error: any }>()
);

export const updateMedicalContact = createAction(
  '[MedicalContact/API] Update MedicalContact',
  props<{ medicalContact: MedicalContact }>()
);

export const updateMedicalContactSuccess = createAction(
  '[MedicalContact/API] Update MedicalContact Success',
  props<{ medicalContact: MedicalContact }>()
);

export const updateMedicalContactFailure = createAction(
  '[MedicalContact/API] Update MedicalContact Failure',
  props<{ error: any }>()
);

export const createMedicalContact = createAction(
  '[MedicalContact/API] Create MedicalContact',
  props<{ medicalContact: MedicalContact }>()
);

export const createMedicalContactSuccess = createAction(
  '[MedicalContact/API] Create MedicalContact Success',
  props<{ medicalContact: MedicalContact }>()
);

export const createMedicalContactFailure = createAction(
  '[MedicalContact/API] Create MedicalContact Failure',
  props<{ error: any }>()
);

export const deleteMedicalContact = createAction(
  '[MedicalContact/API] Delete MedicalContact',
  props<{ medicalContactId: number }>()
);

export const deleteMedicalContactSuccess = createAction(
  '[MedicalContact/API] Delete MedicalContact Success',
  props<{ medicalContactId: number }>()
);

export const deleteMedicalContactFailure = createAction(
  '[MedicalContact/API] Delete MedicalContact Failure',
  props<{ error: any }>()
);
