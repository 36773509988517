<div
  *ngIf="showLocationInfo === true"
  class="container"
  [ngClass]="{'icon-only': iconOnly}"
>
  <p
    *ngIf="iconOnly === false"
    aria-label="Für die Nutzung der automatischen Messwertübertragung ist eine Erlaubnis zur Standortzulassung notwendig."
    i18n-aria-label
    class="info-text"
    i18n
  >
    Für die Nutzung der automatischen Messwertübertragung ist eine
    Erlaubnis zur Standortzulassung notwendig.
  </p>

  <div
    class="settings-trigger"
    (click)="openLocationSettings()"
    data-cy="open-location-settings-trigger"
  >
    <ion-icon
      [src]="iconOnly ? 'assets/svgs/arrow-right.svg' : 'assets/svgs/settings.svg'"
      color="primary"
    ></ion-icon>
    <ng-container
      *ngIf="iconOnly === false"
      i18n
    >Zu den Standort-Einstellungen</ng-container>
  </div>
</div>