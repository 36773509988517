import { Injectable } from '@angular/core';
import { BmEffects } from '../bm.effects';
import * as Bm64Actions from './bm64.actions';
import * as fromBm64 from './bm64.reducer';

@Injectable()
export class Bm64Effects {
  addState$ = this.bmEffects.createAddState$(Bm64Actions);
  loadDeviceInformation$ = this.bmEffects.createLoadDeviceInformation$(Bm64Actions);
  loadMeasurements$ = this.bmEffects.createLoadMeasurements$(Bm64Actions, fromBm64);
  deleteDevice$ = this.bmEffects.createDeleteDevice$(Bm64Actions, fromBm64);
  disconnectDevice$ = this.bmEffects.createDisconnectDevice$(Bm64Actions, fromBm64);

  constructor(private bmEffects: BmEffects) {}
}
