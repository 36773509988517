import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
@Component({
  selector: 'pro-bluetooth-permission-info',
  templateUrl: './bluetooth-permission-info.component.html',
  styleUrls: ['./bluetooth-permission-info.component.scss'],
})
export class BluetoothPermissionInfoComponent {
  constructor(public popoverController: PopoverController) {}
}
