import { LocalNotificationSchema } from '@capacitor/local-notifications';

export enum NotificationType {
  'Action' = 'ActionNotification',
  'App' = 'AppNotification',
  'Measurement' = 'MeasurementNotification',
  'Medication' = 'MedicationIntakeNotification',
  'Subscription' = 'SubscriptionNotification',
  'Custom' = 'CustomNotification',
}

export type ILocalNotificationType = Omit<LocalNotificationSchema, 'id'>;
