import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import {
  withLatestFrom,
  switchMap,
  map,
  catchError,
  concatMap,
  mergeMap,
  takeUntil,
  delay,
} from 'rxjs/operators';
import { differenceBy } from 'lodash-es';
import { BmService } from './bm.service';
import { BluetoothHelperService } from '../bluetooth-helper.service';
import { BmResultBloodPressure } from '../devices.model';
import { isInvalidDate } from '../devices.helper';
import * as Bm54Actions from './bm54-store/bm54.actions';
import * as fromBm54 from './bm54-store/bm54.reducer';
import * as Bm57Actions from './bm57-store/bm57.actions';
import * as fromBm57 from './bm57-store/bm57.reducer';
import * as Bm64Actions from './bm64-store/bm64.actions';
import * as fromBm64 from './bm64-store/bm64.reducer';
import * as dayjs from 'dayjs';

@Injectable({ providedIn: 'root' })
export class BmEffects {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private actions$: Actions,
    private store: Store<fromBm54.State | fromBm57.State>,
    private bluetoothHelper: BluetoothHelperService,
    private bmService: BmService
  ) {}

  createAddState$(actions: typeof Bm54Actions | typeof Bm57Actions | typeof Bm64Actions) {
    return createEffect(() => {
      return this.actions$.pipe(
        ofType(actions.addState),
        map((action) =>
          !action.state.address ? actions.addStateSuccess() : actions.startScanning()
        )
      );
    });
  }

  createLoadDeviceInformation$(
    actions: typeof Bm54Actions | typeof Bm57Actions | typeof Bm64Actions
  ) {
    return createEffect(() => {
      return this.actions$.pipe(
        ofType(actions.loadDeviceInformation),
        mergeMap((action) =>
          this.bluetoothHelper.isInitializedInitialize().pipe(
            concatMap(() => this.bluetoothHelper.connectReconnectDiscover(action.address)),
            switchMap(() =>
              this.bluetoothHelper.getDeviceInformation(action.address).pipe(
                map((deviceInformation) =>
                  actions.loadMeasurements({ address: action.address, deviceInformation })
                ),
                catchError((error) => of(actions.loadDeviceInformationFailure({ error })))
              )
            ),
            takeUntil(this.ngUnsubscribe$)
          )
        )
      );
    });
  }

  createLoadMeasurements$(
    actions: typeof Bm54Actions | typeof Bm57Actions | typeof Bm64Actions,
    fromReducer: typeof fromBm54 | typeof fromBm57 | typeof fromBm64
  ) {
    return createEffect(() => {
      return this.actions$.pipe(
        ofType(actions.loadMeasurements),
        mergeMap((action) =>
          this.bluetoothHelper.isInitializedInitialize().pipe(
            concatMap(() => this.bluetoothHelper.isConnectedConnectDiscover(action.address)),
            mergeMap(() =>
              this.bmService.getMeasurementData(action.address).pipe(
                withLatestFrom(this.store.pipe(select(fromReducer.selectAll))),
                map(([measurementData, entities]) => {
                  const diff = differenceBy(measurementData.data, entities, (m) => {
                    return m.device_time;
                  });

                  return measurementData.status === 'subscribed'
                    ? actions.loadMeasurementsSubscribed()
                    : measurementData.status === 'subscribedResult'
                    ? actions.loadMeasurementsSubscribedResult()
                    : actions.loadMeasurementsSuccess({
                        results: measurementData.data,
                        lastResults: this.getLastResults(diff),
                        deviceInformation: {
                          manufacturer: 'Beurer',
                          model:
                            action.type === Bm57Actions.loadMeasurements.type
                              ? 'BM57'
                              : action.type === Bm64Actions.loadMeasurements.type
                              ? 'BM64'
                              : 'BM54',
                          software_version: '',
                          hardware_version: '',
                          firmware_version: '',
                        },
                      });
                }),
                catchError((error) => of(actions.loadMeasurementsFailure({ error })))
              )
            ),
            takeUntil(this.ngUnsubscribe$)
          )
        )
      );
    });
  }

  createDeleteDevice$(
    actions: typeof Bm54Actions | typeof Bm57Actions | typeof Bm64Actions,
    fromReducer: typeof fromBm54 | typeof fromBm57 | typeof fromBm64
  ) {
    return createEffect(() => {
      return this.actions$.pipe(
        ofType(actions.deleteDevice),
        withLatestFrom(this.store.select(fromReducer.selectAddress)),
        map(([, address]) => {
          this.ngUnsubscribe$.next();
          this.ngUnsubscribe$.complete();
          return actions.deleteDeviceSuccess({ address });
        })
      );
    });
  }

  createDisconnectDevice$(
    actions: typeof Bm54Actions | typeof Bm57Actions | typeof Bm64Actions,
    fromReducer: typeof fromBm54 | typeof fromBm57 | typeof fromBm64
  ) {
    return createEffect(() => {
      return this.actions$.pipe(
        ofType(
          actions.deleteDeviceSuccess,
          actions.disconnectDevice,
          actions.loadMeasurementsSuccess
        ),
        withLatestFrom(this.store.pipe(select(fromReducer.selectAddress))),
        delay(3000),
        switchMap(([action, address]) => {
          let addressToDisconnect = address;
          // address in store may be null after deleteDeviceSuccess and disconnectDevice
          // use address from action then
          if (
            action.type === actions.deleteDeviceSuccess.type ||
            action.type === actions.disconnectDevice.type
          ) {
            addressToDisconnect = action.address;
          }

          return this.bluetoothHelper.disconnectClose(addressToDisconnect).pipe(
            map(() =>
              action.type === actions.loadMeasurementsSuccess.type
                ? actions.startScanning()
                : actions.disconnectDeviceSuccess()
            ),
            catchError((error) => of(actions.disconnectDeviceFailure({ error })))
          );
        })
      );
    });
  }

  // Returns measurements without invalid dates
  // and the latest result of user one and two with an invalid date, as last entries.
  // The selection of the last result of user one and two depends on the date in descending order.
  private getLastResults(measurements: BmResultBloodPressure[]) {
    const reversedMeasurements = [...measurements].reverse();

    // last measurements, with invalid date, of user 1 and 2 in descending order
    const lastResultUser1 = reversedMeasurements.find((m) => m.user === 1);
    const lastResultUser2 = reversedMeasurements.find((m) => m.user === 2);
    const lastResultsUser1AndUser2 = [
      isInvalidDate(lastResultUser1?.device_time) ? lastResultUser1 : undefined,
      isInvalidDate(lastResultUser2?.device_time) ? lastResultUser2 : undefined,
    ]
      .filter((m) => m !== undefined)
      .sort((a, b) => (dayjs(b.device_time) as any) - (dayjs(a.device_time) as any));

    // measurements without invalid dates and without lastResultsUser1AndUser2 results
    const measurementsFiltered = differenceBy(
      measurements,
      lastResultsUser1AndUser2.filter((m) => m !== undefined),
      (m) => m.device_time
    ).filter((m) => !isInvalidDate(m.device_time));

    return [...measurementsFiltered, ...lastResultsUser1AndUser2].filter((m) => m !== undefined);
  }
}
