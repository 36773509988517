import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import * as TelemedicineCentersActions from './telemedicine-centers.actions';
import { TelemedicineCenter } from 'app/models';

export const telemedicineCentersFeatureKey = 'telemedicineCenters';

export interface State extends EntityState<TelemedicineCenter> {
  loading: boolean;
}

export const adapter: EntityAdapter<TelemedicineCenter> = createEntityAdapter<TelemedicineCenter>();

export const initialState: State = adapter.getInitialState({
  loading: false,
});

const telemedicineCentersReducer = createReducer(
  initialState,
  on(TelemedicineCentersActions.loadTelemedicineCenters, (state) => ({
    ...state,
    loading: true,
  })),
  on(TelemedicineCentersActions.loadTelemedicineCentersSuccess, (state, action) =>
    adapter.upsertMany(action.telemedicineCenters, state)
  ),
  on(
    TelemedicineCentersActions.loadTelemedicineCentersSuccess,
    TelemedicineCentersActions.loadTelemedicineCentersFailure,
    (state) => ({
      ...state,
      loading: false,
    })
  )
);

export const selectFeature = createFeatureSelector<State>(telemedicineCentersFeatureKey);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export function reducer(state: State | undefined, action: Action) {
  return telemedicineCentersReducer(state, action);
}

export const selectCenters = createSelector(selectFeature, selectAll);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);
