import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { map, catchError, switchMap, mergeMap, withLatestFrom, concatMap } from 'rxjs/operators';
import { ApiService } from 'store/api/api.service';
import * as MedicationIntakesActions from './medication-intakes.actions';
import { MedicationIntake } from 'app/models';
import { Store, select } from '@ngrx/store';
import { differenceBy } from 'lodash-es';
import { catchComplete } from 'app/utils';
import * as fromMedicationIntakes from './medication-intakes.reducer';
import * as MedicationActions from 'store/medications-store/medications.actions';
import * as dayjs from 'dayjs';

@Injectable()
export class MedicationIntakesEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<fromMedicationIntakes.State>
  ) {}

  loadMedicationIntakes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationIntakesActions.loadMedicationIntakes),
      map((action) => action),
      mergeMap((action) =>
        this.apiService
          .getMedicationIntakes(action.from, action.to || dayjs().format('YYYY-MM-DD'), true, true)
          .pipe(
            map((data) => {
              return MedicationIntakesActions.loadMedicationIntakesSuccess({
                medicationIntakes: data.results,
              });
            }),
            catchError((error) =>
              of(MedicationIntakesActions.loadMedicationIntakesFailure({ error }))
            ),
            catchComplete(() =>
              of(MedicationIntakesActions.loadMedicationIntakesComplete({ action }))
            )
          )
      )
    );
  });

  loadTodaysMedicationIntakes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationIntakesActions.loadTodaysMedicationIntakes,
        MedicationActions.createMedicationSuccess,
        MedicationActions.updateMedicationSuccess,
        MedicationActions.deleteMedicationSuccess
      ),
      map((action) => action),
      switchMap(() =>
        this.apiService.getMedicationIntakes(dayjs().format('YYYY-MM-DD')).pipe(
          map((data) => {
            return MedicationIntakesActions.loadTodaysMedicationIntakesSuccess({
              medicationIntakes: data.results,
            });
          }),
          catchError((error) =>
            of(MedicationIntakesActions.loadTodaysMedicationIntakesFailure({ error }))
          )
        )
      )
    );
  });

  updateMedicationIntakeStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationIntakesActions.updateMedicationIntake),
      mergeMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(
              select(
                fromMedicationIntakes.selectMedicationIntakeByUuid({
                  uuid: action.medicationIntake.uuid,
                })
              )
            )
          )
        )
      ),
      map(([action, medicationIntake]) =>
        MedicationIntakesActions.updateMedicationIntakePartialSuccess({
          medicationIntake,
          updatedMedicationIntake: action.medicationIntake,
        })
      )
    );
  });

  updateMedicationIntake$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationIntakesActions.updateMedicationIntakePartialSuccess),
      concatMap((action) =>
        this.apiService.updateMedicationIntake(action.updatedMedicationIntake).pipe(
          map((updatedIntake: MedicationIntake) =>
            MedicationIntakesActions.updateMedicationIntakeSuccess({
              medicationIntake: updatedIntake,
            })
          ),
          catchError(() =>
            of(
              MedicationIntakesActions.updateMedicationIntakeFailure({
                medicationIntake: action.medicationIntake,
              })
            )
          )
        )
      )
    );
  });

  updateMedicationIntakesStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationIntakesActions.updateMedicationIntakes),
      mergeMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(
              select(
                fromMedicationIntakes.selectMedicationIntakesByUuid({
                  uuids: action.medicationIntakes.map((m) => m.uuid),
                })
              )
            )
          )
        )
      ),
      map(([action, entities]) => {
        // filter changed medication intakes by a passed property (changedProp)
        // to not send update requests for entities which haven't been changed.
        const medicationIntakes = action.changedProp
          ? differenceBy(entities, action.medicationIntakes, (m: MedicationIntake) => {
              return m[action.changedProp];
            })
          : entities;
        const updatedMedicationIntakes = action.changedProp
          ? action.medicationIntakes.filter((m) =>
              medicationIntakes.map((i) => i.uuid).includes(m.uuid)
            )
          : action.medicationIntakes;
        return MedicationIntakesActions.updateMedicationIntakesPartialSuccess({
          medicationIntakes,
          updatedMedicationIntakes,
        });
      })
    );
  });

  updateMedicationIntakes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationIntakesActions.updateMedicationIntakesPartialSuccess),
      concatMap((action) =>
        from(action.updatedMedicationIntakes).pipe(
          concatMap((updatedMedicationIntake) =>
            this.apiService.updateMedicationIntake(updatedMedicationIntake).pipe(
              map(() => MedicationIntakesActions.updateMedicationIntakesSuccess()),
              catchError(() => {
                const medicationIntake = action.medicationIntakes.filter(
                  (m) => m.uuid === updatedMedicationIntake.uuid
                )[0];
                return of(
                  MedicationIntakesActions.updateMedicationIntakesFailure({ medicationIntake })
                );
              })
            )
          )
        )
      )
    );
  });
}
