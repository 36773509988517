import { createAction, props } from '@ngrx/store';

export const loadDevices = createAction('[Devices/Storage] Load Devices');

export const startScan = createAction('[Devices] Start Scan', props<{ device: string }>());

export const startScanSuccess = createAction(
  '[Devices] Start Scan Success',
  props<{ name: string; address: string }>()
);

export const startScanFailure = createAction(
  '[Devices] Start Scan Failure',
  props<{ error: any }>()
);

export const startScanningFailure = createAction(
  '[Devices] Start Scanning Failure',
  props<{ error: any }>()
);

export const startScanManualMeasurements = createAction(
  '[Devices] Start Scan Manual Measurements',
  props<{ device: string }>()
);

export const startScanManualMeasurementsFailure = createAction(
  '[Devices] Start Scan Manual Measurements Failure',
  props<{ error: any }>()
);

export const stopScanningSuccess = createAction('[Devices] Stop Scanning Success');

export const stopScanningFailure = createAction(
  '[Devices] Stop Scanning Failure',
  props<{ error: any }>()
);

export const stopScan = createAction('[Devices] Stop Scan');

export const stopScanSuccess = createAction(
  '[Devices] Stop Scan Success',
  props<{ status: string }>()
);

export const stopScanFailure = createAction('[Devices] Stop Scan Failure', props<{ error: any }>());

export const pauseScanning = createAction('[Devices] Pause Scanning');

export const restartScanning = createAction('[Devices] Restart Scanning');

export const setStorageSuccess = createAction('[Devices] Set Storage Success');

export const deleteDevice = createAction('[Devices/API] Delete Device', props<{ id: string }>());

export const loadMeasurementsSuccess = createAction('[Devices] Load Measurements Success');

export const dismissAliveCor = createAction('[Devices] Dismiss Alive Cor');

export const dismissAliveCorSuccess = createAction('[Devices] Dismiss Alive Cor Success');

export const dismissAliveCorFailure = createAction(
  '[Devices] Dismiss Alive Cor Failure',
  props<{ error: any }>()
);
